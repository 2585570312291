import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileComponent } from "./profile.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { SharedModule } from "../../shared/shared.module";
import { BarRatingModule } from "ngx-bar-rating";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { TrackOrderComponent } from "./track-order/track-order.component";
import { NumbersOnlyDirective } from "./numbersonly.directive";

@NgModule({
  declarations: [ProfileComponent, TrackOrderComponent, NumbersOnlyDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    SharedModule,
    ProfileRoutingModule,
    BarRatingModule,
    AutocompleteLibModule, 
  ],
  exports: [TrackOrderComponent],
})
export class ProfileModule {}
