import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/configuration/app.constants';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';
import { HttpService } from 'src/app/services/http/http.service';
declare var $: any
@Component({
  selector: 'app-custom-testimonial',
  templateUrl: './custom-testimonial.component.html',
  styleUrls: ['./custom-testimonial.component.less']
})
export class CustomTestimonialComponent implements OnInit {


  @Input("type") type: any;

  getdata2
  getdata
  imgPth
  custom: any = [];

  constructor(
    public http: HttpService,
    private router : Router,
    public analyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.getcustom('custom');
  }



  //get all testmonial

  getcustom(value){
    this.http.get(`/admin/testimonial/fetch/${value}`).subscribe(
      data => {
 
        this.getdata2 = data;
        this.custom = this.getdata2.content.filter((x) => x?.pageType == 'custom');
        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      error => {

      });
  }

  //refresh owl slider after 200ms
  refreshSlider() {
    $(".test-1 #owl-testimonial").owlCarousel({
      items: 3,
      nav: false,
      dots: false,
      loop: true,
      responsive: {
        0: {
          items: 1,
          navigation: true,
          margin: 5,
          nav: true,
        },
        801: {
          items: 1,
          navigation: true,
          nav: true,
          margin: 50,
        },
        1025: {
          items: 3,
        }
      },
    });
  }

  toView(){
this.router.navigateByUrl('testimonial/custom')
  }

}
