import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmailVerifyComponent } from "./shared/components/email-verify/email-verify.component";
import { AuthGuard } from "src/app/services/auth/auth.guard";
import { ShareableComponent } from "./modules/shareable/shareable.component";
const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "profile",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "sharable/:productId",
    component: ShareableComponent,
  },
  {
    path: "category",
    loadChildren: () =>
      import("./modules/sub-category/sub-category.module").then(
        (m) => m.SubCategoryModule
      ),
  },
  {
    path: "delivery-filter",
    loadChildren: () =>
      import("./modules/sub-category/sub-category.module").then(
        (m) => m.SubCategoryModule
      ),
  },
  {
    path: "cakes",
    loadChildren: () =>
      import("./modules/classic-cakes/classic-cakes.module").then(
        (m) => m.ClassicCakesModule
      ),
  },
  {
    path: "details",
    loadChildren: () =>
      import("./modules/product-details/product-details.module").then(
        (m) => m.ProductDetailsModule
      ),
  },
  {
    path: "reviews",
    loadChildren: () =>
      import("./modules/reviews/reviews.module").then((m) => m.ReviewsModule),
  },
  {
    path: "checkout",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/checkout-page/checkout-page.module").then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: "page",
    loadChildren: () =>
      import("./modules/teams-and-policy/teams-and-policy.module").then(
        (m) => m.TeamsAndPolicyModule
      ),
  },
  {
    path: "blogs",
    loadChildren: () =>
      import("./modules/blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "enquiry-form",
    loadChildren: () =>
      import("./modules/enquiry-form/enquiry-form.module").then(
        (m) => m.EnquiryFormModule
      ),
  },
  {
    path: "email-verify",
    component: EmailVerifyComponent,
  },
  {
    path: "customise",
    loadChildren: () =>
      import("./modules/customise/customise.module").then((m) => m.CustomiseModule),
  },
  {
    path: '**',
    loadChildren: () =>
        import('./modules/error-404/error-404.module').then(
            (m) => m.Error404Module
        ),
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
