import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

@Component({
  selector: 'app-teams-and-policy',
  templateUrl: './teams-and-policy.component.html',
  styleUrls: ['./teams-and-policy.component.less']
})
export class TeamsAndPolicyComponent implements OnInit {

  constructor(
    private actRoute: ActivatedRoute,
    public analyticsService:GoogleAnalyticsService) { }

  public title
  ngOnInit(): void {
    // this.actRoute.paramMap.subscribe(params => {
    //   let id = params.get('id');
    //   switch (id) {
    //     case null:
    //       this.title = "Terms & conditions"
    //       this.analyticsService.pageView(this.title,`page/policy/1`,window.location.origin);
    //       break;
    //     case "1":
    //       this.title = "Terms & conditions"
    //       this.analyticsService.pageView(this.title,`page/policy/${id}`,window.location.origin);
    //       break;
    //     case "2":
    //       this.title = "Shipping Policy"
    //       this.analyticsService.pageView(this.title,`page/policy/${id}`,window.location.origin);
    //       break;
    //     case "3":
    //       this.title = "Cancellation Policy"
    //       this.analyticsService.pageView(this.title,`page/policy/${id}`,window.location.origin);
    //       break;
    //     case "4":
    //       this.title = "Privacy Policy"
    //       this.analyticsService.pageView(this.title,`page/policy/${id}`,window.location.origin);
    //       break;
    //     default:
    //       break;
    //   }
    // });
  }

}
