import { Observable } from "rxjs";
function checkNull(value: any) {
  if (value != null && value != undefined && value != "") {
    return true;
  } else {
    return false;
  }
}

function subTotal(price, offer, quantity) {
  return Number(price * quantity) - percentage(price, offer, quantity);
}

function percentage(price, offer, quantity) {
  return Number(((price * offer) / 100) * quantity);
}

function total(price, quantity) {
  return Number(price) * Number(quantity);
}

export class Calculation {
  public total: any;
  public discount: any;
  public subTotal: any;
  public isPanIdia: any;
  public delivery: any;
  public midNight: any;
  public coupon: any;
  public grandTotal: any;
  public netAmount: any;
  public quantity: any;
  public totalWeight;
  constructor(data: any, cart, panvalue, pincodes) {
    switch (cart) {
      case "hasPan":
        this.panDelivery(data, panvalue);
        break;
      case "hasnoPan":
        this.localDelivery(data, pincodes);
        break;
      default:
        this.localDelivery(data, pincodes);
        break;
    }
  }

  private localDelivery(data: any, pincodes) {
    this.isPanIdia = false;

    let lineItems: any[] = data.data.lineItemsInfo.map((ele) => {
      return {
        offer: percentage(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        subTotal: subTotal(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        total: total(ele.costDetails.itemPrice, ele.quantity),
        quantity: ele.quantity,
      };
    });

    // Total
    this.total = lineItems
      .map((ele) => ele.total)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //quantity
    this.quantity = lineItems
      .map((ele) => ele.quantity)
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Discount
    this.discount = lineItems
      .map((ele) => ele.offer)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //SubTotal
    this.subTotal = lineItems
      .map((ele) => ele.subTotal)
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Coupon code
    this.coupon = data.data.costDetails.discountDetails
      .map((ele) => Number(ele.discount))
      .reduce((a, b) => a + b, 0);

    //   Net Amount
    this.netAmount =
      Number(this.subTotal) - Number(this.coupon > 0 ? this.coupon : 0);

    this.localDeliveryAmount(data, pincodes).subscribe((res) => {
      this.grandTotal = Number(
        Number(
          (
            Number(this.subTotal) +
            Number(this.delivery) +
            Number(this.midNight ?? 0) -
            Number(this.coupon ?? 0)
          ).toFixed(2)
        )
      );
    });
  }

  private localDeliveryAmount(data: any, pincodes) {
    const isDoor = data.data.lineItemsInfo.some((ele) => ele.attribute.door == true);
    return new Observable((observer) => {
      if (data?.homeAddress?.pinCode && isDoor) {
        const pincode = data.homeAddress.pinCode;
        const chargesData = pincodes?.find(
          (ele) => String(ele?.pincode) == String(pincode)
        );
        this.delivery = chargesData?.deliveryCharge ?? 0;

        if (checkNull(chargesData) && chargesData?.enablemidDelivery) {
          const deliveryDates = data.data.lineItemsInfo
            .filter((ele) => ele.attribute.door == true)
            .map((ele) => ele.attribute.deliveryIn);
          const hasOne = deliveryDates
            .map((ele) => ele.split(" ")[1].split(":")[0])
            .some((ele) => Number(ele) >= 21);

          if (hasOne) {
            this.midNight = chargesData.middeliveryCharge;
          } else {
            this.midNight = 0;
          }
        } else {
          this.midNight = 0;
        }
      } else {
        this.delivery = 0;
        this.midNight = 0;
      }

      observer.next("ok");
    });
  }

  private panDelivery(data: any, panvalue) {
    this.isPanIdia = true;
    let lineItems: any[] = data.data.lineItemsInfo.map((ele) => {
      return {
        offer: percentage(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        subTotal: subTotal(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        total: total(ele.costDetails.itemPrice, ele.quantity),
        quantity: Number(ele.quantity),
      };
    });
    // Total
    this.total = lineItems
      .map((ele) => ele.total)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //quantity
    this.quantity = lineItems
      .map((ele) => Number(ele.quantity))
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Discount
    this.discount = lineItems
      .map((ele) => ele.offer)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //SubTotal
    this.subTotal = lineItems
      .map((ele) => ele.subTotal)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //Delivery Charge
    this.delivery =
      Number(
        panvalue?.deliveryCharge ? panvalue?.deliveryCharge : data.panDeliveryCharge
      ) ?? 0;

    // Coupon code
    this.coupon = data.data.costDetails.discountDetails
      .map((ele) => Number(ele.discount))
      .reduce((a, b) => a + b, 0);

    //   Net Amount
    this.netAmount =
      Number(this.subTotal) - Number(this.coupon > 0 ? this.coupon : 0);

    // Grand Total
    this.grandTotal = Number(
      Number(
        Number(this.subTotal) -
          Number(this.coupon) +
          Number(String(this.delivery) != "NaN" ? Number(this.delivery) : 0)
      ).toFixed(2)
    );
  }
}

export class OrdersCalculation {
  public total: any;
  public discount: any;
  public subTotal: any;
  public isPanIdia: any;
  public delivery: any;
  public midNight: any;
  public coupon: any;
  public grandTotal: any;
  public netAmount: any;
  public quantity: any;
  public totalWeight;
  constructor(data: any, cart, panvalue) {
    switch (cart) {
      case "hasPan":
        this.panDelivery(data, panvalue);
        break;
      case "hasnoPan":
        this.localDelivery(data);
        break;
      default:
        this.localDelivery(data);
        break;
    }
  }

  private localDelivery(data: any) {
    this.isPanIdia = false;

    let lineItems: any[] = data.data.lineItemsInfo.map((ele) => {
      return {
        offer: percentage(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        subTotal: subTotal(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        total: total(ele.costDetails.itemPrice, ele.quantity),
        quantity: ele.quantity,
      };
    });

    // Total
    this.total = lineItems
      .map((ele) => ele.total)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //quantity
    this.quantity = lineItems
      .map((ele) => ele.quantity)
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Discount
    this.discount = lineItems
      .map((ele) => ele.offer)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //SubTotal
    this.subTotal = lineItems
      .map((ele) => ele.subTotal)
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Coupon code
    this.coupon = data.data.costDetails.discountDetails
      .map((ele) => Number(ele.discount))
      .reduce((a, b) => a + b, 0);

    //   Net Amount
    this.netAmount =
      Number(this.subTotal) - Number(this.coupon > 0 ? this.coupon : 0);

    this.localDeliveryAmount(data).subscribe((res) => {
      this.grandTotal = Number(
        Number(
          (
            Number(this.subTotal) +
            Number(this.delivery) +
            Number(this.midNight ?? 0) -
            Number(this.coupon ?? 0)
          ).toFixed(2)
        )
      );
    });
  }

  private localDeliveryAmount(data: any) {
    const isDoor = data.data.lineItemsInfo.some((ele) => ele.attribute.door == true);
    return new Observable((observer) => {
      if (isDoor) {

        const chargesData: any = Number(data?.homeAddress?.deliveryCharge) ?? 0; //data.data.costDetails.delivery ?? 0
        this.delivery = chargesData ?? "";
       

        if (checkNull(data?.midnightCharge) && data?.midnightCharge > 0) {
          const deliveryDates = data.data.lineItemsInfo
            .filter((ele) => ele.attribute.door == true)
            .map((ele) => ele.attribute.deliveryIn);
          const hasOne = deliveryDates
            .map((ele) => ele.split(" ")[1].split(":")[0])
            .some((ele) => Number(ele) >= 21);

          if (hasOne) {
            this.midNight = Number(data?.midnightCharge);
          } else {
            this.midNight = 0;
          }
        } else {
          this.midNight = 0;
        }
      } else {
        this.delivery = 0;
        this.midNight = 0;
      }

      observer.next("ok");
    });
  }

  private panDelivery(data: any, panvalue) {
    this.isPanIdia = true;
    let lineItems: any[] = data.data.lineItemsInfo.map((ele) => {
      return {
        offer: percentage(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        subTotal: subTotal(
          ele.costDetails.itemPrice,
          ele.costDetails.offer,
          ele.quantity
        ),
        total: total(ele.costDetails.itemPrice, ele.quantity),
        quantity: Number(ele.quantity),
      };
    });
    // Total
    this.total = lineItems
      .map((ele) => ele.total)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //quantity
    this.quantity = lineItems
      .map((ele) => Number(ele.quantity))
      .reduce((a, b) => Number(a) + Number(b), 0);

    // Discount
    this.discount = lineItems
      .map((ele) => ele.offer)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //SubTotal
    this.subTotal = lineItems
      .map((ele) => ele.subTotal)
      .reduce((a, b) => Number(a) + Number(b), 0);

    //Delivery Charge
    this.delivery =
      Number(
        panvalue?.deliveryCharge ? panvalue?.deliveryCharge : data.panDeliveryCharge
      ) ?? 0;

    // Coupon code
    this.coupon = data.data.costDetails.discountDetails
      .map((ele) => Number(ele.discount))
      .reduce((a, b) => a + b, 0);

    //   Net Amount
    this.netAmount =
      Number(this.subTotal) - Number(this.coupon > 0 ? this.coupon : 0);

    // Grand Total
    this.grandTotal = Number(
      Number(
        Number(this.subTotal) -
          Number(this.coupon) +
          Number(String(this.delivery) != "NaN" ? Number(this.delivery) : 0)
      ).toFixed(2)
    );
  }
}
