import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./partials/footer/footer.component";
import { NavComponent } from "./partials/nav/nav.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgOtpInputModule } from "ng-otp-input";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { JwtInterceptor } from "./services/interceptor/jwt.interceptor";
import { ErrorInterceptor } from "./services/interceptor/error.interceptor";
import { GalleryModule } from "@ngx-gallery/core";
import { LightboxModule } from "ngx-lightbox";

import { SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { NumbersOnlyDirective } from "./shared/directives/numbersonly.directive";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { LoaderComponent1 } from "./shared/components/loader copy/loader.component";
import { ShareableComponent } from "./modules/shareable/shareable.component";
import { GlobalSearchTemplateComponent } from "./partials/nav/global-search-template/global-search-template.component";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavComponent,
    LoaderComponent1,
    ShareableComponent,
    GlobalSearchTemplateComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    AutocompleteLibModule,
    SharedModule,
    SocialLoginModule,
    GalleryModule,
    LightboxModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "512628770032-r6rqclh38llqpnh0ih59irdoep32168j.apps.googleusercontent.com",
              // "532872891219-vvjavtmv656paeu0uqqltlu36vgqeg5f.apps.googleusercontent.com",
              { plugin_name: "Puppy's Bakery Web" }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("1070439720495931"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    NumbersOnlyDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
