import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/configuration/app.constants";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { HttpService } from "src/app/services/http/http.service";

declare var $: any;
declare var $readMoreJS: any;
@Component({
  selector: "app-blogs",
  templateUrl: "./blogs.component.html",
  styleUrls: ["./blogs.component.less"],
})
export class BlogsComponent implements OnInit {

  imgPth
  constructor(
    public http: HttpService,
    private router: Router,
    public analyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.get();
  }

  //get blog list
  getData: any = [];
  get() {
    this.http.get('/admin/blogs?page=0').subscribe(
      data => {
        let result: any = data;
        this.getData = result.content;
      

        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      error => {

      });
  }

  blogReadMore(id) {
    this.router.navigate(['/blog', id])
  }

  blogReadMore2(id) {
    this.router.navigate(['/blog', id])
  }


  refreshSlider() {
    $(document).ready(function () {
   
      $readMoreJS.init({
        target: '.text:first-child',           // Selector of the element the plugin applies to (any CSS selector, eg: '#', '.'). Default: ''
        numOfWords: 30,               // Number of words to initially display (any number). Default: 50
        toggle: false,                 // If true, user can toggle between 'read more' and 'read less'. Default: true
        moreLink: '',    // The text of 'Read more' link. Default: 'read more ...'
        lessLink: ''         // The text of 'Read less' link. Default: 'read less'
      });
      $("#owl-blogs").owlCarousel({
        items: 3,
        nav: true,
        responsive: {
          0: {
            items: 1,
            navigation: true,
            margin: 5,
            nav: true,
            dots: true,
            navText: [
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
            ],
          },
          640: {
            items: 1,
            navigation: true,
            dots: false,
            nav: true,
            margin: 20,
            navText: ["<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>", "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>"]
          },
          768: {
            items: 2,
            navigation: true,
            dots: false,
            nav: true,
            margin: 20,
            navText: ["<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>", "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>"]
          },
          1200: {
            items: 3,
            navigation: true,
            dots: false,
            nav: true,
            margin: 20,
            navText: ["<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>", "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>"]
          }
        },
        navigation: true,
        lazyLoad: true,
        pagination: false,
        scrollPerPage: true,
      });
    });
  }
  showAll(){
    this.analyticsService.commonEvents("Onclick",{'event_category': 'Route Blogs List Page',
    'event_label': 'Show All Blogs List',
    'value': 'Route Blogs list page'});
  }
}
