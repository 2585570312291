<section class="blog-view">
  <div class="top-rounded-border"></div>




  <div class="container-fluid">
    <h4 class="text-center">Blogs</h4>

    <!--row-->
    <div
      class="row mt-3 pxd-5"
      infiniteScroll
      [infiniteScrollDistance]="0.1"
      (scrolled)="onScroll()"
      [alwaysCallback]="true"
    >
      <div class="col-lg-4 col-md-6" *ngFor="let data of getData">
        <div class="card blogs-card ">
          <img
            class="card-img-top"
            alt="Thumbnail [100%x225]"
            style="width: 100%; display: block"
            src="{{ data?.images }}"
            data-holder-rendered="true"
          />
          <div class="card-body">
            <div
              class="d-flex align-items-center justify-content-between"
              style="margin-left: 8px"
            >
              <h6 style="font-weight: 700 !important">
                by <small>{{ data?.author }} </small> <span>, {{ data?.updatedOn|date:'mediumDate'}}</span>
               
              </h6>

              
            </div>
            <h5>{{ data?.title }}</h5>
            <p class="card-text" style="font-weight: 700 !important">
              <span class="text text-trim" [innerHTML]="data?.content"></span>
            </p>
            <div class="" style="margin-left: 17px">
              <button type="button" class="btn my-3">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end rows-->
  </div>
  <!-- loader gif -->
  <div class="text-center" *ngIf="loader">
    <img
      src="/assets/images/gif-infinite-scroll3.gif"
      style="height: 100px; width: auto"
    />
  </div>
</section>
