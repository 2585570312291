import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TeamsAndPolicyRoutingModule } from "./teams-and-policy-routing.module";
import { PressReleaseComponent } from "./components/press-release/press-release.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextDecerationPipe } from "./textDeceration/text-deceration.pipe";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { BranchDetailsComponent } from "./components/branch-details/branch-details.component";
import { OurStoryComponent } from "./components/our-story/our-story.component";
import { GalleryModule } from "@ngx-gallery/core";
import { LightboxModule } from "@ngx-gallery/lightbox";
import { ShippingPolicyComponent } from "./components/shipping-policy/shipping-policy.component";
import { CancellationPolicyComponent } from "./components/cancellation-policy/cancellation-policy.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
@NgModule({
  declarations: [
    PressReleaseComponent,
    ContactUsComponent,
    TextDecerationPipe,
    BranchDetailsComponent,
    OurStoryComponent,
    ShippingPolicyComponent,
    CancellationPolicyComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    CommonModule,
    TeamsAndPolicyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    LightboxModule,
    GalleryModule,
  ],
})
export class TeamsAndPolicyModule {}
