import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.less']
})
export class BranchDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  imgUrl = "https://s3-ap-south-1.amazonaws.com/puppysimages/1619592812288.jpg"
}
