import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

@Component({
  selector: 'app-testimonial-view',
  templateUrl: './testimonial-view.component.html',
  styleUrls: ['./testimonial-view.component.less']
})
export class TestimonialViewComponent implements OnInit {
id:any
  testimonial: any=[];
  gifLoader:boolean=true
  // throttle = 300;
  // scrollDistance = 1;
  // scrollUpDistance = 2;
  showloader: boolean;
  testimonial2: any=[];
  constructor(private activatedRoute : ActivatedRoute,
    private http : HttpService,
    public analyticsService:GoogleAnalyticsService,) {
   }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.analyticsService.pageView("Testimonial Home View",`testimonial/${this.id}`,window.location.origin);
    // this.getTestimonials()
   this.searchAll()
    
  }

public page=1
public totalPages
public
 noScroll:boolean=false
  onScroll(){
if(this.noScroll==false){
  this.page++;
  this.searchAll() 
 
}


  }
// gifLoader:boolean
  searchAll(){
    let obj ={
      pageSize: 2,
       search:this.id,
       pageNo:this.page
      }
      this.gifLoader=true
    this.http.getList('/admin/testimonial/Pagination',obj).subscribe((res:any)=>{
      this.testimonial2=res.content
      this.testimonial=[...this.testimonial,...this.testimonial2]
     setTimeout(() => {
      this.gifLoader=false
     }, 800); 
    
    if(res.currentPageNo >= res.totalPages+1){
      this.noScroll=true
      this.gifLoader=false
    }
      
    })
  }



  getTestimonials(){
this.http.get(`/admin/testimonial/fetch/${this.id}`).subscribe((res:any)=>{
 
  this.testimonial=res.content
  
})

  }

}
