import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "../../services/http/http.service";
declare var $: any;
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { AppConstants } from "../../configuration/app.constants";
import { sweetAlert } from "../../shared/function/toaster";
import Swal from "sweetalert2";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { checkNull } from "../product-details/date-and-time-validations";
import {
  Calculation,
  OrdersCalculation,
} from "../checkout-page/service/calculation";
import {
  ORDER,
  StatesDistrictsService,
} from "../checkout-page/service/states-districts.service";
import { ShipRocketService } from "../checkout-page/service/ship-rocket.service";
import { LoaderService } from "src/app/services/memory-services/loader.service";
import { UserService } from "src/app/services/memory-services/user.service";
import moment from "moment";
var domtoimage = require("dom-to-image");
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.less"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("reviewForm") reviewForm: NgForm;
  public loading = true;
  public submitted = false;
  public form: FormGroup;
  public remainderForm: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public imgUrl: any;
  public imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
  public currentWindow: string;
  public reportOrderId: any;
  public agentName;
  trackingId;
  trackingUrl;
  public myOrderList: any;
  public myOrderList2: any;
  GST: number;
  NetAmount: number;

  constructor(
    private actRoute: ActivatedRoute,
    public http: HttpService,
    private formBuilder: FormBuilder,
    public addCartService: AddCartService,
    public analyticsService: GoogleAnalyticsService,
    public stateService: StatesDistrictsService,
    private shiprocketService: ShipRocketService,
    private loader: LoaderService,
    private userService: UserService,
    private ngzone: NgZone) { }

  ngOnInit(): void {
    this.panControl = new FormControl("");
    this.currentWindow = this.actRoute.snapshot.params.id;
    this.getStates();
    this.openDate();
    //show and hide div based on url
    if (this.currentWindow == undefined) {
      this.currentWindow = "profile";
      this.accordionOpen("profile");
    } else {
      this.actRoute.paramMap.subscribe((params) => {
        this.currentWindow = params.get("id");
        if (this.currentWindow == "address") this.accordionOpen("address");
        if (this.currentWindow == "order") this.accordionOpen("order");
        if (this.currentWindow == "loyalty") this.accordionOpen("loyalty");
        if (this.currentWindow == "remainder") this.accordionOpen("remainder");
        else this.accordionOpen("order");
      });
    }

    this.getAllPincodes();

    this.imgUrl = "";
    this.loading = false;
    this.submitted = false;
    //profile detail form
    this.form = this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.minLength(2), Validators.maxLength(25)],
      ],
      lastName: [""],
      gender: ["", Validators.required],
      birthdayDate: [""],
      email: ["", [ Validators.email]],
      phoneNumber: ["", [ Validators.pattern("[0-9]{10,10}$")]],
      imageUrl: [""],
      oldPassword: ["", Validators.minLength(6)],
      newPassword: ["", Validators.minLength(6)],
      confirmPassword: ["", Validators.minLength(6)],
      updatePicture: [""],
      updateUserPassword: [""],
    });

    this.remainderForm = this.formBuilder.group({
      title: ["", Validators.required],
      date: ["", Validators.required],
      description: [""],
      remaindEveryYear: [false],
      type: ["", Validators.required],
    });
    this.get();
    this.getAddress();
    this.emptyAddressForm();

    this.getLoyalty();
    this.getRemainder();
  }

  pincodes1: any = [];
  getAllPincodes() {
    this.userService.pincode.subscribe((res: any) => {
      new Promise((resolve, reject) => {
        this.pincodes1 = res;
        resolve(true);
      }).then(() => {
        this.getMyOrder();
      });
    });
  }

  allStates: any;
  states: any[] = [];

  getStates() {
    this.allStates = this.stateService.getStatesandDistricts();
    this.states = this.stateService
      .getStatesandDistricts()
      .map((ele: any) => ele?.state);
  }
  districts: any[] = [];
  stateChanged(data: any) {
    this.addressForm.get("city").setValue("");
    this.addressForm.get("city").updateValueAndValidity();
    let dst = this.allStates.find((ele: any) => String(ele?.state) === String(data));
    this.districts = dst?.districts;
  }

  checkNull(data) {
    return checkNull(data);
  }

  //////////////////////////////My Order Section/////////////////////////////////
  //get all users order
  getMyOrder() {
    this.loading = true;
    this.http.get("/order/all").subscribe(
      (data) => {
        this.loading = false;
        this.myOrderList2 = data;

        this.myOrderList = this.myOrderList2;
        console.log(this.myOrderList, "my orderslist");
        this.myOrderList.map((x) => {
          x.hasHome = x.data?.lineItemsInfo?.some(
            (ele) => ele.attribute?.door == true
          );
          x.hasStore = x.data?.lineItemsInfo?.some(
            (ele) => ele.attribute?.store == true
          );
          if (x.status) {
            switch (x.status) {
              case "InCart":
                x.statusDisplayName = "Abandoned Carts";
                break;
              case "Confirmed":
                x.statusDisplayName = "Accepted";
                break;
              case "Baking":
                x.statusDisplayName = "Cake is Baking";
                break;
              case "Shipped":
                x.statusDisplayName = "On Transit";
                break;
              default:
                x.statusDisplayName = x.status;
                break;
            }
          }
        });

        this.myOrderList.map((x) => {
          x.calculation = this.setCalculation(x);
          x.data.lineItemsInfo.map((y) => {
            if (y.product.productImages) {
              y.product.productImages = y.product.productImages.split(",")[0];
            }
            if (y.product.sku) {
              y.product.skuType = y.product.sku.replace(/[0-9,-]/g, "");
            }
          });
        });
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Try Again");
      }
    );
  }
  trackOrder;
  random;

  openTrackOrder(data) {
    if (checkNull(data?.shipmentId)) {
      this.trackOrder = data;
      this.random = Math.random();
      $("#trackOrderModal").modal("show");
    } else {
      Swal.fire({
        title: "<strong style='color:#ff9966'>Track Order</strong>",
        // style="color:#fc98b2"
        html: ` <b>NO TRACKING ACTIVITIES FOUND</b>`,
        // icon: "warning",
        showCancelButton: false,
        confirmButtonText: `OKAY`,
        allowOutsideClick: true,
        confirmButtonColor: "#fc98b2",
        cancelButtonColor: "#fc98b2",
        cancelButtonText: 'Okay <i class="fa fa-check" aria-hidden="true"></i>',
      });
    }

    // data-toggle="modal" data-target="#trackOrderModal"
  }

  setCalculation(data: any) {
    let result = data;
    let pincode: any;
    function cartType() {
      const zeroLength = result?.data?.lineItemsInfo?.length == 0;
      const hasnoPan = result?.data?.lineItemsInfo
        .map((ele) => ele.attribute.deliveryIn)
        .every((ele) => checkNull(ele));
      const hasPan = result?.data?.lineItemsInfo
        .map((ele) => ele.attribute.deliveryIn)
        .every((ele) => !checkNull(ele));
      switch (true) {
        case zeroLength:
          {
            pincode = "";
            return "zeroLength";
          }
          break;
        case hasnoPan:
          {
            pincode = "";
            return "hasnoPan";
          }
          break;
        case hasPan:
          {
            pincode = data.homeAddress.pinCode;
            return "hasPan";
          }
          break;
        default: {
          pincode = "";
          return "hasboth";
        }
      }
    }
    return new OrdersCalculation(data, cartType(), pincode);
  }

  //show and hide shipping indo
  showShippingInfo(val) {
    this.agentName = val?.agentName;
    this.trackingId = val?.trackingId;
    this.trackingUrl = val?.trackingUrl;
    $("#shipping").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "View Shipping Details",
      event_label: "View Shipping Details",
      value: "View Shipping Details",
    });
  }

  //order report open popup
  reportOrder(data) {
    this.reportOrderId = data;
    $("#reportOrderModal").modal("show");
    if (data.issueRaised == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "View Report for Ordered Particular Product",
        event_label: "View Report for Ordered Particular Product",
        value: "View Report for Ordered Particular Product",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Report to Ordered Particular Product",
        event_label: "Report to Ordered Particular Product",
        value: "Report to Ordered Particular Product",
      });
    }
  }

  //send report to server
  sendReport(data) {
    if (!data) {
      sweetAlert("error", "Report text is required");
      return;
    }
    let body = {
      comments: data,
      orderId: this.reportOrderId.orderId,
    };
    this.loading = true;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Send Report For Particular Product",
      event_label: "Send Report For Particular Product",
      value: "Send Report For Particular Product",
    });
    this.http.post("/user/order/issue", body).subscribe(
      (data) => {
        $("#reportOrderModal").modal("hide");
        this.loading = false;
        sweetAlert(
          "success",
          "Issue has been successfully raised,our team will contact you soon"
        );
        this.myOrderList.map((x) => {
          if (x.orderId == this.reportOrderId.orderId) {
            x.issueRaised = true;
          }
        });
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", error.message);
      }
    );
  }

  //Order Review Section
  public productInfo;
  addReviewModal(item) {
    this.productInfo = item;
    console.log(item);

    $("#addReview").modal("show");
    if (item.rating != null) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "View Review to Particular Product",
        event_label: "View Review to Particular Product",
        value: "View Review to Particular Product",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Add Review to Particular Product",
        event_label: "Add Review to Particular Product",
        value: "Add Review to Particular Product",
      });
    }
  }

  addReview(value) {
    let body = {
      comments: value.review,
      productId: this.productInfo.product.productId,
      rating: true,
      stars: value.rating,
      status: "pending",
    };
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Save Review Detail to Particular Product",
      event_label: "Save Review Detail to Particular Product",
      value: "Save Review Detail to Particular Product",
    });
    this.http.post("/user/ratings", body).subscribe(
      (data: any) => {
        sweetAlert("success", data.message);
        $("#addReview").modal("hide");
        this.reviewForm.resetForm();
        this.productInfo.rating = data.content;
        this.getMyOrder();
      },
      (error) => {
        sweetAlert("error", "Try Again");
      }
    );
  }

  public viewReview;
  viewReviewModal(item) {
    this.viewReview = item;
    $("#viewReview").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "View Product Review",
      event_label: "View Product Review",
      value: "View Product Review",
    });
  }

  public deleteReview(id) {
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Delete Review Detail to Particular Product",
      event_label: "Delete Review Detail to Particular Product",
      value: "Delete Review Detail to Particular Product",
    });
    this.http.delete(`/admin/ratings/${id}`).subscribe(
      (data: any) => {
        sweetAlert("success", data.message);
        $("#viewReview").modal("hide");
        this.viewReview.rating = null;
        this.getMyOrder();
      },
      (error) => {
        sweetAlert("error", "Try Again");
      }
    );
  }
  //Order Review Section End

  //////////////////////////////End My Order Section/////////////////////////////////

  ////////////////////////////////My Address Section////////////////////////////////
  addressList;
  isAddressEdited = false;
  submitted2 = false;
  addressForm: FormGroup;
  //get current user address from server
  getAddress() {
    this.loading = true;
    this.http.get("/user/" + this.currentUser.id + "/shipping-info").subscribe(
      (data) => {
        this.addressList = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Try Again");
      }
    );
  }

  //initialize address form
  emptyAddressForm() {
    this.isAddressEdited = false;
    this.submitted2 = false;
    this.addressForm = this.formBuilder.group({
      alternativePhoneNumber: [null],
      city: ["", Validators.required],
      email: ["", [Validators.required,Validators.email]],
      id: [""],
      line1: ["", Validators.required],
      name: ["", Validators.required],
      phoneNumber: ["", [Validators.required,Validators.pattern("[0-9]{10,10}$")]],
      pinCode: ["", [Validators.required, Validators.pattern("^[0-9]{6,6}$")]],
      saveAddress: [true],
      state: ["", Validators.required],
    });
  }

  addressListDiv = true;
  //edit address
  editAddress(item) {
    this.addressListDiv = false;
    this.isAddressEdited = true;
    this.submitted2 = false;
    this.addressFormDiv = true;
    this.stateChanged(item?.state);
    this.addressForm = this.formBuilder.group({
      alternativePhoneNumber: [item.alternativePhoneNumber],
      city: [item.city, Validators.required],
      email: [item.email, [Validators.required,Validators.email]],
      id: [item.id],
      line1: [item.line1, Validators.required],
      name: [item.name, Validators.required],
      phoneNumber: [item.phoneNumber, [Validators.required,Validators.pattern("[0-9]{10,10}$")]],
      pinCode: [
        item.pinCode,
        [Validators.required, Validators.pattern("^[0-9]{6,6}$")],
      ],
      saveAddress: [true],
      state: [item.state, Validators.required],
    });
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Edit My Address Form",
      event_label: "Edit My Address Form",
      value: "Edit My Address Form",
    });
  }

  addressFormDiv = false;
  //show address div
  showAddAddress() {
    this.emptyAddressForm();
    this.addressFormDiv = !this.addressFormDiv;
    this.addressListDiv = true;
    if (this.addressFormDiv == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Add My Address Form Open",
        event_label: "Add My Address Form Open",
        value: "Add My Address Form Open",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Add My Address Form Close",
        event_label: "Add My Address Form Close",
        value: "Add My Address Form Close",
      });
    }
  }

  get a() {
    return this.addressForm.controls;
  }

  panControl: FormControl;
  checkPincode() {
    const pincode = this.addressForm.get("pinCode") as FormControl;

    if (pincode.valid) {
      if (
        RegExp(/^[1-9][0-9]{5}$/).test(pincode.value) &&
        !this.pincodes1.some((ele) => String(ele.pincode) == String(pincode.value))
      ) {
        this.panControl.setValue(pincode.value);
        this.panControl.updateValueAndValidity();
      } else {
        this.panControl.setValue("");
        this.panControl.updateValueAndValidity();
      }
    } else {
      this.panControl.setValue("");
      this.panControl.updateValueAndValidity();
    }
  }

  localAddress() {
    this.loader.open();
    let form = this.addressForm.value;

    form["deliveryId"] = this.pincodes1.find(
      (ele) => String(ele.pincode) == String(form.pinCode)
    ).deliveryId;
    if (this.isAddressEdited == true) {
      this.http
        .put("/user/" + this.currentUser.id + "/shipping-info/" + form["id"], form)
        .subscribe(
          (data) => {
            this.loader.close();
            this.loading = false;
            sweetAlert("success", "Address Added Successfully");
            this.emptyAddressForm();
            this.addressFormDiv = false;
            this.addressListDiv = true;
            this.getAddress();
            this.submitted2 = false;
          },
          (error) => {
            this.loader.close();
            sweetAlert("error", "Try Again");
            this.loading = false;
          }
        );
    } else {
      this.http
        .post("/user/" + this.currentUser.id + "/shipping-info", form)
        .subscribe(
          (data) => {
            this.loader.close();
            this.loading = false;
            sweetAlert("success", "Address Added Successfully");
            this.emptyAddressForm();
            this.addressFormDiv = false;
            this.getAddress();
            this.submitted2 = false;
          },
          (error) => {
            this.loader.close();
            sweetAlert("error", "Try Again");
            this.loading = false;
          }
        );
    }
  }
  available = false;
  checkDeliveryAvailability() {
    let form: any = this.addressForm.value;
    this.loader.open();
    this.shiprocketService
      .getDeliveryPartners({
        weight: 1,
        deliveryPostcode: this.panControl.value,
      })
      .subscribe(
        (res) => {
          if (res.data.length > 0) {
            form.deliveryId = "0";
            form.pinCode = this.panControl.value;
            if (this.isAddressEdited == true) {
              this.http
                .put(
                  "/user/" + this.currentUser.id + "/shipping-info/" + form["id"],
                  form
                )
                .subscribe(
                  (data) => {
                    this.loader.close();
                    this.loading = false;
                    sweetAlert("success", "Address Added Successfully");
                    this.emptyAddressForm();
                    this.addressFormDiv = false;
                    this.addressListDiv = true;
                    this.getAddress();
                    this.submitted2 = false;
                  },
                  (error) => {
                    this.loader.close();
                    sweetAlert("error", "Try Again");
                    this.loading = false;
                  }
                );
            } else {
              this.http
                .post("/user/" + this.currentUser.id + "/shipping-info", form)
                .subscribe(
                  (data) => {
                    this.loader.close();
                    sweetAlert("success", "Address Added Successfully");
                    this.emptyAddressForm();
                    this.addressFormDiv = false;
                    this.getAddress();
                    this.submitted2 = false;
                  },
                  (error) => {
                    sweetAlert("error", "Try Again");
                    this.loader.close();
                  }
                );
            }
          } else {
            this.loader.close();
            sweetAlert("error", "Delivery is not available to this area.");
          }
        },
        (err) => {
          this.loader.close();
          this.available = false;
        }
      );
  }

  addAddress() {
    this.submitted2 = true;
    if (this.addressForm.invalid) {
      return;
    }
    if (this.panControl.valid && checkNull(this.panControl.value)) {
      this.checkDeliveryAvailability();
    } else {
      this.localAddress();
    }
  }

  //Save or Edit Address
  saveAddress() {
    this.submitted2 = true;
    if (this.addressForm.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddressEdited == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Edit My Address",
        event_label: "Edit My Address",
        value: "Edit My Address",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Save My Address",
        event_label: "Save My Address",
        value: "Save My Address",
      });
    }
    //check this address have eligible to delivery
    this.http
      .get("/user/delivery/" + this.addressForm.controls["pinCode"].value)
      .subscribe(
        (data) => {
          if (this.isAddressEdited == true) {
            this.http
              .put(
                "/user/" +
                  this.currentUser.id +
                  "/shipping-info/" +
                  this.addressForm.controls["id"].value,
                this.addressForm.value
              )
              .subscribe(
                (data) => {
                  this.loading = false;
                  sweetAlert("success", "Address Added Successfully");
                  this.emptyAddressForm();
                  this.addressFormDiv = false;
                  this.addressListDiv = true;
                  this.getAddress();
                  this.submitted2 = false;
                },
                (error) => {
                  sweetAlert("error", "Try Again");
                  this.loading = false;
                }
              );
          } else {
            this.http
              .post(
                "/user/" + this.currentUser.id + "/shipping-info",
                this.addressForm.value
              )
              .subscribe(
                (data) => {
                  this.loading = false;
                  sweetAlert("success", "Address Added Successfully");
                  this.emptyAddressForm();
                  this.addressFormDiv = false;
                  this.getAddress();
                  this.submitted2 = false;
                },
                (error) => {
                  sweetAlert("error", "Try Again");
                  this.loading = false;
                }
              );
          }
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            title: "Delivery Not Available To This Address",
            confirmButtonColor: "#fe9ab3",
          });
          return;
        }
      );
  }

  //delete specific address
  deleteAddress(id) {
    this.loading = true;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Delete My Address",
      event_label: "Delete My Address",
      value: "Delete My Address",
    });
    this.http
      .delete("/user/" + this.currentUser.id + "/shipping-info/" + id)
      .subscribe(
        (data) => {
          sweetAlert("success", "Address Deleted Successfully");
          this.emptyAddressForm();
          this.getAddress();
          this.loading = false;
        },
        (error) => {
          sweetAlert("error", "Try Again");
          this.loading = false;
        }
      );
  }

  //////////////////////////////////////////////////End Address Section ////////////////////////////////////////////////////

  //////////////////////////////////////////////////My Reminder Section ////////////////////////////////////////////////////

  remainderFormDiv = false;
  allRemainder: any;
  submitted3 = false;

  //show reminder form
  showSetRemainder() {
    this.remainderFormDiv = !this.remainderFormDiv;
    if (this.remainderFormDiv == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Set Reminder Form Open",
        event_label: "Set Reminder Form Open",
        value: "Set Reminder Form Open",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Set Reminder Form Close",
        event_label: "Set Reminder Form Close",
        value: "Set Reminder Form Close",
      });
    }
  }

  get b() {
    return this.remainderForm.controls;
  }
  //set reminder
  saveRemainder() {
    this.submitted3 = true;
    if (this.remainderForm.invalid) {
      return;
    }
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Save Reminder",
      event_label: "Save Reminder",
      value: "Save Reminder",
    });
    this.loading = true;
    let body = {
      date: new Date(this.remainderForm.value.date).toISOString(),
      description: this.remainderForm.value.description,
      name: this.remainderForm.value.title,
      remaindEveryYear: this.remainderForm.value.remaindEveryYear,
      userId: this.currentUser.id,
      type: this.remainderForm.value.type,
    };
    this.http.post("/user/remainder", body).subscribe(
      (data) => {
        this.loading = false;
        this.getRemainder();
        this.remainderFormDiv = false;
        this.emptyRemainderFor();
        sweetAlert("success", "Remainder Added Successfully");
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", error.message);
      }
    );
  }

  //Get Current user reminder list
  getRemainder() {
    this.http.get("/user/remainder").subscribe(
      (data: any) => {
        this.allRemainder = data.content;
      },
      (error) => {
        sweetAlert("error", "Try Again");
      }
    );
  }

  //Delete Current user reminder
  deleteRemainder(id) {
    this.loading = true;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Delete Current User Reminder",
      event_label: "Delete Current User Reminder",
      value: "Delete Current User Reminder",
    });
    this.http.post(`/user/remainder/${id}`, "").subscribe(
      (data: any) => {
        this.getRemainder();
        this.loading = false;
        sweetAlert("success", "Remainder Deleted Successfully");
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Try Again");
      }
    );
  }
  //initialize Reminder form
  emptyRemainderFor() {
    this.submitted3 = false;
    this.remainderForm = this.formBuilder.group({
      title: ["", Validators.required],
      date: ["", Validators.required],
      description: [""],
      remaindEveryYear: [false],
      type: ["", Validators.required],
    });
  }

  handle() {
    let url: string = this.imgUrl;
    this.imgUrl = url.replace(
      "https://api.puppysbakery.com",
      AppConstants.SERVER_CONFIG[AppConstants.SERVER_CONFIG.USE_URL]
    );
  }
  //////////////////////////////////////////////////End My Reminder Section ////////////////////////////////////////////////////

  //////////////////////////////////////////////////My Profile Section ////////////////////////////////////////////////////
  getdata2;
  getdata;
  getLocal;
  putDataMsg;
  PasswordChangeError;
  //get current user details
  get() {
    this.loading = true;
    this.http.get("/user").subscribe(
      (data) => {
        this.getdata = data;
        this.userService.setCurrentUser();

        this.updateForm();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Try Again");
      }
    );
  }

  //autofill users form
  updateForm() {
    this.form = this.formBuilder.group({
      firstName: [
        this.getdata.firstName,
        [Validators.required, Validators.minLength(2), Validators.maxLength(25)],
      ],
      lastName: [this.getdata.lastName, [Validators.required]],
      gender: [this.getdata.gender, Validators.required],
      birthdayDate: [this.getdata.birthdayDate ? moment(this.getdata.birthdayDate).format("DD-MM-YYYY") : ""],
      email: [this.getdata.email, [, Validators.email]],
      phoneNumber: [
        this.getdata.phoneNumber,
        [ Validators.pattern("[0-9]{10,10}$")],
      ],
      imageUrl: [this.getdata.imageUrl],
      oldPassword: ["", Validators.minLength(6)],
      newPassword: ["", Validators.minLength(6)],
      confirmPassword: ["", Validators.minLength(6)],
      updatePicture: [""],
      updateUserPassword: [""],
    });

    if (this.getdata.imageUrl) {
      this.imgUrl = AppConstants.SERVER_CONFIG.IMG_URL + this.getdata.imageUrl;
    }
  }

  public changePassord: Boolean = false;
  showChangePassword() {
    this.changePassord = !this.changePassord;
  }

  get f() {
    return this.form.controls;
  }
  //update profile details
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.PasswordChangeError = "";
    //change password
    if (
      this.form.controls["oldPassword"].value != "" &&
      this.form.controls["oldPassword"].value != ""
    ) {
      if (
        this.form.controls["newPassword"].value ==
        this.form.controls["confirmPassword"].value
      ) {
        this.form.controls["updateUserPassword"].setValue("yes");
      } else {
        this.PasswordChangeError = "show";
        return;
      }
    }
    function dates (value) {
      let dates = value.split('-');
      return `${dates[2] + '-' + dates[1] + '-' + dates[0]}`
    }
    this.analyticsService.commonEvents("Onclick", {
      event_category: "My Profile Detail Updated",
      event_label: "My Profile Detail Updated",
      value: "My Profile Detail Updated",
    });
    console.log(this.form.value,'this.form.value')
    let obj = this.form.value;
    obj.birthdayDate = checkNull(this.form.get('birthdayDate').value) ? dates(this.form.get('birthdayDate').value):'';
    this.loading = true;
    this.http.put("/user", this.form.value).subscribe(
      (data) => {
        //refresh User Profile data in header
        this.getLocal = JSON.parse(localStorage.getItem("currentUser"));
        this.getLocal.firstName = this.form.controls["firstName"].value;
        this.getLocal.lastName = this.form.controls["lastName"].value;
        this.getLocal.imageUrl = this.form.controls["imageUrl"].value;
        this.getLocal.gender = this.form.controls["gender"].value;
        localStorage.setItem("currentUser", JSON.stringify(this.getLocal));
        this.addCartService.setProfile("someValue");
        this.putDataMsg = data;
        this.loading = false;
        this.get();
        this.userService.setCurrentUser();
        if (this.putDataMsg.status == 1011) {
          sweetAlert("error", this.putDataMsg.message);
          return;
        }
        sweetAlert("success", this.putDataMsg.message);
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", error.message);
      }
    );
  }

  //profile image Section

  openImgModal() {
    this.croppedImage = "";
    this.imageChangedEvent = "";
    $("#imageModal").modal("show");
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    // this.imgUrl = '';
    // this.imageChangedEvent = event;
    let file: File = event.target.files[0];
    if (file?.size <= 1024 * 1024 * 2) {
      this.imgUrl = "";
      this.imageChangedEvent = event;
    } else {
      sweetAlert("error", "Image size should not exceed 2MB!");
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  deleteImage() {
    this.croppedImage = "";
    this.imageChangedEvent = "";
    this.imgUrl = "";
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    currentUser.imageUrl = "";
    localStorage.setItem("currentUser", JSON.stringify(currentUser));

    this.addCartService.setProfile("someValue");

    this.analyticsService.commonEvents("Onclick", {
      event_category: "Delete Profile Image",
      event_label: "Delete Profile Image",
      value: "Delete Profile Image",
    });
  }

  Imgfile;
  imgForm;
  //upload image and get url
  uploadImage() {
    this.imgForm = this.formBuilder.group({
      imageUrl: [""],
      updatePicture: ["yes"],
    });
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Upload Profile Image",
      event_label: "Upload Profile Image",
      value: "Upload Profile Image",
    });
    if (this.croppedImage.length > 500) {
      this.Imgfile = this.dataURLtoFile(this.croppedImage, "tempname.jpeg");
      const formData: FormData = new FormData();
      formData.append("image", this.Imgfile, "tempname.jpeg");
      formData.append('fileType', 'profile');
      formData.append('thumbnail', 'true');
      this.loading = true;
      this.http.post("/uploadimage", formData).subscribe(
        (data) => {
          $("#imageModal").modal("hide");
          this.path = data;
          this.imgForm.controls["imageUrl"].setValue(this.path.path);
          this.imgUrl = AppConstants.SERVER_CONFIG.IMG_URL + this.path.path;
          //refresh image in header
          this.getLocal = JSON.parse(localStorage.getItem("currentUser"));
          this.getLocal.imageUrl = this.path.path;
          localStorage.setItem("currentUser", JSON.stringify(this.getLocal));
          this.addCartService.setProfile("someValue");
          this.loading = false;
          this.putImage();
          this.userService.setCurrentUser();
        },
        (error) => {
          sweetAlert("error", "Try Again");
          this.loading = false;
        }
      );
    } else {
      this.putImage();
    }
  }

  putImgMsg;
  //update image url to user profile detail
  putImage() {
    this.loading = true;
    this.http.put("/user", this.imgForm.value).subscribe(
      (data) => {
        this.putImgMsg = data;
        $("#imageModal").modal("hide");
        this.loading = false;
        sweetAlert("success", this.putImgMsg.message);
        this.get();
      },
      (error) => {
        sweetAlert("error", "Try Again");
        this.loading = false;
      }
    );
  }

  //convert base64 to imagefile
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  //End profile image section

  //////////////////////////////////////////////////End My Profile Section ////////////////////////////////////////////////////

  ////////////////////////////////////////////////// My Reward Points ////////////////////////////////////////////////////

  getLoyaltyData;
  referralLink = "";
  //get all loyalty points
  getLoyalty() {
    this.loading = true;
    this.http.get("/user/loyalty").subscribe(
      (data: any) => {
        this.getAllPoints();
        this.getLoyaltyData = data;
        this.loading = false;
        this.getLoyaltyData.redeemHistory.map((x) => {
          x.applyStatusNew = null;
          if (x.couponId) {
            let data = this.getLoyaltyData.coupons.filter(
              (val) => val.id == x.couponId
            );
            x.applyStatusNew = data[0].applyStatus;
            x.couponId = data[0].id;
          }
        });
        this.referralLink =
          window.location.origin + "/#/?ref=" + this.getLoyaltyData.referralCode;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  couponId;
  openloyaltyModal(type, id?) {
    this.couponId = id;
    this.loyaltyModalMsg = "";
    this.loyaltyModalEmail = "";
    if (type == "redeem") {
      this.loyaltyModalEmail = "email";
      $("#loyaltyModal").modal("show");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Redeem Gift Card",
        event_label: "Redeem Gift Card",
        value: "Redeem Gift Card",
      });
    }
    if (type == "resend") {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Redeem Resend Gift Card",
        event_label: "Redeem Resend Gift Card",
        value: "Redeem Resend Gift Card",
      });
      this.loyaltyModalEmail = "resend";
      $("#loyaltyModal").modal("show");
    }
  }

  //giftcard generate function
  giftGenerateFun(type, email) {
    if (type == "redeem") {
      this.redeemFun("giftCard", email);
    }
    if (type == "resend") {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Resend Gift Card",
        event_label: "Resend Gift Card",
        value: "Resend Gift Card",
      });
      var emailPattern = /(\w(=?@)\w+\.{1}[a-zA-Z]{2,})/i;
      if (emailPattern.test(email) == false) {
        sweetAlert("error", "Enter Valid Email");
        return;
      }
      this.loading = true;
      this.http
        .get("/user/giftcard/resend/" + this.couponId + "/?email=" + email)
        .subscribe(
          (data: any) => {
            sweetAlert("success", "Gift Card Send Successfully");
            $("#loyaltyModal").modal("hide");
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            sweetAlert("error", error.message);
          }
        );
    }
  }

  loyaltyModalMsg;
  loyaltyModalEmail;
  redeemFun(type, email?) {
    if (type == "giftCard") {
      var emailPattern = /(\w(=?@)\w+\.{1}[a-zA-Z]{2,})/i;
      if (emailPattern.test(email) == false) {
        sweetAlert("error", "Enter Valid Email");
        return;
      }
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Gift Card generated",
        event_label: "Gift Card generated",
        value: "Gift Card generated",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Generated the Coupon",
        event_label: "Generated the Coupon",
        value: "Generated the Coupon",
      });
    }

    this.loyaltyModalMsg = "";
    this.loyaltyModalEmail = "";
    this.loading = true;
    this.http.get("/user/redeem?type=" + type + "&email=" + email).subscribe(
      (data: any) => {
        //  sweetAlert('success', 'success');
        this.getLoyalty();
        this.loading = false;
        //success Message
        if (type == "Coupon") {
          this.loyaltyModalMsg = "Coupon Generated Successfully";
          $("#loyaltyModal").modal("show");
        }

        if (type == "giftCard") {
          this.loyaltyModalMsg =
            "Gift Card generated successfully, Check your email";
          $("#loyaltyModal").modal("show");
        }
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", error.message);
        $("#loyaltyModal").modal("hide");
      }
    );
  }

  copyToClipBoard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    sweetAlert("success", "Copied");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Copy CouponCode/ReferralLink",
      event_label: "Copy CouponCode/ReferralLink",
      value: "Copy CouponCode/ReferralLink",
    });
  }

  printGiftCode;
  onClickGiftCard(code) {
    this.printGiftCode = "";
    this.printGiftCode = code;
    this.createImg();
    $("#giftCardModal").modal("show");
  }

  getAllPointsDate;
  getAllPoints() {
    this.http.get("/admin/loyalty").subscribe((data) => {
      this.getAllPointsDate = data;
    });
  }

  //html to img convertor download gift card
  @ViewChild("dynamicImgHtml") dynamicImgHtml;
  createImg() {
    setTimeout(
      () =>
        domtoimage
          .toPng(this.dynamicImgHtml.nativeElement)
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            // document.body.appendChild(img);
            var a = document.getElementById("anchorImg");
            a.setAttribute("href", img.src);
            // var url = img.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
            // window.open(url);
            //  document.getElementById("dynamicImg").innerHTML = '';
            // document.getElementById("dynamicImg").appendChild(img);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          }),
      200
    );
  }

  //////////////////////////////////////////////////End My Reward Points ////////////////////////////////////////////////////

  getClickableLink(link) {
    var pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(link)) {
      return 0;
    } else {
      return 1;
    }
  }

  visibleIndex = -1;
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
    this.analyticsService.commonEvents("Onclick", {
      event_category: "View Delivery Address for Ordered Particular Product",
      event_label: "View Delivery Address for Ordered Particular Product",
      value: "View Delivery Address for Ordered Particular Product",
    });
  }

  //show and hide div
  public path: any = "My Profile";
  accordionOpen(val) {
    switch (val) {
      case "profile":
        this.analyticsService.pageView(
          "My Profile",
          `profile`,
          window.location.origin
        );
        // this.path = 'My Profile'
        $("#profile-1").addClass("show");
        $("#profile-2").addClass("active");

        $("#address-1").removeClass("show");
        $("#address-2").removeClass("active");

        $("#order-1").removeClass("show");
        $("#order-2").removeClass("active");

        $("#loyalty-1").removeClass("show");
        $("#loyalty-2").removeClass("active");

        $("#remainder-1").removeClass("show");
        $("#remainder-2").removeClass("active");
        break;
      case "address":
        this.analyticsService.pageView(
          "My Addresses",
          `profile/address`,
          window.location.origin
        );
        //  this.path = 'My Addresses'
        $("#profile-1").removeClass("show");
        $("#profile-2").removeClass("active");

        $("#address-1").addClass("show");
        $("#address-2").addClass("active");

        $("#order-1").removeClass("show");
        $("#order-2").removeClass("active");

        $("#loyalty-1").removeClass("show");
        $("#loyalty-2").removeClass("active");

        $("#remainder-1").removeClass("show");
        $("#remainder-2").removeClass("active");
        break;
      case "order":
        //    this.path = 'My Orders'
        this.analyticsService.pageView(
          "My Orders",
          `profile/order`,
          window.location.origin
        );
        $("#profile-1").removeClass("show");
        $("#profile-2").removeClass("active");

        $("#address-1").removeClass("show");
        $("#address-2").removeClass("active");

        $("#order-1").addClass("show");
        $("#order-2").addClass("active");

        $("#loyalty-1").removeClass("show");
        $("#loyalty-2").removeClass("active");

        $("#remainder-1").removeClass("show");
        $("#remainder-2").removeClass("active");

        break;
      case "loyalty":
        //    this.path = 'loyalty'
        this.analyticsService.pageView(
          "My Reward Points",
          `profile/loyalty`,
          window.location.origin
        );
        $("#profile-1").removeClass("show");
        $("#profile-2").removeClass("active");

        $("#address-1").removeClass("show");
        $("#address-2").removeClass("active");

        $("#order-1").removeClass("show");
        $("#order-2").removeClass("active");

        $("#remainder-1").removeClass("show");
        $("#remainder-2").removeClass("active");

        $("#loyalty-1").addClass("show");
        $("#loyalty-2").addClass("active");
        break;
      case "remainder":
        //    this.path = 'remainder'
        this.analyticsService.pageView(
          "My Reminder",
          `profile/remainder`,
          window.location.origin
        );
        $("#profile-1").removeClass("show");
        $("#profile-2").removeClass("active");

        $("#address-1").removeClass("show");
        $("#address-2").removeClass("active");

        $("#order-1").removeClass("show");
        $("#order-2").removeClass("active");

        $("#loyalty-1").removeClass("show");
        $("#loyalty-2").removeClass("active");

        $("#remainder-1").addClass("show");
        $("#remainder-2").addClass("active");
        break;
    }
  }
  openDate(){
    var $this = this;
     $("#datepicker2").datetimepicker({
      defaultDate: false,
      timepicker:false,
      format: "d-M-Y",
      step: 15,
      onSelectDate: function (ct, $i) {
        $this.ngzone.run(()=>{
          $this.form.get('birthdayDate').setValue(moment(ct).format("DD-MM-YYYY"));
          console.log(moment(ct).format("DD-MM-YYYY"),$this.form.value);
        })
      },
    })
  }
}
