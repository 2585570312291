import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckoutPageRoutingModule } from "./checkout-page-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CheckoutPageComponent } from "./checkout-page.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { StatesDistrictsService } from "./service/states-districts.service";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NumbersOnlyDirective } from "src/app/shared/directives/numbersonly.directive";
import { TrimText } from "src/app/shared/pipes/trim.pipe";

@NgModule({
  declarations: [CheckoutPageComponent, NumbersOnlyDirective],
  imports: [
    CommonModule,
    CheckoutPageRoutingModule,
    SharedModule,
    FormsModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    HttpClientModule,
   
  ],
  providers: [StatesDistrictsService],
})
export class CheckoutPageModule {}
