import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConstants } from "../../configuration/app.constants";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) { }

  config = AppConstants.SERVER_CONFIG;
  baseurl = this.config[this.config.USE_URL];

  post(url: string, user: any) {
    return this.http.post(this.baseurl + url, user);
  }

  put(url: string, user: any) {
    return this.http.put(this.baseurl + url, user);
  }

  patch(url: string) {
    return this.http.patch(this.baseurl + url, "");
  }

  get(url: string) {
    return this.http.get(this.baseurl + url);
  }
  getList(url: string, object: any) {
    let params = new HttpParams();
    for (let key in object) {
      if (object[key] != null && object[key] != undefined && object[key] != "") {
        if (key == "deliverySpeed") {
          object.deliverySpeed != '0' ? (params = params.set(key, object[key])) : "";
        } else {
          params = params.set(key, object[key]);
        }
      }
    }
    return this.http.get(this.baseurl + url, { params });
  }

  getData(url: any, params) {
    return this.http.get(this.baseurl + url, { params });
  }

  delete(url: string) {
    return this.http.delete(this.baseurl + url);
  }
}
