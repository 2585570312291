<section class="c-pt-2 px-3 group-section">
    <div class="container-fluid">
        <div class="row pt">
            <div class="col-6 col-sm-4 col-lg-2 col-md-2  text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_1.png">
                </article>
                <p>Freshly Baked</p>
            </div>
            <div class="col-6 col-sm-4 col-lg-2 col-md-2 text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_2.png">
                </article>
                <p>Handcrafted Deliciousness</p>
            </div>
            <div class="col-6 col-sm-4 col-lg-2 col-md-2 text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_3.png">
                </article>
                <p>Customised Goodness</p>
            </div>
            <div class="col-6 col-sm-4 col-lg-2 col-md-2 text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_4.png">
                </article>
                <p>Creative Designs</p>
            </div>
            <div class="col-6 col-sm-4 col-lg-2 col-md-2 text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_5.png">
                </article>
                <p>Unique Flavours</p>
            </div>
            <div class="col-6 col-sm-4 col-lg-2 col-md-2 text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/group/Mask_Group_6.png">
                </article>
                <p>Made with Love</p>
            </div>
        </div>
    </div>
</section>