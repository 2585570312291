<section *ngIf="getData != ''">
  <div class="container-fluid">
    <h4 class="text-center">
      <img src="assets/images/icons/Layer_10.png" alt="" />
      Blogs
      <img src="assets/images/icons/Layer_11.png" alt="" />
    </h4>

    <!--row-->
    <div class="row mt-3 pxd-5">
      <div id="owl-blogs" class="owl-carousel">
        <div *ngFor="let data of getData" class="item mt-3">
          <div class="blogs-card card">
            <img class="card-img-top" alt="Thumbnail [100%x225]" (click)="blogReadMore(data.blogsId)"
              style="width: 100%; display: block" [src]="imgPth + data.images" data-holder-rendered="true" />
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between" style="margin-left: 8px;">

                <h6 style="font-weight: 700 !important;">
                  by <small>{{ data.author }} </small> <span>,</span>
                  <!-- {{ data.createdOn | date: "dd-MM-yyyy" }} -->{{data?.updatedOn | date :'mediumDate'}}
                </h6>

                <!-- <small>  <img src="assets/images/blogs/icons8_cutlery.png" alt="" srcset="" />Recipe</small> -->

                <!-- <button class="new-btn" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'View Blogs Recipe detail',
                'event_label': 'View Blogs Recipe detail',
                'value': 'View Blogs Recipe detail'})"><small> <img src="assets/images/blogs/icons8_cutlery.png" alt=""
                      srcset="" />Recipe</small>
                    </button> -->


              </div>
              <h5>
                {{ data.title }}
              </h5>

              <!-- <h6>
                by <small>{{ data.author }} </small> <span>,</span>
                {{ data.createdOn | date: "dd-MM-yyyy" }}
              </h6> -->
              <p class="card-text" style="font-weight: 700 !important">
                <span class="text text-trim" [innerHTML]="data.content"></span>
              </p>
              <div class="" style="margin-left: 17px;">
                <button type="button" (click)="blogReadMore2(data.blogsId);analyticsService.commonEvents('Onclick',{'event_category': 'View Blogs more detail',
                'event_label': 'View Blogs more detail',
                'value': 'View Blogs more detail'})" class="btn my-3">
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showall">
      <p>
        <a routerLink="/blog-list" style="float: right; color: pink; margin-bottom: 20px;text-decoration: none;" (click)="showAll()">Show All
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        
        </a>
      </p>


    </div>

    <!--end rows-->
  </div>
</section>