<section id="terms">
    <!-- top circle border -->
    <div class="top-rounded-border"></div>
  
    <div class="container-fluid px-5 py-4">
      <div class="n100">
        <div>
          <h3>TERMS AND CONDITIONS</h3>
          <p>
            All Products made by us are subject to the following Terms and
            Conditions.
          </p>
        </div>
  
        <div>
          <h3>INGREDIENTS</h3>
          <p>
            All cakes, fillings and icings may contain, or come into contact with,
            soy, wheat, dairy, nuts or other allergens.
          </p>
  
          <p>
            All products may use or have come into contact with peanuts, tree
            nuts, soybean derivatives, eggs, wheat gluten, milk, and other
            allergens. We will endeavor to meet your dietary requirements, so
            please be explicit about these when placing your order.
          </p>
  
          <p>
            It is the responsibility of the Customer to inform us prior to the
            confirmation of their booking of any allergy issues. It is the
            responsibility of the Customer to inform their guests of all allergy
            information and accordingly the Supplier will not be held liable for
            any allergic reaction resulting from consumption of the cake.
          </p>
  
          <p>All products are made to be eaten on the day of the celebration.</p>
        </div>
        <div>
          <h3>QUOTATIONS</h3>
  
          <p>All Quotations are valid for 7 days from the date of issue.</p>
        </div>
        <div>
          <h3>ORDERS</h3>
          <p>
            We prefer at least Two (2) days notice for all orders, as we are
            continually booked in advance. We will however endeavor, where
            availability permits accept short notice cakes.
          </p>
  
          <p>
            Any Products ordered from a picture or photo of a product produced by
            any other cake maker, can only be reproduced by us as our
            interpretation of that product and will NOT be an exact reproduction
            of the product in the picture or photo.
          </p>
  
          <p>
            We reserve the right to cancel any order at any time without any prior
            notice due to uncertain conditions/occasions/disasters.
          </p>
          <p>All order(s) are charged in INR (Indian National Rupees)</p>
          <p>Kindly give us 7 working days to process Refund & Cancellation of Order(s)</p>
        </div>
        <div>
          <h3 class="italic">Please note:</h3>
          <p>Delivery will be done only to single address.</p>
          <ol>
            <li>
              Please contact us for multiple address deliveries of bulk orders
              only. (Charges apply)
            </li>
            <li>
              All cake deliveries can only be delivered between the hours of 8am
              and 9pm.
            </li>
            <li>
              Mid night or late night delivery may be accepted with special
              charges on request. Please contact us.
            </li>
            <li>
              Your delivery fee does not include TIP for the delivery person. We
              would suggest you to refrain from tipping.
            </li>
  
            <li>
              A recipient signature will be required for all deliveries to confirm
              the delivery.
            </li>
            <li>
              We are not responsible for any delay or failure in delivery due to
              customer unavailability, invalid address, delays due to peak season
              demand, weather, natural disaster, strike and transportation delays.
            </li>
          </ol>
        </div>
        <div>
          <h3>Online Payment</h3>
          <p>
            For online orders placed on www.puppysbakery.com, the payment
            information, card information and bank details are entered on 3rd
            party website/bank website. puppysbakery.com does not take any card
            information at any point of time from the customer. However,
            www.puppysbakery.com ensures that all 3rd party links for payment have
            the required SSL certificate and maintain complete security of the
            customer data.
          </p>
          <p>
            Customer can make payment through credit card, debit card, Net Banking
            and/or Paytm.
          </p>
          <p>
            Request for any change in the cake not be accepted once order has been
            placed.
          </p>
          <p>
            You agree to pay the price applicable for the product or service on
            our website at the time you submitted your order (Product Price), or
            any applicable taxes. You will be solely responsible for payment of
            all taxes, and other governmental charges.
          </p>
          <p>Kindly note all orders are charged in INR (Indian National Rupee)</p>
        </div>
        <div>
          <h3>UNFORESEEN CIRCUMSTANCES</h3>
          <p>
            Where we cancel the booking due to any unforeseen circumstances that
            affects the ability to deliver the ordered Product, the deposit will
            be refunded within seven (7) days of notification to the Customer.
          </p>
          <p>Kindly give us 7 working days to process Refund & Cancellation of Order(s)</p>
        </div>
        <div>
          <h3>DELIVERY</h3>
          <p style="font: normal normal normal 19px/30px lato">
            <b>
            Delivery Timeline depends on the cake design & Customisation required. All cakes will be delivered within 2hrs-24hrs on the day of delivery.
  
          </b>
          </p>
          <p style="font: normal normal normal 19px/30px lato">
            <b>All our cakes are dispatched from Puppy's Main Branch located at Kochadai (Opp.Master Mahal), Madurai - 625016.</b>
          
          </p>
          <p>
            We offer delivery in Madurai, Dindigul and Sivakasi only. Delivery
            charges vary according to the destination. 
          </p>
          <p>
            Delivery will be carried out in a safe and appropriate manner. Once
            delivered to the customer and change of hands has been completed with
            the product in appropriate condition, it is then up to the customer to
            store and care for the cake as per our instructions. If anything is to
            happen to the cake after this exchange, we are not liable for any
            damages.
          </p>
          <p>
            A delivery date and time will be agreed by both parties, and if the
            customer is not at location at this time and date, and we are not able
            to contact the customer, the product will be transported back to our
            premises and it will be the customer’s responsibility to collect the
            product.
          </p>
         
        </div>
        <div>
          <h3>PUBLICATION</h3>
          <p>
            We reserve the right to use any image of a Product made by us for
            publication at a later date
          </p>
          <p>Other Conditions are:</p>
        </div>
        <div>
          <h3>DAMAGE</h3>
          <p>
            If a third party will be collecting the cake on the customer’s behalf,
            a release form is to be signed by that person, stating the cake is
            received in good condition at the time of pickup/delivery.
          </p>
        </div>
        <div>
          <h3>PICKUP</h3>
  
          <p>
            The Customer is liable for any damage, accident, or mishap that may
            occur once the cake is released from the care and custody of the
            Supplier’s. The Supplier is not liable for any damage that may occur
            thereafter.
          </p>
        </div>
        <div>
          <h3>Damage or Defective Products</h3>
          <p>
            If any product received by you is damaged or defective, you can let us
            know the same. You can call us at +91-<span>8122210222</span> (10:00
            hours to 18:00 hours, 7 days a week) or send us an e-mail with the
            photographs of the same at <span> mailbox@puppys.co.in,</span> within
            24 hours of receiving the product.
          </p>
          <p>
            We will either deliver to you the new product or refund the amount to
            you after investigation the same.
          </p>
        </div>
      </div>
    </div>
  </section>
  