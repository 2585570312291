import { Component, OnInit } from "@angular/core";
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ImageSize,
  ThumbnailsPosition,
} from "@ngx-gallery/core";
import { Lightbox } from "ngx-lightbox";

@Component({
  selector: "app-press-release",
  templateUrl: "./press-release.component.html",
  styleUrls: ["./press-release.component.less"],
})
export class PressReleaseComponent implements OnInit {
  items: GalleryItem[];
  data;
  border: boolean = false;
  constructor(public gallery: Gallery, public lightbox: Lightbox) {}

  ngOnInit(): void {
  
    this.items = data.map(
      (item) => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    );

    this.basicLightboxExample();
    this.withCustomGalleryConfig();
  }
  ngAfterViewInit() {
    this.border = true;
  }
  basicLightboxExample() {
    this.gallery.ref().load(this.items);
   
  }

  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {
   
    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref("anotherLightbox");

    // (Optional) Set custom gallery config to this lightbox

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
}
const data = [
  {
    srcUrl: "assets/images/press-release/baking-a-passion-WW-magazine.jpg",
    previewUrl: "assets/images/press-release/baking-a-passion-WW-magazine.jpg",
  },
  {
    srcUrl: "assets/images/press-release/earth-day-1.jpg",
    previewUrl: "assets/images/press-release/earth-day-1.jpg",
  },
  {
    srcUrl: "assets/images/press-release/earth-day-2.jpg",
    previewUrl: "assets/images/press-release/earth-day-2.jpg",
  },
  {
    srcUrl: "assets/images/press-release/earth-day-3.jpg",
    previewUrl: "assets/images/press-release/earth-day-3.jpg",
  },
  {
    srcUrl: "assets/images/press-release/earth-day-4.jpg",
    previewUrl: "assets/images/press-release/earth-day-4.jpg",
  },
  {
    srcUrl: "assets/images/press-release/hindu-metroplus-1.jpg",
    previewUrl: "assets/images/press-release/hindu-metroplus-1.jpg",
  },
  {
    srcUrl: "assets/images/press-release/hindu-metroplus-2.jpg",
    previewUrl: "assets/images/press-release/hindu-metroplus-2.jpg",
  },
  {
    srcUrl: "assets/images/press-release/indulgent-treat-hindu.jpg",
    previewUrl: "assets/images/press-release/indulgent-treat-hindu.jpg",
  },
  {
    srcUrl: "assets/images/press-release/jar-hindu-1.jpg",
    previewUrl: "assets/images/press-release/jar-hindu-1.jpg",
  },
  {
    srcUrl: "assets/images/press-release/jar-hindu-2.jpg",
    previewUrl: "assets/images/press-release/jar-hindu-2.jpg",
  },
  {
    srcUrl: "assets/images/press-release/letter-to-santa-metroplus.jpg",
    previewUrl: "assets/images/press-release/letter-to-santa-metroplus.jpg",
  },
  {
    srcUrl: "assets/images/press-release/sunday-special-dinamalar.jpg",
    previewUrl: "assets/images/press-release/sunday-special-dinamalar.jpg",
  },
  {
    srcUrl: "assets/images/press-release/puppys-TOA.jpg",
    previewUrl: "assets/images/press-release/puppys-TOA.jpg",
  },
  {
    srcUrl: "assets/images/press-release/shes-mercedes-article.jpg",
    previewUrl: "assets/images/press-release/shes-mercedes-article.jpg",
  },
  {
    srcUrl: "assets/images/press-release/tic-tac-toe-hindu.jpg",
    previewUrl: "assets/images/press-release/tic-tac-toe-hindu.jpg",
  },
  {
    srcUrl:
      "assets/images/press-release/when-food-is-an-experience-metroplus.jpg",
    previewUrl:
      "assets/images/press-release/when-food-is-an-experience-metroplus.jpg",
  },
  {
    srcUrl: "assets/images/press-release/woman-entrepreneur-MM1.jpg",
    previewUrl: "assets/images/press-release/woman-entrepreneur-MM1.jpg",
  },
  {
    srcUrl: "assets/images/press-release/woman-entrepreneur-MM2.jpg",
    previewUrl: "assets/images/press-release/woman-entrepreneur-MM2.jpg",
  },
  {
    srcUrl: "assets/images/press-release/wonder-woman1.jpg",
    previewUrl: "assets/images/press-release/wonder-woman1.jpg",
  },
  {
    srcUrl: "assets/images/press-release/wonder-woman2.jpg",
    previewUrl: "assets/images/press-release/wonder-woman2.jpg",
  },
  {
    srcUrl: "assets/images/press-release/wonder-woman3.jpg",
    previewUrl: "assets/images/press-release/wonder-woman3.jpg",
  },
];
