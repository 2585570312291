import {
  Component,
  OnInit,
  AfterViewChecked,
  QueryList,
  ViewChildren,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Options, LabelType } from "ng5-slider";
import { AppConstants } from "src/app/configuration/app.constants";
import { HttpService } from "src/app/services/http/http.service";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { Title } from "@angular/platform-browser";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { first, map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { checkNull } from "../product-details/date-and-time-validations";
import { UserService } from "src/app/services/memory-services/user.service";
import { HttpParams } from "@angular/common/http";
declare var $: any;

interface subCategory {
  item: object;
  children?: subCategory[];
}

@Component({
  selector: "app-sub-category",
  templateUrl: "./sub-category.component.html",
  styleUrls: ["./sub-category.component.less"],
})
export class SubCategoryComponent implements OnInit {
  public loading: boolean = false;
  public loginIN: any;
  public orderId: any;
  public imagUrl: any;
  public showLoader: Boolean = true;
  public isBannerImgAvailable = false;
  public bannerImg: any;
  public searchKey: any;
  public deliveryIn: any;
  public isOpened: any = 1;
  public getId: any;
  public noScroll: boolean = false;
  public product: any;
  public categoryName;
  public noProduct: Boolean = false;
  public infiniteScrollDiv: any;
  public gifLoader: any;
  public totalPage = 1;
  public getUrl = "";
  public page: any;
  public categoryId: any;
  public getdata: any;
  public ProductDetails: any;
  public product2 = [];
  public breadcrumb: any;
  public ProductDetails2: any;
  public sortSize: any;
  highLow: FormControl;
  public subCategory: any[] = [];
  @ViewChild("priceFilter") priceFilter: ElementRef;
  parentCategoryId: any;
  subCategoryId: any;
  subCategoryparentId: any;
  treeControl = new NestedTreeControl<any>((node) => node.child);
  dataSource = new MatTreeNestedDataSource<any>();
  deliveryTimes: any[] = [];
  public deliveryInCategory: any;
  public priceSorting: any;
  public routerName: any;

  priceSortBy: any[] = [
    { displayValue: "Price Low To High", value: "pricelowtohigh" },
    { displayValue: "Price High To Low", value: "pricehightolow" },
    { displayValue: "Sort By Discount", value: "discount" },
  ];
  kilograms: any[] = [
    { displayValue: "500g", minValue: "0.0", maxValue: "0.5", id: 1 },
    { displayValue: "1kg", minValue: "1", maxValue: "1", id: 2 },
    { displayValue: "2kg", minValue: "2", maxValue: "2", id: 3 },
    // { displayValue: "3kg", minValue: "3", maxValue: "3", id: 4 },
    // { displayValue: "4kg", minValue: "4", maxValue: "4", id: 5 },
    // {
    //   displayValue: "8.0kg - 10.0kg",
    //   minValue: "8.0",
    //   maxValue: "10.0",f
    //   id: 6,
    // },
  ];
  discounts: any[] = [
    { displayValue: "0 - 10", minValue: "0", maxValue: "10", id: 1 },
    { displayValue: "10 - 20", minValue: "10", maxValue: "20", id: 2 },
    { displayValue: "20 - 30", minValue: "20", maxValue: "30", id: 3 },
    { displayValue: "40 - 50", minValue: "40", maxValue: "50", id: 4 },
    { displayValue: "60 - 70", minValue: "60", maxValue: "70", id: 5 },
    { displayValue: "80 - 90", minValue: "80", maxValue: "90", id: 6 },
  ];
  PriceFilterDatas: any[] = [
    { displayValue: "Under ₹500", minValue: "0", maxValue: "500", id: 1 },
    { displayValue: "₹500 - ₹1000", minValue: "500", maxValue: "1000", id: 2 },
    {
      displayValue: "₹1000 - ₹2000",
      minValue: "1000",
      maxValue: "2000",
      id: 3,
    },
    {
      displayValue: "₹2000 - ₹3000",
      minValue: "2000",
      maxValue: "3000",
      id: 4,
    },
    {
      displayValue: "₹3000 - ₹4000",
      minValue: "3000",
      maxValue: "4000",
      id: 5,
    },
    {
      displayValue: "₹5000 - ₹10000",
      minValue: "5000",
      maxValue: "10000",
      id: 6,
    },
    {
      displayValue: "₹10000 - ₹20000",
      minValue: "10000",
      maxValue: "20000",
      id: 7,
    },
  ];
  filterForm: FormGroup;
  priceMin: any;
  priceMax: any;
  catId: any;
  newId: string;
  isPanIndia: any;
  showprice: boolean = false;
  showkilogram: boolean = false;
  showcategory: boolean = true;
  roterId: any;
  isneedtoday: any;
  sortby: any;
  deliverytime: any;
  constructor(
    private actRoute: ActivatedRoute,
    public http: HttpService,
    public addCartService: AddCartService,
    private router: Router,
    private seoTitle: Title,
    private formBuilder: FormBuilder,
    public analyticsService: GoogleAnalyticsService,
    private cd: ChangeDetectorRef,
    private memoryService: MemoryService,
    private auth: AuthenticationService,
    private userService: UserService
  ) {
    this.actRoute.paramMap.subscribe((params: any) => {
      let id: string = params.params.id;
      this.roterId = id;
      if (id != undefined) {
        if (id.includes("-")) {
          let data = id.split("-").slice(0, -1);
          this.categoryName = data.join(" ");
        }
      }
    });
  }

  ngOnInit(): void {
    this.forms();
    // this.newPagination();
    this.getDeliveryTime();
    this.loginIN = localStorage.getItem("loginIN");
    this.imagUrl = AppConstants.SERVER_CONFIG.IMG_URL;
    //set category id
    this.actRoute.paramMap.subscribe((params) => {
      let getIdUrl = params.get("id");
      let getName = params.get("name");
      this.newId = getIdUrl;
      // this.highLow = new FormControl('pricelowtohigh')
      if (getIdUrl) {
        if (String(getIdUrl) == "wishList") {
          this.analyticsService.pageView(
            "Wish List",
            `category/${params.get("id")}`,
            window.location.origin
          );
        } else {
          this.analyticsService.pageView(
            "Product List",
            `category/${params.get("id")}`,
            window.location.origin
          );
        }
        let IsSeoUrl = getIdUrl.includes("-");
        if (IsSeoUrl) {
          this.getId = getIdUrl.split("-").splice(-1);
          this.subCategoryId = getIdUrl.split("-").splice(-1);
          this.catId = getIdUrl.split("-").splice(-1);
          this.seoTitle.setTitle("Cake Category");
          this.isPanIndia = 0;
        } else {
          if (params.get("id") == "Pan_India") {
            this.isPanIndia = 1;
          } else {
            this.getId = params.get("id"); //get category id
            this.subCategoryId = params.get("id");
            this.catId = params.get("id");
            this.isPanIndia = 0;
          }
        }
      } else {
        this.getId = "";
        this.subCategoryId = "";
        this.catId = "";
        this.isPanIndia = 0;
      }
      this.bannerImg = localStorage.getItem("_Bimg_");

      this.isBannerImgAvailable = false;
      if (this.bannerImg) {
        this.isBannerImgAvailable = true;
      } else {
      }
      this.noScroll = false;
      this.totalPage = 1;
      this.page = "";
      this.product2 = [];
      this.product = [];
      this.parentCategoryId = "";
      this.subCategoryparentId = "";
      this.subCategoryId = this.catId;

      // this.filterForm.get("priceMin").setValue("");
      // this.filterForm.get("priceMax").setValue("");
      // this.filterForm.get("price").setValue("");
      // this.priceMin = "";
      // this.priceMax = "";
      // this.filterForm.get("discount").setValue("");
      // this.filterForm.get("kilograms").setValue("");
      // this.deliveryIn = "";
      // this.priceSorting = "";
    });

    // this.actRoute.queryParams.subscribe((params) => {
    //   if(params?.categortyId){
    //     this.getId = params?.categortyId;
    //   }
    // })

    // this.highLow.setValue('pricelowtohigh');
    // setTimeout(() => {
    // this.priceFilterFun('pricelowtohigh')

    // }, 1000);

    this.memoryService.memoryEmitter$.pipe(first()).subscribe((res: any) => {
      this.loginIN = localStorage.getItem("loginIN");
      if (res.component == "subcategory") {
        this.getWishList();
        if (checkNull(res?.functionName)) {
          console.log(res, "in the subcategory component", this.loginIN);
          setTimeout(() => {
            this[res?.functionName](res.params.p1, res.params.p2);
          }, 200);
        }
      } else {
        this.getWishList();
      }
    });

    this.auth.loggedOut$.subscribe((res) => {
      this.orderId = localStorage.getItem("orderId");
      this.loginIN = localStorage.getItem("loginIN");
    });
  }

  opencategory() {
    this.showcategory = true;
  }
  closecategory() {
    this.showcategory = false;
  }
  openprice() {
    this.showprice = true;
  }
  closeprice() {
    this.showprice = false;
  }
  openkilogram() {
    this.showkilogram = true;
  }
  closekilogram() {
    this.showkilogram = false;
  }

  //Form
  forms() {
    this.filterForm = this.formBuilder.group({
      discount: new FormControl(""),
      kilograms: new FormControl(""),
      priceMin: new FormControl(""),
      priceMax: new FormControl(""),
      price: new FormControl(""),
    });
  }

  //deliveryTime
  getDeliveryTime() {
    console.log("getDeliveryTime----->>>>");
    this.http.get("/admin/deliveryTime").subscribe(
      (data: any) => {
        this.deliveryTimes = [];
        this.deliveryTimes = data;
        this.actRoute.queryParams.subscribe((params) => {
          //get search key
          if (params.hasOwnProperty("key")) {
            this.searchKey = params["key"]; //get search key
            this.getProduct(this.getId);
            console.log("key", this.getId);
            this.analyticsService.pageView(
              "View Search Category Based Product List",
              `category?key=${this.searchKey}`,
              window.location.origin
            );
          } else {
            this.searchKey = "";
          }
          //get delivery in
          if (this.deliveryIn) {
          }

          if (params.hasOwnProperty("deliveryIn") && params["deliveryIn"]) {
            this.isneedtoday = params["deliveryIn"];
            let deliveryTimes: any = this.deliveryTimes.filter((res) =>
              res?.backingTime ? res?.backingTime < 5 : false
            );
            this.deliveryTimes = deliveryTimes;
            console.log(params?.deliverySpeed, "params?.deliverySpeed");
            this.deliveryIn = params?.deliverySpeed
              ? String(params?.deliverySpeed)
              : "0"; //params["deliveryIn"]; //get delivery time
            this.deliveryInCategory = params?.deliverySpeed
              ? String(params?.deliverySpeed)
              : "0";
            this.priceSorting = params?.sortBy ? params?.sortBy : "";
            this.getProduct(this.getId);
            console.log("deliveryIn", this.getId);
            this.analyticsService.pageView(
              "View Delivery Time Based Product List",
              `delivery-filter?deliveryIn=0`,
              window.location.origin
            );
          } else {
            this.deliveryIn = "";
          }
        });

        //send id to getProduct function
        this.actRoute.paramMap.subscribe((params) => {
          if (!this.deliveryIn && !this.searchKey) {
            this.getProduct(this.getId);
          }
        });
      },
      (error) => {
        this.deliveryTimes = [];
        this.actRoute.queryParams.subscribe((params) => {
          //get search key
          if (params.hasOwnProperty("key")) {
            this.searchKey = params["key"]; //get search key
            this.getProduct(this.getId);
            console.log("key", this.getId);
          } else {
            this.searchKey = "";
          }
          //get delivery in
          if (params.hasOwnProperty("deliveryIn") && params["deliveryIn"]) {
            console.log(params?.deliverySpeed, "params?.deliverySpeed");
            this.deliveryIn = params?.deliverySpeed
              ? String(params?.deliverySpeed)
              : "0"; //params["deliveryIn"]; //get delivery time
            this.deliveryInCategory = params?.deliverySpeed
              ? String(params?.deliverySpeed)
              : "0";
            this.priceSorting = params?.sortBy ? params?.sortBy : "";
            this.getProduct(this.getId);
            console.log("deliveryIn", this.getId);
          } else {
            this.deliveryIn = "";
          }
        });

        //send id to getProduct function
        this.actRoute.paramMap.subscribe((params) => {
          if (!this.deliveryIn && !this.searchKey) {
            this.getProduct(this.getId);
          }
        });
      }
    );
  }

  //get product Data
  getProduct(id: string) {
    console.log("getProduct========>>>>>>>");
    this.initialOpenNav();
    this.getSubCategory();
    this.getdata = [];
    this.page = "";
    this.selection = [];
    this.product2 = [];
    this.product = [];
    window.scrollTo(0, 0);
    this.totalPage = 1;
    this.ProductDetails2 = id;

    this.breadcrumb = this.ProductDetails2?.path;

    this.ProductDetails = this.ProductDetails2?.content;

    if (id == "trendingCake" || id == "bestSellerCake" || id == "featureCake") {
      // this.noScroll = true;
      this.subCategoryId = ["1"];
      /* hiding filter options for the condion(id == "trendingCake" || id == "bestSellerCake" || id == "featureCake")  */
      this.filterHide();
      if (id == "trendingCake") this.categoryName = "Trending Cake";
      if (id == "bestSellerCake") this.categoryName = "Best Seller Cake";
      if (id == "featureCake") this.categoryName = "Cakes By occasion";
      this.seoTitle.setTitle(this.categoryName);
      this.showLoader = true;
      this.http
        .post("/admin/product/search", JSON.parse('{"listing": "' + id + '"}'))
        .subscribe(
          (data) => {
            // this.productData(data);
            console.log("1111----->>>>>>");
            this.get("0");
          },
          (error) => {
            this.noProduct = true;
            this.showLoader = false;
          }
        );
      // this.get(id)
      // this.newPagination()
    }

    //get all wishlist product
    else if (id == "wishList") {
      this.filterHide();
      this.noScroll = true;
      this.categoryName = "WishList";
      this.seoTitle.setTitle(this.categoryName);
      this.showLoader = true;
      this.http.get("/user/wishlist/product").subscribe(
        (data) => {
          this.productData(data);
        },
        (error) => {
          this.noProduct = true;
          this.showLoader = false;
        }
      );
    } else {
      let catId: any;
      this.actRoute.queryParams.subscribe((params) => {
        if (params?.categortyId) {
          catId = params?.categortyId;
          this.getId = params?.categortyId;
          this.priceMax = params?.priceMax;
          this.priceMin = params?.priceMin;
          this.priceSorting = params?.sortBy ? params?.sortBy : "";
          this.deliveryIn = params?.deliverySpeed
            ? String(params?.deliverySpeed)
            : String(this.deliveryIn);
          this.deliveryInCategory = params?.deliverySpeed
            ? String(params?.deliverySpeed)
            : "0";
        }
        this.kilograms.filter((res) => {
          if (
            res?.minValue == params?.kiloGramMin &&
            res?.maxValue == params?.kiloGramMax
          ) {
            this.filterForm
              .get("kilograms")
              .setValue(res?.id ? Number(res?.id) : "");
            this.showkilogram = true;
          }
        });
      });

      if (catId) {
        console.log("22222----->>>>>>");
        this.get(catId);
      } else {
        console.log("3333----->>>>>>");
        this.get(id);
      }
    }
  }

  getForBreadcrumb() {
    this.http.get(`/admin/category/tree/${1}`).subscribe((res: any) => {
      let subcategory = res;
      this.breadcrumb = subcategory.filter(
        (ele) => ele.item.categoryId == this.subCategoryId[0]
      );
    });
  }

  getSubCategory() {
    this.getForBreadcrumb();
    if (this.subCategoryId != "wishList") {
      this.http
        .get(`/admin/category/tree/${this.subCategoryId ? this.subCategoryId : 0}`)
        .subscribe(
          (res: any) => {
            this.subCategory = res;
            this.dataSource.data = res;
            let dataSourceValue: any = [];

            dataSourceValue = this.dataSource.data;
            this.actRoute.queryParams.subscribe((params) => {
              let param = params;
              let data: any;
              dataSourceValue.filter((res) => {
                if (Number(res?.item?.categoryId) == Number(param?.categortyId)) {
                  this.parentCategoryId = Number(param?.categortyId);
                }
                res?.child.filter((resp) => {
                  if (resp?.item?.categoryId == Number(param?.categortyId)) {
                    this.subCategoryparentId = Number(param?.categortyId);
                    this.parentCategoryId = Number(res?.item?.categoryId);
                    data = res;
                  }
                });
              });
              this.treeControl.expand(data);
              this.treeControl.isExpanded(data);
              this.priceMax = param?.priceMax;
              this.priceMin = param?.priceMin;
              let kilogram: any;
              let discount: any;
              let price: any;
              this.priceSorting = param?.sortBy ? param?.sortBy : "";
              console.log(
                " this.deliveryInCategory = params?.deliverySpeed",
                params?.deliverySpeed
              );
              this.deliveryIn = param?.deliverySpeed
                ? String(param?.deliverySpeed)
                : String(this.deliveryIn);
              this.deliveryInCategory = params?.deliverySpeed
                ? String(params?.deliverySpeed)
                : "0";
              if (this.priceMax != "" && this.priceMin != "") {
                this.showprice = true;
              } else {
                this.showprice = false;
              }

              this.kilograms.filter((res) => {
                if (
                  res?.minValue == param?.kiloGramMin &&
                  res?.maxValue == param?.kiloGramMax
                ) {
                  kilogram = res?.id;
                }
              });

              this.discounts.filter((res) => {
                if (
                  res?.minValue == param?.discountMin &&
                  res?.maxValue == param?.discountMax
                ) {
                  discount = res?.id;
                }
              });
              this.PriceFilterDatas.filter((res) => {
                if (
                  res?.minValue == this.priceMin &&
                  res?.maxValue == this.priceMax
                ) {
                  price = res?.displayValue;
                }
              });

              this.isPanIndia = param?.isPanIndiaProduct;
              // this.page = param?.pageNo;
              // this.getId = param?.categoryId;
              this.filterForm.patchValue({
                discount: discount ? discount : "",
                kilograms: kilogram ? kilogram : "",
                priceMin: this.priceMin ? this.priceMin : "",
                priceMax: this.priceMax ? this.priceMax : "",
                price: price ? price : "",
              });
            });

            if (res?.length == 0) {
            }
          },
          (error) => {
            // this.showLoader = false;
          }
        );
    }
  }

  public pageInc = 0;
  public nonScroll: boolean = false;
  paginateProducts() {
    //   if (this.nonScroll == false) {
    //     this.newPagination();
    //   }
  }

  // newPagination() {
  //   let id = this.newId;
  //   if (id == "trendingCake" || id == "bestSellerCake" || id == "featureCake") {
  //     this.pageInc++;
  //     let obj = {
  //       search: this.newId,
  //       pageSize: 2,
  //       pageNo: this.pageInc,
  //     };
  //     // this.showLoader=true
  //     this.gifLoader=true
  //     this.http
  //       .getList("/admin/product/Pagination", obj)
  //       .subscribe((res: any) => {
  //         let data: any = res?.content;
  //         // this.showLoader=false
  //         setTimeout(() => {
  //           this.gifLoader=false
  //         }, 500);
  //         if (this.product2.length >= res.totalPages) {
  //           this.nonScroll = true;
  //         }
  //         if (data.length != 0) {
  //           this.productData(data);
  //         }
  //       });
  //       // this.get(id)
  //   }
  // }

  filterProducts(type, value) {
    if (type == 0) {
      this.filterForm.get("discount").setValue(value ? value : "");
      let discount: any = this.discounts.find(
        (x) => Number(x.id) == Number(this.filterForm.value.discount)
      );
      this.analyticsService.commonEvents("Discount Based Filter to Product", {
        event_category: `Discount Range ${discount.minValue}% to ${discount.maxValue}%`,
        event_label: `Discount Range ${discount.minValue}% to ${discount.maxValue}%`,
        value: `Discount Range ${discount.minValue}% to ${discount.maxValue}%`,
      });
    } else if (type == 1) {
      this.filterForm.get("kilograms").setValue(value ? value : "");
      let kilogram: any = this.kilograms.find(
        (x) => Number(x.id) == Number(this.filterForm.value.kilograms)
      );
      this.analyticsService.commonEvents("Kilograms Based Filter to Product", {
        event_category: `Kilograms Range ${kilogram.minValue}kg to ${kilogram.maxValue}kg`,
        event_label: `Kilograms Range ${kilogram.minValue}kg to ${kilogram.maxValue}kg`,
        value: `Kilograms Range ${kilogram.minValue}kg to ${kilogram.maxValue}kg`,
      });
    } else if (type == 3) {
      this.filterForm.get("priceMin").setValue("");
      this.filterForm.get("priceMax").setValue("");
      this.priceMin = value.minValue;
      this.priceMax = value.maxValue;
      this.analyticsService.commonEvents("Price Based Filter to Product", {
        event_category: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
        event_label: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
        value: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
      });
    } else if (type == 2) {
      this.priceMin = this.filterForm.get("priceMin").value;
      this.priceMax = this.filterForm.get("priceMax").value;
      this.analyticsService.commonEvents("Price Based Filter to Product", {
        event_category: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
        event_label: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
        value: `Price Range ₹${this.priceMin} to ₹${this.priceMax}`,
      });
    }
    this.totalPage = 1;
    this.page = "";
    this.noScroll = false;
    this.product2 = [];
    this.product = [];
    this.actRoute.queryParams.subscribe((params) => {
      if (params?.categortyId) {
        this.getId = Number(params?.categortyId);
      }
    });
    console.log("4444----->>>>>>");
    this.get("0");
  }

  getActiveParent(type: string, data) {
    if (type == "0") {
      this.noScroll = false;
      this.parentCategoryId = data?.item?.categoryId;
      this.subCategoryparentId = "";
      this.getId = this.parentCategoryId;
      this.totalPage = 1;
      this.page = "";
      this.product2 = [];
      this.product = [];
      // this.getSubCategory();
      this.analyticsService.commonEvents("Category Based Filter to Product", {
        event_category: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
        event_label: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
        value: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
      });
      console.log("55555----->>>>>>");
      this.get(this.parentCategoryId);
    } else if (type == "1") {
      this.noScroll = false;
      this.subCategoryparentId = data?.item?.categoryId;
      this.parentCategoryId = data?.item?.parentCategoryId;
      this.getId = this.subCategoryparentId;
      this.totalPage = 1;
      this.page = "";
      this.product2 = [];
      this.product = [];
      //this.getSubCategory();
      this.analyticsService.commonEvents("Category Based Filter to Product", {
        event_category: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
        event_label: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
        value: `Category : ${data?.item.subCategoryName}/${data?.item.categoryName}`,
      });
      console.log("6666----->>>>>>");
      this.get(this.parentCategoryId);
    }
  }

  hasChild = (_: number, node: any) => !!node.child && node.child.length > 0;
  //get product data if not in cakeListing & wishlist
  get(id) {
    console.log(id, "id----gettttt-----?>>>>");
    if (
      this.getId != "trendingCake" &&
      this.getId != "bestSellerCake" &&
      this.getId != "featureCake"
    ) {
      // this.noScroll = false;

      if (!this.page || this.noScroll) {
        this.page = 1;
      } else {
        this.page = this.page + 1;
      }
      if (!this.getId) {
        this.getId = "";
      } else {
        document.getElementById("filter-button").style.display = "block";
      }

      if (this.deliveryIn) {
        this.filterHide();
      }
      // if (this.totalPage < this.page) {
      //   this.gifLoader = false;
      //   return;
      // }
      // this.categoryName = "";

      //set Category Name
      if (this.deliveryIn) {
        // if (this.deliveryIn == "2hour,sameDay") {
        //   this.categoryName = "Same Day Delivery Cakes";
        // }
        // if (this.deliveryIn == "2hour") {
        //   this.categoryName = "2 Hour Delivery Cakes";
        // }
        console.log(id, "this.deliveryIn.id");
        this.categoryName = "Need Today";
        // this.categoryName = this.deliveryTimes.find((x) => Number(x.deliverytimeId) == Number(this.deliveryIn))?.deliveryName
        //   ? this.deliveryTimes.find((x) => Number(x.deliverytimeId) == Number(this.deliveryIn))?.deliveryName : this.categoryName;
        //set seo title
        this.seoTitle.setTitle(this.categoryName);
        this.isBannerImgAvailable = true;
      }

      // "/product/filter?categoryId=" +
      // this.getId +
      // "&deliveryIn=" +
      // (id ? id : this.deliveryIn) +
      // "&search=" +
      // this.searchKey +
      // "&page=" +
      // this.page;
      let kilogram: any = this.kilograms.find(
        (x) => Number(x.id) == Number(this.filterForm.value.kilograms)
      );
      let discount: any = this.discounts.find(
        (x) => Number(x.id) == Number(this.filterForm.value.discount)
      );

      let params = {
        categortyId: this.getId,
        //'search': this.searchKey,
        // pageNo: this.page,
        // pageSize: 6,
        deliverySpeed: this.deliveryIn ? this.deliveryIn : "",
        priceMax: this.filterForm.value.priceMax
          ? this.filterForm.value.priceMax
          : this.priceMax
          ? this.priceMax
          : "",
        priceMin: this.filterForm.value.priceMin
          ? this.filterForm.value.priceMin
          : this.priceMin
          ? this.priceMin
          : "",
        kiloGramMin: kilogram != undefined ? kilogram?.minValue : "",
        kiloGramMax: kilogram != undefined ? kilogram?.maxValue : "",
        discountMax: discount != undefined ? discount?.maxValue : "",
        discountMin: discount != undefined ? discount?.minValue : "",
        sortBy: this.priceSorting ? this.priceSorting : "",
        isPanIndiaProduct: this.isPanIndia ? this.isPanIndia : 0,
      };

      if (!this.isneedtoday) {
        this.router.navigate([`/category/${this.roterId}`], {
          queryParams: params,
          queryParamsHandling: "merge",
          // preserve the existing query params in the route
          // skipLocationChange: true,
        });
      } else {
        this.deliveryTimes = this.deliveryTimes.filter(
          (element) => element.backingTime <= 4
        );
        this.priceSortBy = this.priceSortBy.filter(
          (element) => element.value != "discount"
        );
        let todayParams = {
          deliverySpeed: this.deliveryIn ? this.deliveryIn : "",
          deliveryIn: this.deliveryIn ? this.deliveryIn : "",
          sortBy: this.priceSorting ? this.priceSorting : "",
        };
        this.router.navigate([`/delivery-filter`], {
          queryParams: todayParams,
          queryParamsHandling: "merge",
          // preserve the existing query params in the route
          skipLocationChange: true,
        });
      }

      let object: any = {
        categortyId: this.getId,
        //'search': this.searchKey,
        pageNo: this.page,
        pageSize: 6,
        deliverySpeed: this.deliveryIn
          ? this.deliveryIn
          : this.deliveryInCategory
          ? this.deliveryInCategory
          : '',
        priceMax: this.filterForm.value.priceMax
          ? this.filterForm.value.priceMax
          : this.priceMax
          ? this.priceMax
          : "",
        priceMin: this.filterForm.value.priceMin
          ? this.filterForm.value.priceMin
          : this.priceMin
          ? this.priceMin
          : "",
        kiloGramMin: kilogram != undefined ? kilogram?.minValue : "",
        kiloGramMax: kilogram != undefined ? kilogram?.maxValue : "",
        discountMax: discount != undefined ? discount?.maxValue : "",
        discountMin: discount != undefined ? discount?.minValue : "",
        sortBy: this.priceSorting ? this.priceSorting : "",
        isPanIndiaProduct: this.isPanIndia ? this.isPanIndia : 0,
      };

      this.getUrl = "/productfilter/searchAll";
      if (this.page == 1) {
        this.showLoader = true;
      } else {
        this.gifLoader = true;
      }

      // let params1: HttpParams = new HttpParams();

      // for (let data in object) {
      //   if (data == "deliverySpeed") {
      //     if (object[data] != "0") {
      //       console.log("do nothing");
      //     } else {
      //       params1 = params1.append(data, object[data]);
      //     }
      //   } else {
      //     params1 = params1.append(data, object[data]);
      //   }
      // }

      this.http.getList(this.getUrl, object).subscribe(
        (data: any) => {
          this.gifLoader = false;
          this.getdata = data;
          setTimeout(() => {
            this.gifLoader = false;
          }, 800);

          if (
            Math.ceil(this.getdata.totalElements / this.getdata.currentPageSize) <=
            this.page
          ) {
            this.noScroll = true;
          }
          if (this.getdata.content.product?.length >= 1) {
            this.productData(this.getdata.content.product);
          } else {
            this.showLoader = false;
            this.product2.length > 0
              ? (this.noProduct = false)
              : (this.noProduct = true);
          }
          if (this.getdata.totalElements) {
            this.totalPage = Math.ceil(this.getdata.totalElements / 10);
          }

          let dataSourceValue = this.dataSource.data;
          // this.actRoute.queryParams.subscribe((params) => {
          //   let param = params;
          //   let data: any;
          //   dataSourceValue.filter((responce) => {
          //     responce?.child.filter((resp) => {
          //       if (resp?.item?.categoryId == Number(param?.categortyId)) {
          //         data = responce;
          //       }
          //     });
          //   });
          //   console.log(data, "dataaaa")
          //   this.treeControl.expand(data)
          //   this.treeControl.isExpanded(data);
          // });
        },
        (error) => {
          this.noProduct = true;
          // this.filterHide();
          this.showLoader = false;
          this.gifLoader = false;
        }
      );
    } else {
      // this.noScroll = false;
      if (!this.page || this.noScroll) {
        this.page = 1;
      } else {
        this.page = this.page + 1;
      }

      if (!this.getId) {
        this.getId = "";
      } else {
        document.getElementById("filter-button").style.display = "block";
      }

      if (this.deliveryIn) {
        //  this.filterHide();
      }
      // if (this.totalPage < this.page) {
      //   this.gifLoader = false;
      //   return;
      // }
      // this.categoryName = "";

      //set Category Name
      if (this.deliveryIn) {
        // if (this.deliveryIn == "2hour,sameDay") {
        //   this.categoryName = "Same Day Delivery Cakes";
        // }
        // if (this.deliveryIn == "2hour") {
        //   this.categoryName = "2 Hour Delivery Cakes";
        // }
        console.log();
        this.categoryName = "Need Today";
        // this.categoryName = this.deliveryTimes.find((x) => Number(x.deliverytimeId) == Number(this.deliveryIn))?.deliveryName
        //   ? this.deliveryTimes.find((x) => Number(x.deliverytimeId) == Number(this.deliveryIn))?.deliveryName : this.categoryName;
        //set seo title
        this.seoTitle.setTitle(this.categoryName);
        this.isBannerImgAvailable = true;
      }

      // "/product/filter?categoryId=" +
      // this.getId +
      // "&deliveryIn=" +
      // (id ? id : this.deliveryIn) +
      // "&search=" +
      // this.searchKey +
      // "&page=" +
      // this.page;
      let kilogram: any = this.kilograms.find(
        (x) => Number(x.id) == Number(this.filterForm.value.kilograms)
      );
      let discount: any = this.discounts.find(
        (x) => Number(x.id) == Number(this.filterForm.value.discount)
      );

      let params = {
        categortyId: this.getId,
        //'search': this.searchKey,
        // pageNo: this.page,
        // pageSize: 6,
        deliverySpeed: this.deliveryIn ? this.deliveryIn : "",
        priceMax: this.filterForm.value.priceMax
          ? this.filterForm.value.priceMax
          : this.priceMax
          ? this.priceMax
          : "",
        priceMin: this.filterForm.value.priceMin
          ? this.filterForm.value.priceMin
          : this.priceMin
          ? this.priceMin
          : "",
        kiloGramMin: kilogram != undefined ? kilogram?.minValue : "",
        kiloGramMax: kilogram != undefined ? kilogram?.maxValue : "",
        discountMax: discount != undefined ? discount?.maxValue : "",
        discountMin: discount != undefined ? discount?.minValue : "",
        sortBy: this.priceSorting ? this.priceSorting : "",
        isPanIndiaProduct: this.isPanIndia ? this.isPanIndia : 0,
      };

      if (!this.isneedtoday) {
        this.router.navigate([`/category/${this.roterId}`], {
          queryParams: params,
          queryParamsHandling: "merge",
          // preserve the existing query params in the route
          // skipLocationChange: true,
        });
      } else {
        this.deliveryTimes = this.deliveryTimes.filter(
          (element) => element.backingTime <= 4
        );
        this.priceSortBy = this.priceSortBy.filter(
          (element) => element.value != "discount"
        );
        let todayParams = {
          deliverySpeed: this.deliveryIn ? this.deliveryIn : "",
          deliveryIn: this.deliveryIn ? this.deliveryIn : "",
          sortBy: this.priceSorting ? this.priceSorting : "",
        };
        this.router.navigate([`/delivery-filter`], {
          queryParams: todayParams,
          queryParamsHandling: "merge",
          // preserve the existing query params in the route
          skipLocationChange: true,
        });
      }

      let object: any = {
        // categortyId: this.getId,
        search: this.getId,
        pageNo: this.page,
        pageSize: 6,
        deliverySpeed: this.deliveryIn
          ? this.deliveryIn
          : this.deliveryInCategory
          ? this.deliveryInCategory
          : "0",
        priceMax: this.filterForm.value.priceMax
          ? this.filterForm.value.priceMax
          : this.priceMax
          ? this.priceMax
          : "",
        priceMin: this.filterForm.value.priceMin
          ? this.filterForm.value.priceMin
          : this.priceMin
          ? this.priceMin
          : "",
        kiloGramMin: kilogram != undefined ? kilogram?.minValue : "",
        kiloGramMax: kilogram != undefined ? kilogram?.maxValue : "",
        discountMax: discount != undefined ? discount?.maxValue : "",
        discountMin: discount != undefined ? discount?.minValue : "",
        sortBy: this.priceSorting ? this.priceSorting : "",
      };

      this.getUrl = "/productfilter/searchAll";
      if (this.page == 1) {
        this.showLoader = true;
      } else {
        this.gifLoader = true;
      }
      this.http.getList(this.getUrl, object).subscribe(
        (data: any) => {
          this.getdata = data;

          if (
            Math.ceil(this.getdata.totalElements / this.getdata.currentPageSize) <=
            this.page
          ) {
            this.noScroll = true;
          }
          if (this.getdata.content.product?.length >= 1) {
            this.productData(this.getdata.content.product);
          } else {
            this.showLoader = false;
            this.product2.length > 0
              ? (this.noProduct = false)
              : (this.noProduct = true);
          }
          if (this.getdata.totalElements) {
            this.totalPage = Math.ceil(this.getdata.totalElements / 10);
          }
          setTimeout(() => {
            this.gifLoader = false;
          }, 800);

          // this.productData(this.getdata.content.product);
        },
        (error) => {
          this.noProduct = true;
          // this.filterHide();
          this.showLoader = false;
          this.gifLoader = false;
        }
      );
    }
  }

  //claculate product Price
  productData(data: any) {
    this.showLoader = false;
    this.product = [];
    this.product = data;

    let analyticProducts = [];
    this.getWishList();
    this.product.map((x) => {
      x.seoUrl =
        x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
      if (x.productImages) {
        x.productImages = x.productImages[0].split(",")[0];
      } else {
        x.productImages = "";
      }
      x.productDetails.pricing.map((y) => {
        let decPercentage = y.discount / 100;
        let save = y.price * decPercentage;
        y.save = save;
        y.disPrice = y.price - save;
      });
      let list_name: any;
      x.listings.forEach((element, index) => {
        if (index == 0) {
          list_name = element;
        } else {
          list_name = +"-" + element;
        }
      });

      if (
        this.filterForm.value.kilograms != "" &&
        this.filterForm.value.kilograms != undefined
      ) {
        let kilogram: any = this.kilograms.find(
          (x) => Number(x.id) == Number(this.filterForm.value.kilograms)
        );
        if (
          kilogram.minValue != "" &&
          kilogram.minValue != undefined &&
          kilogram.maxValue != "" &&
          kilogram.maxValue != undefined
        ) {
          let filteredKg: any = [];
          x.productDetails?.pricing.filter((res) => {
            if (
              Number(res.kg) >= kilogram.minValue &&
              Number(res.kg) <= kilogram.maxValue
            ) {
              filteredKg.push(res);
            }
          });
          x.productDetails.pricing = filteredKg;
          let kg: any = [];
          let filtereddate: any = [];
          x.productDetails.pricing.filter((res) => {
            kg.push(Number(res?.kg));
          });
          let min = Math.min.apply(null, kg);
          x.productDetails?.pricing.filter((res) => {
            if (Number(res?.kg) == min) {
              filtereddate.push(res);
            }
          });
          x.productDetails.pricing = filtereddate;
        }
      }

      if (
        (this.priceMin != "" && this.priceMin != undefined) ||
        (this.priceMax != "" && this.priceMax != undefined)
      ) {
        let filteredprice: any = [];
        x.productDetails?.pricing.filter((res) => {
          if (
            Number(res.price) >= this.priceMin &&
            Number(res.price) <= this.priceMax
          ) {
            filteredprice.push(res);
          }
        });
        x.productDetails.pricing = filteredprice;
        let price: any = [];
        let filtereddate: any = [];
        x.productDetails.pricing.filter((res) => {
          price.push(Number(res?.disPrice ? res?.disPrice : res?.price));
        });
        let min = Math.min.apply(null, price);
        x.productDetails?.pricing.filter((res) => {
          if (
            res?.disPrice ? Number(res?.disPrice) == min : Number(res?.price) == min
          ) {
            filtereddate.push(res);
          }
        });
        x.productDetails.pricing = filtereddate;
      }
      analyticProducts.push({
        id: x.productId,
        name: x.productName,
        list_name: `Product List - ${x.categoryName}`,
        brand: "Puppy's",
        category: x.categoryName,
        variant: x.productDetails.pricing[0]?.flavour,
        price: x.productDetails.pricing[0]?.disPrice,
      });
    });

    this.analyticsService.viewItemList(analyticProducts);
    if (this.product.length != 0) {
      this.product2 = [...this.product2, ...this.product];
      const removeDublicate = [
        ...new Map(this.product2.map((m) => [m.productId, m])).values(),
      ];
      this.product2 = removeDublicate;
    }
    if (
      this.product.length != 0 &&
      this.product2.length != 0 &&
      this.product2 != undefined &&
      this.product2 != null
    ) {
      this.noProduct = false;
    } else {
      this.noProduct = true;
      //  this.filterHide();
    }
  }

  //////////////////////////////Open & Close Navbar/////////////////////////////////

  openNav() {
    this.isOpened = 1;
  }

  closeNav() {
    this.isOpened = 0;
  }

  initialOpenNav() {
    this.isOpened = 0;
    if (window.innerWidth > 1010) {
      this.openNav();
    }
  }

  filterHide() {
    this.closeNav();
    document.getElementById("filter-button").style.display = "none";
  }

  //////////////////////////////Open & Close Navbar End/////////////////////////////////

  //set seo title on destroy component
  ngOnDestroy() {
    this.seoTitle.setTitle(" Puppy's Bakery ");
  }

  //////////////////////// Filter ///////////////////////////////
  selection = [];
  changeCheckbox(item: any, event) {
    if (event.target.checked === true) {
      //push
      this.selection = [...this.selection, item.categoryId];
    } else {
      //remove
      this.selection = this.selection.filter((user) => user !== item.categoryId);
    }
  }

  filterProduct(time) {
    this.router.navigate(["/category", this.selection.toString()], {
      queryParams: {
        deliveryIn: time,
      },
    });
  }

  deliveryFilterFun(data) {
    if (data) {
      console.log(data, "data");
      // this.filterHide();
      // this.router.navigate(["/delivery-filter"], {
      //   queryParams: { deliveryIn: data },
      // });
      // this.deliveryIn = String(data);
      if (this.deliveryIn) {
        this.deliveryIn = String(data);
      }
      this.deliveryInCategory = String(data);
      this.totalPage = 1;
      this.page = "";
      this.product2 = [];
      this.product = [];
      let deliverySingleData = this.deliveryTimes.filter(
        (x) => Number(x.deliverytimeId) == Number(data)
      )[0];
      if (deliverySingleData != undefined) {
        this.analyticsService.commonEvents("Delivery Time Based Filter to Product", {
          event_category: `Delivery Time : ${deliverySingleData.deliveryName},From Time:${deliverySingleData.fromTime},To Time:${deliverySingleData.toTime}`,
          event_label: `Delivery Time : ${deliverySingleData.deliveryName},From Time:${deliverySingleData.fromTime},To Time:${deliverySingleData.toTime}`,
          value: `Delivery Time : ${deliverySingleData.deliveryName},From Time:${deliverySingleData.fromTime},To Time:${deliverySingleData.toTime}`,
        });
      }
      this.actRoute.queryParams.subscribe((params) => {
        if (params?.categortyId) {
          this.getId = Number(params?.categortyId);
        }
      });
      // this.categoryName = data
      // ? this.deliveryTimes.find((x) => x.deliverytimeId == data)?.deliveryName
      // : this.deliveryTimes[0]?.deliveryName;
      // console.log('77777----->>>>>>')
      this.get(data);
    }
  }

  /*******************************************************Sort By op[tion************************************************************/
  priceFilterFun(data) {
    let deliverySingleData = this.deliveryTimes.filter(
      (x) => Number(x.deliverytimeId) == Number(data)
    )[0];
    this.analyticsService.commonEvents("Price Sorting", {
      event_category: `Delivery Time : Price Sorting ${data}`,
      event_label: `Price Sorting ${data}`,
      value: `Price Sorting ${data}`,
    });
    // if (data == "pricelowtohigh") {
    //   this.priceSorting=data;
    //   this.product2 = this.product2.sort(function (a, b) {
    //     return (
    //       a.productDetails.pricing[0].disPrice -
    //       b.productDetails.pricing[0].disPrice
    //     );
    //   });
    // }
    //  else if (data == "pricehightolow") {
    //   this.priceSorting=data;
    //     this.product2 = this.product2.sort(function (a, b) {
    //       return (
    //         b.productDetails.pricing[0].disPrice -
    //         a.productDetails.pricing[0].disPrice
    //       );
    //     });
    //   }
    //   else{
    //     this.priceSorting=data;
    //   }
    // if (data) {
    this.priceSorting = data;
    //   this.totalPage = 1;
    this.noScroll = false;
    this.page = "";
    this.product2 = [];
    this.product = [];
    this.actRoute.queryParams.subscribe((params) => {
      if (params?.categortyId) {
        this.getId = Number(params?.categortyId);
      }
    });
    console.log("8888----->>>>>>");
    this.get(data);
    // }
  }
  //onscroll load data
  onScroll() {
    if (this.noScroll == false) {
      console.log("9999----->>>>>>");
      this.get("0");
    }
  }

  ////////////////////////// WishList Section //////////////////////////////////////////
  wishList;
  getWishList() {
    if (this.loginIN == 1) {
      this.http.get("/user/wishlist").subscribe(
        (data) => {
          this.wishList = data;
          this.product?.map((x) => {
            if (
              this.wishList.content.filter((s) => s.productID == x.productId)
                .length >= 1
            ) {
              x.like = true;
            } else {
              x.like = false;
            }
          });
        },
        (error) => {}
      );
    }
  }

  //add or remove wishList
  hit: any = 0;
  // subcategory

  likeData;
  like(id, e) {
    console.log(this.product2,"product2")
    console.log(this.loginIN, "while logging in ----------->", {
      functionName: "like",
      params: { p1: id, p2: e },
      component: "subcategory",
      date: Date.now(),
    });

    if (this.loginIN != 1) {
      this.memoryService.setMemory({
        functionName: "like",
        params: { p1: id, p2: e },
        component: "subcategory",
        date: Date.now(),
      });
      $("#loginSignup").modal("show");
      return;
    }
    let addWishListData: any;
    $(e).addClass("fa-circle-o-notch fa-spin disabled");
    console.log(this.getdata);

    this.http.post("/user/wishlist/" + id, "").subscribe(
      (data) => {
        this.loading = false;
        this.likeData = data;
        this.addCartService.setWishList("Refresh Wishlist");
        this.userService.setWishlist();
        if (
          this.likeData?.status == 1034 ||
          this.likeData?.message == "Wishlist Added "
        ) {
          this.product2.map((x) => {
            if (x.productId == id) {
              x.like = true;
              addWishListData = {
                id: x.productId,
                name: x.productName,
                brand: "Puppy's",
                category: x.categoryName,
                quantity: 1,
              };
            }
          });
          this.cd.markForCheck();
        }
        if (
          this.likeData?.status == 1036 ||
          this.likeData?.message == "Wishlist removed "
        ) {
          this.product2.map((x) => {
            if (x.productId == id) {
              x.like = false;
            }
          });
          this.cd.markForCheck();
        }
      },
      (error) => {
        this.loading = false;
        this.cd.markForCheck();
      }
    );
    if (
      this.likeData?.status == 1034 ||
      this.likeData?.message == "Wishlist Added "
    ) {
      this.analyticsService.addToWishlist({
        currency: "INR",
        items: addWishListData,
      });
    }
  }
  //////////////////////////End WishList Section //////////////////////////////////////////

  //show & hide filter drop down
  async openAccordion(e) {
    var $current = $(e).find(".accordion-arrow");
    $current.toggleClass("fa-angle-up fa-angle-down");
  }

  public skeletonLoader: Boolean = true;
  checkImageLoad() {
    this.skeletonLoader = false;
  }

  /////////////////filter price change Currently Not implemented ///////////////////////////////
  @ViewChildren("allTheseThings") things: QueryList<any>;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    var bodyPos =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    var windowH =
      document.documentElement.clientHeight || document.body.clientHeight;
    bodyPos = bodyPos - windowH;
    var windowPos = document.documentElement.scrollTop || document.body.scrollTop;
    var percentage = Math.round((windowPos / bodyPos) * 100);
    if (Math.round(percentage) > 95 && this.NextCall == 1) {
      this.NextCall = 0;
    }
  }
  public NextCall: any;
  minValue: number = 100;
  maxValue: number = 2000;
  options: Options = {
    floor: 0,
    ceil: 3000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "Rs. " + value;
        case LabelType.High:
          return "Rs. " + value;
        default:
          return "";
      }
    },
  };
  onlyNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  reset(type) {
    let id = this.newId;
    //     if(id == "trendingCake" || id == "bestSellerCake" || id == "featureCake"){
    // this.newPagination()
    //     }

    if (type == 1) {
      this.parentCategoryId = "";
      this.subCategoryparentId = "";
      this.subCategoryId = this.catId;
      this.getId = this.catId;
      this.getSubCategory();
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Reset Category Filter Data",
        event_label: "Reset Category Filter Data",
        value: "Reset Category Filter Data",
      });
    } else if (type == 2) {
      this.filterForm.get("priceMin").setValue("");
      this.filterForm.get("priceMax").setValue("");
      this.filterForm.get("price").setValue("");
      this.priceMin = "";
      this.priceMax = "";
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Reset Price Filter Data",
        event_label: "Reset Price Filter Data",
        value: "Reset Price Filter Data",
      });
      this.actRoute.queryParams.subscribe((params) => {
        if (params?.categortyId) {
          this.getId = Number(params?.categortyId);
        }
      });
    } else if (type == 3) {
      this.filterForm.get("discount").setValue("");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Reset Discount Filter Data",
        event_label: "Reset Discount Filter Data",
        value: "Reset Discount Filter Data",
      });
    } else if (type == 4) {
      this.filterForm.get("kilograms").setValue("");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Reset Kilograms Filter Data",
        event_label: "Reset Kilograms Filter Data",
        value: "Reset Kilograms Filter Data",
      });
      this.actRoute.queryParams.subscribe((params) => {
        if (params?.categortyId) {
          this.getId = Number(params?.categortyId);
        }
      });
      this.actRoute.queryParams.subscribe((params) => {
        if (params?.categortyId) {
          this.getId = Number(params?.categortyId);
        }
      });
    }
    this.noScroll = false;
    this.totalPage = 1;
    this.page = "";
    this.product2 = [];
    this.product = [];
    console.log("100000----->>>>>>");
    this.get("0");
  }

  /////////////////end filter price change///////////////////////////////
  child(val) {}

  getprice(value) {
    let data = value;
    let filteredprice = data.filter(
      (res) =>
        Number(res.price) >= this.priceMin && Number(res.price) <= this.priceMax
    );
    let price: any = [];
    filteredprice.filter((res) => {
      price.push(Number(res?.disPrice ? res?.disPrice : res?.price));
    });
    let min = Math.min.apply(null, price);
    let filtereddate = filteredprice.find((res) =>
      res?.disPrice ? res?.disPrice == min : res?.price == min
    );

    return filtereddate?.disPrice
      ? filtereddate?.disPrice.toFixed(2)
      : filtereddate?.price.toFixed(2);
  }
}
