import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";

@Component({
  selector: "app-baking",
  templateUrl: "./baking.component.html",
  styleUrls: ["./baking.component.less"],
})
export class BakingComponent implements OnInit {
  @Input() public imgDate: any;
  constructor(
    private router: Router,
    private analyticsService: GoogleAnalyticsService
  ) {}
  getdata;

  ngOnChanges() {
    if (this.imgDate) {
      this.getdata = this.imgDate
        .filter((element) => element.bannerType == "index_Baking_1")
        .sort(function (a, b) {
          return a?.sortOrder - b?.sortOrder;
        });
    }
  }

  bannerClick() {
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Route Product list page",
      event_label: "View Category based Product list",
      value: "View Category based Product list",
    });
  }

  ngOnInit(): void {}

  public skeletonLoader: Boolean = true;
  checkImageLoad() {
    this.skeletonLoader = false;
  }
}
