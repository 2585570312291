<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" *ngIf="getdata">
  <div class="top-rounded-border">
  </div>
  <div class="icon-container">
    <div class="row py-2">
      <div class="col-12 col-lg-12">
        <div class="row">
          <div class="col-12  text-center">
            <h6 class="hotline">
              {{title}}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-inner">
    <ngx-skeleton-loader *ngIf="skeletonLoader" [theme]="{ height: '465px', width: '100%', 'margin-bottom': '0' }"
      count="1" appearance="line"> </ngx-skeleton-loader>
    <div class="carousel-item" *ngFor="let item of getdata; let index = index" [class.active]="index == 0">
      <img src="{{item.image}}" (load)="checkImageLoad()" class="d-block w-100"
        (click)="bannerClick(item.isClickable, item.redirectId)">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>