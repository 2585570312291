import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";

@Component({
  selector: "app-dessert-and-wedding",
  templateUrl: "./dessert-and-wedding.component.html",
  styleUrls: ["./dessert-and-wedding.component.less"],
})
export class DessertAndWeddingComponent implements OnInit {
  @Input() public imgDate: any;
  constructor(
    private router: Router,
    public analyticsService: GoogleAnalyticsService
  ) {}
  getdata;
  data1 = "";
  data2 = "";

  ngOnChanges() {
    if (this.imgDate) {
      this.getdata = this.imgDate
        .filter((element) => element.bannerType == "index_cake_design")
        .sort(function (a, b) {
          return a?.sortOrder - b?.sortOrder;
        });
      this.data1 = this.getdata[0];
      this.data2 = this.getdata[1];
    }
  }

  bannerClick(click, id) {
    if (click == true && id) {
      this.router.navigate(["/category", id]);
    }
  }

  ngOnInit(): void {}
}
