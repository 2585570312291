import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { HttpService } from "../../../services/http/http.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { debounceTime, first } from "rxjs/operators";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";
import { AddCartService } from "src/app/services/http/add-cart.service";
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
  SocialAuthServiceConfig,
  FacebookLoginProvider,
} from "angularx-social-login";
import { HttpParams } from "@angular/common/http";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { Subscription } from "rxjs";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";
import { promise } from "protractor";
import { UserService } from "src/app/services/memory-services/user.service";
import moment from "moment";

declare var $: any;
declare var FB: any;
@Component({
  selector: "app-login-signup",
  templateUrl: "./login-signup.component.html",
  styleUrls: ["./login-signup.component.less"],
})
export class LoginSignupComponent implements OnInit {
  socialUser: SocialUser;
  isLoggedin: boolean;
  googleSub!: Subscription;
  @ViewChild("loginSignupModal") loginSignupModal: ElementRef;

  urlType;
  public loginIN: any = localStorage.getItem("loginIN") == "1" ? true : false;

  public Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  signUpError = "";
  loginError = "";
  emailOrPhone = "";
  referral = "";
  users: Object;
  faceBookSub!: Subscription;
  loading = false;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public addCartService: AddCartService,
    private socialAuthService: SocialAuthService,
    public analyticsService: GoogleAnalyticsService,
    public cd: ChangeDetectorRef,
    private memoryService: MemoryService,
    private userService: UserService,
    private ngzone: NgZone
  ) {}

  // public loginIN :Boolean = false
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("ref")) {
        this.referral = params["ref"];
        this.emptyForm();
      }
      this.analyticsService.pageView(
        "View Sign-in/Sign-up",
        `Sign-in/Sign-up Form`,
        "Popup"
      );
    });

    this.authenticationService.loggedOut$.subscribe((res) => {
      this.loginIN = null;
      this.resetForms();
    });

    this.memoryService.memoryEmitter$.subscribe((res) => {
      this.loginIN = true;
    });

    this.emptyForm();
    this.emptyForm2();
    this.emptyForm3();
    //open modal after email verify & for got password
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("token") && params.hasOwnProperty("type")) {
        this.signInSingUp = false;
        this.urlType = "email-verify";
      }
    });
  }

  form3: FormGroup;

  submitted3;
  emptyForm3() {
    this.submitted3 = false;
    this.form3 = this.formBuilder.group({
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
    });
  }

  get f3() {
    return this.form3.controls;
  }

  loginWithGoogle(): void {
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.loading = true;
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(() => {
        this.googleSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;
            this.isLoggedin = User != null;
            this.setValues(this.socialUser);
            this.analyticsService.commonEvents("sign_up", {
              event_category: "Login With Google",
              event_label: "Login With Google",
              value: "Login With Google",
            });
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }
  setValues(item) {
    this.form.patchValue({
      firstName: item.firstName,
      lastName: item.lastName,
      provider: item.provider,
      email: item.email,
      imageUrl: item?.photoUrl,
    });
  }

  loginWithFacebook(): void {
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.loading = true;
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        this.faceBookSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;

            this.isLoggedin = userData != null;
            this.setValues(this.socialUser);
            this.analyticsService.commonEvents("sign_up", {
              event_category: "Login With Face Book",
              event_label: "Login With Face Book",
              value: "Login With Face Book",
            });
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  signUpWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  forgotPassword() {
    this.submitted2 = false;
    this.form2.reset();
    this.signInSingUp = false;
    $("#loginSignup").modal("hide");
    $("#forgotPassword").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Forgot Password Popup Form",
      event_label: "Open Forgot Password Popup Form",
      value: "Open Forgot Password Popup Form",
    });
  }
  getotp() {
    this.submitted3 = true;
    let form = this.form3.value;
    console.log("hitting get otp call ", this.form3.value);

    let obj = {
      phoneNumber: form?.mobileNumber,
    };
    let params = new HttpParams();
    for (let key in obj) {
      params = params.set(key, obj[key]);
    }
    if (this.form3.valid) {
      this.loading = true;
      this.http.post("/auth/otpLogin", params).subscribe(
        (data) => {
          this.loginSignupModal.nativeElement.click();
          localStorage.setItem("loginIN", "2");
          this.loginIN = false;
          this.signInSingUp = false;
          // this.loginwithMobile = false;
          this.signUpLoader = false;
          let mobNumber = form.mobileNumber;
          sessionStorage.setItem("otpMobile", mobNumber);
          $("#loginSignup").modal("hide");
          $("#verifications").modal("show");
          // $("#loginSignup").on("hidden.bs.modal", function () {
          //   if(checkNull(mobNumber)){
          //     console.log(mobNumber,'in the function---------->');

          //     $("#verifications").modal("show");
          //   }
          // });
          this.analyticsService.commonEvents("Onclick", {
            event_category: "Open OTP Verification Popup Form",
            event_label: "Open OTP Verification Popup Form",
            value: "Open OTP Verification Popup Form",
          });
          this.addCartService.setTimer("someValue");
          this.loading = false;
        },
        (error) => {
          if (this.form3.invalid) {
            this.signUpError = "Enter Valid 10 digit Mobile Number";
          } else {
            this.signUpError = error.message;
          }

          this.Toast.fire({
            icon: "error",
            title: this.signUpError,
          });

          if (error?.status == 1002) {
            sessionStorage.setItem("otpMobile", error?.PhoneNumber);
            $("#loginSignup").modal("hide");
            $("#verification").modal("show");
          }
          this.signUpLoader = false;
          this.loading = false;
          this.addCartService.setTimer("someValue");
        }
      );
    } else {
      this.signUpError = "Enter Valid 10 digit Mobile Number";
      this.Toast.fire({
        icon: "error",
        title: this.signUpError,
      });
    }
  }

  reset1() {
    this.form.reset();
  }

  /////////////////////sign up start//////////////////////////////
  form: FormGroup;
  submitted;
  emptyForm() {
    if (!this.referral) {
      this.referral = "";
    }
    this.submitted = false;
    this.form = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: [""],
      gender: ["", Validators.required],
      birthdayDate: ["", Validators.required],
      email: ["", [Validators.required,Validators.email]],
      phoneNumber: ["", [Validators.required, Validators.pattern("[0-9]{10,10}$")]],
      password: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(50)],
      ],
      imageUrl: [""],
      role: ["user"],
      referralCode: [this.referral],
      provider: [],
      approvedEmail: [],
      approvedMobile: [],
    });
  }

  public signUpLoader: Boolean = false;
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.emailOrPhone = "";
    this.signUpError = "";
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var form = this.form.value;
    this.signUpLoader = true;
    if (form.provider == "GOOGLE") {
      this.analyticsService.commonEvents("sign_up", {
        event_category: "User Sign Up Via Google",
        event_label: "User Sign Up Via Google",
        value: "User Sign Up Via Google",
      });
      let obj = {
        email: form?.email,
        firstName: form?.firstName,
        gender: form?.gender,
        imageUrl: form?.imageUrl ?? "",
        lastName: form?.lastName,
        password: form?.password,
        phoneNumber: form?.phoneNumber,
        provider: (form?.provider).toLowerCase(),
        approvedEmail: true,
        approvedMobile: false,
        referralCode: this.referral,
        role: "user",
        roleId: 0,
        birthdayDate: moment(form?.birthdayDate).format("YYYY-MM-DD"),
      };
      this.loading = true;
      this.http.post("/auth/signup", obj).subscribe(
        (data) => {
          this.loginSignupModal.nativeElement.click();
          localStorage.setItem("loginIN", "2");
          // this.loginIN = true;
          this.signInSingUp = false;
          this.signUpLoader = false;
          let mobNumber = this.form.controls["phoneNumber"].value;
          sessionStorage.setItem("otpMobile", mobNumber);
          this.emptyForm();
          $("#loginSignup").modal("hide");
          $("#verification").modal("show");
          this.analyticsService.commonEvents("Onclick", {
            event_category: "Open OTP Verification Popup Form",
            event_label: "Open OTP Verification Popup Form",
            value: "Open OTP Verification Popup Form",
          });
          this.addCartService.setTimer("someValue");
          this.loading = false;
        },
        (error) => {
          this.signUpError = error.message;
          this.Toast.fire({
            icon: "error",
            title: this.signUpError,
          });
          this.signUpLoader = false;
          this.loading = false;
        }
      );
    } else if (form.provider == "FACEBOOK") {
      this.analyticsService.commonEvents("sign_up", {
        event_category: "User Sign Up Via Face BooK",
        event_label: "User Sign Up Via Face BooK",
        value: "User Sign Up Via Face BooK",
      });
      if (form.email != undefined) {
        let obj = {
          email: form?.email,
          firstName: form?.firstName,
          gender: form?.gender,
          imageUrl: form?.imageUrl ?? "",
          lastName: form?.lastName,
          password: form?.password,
          phoneNumber: form?.phoneNumber,
          provider: (form?.provider).toLowerCase(),
          approvedEmail: true,
          approvedMobile: false,
          referralCode: this.referral,
          role: "user",
          roleId: 0,
          birthdayDate: moment(form?.birthdayDate).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.http.post("/auth/signup", obj).subscribe(
          (data) => {
            this.loginSignupModal.nativeElement.click();
            localStorage.setItem("loginIN", "2");
            // this.loginIN = true;
            this.signInSingUp = false;
            this.signUpLoader = false;
            let mobNumber = this.form.controls["phoneNumber"].value;
            sessionStorage.setItem("otpMobile", mobNumber);
            this.emptyForm();
            $("#loginSignup").modal("hide");
            $("#verification").modal("show");
            this.analyticsService.commonEvents("Onclick", {
              event_category: "Open OTP Verification Popup Form",
              event_label: "Open OTP Verification Popup Form",
              value: "Open OTP Verification Popup Form",
            });
            this.addCartService.setTimer("someValue");
            this.loading = false;
          },
          (error) => {
            this.signUpError = error.message;
            this.Toast.fire({
              icon: "error",
              title: this.signUpError,
            });
            this.signUpLoader = false;
            this.loading = false;
          }
        );
      } else {
        let obj = {
          email: form?.email,
          firstName: form?.firstName,
          gender: form?.gender,
          imageUrl: form?.imageUrl ?? "",
          lastName: form?.lastName,
          password: form?.password,
          phoneNumber: form?.phoneNumber,
          provider: (form?.provider).toLowerCase(),
          approvedEmail: false,
          approvedMobile: false,
          referralCode: this.referral,
          role: "user",
          roleId: 0,
          birthdayDate: moment(form?.birthdayDate).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.http.post("/auth/signup", obj).subscribe(
          (data) => {
            this.loginSignupModal.nativeElement.click();
            localStorage.setItem("loginIN", "2");
            // this.loginIN = true;
            this.signInSingUp = false;
            this.signUpLoader = false;
            let mobNumber = this.form.controls["phoneNumber"].value;
            sessionStorage.setItem("otpMobile", mobNumber);
            this.emptyForm();
            $("#loginSignup").modal("hide");
            $("#verification").modal("show");
            this.analyticsService.commonEvents("Onclick", {
              event_category: "Open OTP Verification Popup Form",
              event_label: "Open OTP Verification Popup Form",
              value: "Open OTP Verification Popup Form",
            });
            this.addCartService.setTimer("someValue");
            this.loading = false;
          },
          (error) => {
            this.signUpError = error.message;
            this.Toast.fire({
              icon: "error",
              title: this.signUpError,
            });
            this.signUpLoader = false;
            this.loading = false;
          }
        );
      }
    } else {
      this.analyticsService.commonEvents("sign_up", {
        event_category: "User Sign Up Via Email ID / Mobile Number",
        event_label: "User Sign Up Via Mobile Number",
        value: "User Sign Up Via Mobile Number",
      });
      let obj = {
        email: form?.email ?? "",
        firstName: form?.firstName,
        gender: form?.gender,
        imageUrl: form?.imageUrl ?? "",
        lastName: form?.lastName,
        password: form?.password,
        phoneNumber: form?.phoneNumber,
        provider: "local",
        approvedEmail: false,
        approvedMobile: false,
        referralCode: this.referral,
        role: "user",
        roleId: 0,
        birthdayDate: moment(form?.birthdayDate).format("YYYY-MM-DD"),
      };
      this.loading = true;
      this.http.post("/auth/signup", obj).subscribe(
        (data) => {
          this.signInSingUp = false;
          // this.loginIN = true;
          this.signUpLoader = false;
          let mobNumber = this.form.controls["phoneNumber"].value;
          sessionStorage.setItem("otpMobile", mobNumber);
          this.emptyForm();
          $("#loginSignup").modal("hide");
          $("#verification").modal("show");
          this.analyticsService.commonEvents("Onclick", {
            event_category: "Open OTP Verification Popup Form",
            event_label: "Open OTP Verification Popup Form",
            value: "Open OTP Verification Popup Form",
          });
          this.addCartService.setTimer("someValue");
          this.loading = false;
        },
        (error) => {
          this.signUpError = error.message;
          this.Toast.fire({
            icon: "error",
            title: this.signUpError,
          });
          this.signUpLoader = false;
          this.loading = false;
        }
      );
    }
  }
  //////////////////signup end//////////////////////////

  ///////////////////sign in start////////////////////////
  form2: FormGroup;
  submitted2;
  emptyForm2() {
    this.submitted2 = false;
    this.form2 = this.formBuilder.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  resetForms() {
    this.emptyForm();
    this.emptyForm2();
    this.emptyForm3();
  }

  // resetLogin(){
  //   this.form2.reset();
  // }

  user;
  public loginLoader: Boolean = false;
  get f2() {
    return this.form2.controls;
  }
  onSubmit2() {
    this.loginError = "";
    this.submitted2 = true;
    if (this.form2.invalid) {
      return;
    }
    this.analyticsService.commonEvents("login", {
      event_category: "User Sign Up Via Email ID / Mobile Number",
      event_label: "User Sign Up Via Mobile Number",
      value: "User Sign Up Via Mobile Number",
    });
    this.loginLoader = true;
    this.authenticationService
      .login(this.f2.userName.value, this.f2.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.user = data;
          //if admin login
          if (this.user.role != "user") {
            this.Toast.fire({
              icon: "error",
              title: "You are not having user access",
            });
            this.loginLoader = false;
            return;
          }
          this.Toast.fire({
            icon: "success",
            title: "Login Successfully",
          });

          this.loginSignupModal.nativeElement.click();
          localStorage.setItem("loginIN", "1");
          this.loginIN = true;
          this.loginLoader = false;
          this.memoryService.memorydata
            .pipe(debounceTime(300))
            .subscribe((res: any) => {
              if (checkNull(res)) {
                this.memoryService.memoryEmitter$.emit(res);
              } else {
                this.memoryService.memoryEmitter$.emit("");
              }
            });
          if (this.urlType == "email-verify") {
            window.location.href = "/";
            return;
          }

          //
        },
        (error) => {
          this.loginError = error.message;
          console.log(error, "errorz");

          this.Toast.fire({
            icon: "error",
            title: error?.message ?? "Something went wrong. Try again.",
          });
          if (error.status == 1002) {
            sessionStorage.setItem("otpMobile", error?.PhoneNumber);
            $("#loginSignup").modal("hide");
            $("#verification").modal("show");
            this.analyticsService.commonEvents("Onclick", {
              event_category: "Open OTP Verification Popup Form",
              event_label: "Open OTP Verification Popup Form",
              value: "Open OTP Verification Popup Form",
            });
            this.addCartService.setTimer("someValue");
          }
          this.loginLoader = false;
        }
      );
  }

  loginWithGoogles() {
    this.analyticsService.commonEvents("login", {
      event_category: "Login With Google",
      event_label: "Login With Google",
      value: "Login With Google",
    });
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.loading = true;
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(() => {
        this.googleSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;
            this.isLoggedin = User != null;
            if (this.socialUser != undefined) {
              let obj = {
                emailOrMobile: this.socialUser?.email,
                provider: (this.socialUser?.provider).toLowerCase(),
                providerAccessToken: this.socialUser?.authToken,
              };

              let params = new HttpParams();
              for (let key in obj) {
                params = params.set(key, obj[key]);
              }
              this.http.post("/auth/socialLogin", params).subscribe(
                (data) => {
                  this.users = data;
                  this.loginSignupModal.nativeElement.click();
                  localStorage.setItem("loginIN", "1");
                  this.loginIN = true;
                  localStorage.setItem("currentUser", JSON.stringify(this.users));
                  this.emptyForm();
                  this.addCartService.setTimer("someValue");
                  this.loading = false;
                  this.memoryService.memorydata
                    .pipe(debounceTime(300))
                    .subscribe((res: any) => {
                      if (checkNull(res)) {
                        this.memoryService.memoryEmitter$.emit(res);
                      } else {
                        this.memoryService.memoryEmitter$.emit("");
                      }
                    });
                },
                (error) => {
                  this.signUpError = error.message;
                  this.Toast.fire({
                    icon: "error",
                    title: this.signUpError,
                  });
                  this.signUpLoader = false;
                  this.loading = false;
                  if (error?.status == 1002) {
                    sessionStorage.setItem("otpMobile", error?.PhoneNumber);
                    $("#loginSignup").modal("hide");
                    $("#verification").modal("show");
                    this.addCartService.setTimer("someValue");
                  }
                }
              );
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  loginWithFacebooks() {
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.analyticsService.commonEvents("login", {
      event_category: "Login With Face Book",
      event_label: "Login With Face Book",
      value: "Login With Face Book",
    });
    this.loading = true;
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(() => {
        this.faceBookSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;
            this.isLoggedin = User != null;
            if (this.socialUser != undefined) {
              let obj = {
                emailOrMobile: this.socialUser?.email,
                provider: (this.socialUser?.provider).toLowerCase(),
                providerAccessToken: this.socialUser?.authToken,
              };

              let params = new HttpParams();
              for (let key in obj) {
                params = params.set(key, obj[key]);
              }
              this.http.post("/auth/socialLogin", params).subscribe(
                (data) => {
                  this.users = data;
                  this.loginIN = true;
                  this.loginSignupModal.nativeElement.click();
                  localStorage.setItem("loginIN", "1");
                  localStorage.setItem("currentUser", JSON.stringify(this.users));
                  // location.reload();
                  this.emptyForm();
                  this.loading = false;
                  this.addCartService.setTimer("someValue");
                  this.memoryService.memorydata
                    .pipe(debounceTime(300))
                    .subscribe((res: any) => {
                      if (checkNull(res)) {
                        this.memoryService.memoryEmitter$.emit(res);
                      } else {
                        this.memoryService.memoryEmitter$.emit("");
                      }
                    });
                },
                (error) => {
                  this.signUpError = error.message;
                  this.Toast.fire({
                    icon: "error",
                    title: this.signUpError,
                  });
                  if (error?.status == 1002) {
                    sessionStorage.setItem("otpMobile", error?.PhoneNumber);
                    $("#loginSignup").modal("hide");
                    $("#verification").modal("show");
                    this.addCartService.setTimer("someValue");
                  }
                  this.signUpLoader = false;
                  this.loading = false;
                }
              );
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }
  public openModel(id) {
    console.log(id);

    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("ref")) {
        this.referral = params["ref"];
      }
    });
    this.submitted = false;
    this.submitted3 = false;
    this.submitted2 = false;
    this.form.reset();
    this.form2.reset();
    this.form3.reset();
    this.emptyForm();
    this.emptyForm2();
    this.emptyForm3();
    if (id == "login") {
      this.signInSingUp = false;
    } else {
      this.signInSingUp = true;
      $("#verifications").modal("hide");
      $("#verification").modal("hide");
    }

    this.loginSignupModal.nativeElement.click();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  // ngOnDestroy(){
  //   this.signInSingUp = false;
  // }

  openMobile(id) {
    if (id == "mobile") this.loginwithMobile = true;
    else this.loginwithEmail = true;
    this.loginSignupModal.nativeElement.click();
  }
  //before logout open need help component
  logout() {
    $("#needHelp").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Help Inform Popup",
      event_label: "Open Help Inform Popup",
      value: "Open Help Inform Popup",
    });
  }

  public showPassword: Boolean = false;
  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  public signInSingUp: Boolean = false;
  public loginSignUP() {
    this.submitted = false;
    this.form.reset();
    this.submitted2 = false;
    this.form2.reset();
    this.submitted3 = false;
    this.form3.reset();
    this.signInSingUp = !this.signInSingUp;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Sign Up Popup Form",
      event_label: "Open Sign Up Popup Form",
      value: "Open Sign Up Popup Form",
    });
    setTimeout(() => {
      this.openDate();
    }, 500);
  }

  public loginwithMobile: Boolean = false;
  loginwithmobile() {
    this.submitted3 = false;
    this.form3.reset();
    this.loginwithEmail = false;
    this.loginwithMobile = !this.loginwithMobile;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Login With Mobile No Popup Form",
      event_label: "Open Login With Mobile No Popup Form",
      value: "Open Login With Mobile No Popup Form",
    });
  }

  public loginwithEmail: Boolean = true;
  loginwithemail() {
    this.submitted2 = false;
    this.form2.reset();
    this.loginwithMobile = false;
    this.loginwithEmail = !this.loginwithEmail;
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Login With Email Popup Form",
      event_label: "Open Login With Email Popup Form",
      value: "Open Login With Email Popup Form",
    });
  }

  doSignIn() {
    localStorage.setItem("loginIN", "1");
    this.loginSignupModal.nativeElement.click();
  }

  openDate() {
    var $this = this;
    $("#datepicker2").datetimepicker({
      defaultDate: false,
      timepicker: false,
      format: "d-M-Y",
      step: 15,
      onChangeDateTime: function (ct, $i) {
        $this.ngzone.run(() => {
          $this.form.get("birthdayDate").setValue(moment(ct).format("YYYY-MM-DD"));
          console.log(moment(ct).format("DD-MM-YYYY"), $this.form.value);
        });
      },
    });
  }
}
