import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/services/http/http.service";
declare var $: any;
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { sweetAlert } from "src/app/shared/function/toaster";
import { Title } from "@angular/platform-browser";
import { AppConstants } from "src/app/configuration/app.constants";
import Swal from "sweetalert2";
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ImageSize,
  ThumbnailsPosition,
} from "@ngx-gallery/core";
import { Lightbox } from "ngx-lightbox";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { paginationDTO } from "src/app/shared/paginationDTO";
import moment from "moment";

@Component({
  selector: "app-enquiry-form",
  templateUrl: "./enquiry-form.component.html",
  styleUrls: ["./enquiry-form.component.less"],
})
export class EnquiryFormComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  formType;
  title;
  subtitle;
  imgUrl;
  fileImg;
  fileName;
  loading;
  path2;
  DessertPreferences = [];
  Gallery: any = [];
  getdata2: any;
  imgPth: any;
  weddingGallery: any = [];
  customGallery: any = [];
  selectedTabIndex = 0;
  data;
  items: GalleryItem[];
  // pagination for infinite scroll
  paginationDTO: paginationDTO;
  galleryResult: any;
  pageNo: number = 1;
  noScroll: boolean = false;
  loader: boolean = false;

  constructor(
    private actRoute: ActivatedRoute,
    public http: HttpService,
    private formBuilder: FormBuilder,
    private seoTitle: Title,
    public gallery: Gallery,
    public lightbox: Lightbox,
    public analyticsService: GoogleAnalyticsService
  ) {
    this.paginationDTO = new paginationDTO();
  }

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.actRoute.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("f")) {
        this.formType = params["f"]; //get form type
        let data = params.f;

        data == 1
          ? (this.paginationDTO.search = "custom")
          : data == 2
          ? (this.paginationDTO.search = "desert")
          : data == 3
          ? (this.paginationDTO.search = "wedding")
          : (this.paginationDTO.search = "custom");
        this.pageNo = 1;
        this.noScroll = false;
        this.Gallery = [];
        this.galleryInfinite();
      }
      if (!this.formType) {
        this.formType = 1;
      }
      this.emptyForm();
      this.analyticsService.pageView(
        this.formType == 1
          ? "Wedding Cakes Enquiry"
          : this.formType == 2
          ? "Custom Cakes Enquiry"
          : this.formType == 3
          ? "Dessert Tables Enquiry"
          : "Enquiry",
        `enquiry-form?f=${this.formType}`,
        window.location.origin
      );
    });
    setTimeout(() => {
      // $("#datetimepicker").datetimepicker({
      //   minDate: new Date(),
      //   format: "d/m/Y H:i",
      // });
      this.onDateChange()
    }, 200);

    this.DessertPreferences = [
      "Cup Cake",
      "Dessert Jar",
      "Cookies",
      "Donut",
      "Brownie",
      "Cakesicles",
      "Jujubes",
      "Marshmallow",
      "French Macaron",
      "Mousse",
      "Cake Pop",
    ];
    // this.getGallery("desert");
    // this.getweddingGallery("wedding");
    // this.getcustomGallery("custom");
  }

  ngOnDestroy() {
    this.seoTitle.setTitle(" Puppy's Bakery ");
  }

  galleryInfinite() {
    // this.pageNo++;
    this.paginationDTO.pageNo = this.pageNo;
    this.paginationDTO.pageSize = 4;
    this.loader = true;
    this.http
      .getList("/admin/gallery/admin/product/Pagination", this.paginationDTO)
      .subscribe((res: any) => {
        this.galleryResult = res.content;
        this.Gallery = [...this.Gallery, ...this.galleryResult];
        if (this.Gallery.length >= res.totalElements) {
          this.noScroll = true;
        }
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      });
  }
  onScroll() {
    if (!this.noScroll) {
      this.pageNo++;
      this.galleryInfinite();
    }
  }

  getGallery(value) {
    this.http.get(`/admin/gallery/fetch/${value}`).subscribe(
      (data) => {
        this.getdata2 = data;
        // this.Gallery = this.getdata2.content.filter(
        //   (x) => x?.pageType == "desert"
        // );
        // setTimeout(() => {
        //   this.refreshSlider();
        // }, 200);
      },
      (error) => {}
    );
  }
  getweddingGallery(value) {
    this.http.get(`/admin/gallery/fetch/${value}`).subscribe(
      (data) => {
        this.getdata2 = data;
        this.weddingGallery = this.getdata2.content.filter(
          (x) => x?.pageType == "wedding"
        );
        // setTimeout(() => {
        //   this.refreshSlider();
        // }, 200);
      },
      (error) => {}
    );
  }
  getcustomGallery(value) {
    this.http.get(`/admin/gallery/fetch/${value}`).subscribe(
      (data) => {
        this.getdata2 = data;
        this.customGallery = this.getdata2.content.filter(
          (x) => x?.pageType == "custom"
        );
        // setTimeout(() => {
        //   this.refreshSlider();
        // }, 200);
      },
      (error) => {}
    );
  }
  deleteImage() {
    this.imgUrl = "";
    this.form.controls["imageUrl"].setValue("");
    this.analyticsService.commonEvents("Onclick", {
      event_category: `Deleted Reference Image In ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry`,
      event_label: `Deleted Reference Image In ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry`,
      value: `Deleted Reference Image In ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry`,
    });
  }

  emptyForm() {
    setTimeout(() => {
      this.formTextBoxDesign();
    }, 1000);
    this.imgUrl = "";
    if (this.formType == 1)
      (this.title = "Cake Enquiry Form"), (this.subtitle = "Custom Cakes");
    if (this.formType == 2)
      (this.title = "Dessert Table Enquiry Form"), (this.subtitle = "Dessert Table");
    if (this.formType == 3)
      (this.title = "Wedding Cake Enquiry Form"), (this.subtitle = "Wedding Cake");
    this.seoTitle.setTitle(this.title);
    this.selection = [];
    this.submitted = false;
    this.form = this.formBuilder.group({
      address: [""],
      airConditioned: [""],
      backDrop: [""],
      cakeIcing: [""],
      cakeShape: [""],
      cakeTheme: [""],
      cakeTiers: [""],
      cakeWeight: [""],
      contactAtVenue: [""],
      creamType: [""],
      deliveryDateTime: [""],
      deliveryType: [""],
      email: ["", Validators.email],
      eventDateTime: [""],
      eventVenue: [""],
      extraInformation: [""],
      lastName: [""],
      imageUrl: [""],
      flavour: [""],
      formType: [this.formType],
      name: ["", [Validators.required]],
      noOfGuest: [""],
      phoneNumber: ["", [Validators.required, Validators.pattern("[0-9]{10,10}$")]],
      referenceImages: [""],
      specialInstructions: [""],
      dessertPreference: [""],
    });
    this.onDateChange();
  }

  onDateChange() {
    var $this = this;
    $("#datetimepicker").datetimepicker({
      defaultDate: false,
      format: "d-m-Y H:i",
      minDate:new Date(),
      step: 15,
      onSelectDate: function (ct, $i) {
        $this.form.get("deliveryDateTime").setValue(moment(ct).format('DD-MM-yyyy hh:mm a'));
        $this.form.get("deliveryDateTime").updateValueAndValidity();
        console.log(
          $this.form.value,
          "this is the value while changing the input field"
        );
      },
    });
  }
  tabChanged(index): void {
    if (index == 1) {
      this.submitted = true;
      if (this.form.get("phoneNumber").valid && this.form.get("name").valid) {
        this.selectedTabIndex = index;
      } else {
        let label =
          index == 1
            ? "Delivery Details"
            : index == 2
            ? "Cake Preferences"
            : "Dessert Preferences";
        Swal.fire({
          title: "Check Required",
          text: `Fill Mandatory Fields In ${label} & Go To Next`,
          icon: "warning",
        });
      }
    }
    if (index == 2) {
      this.selectedTabIndex = index;
    }
  }
  selection = [];
  //Dessert Preferences Check box
  changeCheckbox(item: any, event) {
    if (event.target.checked === true) {
      //push
      this.selection = [...this.selection, item];
    } else {
      //remove
      this.selection = this.selection.filter((user) => user !== item);
    }
  }

  //submit form
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.form.controls["dessertPreference"].setValue(this.selection);

    this.loading = true;
    this.http.post("/user/enquiry", this.form.value).subscribe(
      (data) => {
        this.loading = false;
        sweetAlert("success", "Success");
        this.emptyForm();
        this.submitted = false;
        this.analyticsService.commonEvents("Onclick", {
          event_category: `${
            this.formType == 1
              ? "Wedding Cakes"
              : this.formType == 2
              ? "Custom Cakes"
              : this.formType == 3
              ? "Dessert Tables"
              : ""
          } Enquiry Form Submited`,
          event_label: `${
            this.formType == 1
              ? "Wedding Cakes"
              : this.formType == 2
              ? "Custom Cakes"
              : this.formType == 3
              ? "Dessert Tables"
              : ""
          } Enquiry Form Submited`,
          value: `${
            this.formType == 1
              ? "Wedding Cakes"
              : this.formType == 2
              ? "Custom Cakes"
              : this.formType == 3
              ? "Dessert Tables"
              : ""
          } Enquiry Form Submited`,
        });
      },
      (error) => {
        sweetAlert("error", "Try Again");
        this.loading = false;
      }
    );
    this.selectedTabIndex = 0;
  }

  //upload image
  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //////upload file and get path///////
        if (this.fileImg) {
          const formData: FormData = new FormData();
          formData.append("image", this.fileImg, this.fileName);
          formData.append("fileType", "enquiry");
          formData.append("thumbnail", "false");
          this.loading = true;
          this.http.post("/uploadimage", formData).subscribe(
            (data) => {
              this.path2 = data;
              this.imgUrl = this.path2.path;

              this.form.controls["referenceImages"].setValue(this.imgUrl);
              this.loading = false;
              this.analyticsService.commonEvents("Onclick", {
                event_category: `Upload Reference Image In ${
                  this.formType == 1
                    ? "Wedding Cakes"
                    : this.formType == 2
                    ? "Custom Cakes"
                    : this.formType == 3
                    ? "Dessert Tables"
                    : ""
                } Enquiry`,
                event_label: `Upload Reference Image In ${
                  this.formType == 1
                    ? "Wedding Cakes"
                    : this.formType == 2
                    ? "Custom Cakes"
                    : this.formType == 3
                    ? "Dessert Tables"
                    : ""
                } Enquiry`,
                value: `Upload Reference Image In ${
                  this.formType == 1
                    ? "Wedding Cakes"
                    : this.formType == 2
                    ? "Custom Cakes"
                    : this.formType == 3
                    ? "Dessert Tables"
                    : ""
                } Enquiry`,
              });
            },
            (error) => {
              this.loading = false;
              sweetAlert("error", "Try Again");
            }
          );
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  formTextBoxDesign() {
    //material contact form animation
    // $(".contact-form")
    //   .find(".form-control")
    //   .each(function () {
    //     var targetItem = $(this).parent();
    //     if ($(this).val()) {
    //       $(targetItem).find("label").css({
    //         top: "-6px",
    //         fontSize: "12px",
    //         color: "#ff512f",
    //       });
    //     }
    //   });
    // $(".contact-form")
    //   .find(".form-control")
    //   .focus(function () {
    //     $(this).parent(".input-block").addClass("focus");
    //     $(this).parent().find("label").animate(
    //       {
    //         top: "-6px",
    //         fontSize: "12px",
    //         color: "#ff512f",
    //       },
    //       300
    //     );
    //   });
    // $(".contact-form")
    //   .find(".form-control")
    //   .blur(function () {
    //     if ($(this).val().length == 0) {
    //       $(this).parent(".input-block").removeClass("focus");
    //       $(this).parent().find("label").animate(
    //         {
    //           top: "10px",
    //           fontSize: "14px",
    //         },
    //         300
    //       );
    //     }
    //   });
  }

  goUpEnquiry() {
    document.getElementById("enquiryFormContainer").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  gallerypopup(img) {
    this.data = [
      {
        srcUrl: img,
        previewUrl: img,
      },
    ];

    this.items = this.data.map((item) => {
      return new ImageItem({
        src: `${this.imgPth + item.srcUrl}`,
        thumb: `${this.imgPth + item.previewUrl}`,
      });
    });

    this.basicLightboxExample();
    this.withCustomGalleryConfig();
    this.analyticsService.commonEvents("Onclick", {
      event_category: `Preview Our Speciality Image in ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry Page`,
      event_label: `Preview Our Speciality Image in ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry Page`,
      value: `Preview Our Speciality Image in ${
        this.formType == 1
          ? "Wedding Cakes"
          : this.formType == 2
          ? "Custom Cakes"
          : this.formType == 3
          ? "Dessert Tables"
          : ""
      } Enquiry Page`,
    });
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }

  withCustomGalleryConfig() {
    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref("anotherLightbox");

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top,
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
}
