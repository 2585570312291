<button type="button" id class="btn btn-primary" id="openOfferModal" #openOfferModal hidden="true" data-toggle="modal"
  data-target="#offerModal"></button>

<div class="modal fade" id="offerModal" tabindex="-1" role="dialog" aria-labelledby="offerModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <a href="#" class="text-center"><img src="assets/images/logo/logo.png" class="rounded-circle" alt="" /></a>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h4>Get 15% Offer</h4>
        <p class="mt-4 mx-5">Take 15% off your first purchase and Use promo code <strong>FIRST</strong></p>
        <button type="submit" class="btn btn-custom" (click)="openSignUp()">Signup Now</button>
      </div>
    </div>
  </div>
</div>