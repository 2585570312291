<section>
  <h4 class="mb-45 mt-45">Our Branches</h4>
  <!--container start-->
  <div class="container-fluid">
    <!--row start-->
    <div class="row">

      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="align-change"> Puppy's Bakery (Kochadai)</h5>
            <p class="card-text py-1">
              No.224, Theni Main Road,
              <br>
              (Opp.Master Mahal)
              <br>
              Madurai - 625016.
              <br />
              <strong>Ph</strong> :+91 73977 33561 / +91 87540 21528
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="card-title align-change">Puppy's Bakery (Anna Nagar)</h5>
            <p class="card-text py-1">
              No 58, 80  Feet Road,
              
              (Opp.Coffee Day)
              <br>
               Anna Nagar,
               <br>
               Madurai - 625020
               <br>
               <strong> Ph</strong> :+91 73977 33563
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-4">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="card-title">Bell Hotel</h5>
            <p class="card-text">
              No.55,Tamil Sangam Road,<br> Madurai – 625 001.
              <br />
              <strong> Ph</strong> : 0452 4230832
            </p>
          </div>
        </div>
      </div> -->

      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="align-change"> Puppy's Express (China Chokkikulam)</h5>
            <p class="card-text py-1">
             No 32, Kamala 2nd Street, (Opp.Kids Talk)
             <br>
              Chinna Chokkikulam,
              <br>
              Madurai - 625002
              <br />
              <strong> Ph </strong>: 0452 - 4959371
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="align-change">Puppy's Bakery (Sivakasi)</h5>
            <p class="card-text py-1">
              No 353,  Thiruthangal Road, <br>
              Bell Hotel Campus,
               <br> Sivakasi - 626123
              <br />
              <strong> Ph </strong>:+91 87540 01472,   04562 - 222855
            </p>
          </div>
        </div>
      </div>

    

      <!-- <div class="col-lg-4">
        <div class="card">
          <img class="card-img-top" src="{{imgUrl}}" alt="Card image cap" />
          <div class="card-body text-center">
            <a href="#">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </a>
            <h5 class="card-title">Puppy’s Bakery</h5>
            <p class="card-text py-1">
              30, Palani Rd, Near Sona towers, New Agraharam, Nehruji Nagar <br> Dindigul.
              
              Tamil Nadu 624001
              <br />
              <strong> Ph </strong>: 75500 33997
            </p>
          </div>
        </div>
      </div> -->

    </div>
    <!--row end-->
  </div>
  <!--container end-->
</section>