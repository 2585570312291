<section *ngIf="getdata.length > 0">
    <div class="container-fluid ">
        <h4 class="text-center"> <img src="assets/images/icons/Layer_10.png" alt="">
            Follow Us On Instagram
            <img src="assets/images/icons/Layer_11.png" alt="">
        </h4>
        <div class="row mt-5 pxd-5 mbottom">
            <div id="owl-insta" class="owl-carousel">
                <div class="item mx-3" *ngFor="let item of getdata">
                    <iframe class="iframe-insta " [src]="item.url | safe" frameborder="0"></iframe>
                    <script async src="//www.instagram.com/embed.js"></script>
                    <script async src="//www.instagram.com/embed.js"></script>
                </div>
            </div>
        </div>
    </div>
</section>