import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/services/http/http.service";

@Injectable({
    providedIn: 'root'
})

export class GlobalSearchService {


    constructor(
        private http: HttpService
    ) { }



    getSearchedData(data) {
        let obj = { searchKeyword: data }
        let params = new HttpParams();
        for (let key in obj) {
            if (obj[key] != null && obj[key] != undefined && obj[key] != "") {
                params = params.set(key, obj[key]);
            }
        }
        return this.http.getData(`/search`, params).pipe(map((ele: any) => this._adaptKeywords(ele)))
    }
    private _adaptKeywords(ele) {
        console.log(ele, 'this is the response');
        return ele 
    }

}