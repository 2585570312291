<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
        <li  (click)="pagination('sub')" class="page-item back mar-right" style="cursor: pointer;">
            <a class="page-link disable-one" tabindex="-1">
                <i class="fa mr-2 arrowSet fa-angle-double-left" aria-hidden="true"></i> Previous </a>
        </li>
        <!-- <li class="page-item active" >
            <a class="page-link" >1</a>
        </li>
        <li class="page-item"  style="cursor: pointer;" >
            <a class="page-link">2</a>
        </li>
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link">3</a>
        </li>
        <li class="page-item"  style="cursor: pointer;">
            <a class="page-link">4 </a>
        </li>
        <li class="page-item"  style="cursor: pointer;">
            <a class="page-link">5</a>
        </li> -->

        <li class="page-item" style="cursor: pointer;" *ngIf="(currentPage == lastPage)&&currentPage-2 > 0">
            <a class="page-link" (click)="paginateByNumber(currentPage - 2)">{{ currentPage - 2 }}</a>
        </li>
        <li class="page-item" style="cursor: pointer;" *ngIf="currentPage > 1">
            <a class="page-link" (click)="paginateByNumber(currentPage - 1)">{{ currentPage - 1 }}</a>
        </li>
        <li class="page-item active">
            <a class="page-link">{{ currentPage }}</a>
        </li>
        <li class="page-item" style="cursor: pointer;" *ngIf="(currentPage != lastPage)&&(currentPage + 1<=lastPage)">
            <a class="page-link" (click)="paginateByNumber( currentPage + 1)">{{ currentPage + 1 }} </a>
        </li>
        <li class="page-item" style="cursor: pointer;"
            *ngIf="(currentPage != 0 )&& (currentPage == 1)&&(currentPage + 2<=lastPage)">
            <a class="page-link" (click)="paginateByNumber( currentPage + 2)">{{ currentPage + 2 }}</a>
        </li>




        <li (click)="pagination('add')" class="page-item mar-left" style="cursor: pointer;">
            <a class="page-link disable-one">Next <i class="fa ml-2 arrowSet fa-angle-double-right" aria-hidden="true"></i></a>
        </li>
    </ul>
</nav>