<footer class="footer">
  <div class="container-fluid bottom_border">
    <!-- Mobile and desktop view port -->
    <div class="row mlr-5 mobile-desktop-view">
      <!-- For Support -->
      <div class="col-12 col-lg-2 col-md-6 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 mobile-text-center">
            <img src="assets/images/logo/logo_1.png" alt="" srcset=""
              style="height: 115px; width: 115px;object-fit: contain; " />
          </div>
          <div class="col-lg-12">
            <div class="row m-0 p-0 mt-3">
              <div class="col-7 col-sm-12 col-md-6 col-lg-12 p-0">
                <h5 class="headin5_amrc col_white_amrc">For Support</h5>
                <p><a href="tel:8122210222" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Support Via Mobile Number',
                  'event_label': 'Support Via Mobile Number',
                  'value': 'Support Via Mobile Number'})"> <i class="fa fa-phone"></i>&nbsp;&nbsp; +91 8122210222</a>
                </p>
                <p class="mail-id"><i class="fa fa fa-envelope"></i>&nbsp;&nbsp; mailbox@puppys.co.in</p>
              </div>
              <div class="col-5 col-sm-12 col-md-12 col-lg-12 mobile-pull-right p-0  ">
                <img src="assets/images/ssl.jpg" style="height:35px;border-radius: 5px; margin-top: 10px; "
                  class="mobile-pull-rightt  ssl m_20 mr-15" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 mobile-responce-hide">
            <div class="row m-0 p-0 mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0 pull-left">
                <h5 class="headin5_amrc col_white_amrc">Secure Transactions</h5>
                <ul class="transactions_footer_ul">
                  <li class="mr-4">
                    <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
                  </li>
                  <li class="mr-4">
                    <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
                  </li>
                  <li>
                    <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
                  </li>
                </ul>
                <h5 class="headin5_amrc col_white_amrc">Connect With Us</h5>
                <ul class="transactions_footer_ul">
                  <li class="mr-4">
                    <a href="https://www.facebook.com/puppysbakery" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Facebook Page',
                    'event_label': 'Open Facebook Page',
                    'value': 'Open Facebook Page'})">
                      <img src="assets/images/connect_with_us/icons8_facebook.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://www.instagram.com/puppysbakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Instagram Page',
                    'event_label': 'Open Instagram Page',
                    'value': 'Open Instagram Page'})">
                      <img src="assets/images/connect_with_us/icons8_instagram.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank" rel="noopener noreferrer"
                      (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Linkedin Page',
                    'event_label': 'Open Linkedin Page',
                    'value': 'Open Linkedin Page'})">
                      <img src="assets/images/connect_with_us/icons8_linkedin.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://in.pinterest.com/puppys_bakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Pinterest Page',
                    'event_label': 'Open Pinterest Page',
                    'value': 'Open Pinterest Page'})">
                      <img src="assets/images/connect_with_us/icons8_pinterest.png" alt="" srcset="" /></a>
                  </li>

                  <li>
                    <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"
                      rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Youtube Page',
                    'event_label': 'Open Youtube Page',
                    'value': 'Open Youtube Page'})">
                      <img src="assets/images/connect_with_us/youtube.png" alt="" srcset="" /></a>
                  </li>

                </ul>
                <div class="mobile-bak-image">
                  <img src="assets/images/Vector_Smart_Object.png" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Secure Transactions  and About Us and Connect With Us -->
      <div class="col-7 mobile-view-hide col-lg-8 col-md-6 col-sm-12 mt-2 plr-5">
        <div class="row">
          <div class="col-lg-4">
            <h5 class="headin5_amrc col_white_amrc pt1">Secure Transactions</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
              </li>
              <li class="mr-4">
                <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
              </li>
              <li>
                <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
              </li>
            </ul>
            <h5 class="headin5_amrc col_white_amrc pt1 mobile-view-hide">About Us</h5>
            <p><a [routerLink]="['/page/pressRelease']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Press Release Page',
              'event_label': 'Open Press Release Page',
              'value': 'Open Press Release Page'})"> Press Release</a></p>
            <p><a [routerLink]="['/page/contactUs']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Contact Us Page',
              'event_label': 'Open Contact Us Page',
              'value': 'Open Contact Us Page'})"> Contact Us</a></p>
            <p><a [routerLink]="['/page/ourStory']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Our Story Page',
              'event_label': 'Open Our Story Page',
              'value': 'Open Our Story Page'})"> Our Story</a></p>
            <h5 class="headin5_amrc col_white_amrc pt1">Connect With Us</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <a href="https://www.facebook.com/puppysbakery" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Facebook Page',
                'event_label': 'Open Facebook Page',
                'value': 'Open Facebook Page'})">
                  <img src="assets/images/connect_with_us/icons8_facebook.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.instagram.com/puppysbakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Instagram Page',
                'event_label': 'Open Instagram Page',
                'value': 'Open Instagram Page'})">
                  <img src="assets/images/connect_with_us/icons8_instagram.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank" rel="noopener noreferrer"
                  (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Linkedin Page',
                'event_label': 'Open Linkedin Page',
                'value': 'Open Linkedin Page'})">
                  <img src="assets/images/connect_with_us/icons8_linkedin.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://in.pinterest.com/puppys_bakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Pinterest Page',
                'event_label': 'Open Pinterest Page',
                'value': 'Open Pinterest Page'})">
                  <img src="assets/images/connect_with_us/icons8_pinterest.png" alt="" srcset="" /></a>
              </li>

              <li>
                <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"
                  rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Youtube Page',
                'event_label': 'Open Youtube Page',
                'value': 'Open Youtube Page'})">
                  <img src="assets/images/connect_with_us/youtube.png" alt="" srcset="" /></a>
              </li>
            </ul>
          </div>
          <div class="col-lg-8">
            <!--desktop dynamic list-->
            <div class="row">
              <ng-container *ngFor="let data of leftCategory | slice:0:6; let i = index">
                <ng-container *ngIf="data.isSubCategory">
                  <div class="col-lg-4">
                    <h5 class="headin5_amrc col_white_amrc pt1">
                      <a routerLinkActive="active" href="javascript: void(0)" (click)="setImage(data.img);
                        redirectCategory(data);"> {{ data.name }} </a>
                    </h5>
                    <div class="btn-group dropright disblck" *ngFor="let subData of data.subCategory">
                      <a class="desk-subCategory" routerLinkActive="active"
                        (click)="setImage(subData.imagePath); redirectCategory(subData);">
                        {{ subData.categoryName }}
                      </a>
                    </div>
                  </div>
                </ng-container>
                <!-- <div *ngIf="!data.isSubCategory" class="col-lg-4">
                  <h5 class="headin5_amrc col_white_amrc pt1">
                    <a routerLinkActive="active" (click)="setImage(data.img);redirectCategory(data);"
                      href="javascript: void(0)"> {{ data.name }} </a>
                  </h5>
                </div> -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-12 mt-2 mobile-view-hide">
        <div class="bak-image">
          <img src="assets/images/Vector_Smart_Object.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <!-- End Mobile and desktop view port -->


    <div class="row mlr-5 ipad-air">
      <!-- For Support -->
      <div class="col-12  mt-4">
        <div class="row">
          <div class="col-lg-12 mobile-text-center">
            <img src="assets/images/logo/logo_1.png" alt="" srcset=""
              style="height: 115px; width: 115px;object-fit: contain; " />
          </div>
          <div class="col-4">
            <div class="row m-0 p-0 mt-3">
              <div class="col-7 col-sm-12 col-md-6 col-lg-12 p-0">
                <h5 class="headin5_amrc col_white_amrc">For Support</h5>
                <p><a href="tel:8122210222" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Support Via Mobile Number',
                  'event_label': 'Support Via Mobile Number',
                  'value': 'Support Via Mobile Number'})"> <i class="fa fa-phone"></i>&nbsp;&nbsp; +91 8122210222</a>
                </p>
                <p><i class="fa fa fa-envelope"></i>&nbsp;&nbsp; mailbox@puppys.co.in</p>
              </div>
              <div class="col-5 col-sm-12 col-md-12 col-lg-12 mobile-pull-right p-0  ">
                <img src="assets/images/ssl.jpg" style="height:35px;border-radius: 5px; margin-top: 10px; "
                  class="mobile-pull-rightt  ssl m_20 mr-15" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="col-4 mobile-responce-hide">
            <div class="row m-0 p-0 mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0 pull-left">
                <h5 class="headin5_amrc col_white_amrc">Secure Transactions</h5>
                <ul class="transactions_footer_ul">
                  <li class="mr-4">
                    <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
                  </li>
                  <li class="mr-4">
                    <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
                  </li>
                  <li>
                    <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
                  </li>
                </ul>
                <h5 class="headin5_amrc col_white_amrc">Connect With Us</h5>
                <ul class="transactions_footer_ul">
                  <li class="mr-4">
                    <a href="https://www.facebook.com/puppysbakery" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Facebook Page',
                    'event_label': 'Open Facebook Page',
                    'value': 'Open Facebook Page'})">
                      <img src="assets/images/connect_with_us/icons8_facebook.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://www.instagram.com/puppysbakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Instagram Page',
                    'event_label': 'Open Instagram Page',
                    'value': 'Open Instagram Page'})">
                      <img src="assets/images/connect_with_us/icons8_instagram.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank" rel="noopener noreferrer"
                      (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Linkedin Page',
                    'event_label': 'Open Linkedin Page',
                    'value': 'Open Linkedin Page'})">
                      <img src="assets/images/connect_with_us/icons8_linkedin.png" alt="" srcset="" /></a>
                  </li>
                  <li class="mr-4">
                    <a href="https://in.pinterest.com/puppys_bakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Pinterest Page',
                    'event_label': 'Open Pinterest Page',
                    'value': 'Open Pinterest Page'})">
                      <img src="assets/images/connect_with_us/icons8_pinterest.png" alt="" srcset="" /></a>
                  </li>

                  <li>
                    <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"
                      rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Youtube Page',
                    'event_label': 'Open Youtube Page',
                    'value': 'Open Youtube Page'})">
                      <img src="assets/images/connect_with_us/youtube.png" alt="" srcset="" /></a>
                  </li>

                </ul>
                <div class="mobile-bak-image">
                  <img src="assets/images/Vector_Smart_Object.png" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Secure Transactions  and About Us and Connect With Us -->
      <div class="col-7 mobile-view-hide col-lg-8 col-md-6 col-sm-12 mt-2 plr-5">
       
         
            <h5 class="headin5_amrc col_white_amrc pt1">Secure Transactions</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
              </li>
              <li class="mr-4">
                <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
              </li>
              <li>
                <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
              </li>
            </ul>
            <h5 class="headin5_amrc col_white_amrc pt1 mobile-view-hide">About Us</h5>
            <p><a [routerLink]="['/page/pressRelease']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Press Release Page',
              'event_label': 'Open Press Release Page',
              'value': 'Open Press Release Page'})"> Press Release</a></p>
            <p><a [routerLink]="['/page/contactUs']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Contact Us Page',
              'event_label': 'Open Contact Us Page',
              'value': 'Open Contact Us Page'})"> Contact Us</a></p>
            <p><a [routerLink]="['/page/ourStory']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Our Story Page',
              'event_label': 'Open Our Story Page',
              'value': 'Open Our Story Page'})"> Our Story</a></p>
            <h5 class="headin5_amrc col_white_amrc pt1">Connect With Us</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <a href="https://www.facebook.com/puppysbakery" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Facebook Page',
                'event_label': 'Open Facebook Page',
                'value': 'Open Facebook Page'})">
                  <img src="assets/images/connect_with_us/icons8_facebook.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.instagram.com/puppysbakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Instagram Page',
                'event_label': 'Open Instagram Page',
                'value': 'Open Instagram Page'})">
                  <img src="assets/images/connect_with_us/icons8_instagram.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank" rel="noopener noreferrer"
                  (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Linkedin Page',
                'event_label': 'Open Linkedin Page',
                'value': 'Open Linkedin Page'})">
                  <img src="assets/images/connect_with_us/icons8_linkedin.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://in.pinterest.com/puppys_bakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Pinterest Page',
                'event_label': 'Open Pinterest Page',
                'value': 'Open Pinterest Page'})">
                  <img src="assets/images/connect_with_us/icons8_pinterest.png" alt="" srcset="" /></a>
              </li>

              <li>
                <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"
                  rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Youtube Page',
                'event_label': 'Open Youtube Page',
                'value': 'Open Youtube Page'})">
                  <img src="assets/images/connect_with_us/youtube.png" alt="" srcset="" /></a>
              </li>
            </ul>
          

      

       
      </div>

    <!--desktop dynamic list-->
    <div class="row">
      <ng-container *ngFor="let data of leftCategory | slice:0:6; let i = index">
        <ng-container *ngIf="data.isSubCategory">
          <div class="col-4">
            <h5 class="headin5_amrc col_white_amrc pt1">
              <a routerLinkActive="active" href="javascript: void(0)" (click)="setImage(data.img);
                redirectCategory(data);"> {{ data.name }} </a>
            </h5>
            <div class="btn-group dropright disblck" *ngFor="let subData of data.subCategory">
              <a class="desk-subCategory" routerLinkActive="active"
                (click)="setImage(subData.imagePath); redirectCategory(subData);">
                {{ subData.categoryName }}
              </a>
            </div>
          </div>
        </ng-container>
        <!-- <div *ngIf="!data.isSubCategory" class="col-lg-4">
          <h5 class="headin5_amrc col_white_amrc pt1">
            <a routerLinkActive="active" (click)="setImage(data.img);redirectCategory(data);"
              href="javascript: void(0)"> {{ data.name }} </a>
          </h5>
        </div> -->
      </ng-container>
    </div>
  


     






      <div class="col-lg-2 col-md-3 col-sm-12 mt-2 mobile-view-hide">
        <div class="bak-image">
          <img src="assets/images/Vector_Smart_Object.png" alt="" srcset="" />
        </div>
      </div>
    </div>



    <!-- Tablet view port -->
    <div class="row tablet-view m-0">
      <div class="col-lg-12">
        <div class="row">

          <!-- <div class="col-md-1">
            <div class="row">
              <ng-container *ngFor="let data of allCategory | slice:0:6; let i = index">
                <ng-container *ngIf="data.isSubCategory">
                  <div class="col-md-4">
                    <h5 class="headin5_amrc col_white_amrc pt1">
                      <a routerLinkActive="active" (click)="setImage(data.img)" [routerLink]="['/category', data.id]">
                        {{ data.name }} </a>
                    </h5>
                    <p *ngFor="let subData of data.subCategory">
                      <a routerLinkActive="active" (click)="setImage(subData.imagePath)"
                        [routerLink]="['/category', subData.categoryId]">
                        {{ subData.categoryName }}
                      </a>
                    </p>
                  </div>
                </ng-container>
                <div *ngIf="!data.isSubCategory" class="col-md-4">
                  <h5 class="headin5_amrc col_white_amrc pt1">
                    <a routerLinkActive="active" (click)="setImage(data.img)" [routerLink]="['/category', data.id]"> {{
                      data.name }} </a>
                  </h5>
                </div>
              </ng-container>
            </div>
          </div> -->
          <div class="col-md-4 col-sm-4">
            <div class="col-md-12">
              <img src="assets/images/logo/Puppys_White_Logo.png" alt="" srcset="" />
            </div>
            <div class="col-md-12 mt-3 ml-2">
              <img src="assets/images/sectigo_trust_seal_lg_140x54.png" class="" alt="" srcset="" />
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <h5 class="headin5_amrc col_white_amrc pt1">Secure Transactions</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
              </li>
              <li class="mr-4">
                <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
              </li>
              <li>
                <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
              </li>
            </ul>
            <h5 class="headin5_amrc col_white_amrc pt1">Connect With Us</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <a href="https://www.facebook.com/puppysbakery" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Facebook Page',
                'event_label': 'Open Facebook Page',
                'value': 'Open Facebook Page'})">
                  <img src="assets/images/connect_with_us/icons8_facebook.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.instagram.com/puppysbakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Instagram Page',
                'event_label': 'Open Instagram Page',
                'value': 'Open Instagram Page'})">
                  <img src="assets/images/connect_with_us/icons8_instagram.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank" rel="noopener noreferrer"
                  (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Linkedin Page',
                'event_label': 'Open Linkedin Page',
                'value': 'Open Linkedin Page'})">
                  <img src="assets/images/connect_with_us/icons8_linkedin.png" alt="" srcset="" /></a>
              </li>
              <li class="mr-4">
                <a href="https://in.pinterest.com/puppys_bakery/" target="_blank" rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Pinterest Page',
                'event_label': 'Open Pinterest Page',
                'value': 'Open Pinterest Page'})">
                  <img src="assets/images/connect_with_us/icons8_pinterest.png" alt="" srcset="" /></a>
              </li>

              <li>
                <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"
                  rel="noopener noreferrer" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Youtube Page',
                'event_label': 'Open Youtube Page',
                'value': 'Open Youtube Page'})">
                  <img src="assets/images/connect_with_us/youtube.png" alt="" srcset="" /></a>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-sm-4 mb-4 mtop-20">
            <h5 class="headin5_amrc col_white_amrc">For Support</h5>
            <p><a href="tel:8122210222" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Support Via Mobile Number',
              'event_label': 'Support Via Mobile Number',
              'value': 'Support Via Mobile Number'})"> <i class="fa fa-phone"></i>&nbsp;&nbsp; +91 8122210222</a></p>
            <p><i class="fa fa fa-envelope"></i>&nbsp;&nbsp;mailbox@puppys.co.in</p>
            <h5 class="headin5_amrc col_white_amrc">Secure Transactions</h5>
            <ul class="transactions_footer_ul">
              <li class="mr-4">
                <img src="assets/images/transactions/icons8_visa.png" alt="" srcset="" />
              </li>
              <li class="mr-4">
                <img src="assets/images/transactions/mastercard.png" alt="" srcset="" />
              </li>
              <li>
                <img src="assets/images/transactions/paytm.png" alt="" srcset="" />
              </li>
            </ul>
          </div>

        </div>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="bak-image" style="display: none;">
              <img src="assets/images/Vector_Smart_Object.png" style="width: 100%" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Tablet view port -->
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center">
        <p class="foote_bottom_ul_amrc">
          <a [routerLink]="['/page/terms-and-policy']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Tearms & Condition Page',
          'event_label': 'Open Tearms & Condition Page',
          'value': 'Open Tearms & Condition Page'})">TERMS & CONDITIONS</a>
          <a [routerLink]="['/page/shipping-policy']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Shipping Policy Page',
          'event_label': 'Open Shipping Policy Page',
          'value': 'Open Shipping Policy Page'})">SHIPPING POLICY</a>
          <a [routerLink]="['/page/cancellation-policy']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Cancellation Policy Page',
          'event_label': 'Open Cancellation Policye Page',
          'value': 'Open Cancellation Policy Page'})">CANCELLATION POLICY</a>
          <a [routerLink]="['/page/privacy-policy']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Open Privacy Policy Page',
          'event_label': 'Open Privacy Policy Page',
          'value': 'Open Privacy Policy Page'})">PRIVACY POLICY</a>
        </p>
      </div>
    </div>
    <p class="text-center">Copyright © 2020 | Puppys Bakery</p>
  </div>
</footer>