import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {

  constructor() { }


  public loading: boolean = true;
  ngOnInit(): void {
    // setTimeout(() => {
    //   this.loading = false
    // }, 3000);
  }

}
