import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClassicCakesRoutingModule } from './classic-cakes-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClassicCakesComponent } from './classic-cakes.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [ClassicCakesComponent],
  imports: [
    CommonModule,
    ClassicCakesRoutingModule,
    SharedModule,
    NgxSkeletonLoaderModule
  ]
})
export class ClassicCakesModule { }
