import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';


declare var $: any
@Component({
  selector: 'app-customise',
  templateUrl: './customise.component.html',
  styleUrls: ['./customise.component.less']
})
export class CustomiseComponent implements OnInit {
  @Input()public imgDate: any;
  constructor(private router: Router) { }

  getdata
  ngOnChanges() {
    //get data from home component
     
    if (this.imgDate) {
      this.getdata = this.imgDate.filter(element => element.bannerType == 'index_slider_2');
      setTimeout(() => {
        this.refreshSlider();
      }, 200);
    }
  }

  ngOnInit(): void {
  }

  //refresh slider onload
  refreshSlider() {

    $("#owl-customise").owlCarousel({
      items: 1,
      dots: true,
      nav: true,
      loop: true,
      autoplay:true,
      autoplayTimeout:5000,
      smartSpeed: 2000, 
      responsive: {
        0: {
          items: 1,
          navigation: true,
          margin: 5,
          nav: true,
          navText: ["<img src='assets/images/icons/left_arrow_cover.png' style='width: 10px;'>", "<img src='assets/images/icons/right_arrow_cover-1.png' style='width: 10px;'>"]
        },

        640: {
          items: 1,
          navigation: true,
          nav: true,
          margin: 20,
          navText: ["<img src='assets/images/icons/left_arrow_cover.png' style='width: 10px;'>", "<img src='assets/images/icons/right_arrow_cover-1.png' style='width: 10px;'>"]
        }

      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true
    });
    
  }

}