import { Component, Input, OnInit } from "@angular/core";
import { AppConstants } from "src/app/configuration/app.constants";
import { HttpService } from "src/app/services/http/http.service";
import { sweetAlert } from "../../../../shared/function/toaster";

@Component({
  selector: "app-feed-back",
  templateUrl: "./feed-back.component.html",
  styleUrls: ["./feed-back.component.less"],
})
export class FeedBackComponent implements OnInit {
  @Input("productId") public id;
  imgPth = AppConstants.SERVER_CONFIG.IMG_URL;

  constructor(private http: HttpService) {}

  ngOnInit(): void {
    this.getUserReviews(this.id);
  }

  //get current user reviews
  public allReview = [];
  getUserReviews(id) {
    this.http.get(`/user/ratings/${id}`).subscribe(
      (data: any) => {
   
        this.allReview = data.content;
        
      },
      (error) => {
        sweetAlert("error", "Try Again");
      }
    );
  }
}
