import { Injectable } from "@angular/core";
import moment from "moment";
import { CurrentTimeAndDate } from "./current-date-time";
import { checkNull } from "./date-and-time-validations";

interface DeliveryTimeI {
  deliverytimeId: number;
  deliveryName: string;
  fromTime: string;
  toTime: string;
  status: number;
  cutOffTime: string;
  shortCode: string;
  backingTime: number;
}
export let DeliveryTime = {
  deliverytimeId: 0,
  deliveryName: "0hrs",
  fromTime: "10:00",
  toTime: "22:00",
  status: 1,
  cutOffTime: "",
  shortCode: "",
  backingTime: 0,
};

function addDays(days) {
  let current = new CurrentTimeAndDate();
  return moment(current.date).add(days, "day").format("YYYY-MM-DD");
}

@Injectable({
  providedIn: "root",
})
export class DeliveryDateService {
  constructor() {}

  getCurrentTime() {}

  validDate(delivery: any) {
    delivery = checkNull(delivery) ? delivery : DeliveryTime;
    let current = new CurrentTimeAndDate();
    let beforeCutOff = checkNull(delivery.cutOffTime)
      ? moment(`${current.dateTime}`)
          .add(0, "hours")
          .isSameOrBefore(`${current.date} ${delivery.cutOffTime}`)
      : true;
    let beforeToTime = moment(`${current.dateTime}`)
      .add(delivery.backingTime ?? 0, "hours")
      .isSameOrBefore(`${current.date} ${delivery.toTime}`);

    switch (true) {
      case beforeCutOff && beforeToTime:
        return current.dateTime;
      case delivery?.backingTime > 24:
        return current.addBakingHours(delivery?.backingTime);
      default:
        return current.nextDate;
    }
  }

  validateTime(delivery: any, selectedDate, nightDelivery?: boolean) {
    let current = new CurrentTimeAndDate();
    delivery = checkNull(delivery) ? delivery : DeliveryTime;
    console.log(delivery, selectedDate);
    let minTime: any;
    let maxTime: any;

    let date = moment(selectedDate).format("YYYY-MM-DD");

    maxTime = nightDelivery == true ? "23:59" : delivery.toTime;

    const bakingTime = delivery?.backingTime ?? 0;

    const openingTime =
      Number(current.time.replace(":", ".")) >=
      Number(delivery.fromTime.replace(":", "."))
        ? current.time
        : delivery.fromTime;

    console.log(bakingTime, openingTime);

    const isToday = moment(current.date).isSame(date);
    const isTomorrow = moment(addDays(1)).isSame(date);
    const shopOpenedTime = moment(`${date} ${delivery.fromTime}`).format("HH:mm");
    if (isToday) {
      const shopOpened = moment(`${current.date} ${current.time}`).isSameOrAfter(
        `${current.date} ${delivery.fromTime}`
      );
      const giveCurrentTime = moment(`${date} ${current.time}`)
        .add(delivery.backingTime, "hours")
        .format("HH:mm");
      minTime = shopOpened ? giveCurrentTime : shopOpenedTime;
    } else if (isTomorrow) {
      console.log(maxTime, current.time, bakingTime);

      const hasSomeTime = Math.ceil(
        Number(
          Number(delivery.toTime.replace(":", ".")) -
            Number(current.time.replace(":", ".")) -
            bakingTime
        )
      );
      console.log(hasSomeTime, "hasSomeTime");

      if (hasSomeTime > 0) {
        minTime = shopOpenedTime;
      } else {
        if (bakingTime < 12) {
          minTime = moment(`${date} ${delivery.fromTime}`)
            .add(bakingTime, "hours")
            .format("HH:mm");
        } else {
          // should be after fromTime and should before closing time
          let calculatedTime = Number(
            moment(`${date} ${current.time}`)
              .add(bakingTime, "hours")
              .format("HH:mm")
              .replace(":", ".")
          );
          let fromTime = Number(delivery.fromTime.replace(":", "."));
          let toTime = Number(maxTime.replace(":", "."));

          if (calculatedTime >= fromTime && calculatedTime <= toTime) {
            minTime = moment(`${date} ${current.time}`)
              .add(bakingTime, "hours")
              .format("HH:mm");
          } else {
            minTime = !(calculatedTime >= fromTime)
              ? delivery.fromTime
              : !(calculatedTime <= toTime)
              ? maxTime
              : moment(`${date} ${current.time}`)
                  .add(bakingTime, "hours")
                  .format("HH:mm");
          }
        }
      }
    } else {
      minTime = shopOpenedTime;
    }

    console.log({ minTime, maxTime }, nightDelivery);

    return { minTime, maxTime };
  }

  validateDeliveryTime(bakingTime, deliveryDate) {
    let bufferTime = 0;
    let bTime = bakingTime > 0 ? bakingTime : 0;
    let currentDate = moment().add(bTime, "hours").format("YYYY-MM-DDTHH:mm");
    let f1 = moment(currentDate).format("YYYY-MM-DDTHH:mm");
    let f2 = moment(deliveryDate).format("YYYY-MM-DDTHH:mm");
    const isNextDay = moment(moment(f2).format("MM-DD-YYYY")).isAfter(
      new CurrentTimeAndDate().date
    );
    return checkNull(deliveryDate)
      ? isNextDay
        ? true
        : moment(f2).add(bufferTime, "minutes").isSameOrAfter(f1)
      : true;
  }

  validateDeliveryStatus(data) {
    console.log(data);
    return {
      status: data?.product?.deliveryStatus,
      message:
        data?.product?.deliveryStatus != true
          ? `Sorry kindly remove this order from the cart. Delivery is not available for ${data?.product?.productName}`
          : "",
    };
  }
}
