<div class="global-search">


   <div class=" mb-0 cust_input" *ngIf="searchedData?.category?.length>0">
      <label for="">Collections</label>
      <div class="scrollable">
         <p (click)="navigate('category',data)" class="category-p"
            *ngFor="let data of searchedData?.category;let i=index;">{{data?.categoryName}}</p>
      </div>
   </div>


   <div class=" mb-1 cust_input" *ngIf="searchedData?.product?.length>0">
      <label for="">Products</label>
      <div class=" scrollable-product">
         <div (click)="navigate('product',data)" class="d-flex show_product" style="cursor: pointer;"
            *ngFor="let data of searchedData?.product;let i=index;">
            <img [src]="baseURL+data?.productImages" alt="product image svg">
            <h1>{{data?.productName}}</h1>
         </div>
      </div>



   </div>
</div>

