import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppService } from 'src/app/app.service';

declare var $: any
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.less']
})
export class OfferComponent implements OnInit, AfterViewInit {

  @ViewChild('openOfferModal') openOfferModal: ElementRef;
  @ViewChild('getOfferData') myForm: NgForm;

  constructor(
    private AppService: AppService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.openOfferModal.nativeElement.click()
  }

  openModel() {
    this.openOfferModal.nativeElement.click()
  }

  openSignUp() {
    $('#offerModal').modal('hide');
    $('#loginSignup').modal('show');
  }

}
