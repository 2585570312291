import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textDecorator'
})
export class TextDecerationPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let fs = ""
    let temp = value != null ? value.split("-") : ""
    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      fs += element.charAt(0).toUpperCase() + element.slice(1) + " "
    }
    return fs
  }
}
