import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConstants } from "../../configuration/app.constants";
import { SocialAuthService } from "angularx-social-login";
import { Router } from "@angular/router";
import { MemoryService } from "../memory-services/memory.service";
import { UserService } from "../memory-services/user.service";
declare var $: any;
@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: any;
  data2: any;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  loggedOut$: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private authService: SocialAuthService,
    private router: Router,
    private memoryService: MemoryService,
    private userService: UserService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  config = AppConstants.SERVER_CONFIG;
  baseurl = this.config[this.config.USE_URL];

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this.http
      .post<any>(this.baseurl + "/auth/login", { userName, password })
      .pipe(
        map((data) => {
          this.user = data;
          // login successful if there's a jwt token in the response
          if (this.user && this.user.accessToken && this.user.role == "user") {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(this.user));
            this.currentUserSubject.next(this.user);
          }
          return this.user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("deliveryPin");
    localStorage.clear();
    this.currentUserSubject.next(null);
    $("#needHelp").modal("hide");
    // window.location.href = '/';
    this.loggedOut$.emit("true");
    this.userService.setCurrentUser();
    this.router.navigate(["/"]);
  }
  googlelogout(): void {
    this.authService.signOut();
    localStorage.removeItem("deliveryPin");
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.currentUserSubject.next(null);
    $("#needHelp").modal("hide");
    // window.location.href = '/';
    this.userService.setCurrentUser();
    this.loggedOut$.emit("true");
    this.router.navigate(["/"]);
  }
}
