import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

//Service for refresh all the function in whole project
export class AddCartService {
    public cartValue: BehaviorSubject<any> = new BehaviorSubject(null);
    public pinCodeValue: BehaviorSubject<any> = new BehaviorSubject(null);
    public wishListValue: BehaviorSubject<any> = new BehaviorSubject(null);
    public otpTimer: BehaviorSubject<any> = new BehaviorSubject(null);
    public profileImg: BehaviorSubject<any> = new BehaviorSubject(null);
    public addToCart: BehaviorSubject<any> = new BehaviorSubject(null);


    getVal(): Observable<any> {
        return this.cartValue.asObservable();
    }

    setVal(val: any) {
        this.cartValue.next(val);
    }


    getPinCode(): Observable<any> {
        return this.pinCodeValue.asObservable();
    }

    setPincode(val: any) {
        this.pinCodeValue.next(val);
    }


    getWishList(): Observable<any> {
        return this.wishListValue.asObservable();
    }

    setWishList(val: any) {
        this.wishListValue.next(val);
    }


    getTimer(): Observable<any> {
        return this.otpTimer.asObservable();
    }

    setTimer(val: any) {
        this.otpTimer.next(val);
    }

    getProfile(): Observable<any> {
        return this.profileImg.asObservable();
    }

    setProfile(val: any) {
        this.profileImg.next(val);
    }

    getCart(): Observable<any> {
        return this.addToCart.asObservable();
    }

    setCart(val: any) {
        this.addToCart.next(val);
    }

}
