import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemedCakesComponent } from './themed-cakes/themed-cakes.component';

const routes: Routes = [
  {
    path:"themed",
    component:ThemedCakesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomiseRoutingModule { }
