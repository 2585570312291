<app-loader *ngIf="loading"></app-loader>
<button type="button" id class="btn btn-primary" id="loginSignupModal" #loginSignupModal hidden="true"
  data-toggle="modal" data-target="#loginSignup" data-refresh='true'></button>
<div class="modal fade" id="loginSignup" tabindex="-1" data-refresh='true' role="dialog"
  aria-labelledby="loginSignupLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>


        <!----------- sign up ----------------------->
        <div class="row ml-0  " *ngIf="signInSingUp">
          <div class="col-lg-7 col-sm-7 col-xs-7 ptb-4">
            <div class="row align-center pt-4 pb-4">

              <div class="col-lg-4 col-sm-4 col-xs-4  add-new">
                <h4>Sign Up</h4>
              </div>
              <div class="col-lg-8 col-sm-8 col-xs-8 text-right">
                <!-- <a href="#" (click)="loginWithFacebook()" class="fa fa-facebook"></a> -->
                <a (click)="loginWithGoogle()" class="fa fa-google"></a>
              </div>
            </div>
            <p style="line-height: 1.3;">
              Please signup with your mobile number to continue
            </p>


            <form [formGroup]="form" class="pt-20 pb-4">

              <div class="row">
                <div class="col-lg-6 col-sm-6 ">
                  <div class="input-group">
                    <input type="text" class="form-control required" formControlName="firstName"
                      placeholder="First name" autocomplete="new-firstName" />
                  </div>
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-text">
                    <div *ngIf="f.firstName.errors.required"> First Name is required</div>
                  </div>
                </div>

                <div class="col-lg-6 col-sm-6 ">
                  <div class="input-group">
                    <input type="text" formControlName="lastName" class="form-control" placeholder="Last name"
                      aria-label="Username" aria-describedby="basic-addon1" autocomplete="new-lastName" />
                  </div>
                </div>
              </div>

              <div class="row your-gender pt-4">
                <div class="col-lg-12">
                  <label for="id_gender" class="control-label mr-4 label requiredField"> Your Gender </label>
                  <div class="form-check form-check-inline mal-20">
                    <label class="form-check-label radio">
                      <input type="radio" class="form-check-input" value="male" formControlName="gender" name="gender"
                        id="membershipRadios1" autocomplete="new-gender" />
                      Male
                      <span class="checkround"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label radio">
                      <input type="radio" class="form-check-input" value="female" formControlName="gender" name="gender"
                        id="membershipRadios2" />
                      Female
                      <span class="checkround"></span>
                    </label>
                  </div>

                  <div *ngIf="submitted && f.gender.errors" class="invalid-text mt-n1">
                    <div *ngIf="f.gender.errors.required">Gender is required</div>
                  </div>

                </div>
              </div>
              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="relative">
                    <input placeholder="Enter DOB" readonly="readonly" type="text" class="form-control del-time"
                      id="datepicker2" autocomplete="datepicker2" />
                    <i class="fa fa-calendar calender_icon" aria-hidden="true"></i>
                  </div>

                </div>
                <div *ngIf="submitted && f.birthdayDate.errors" class="invalid-text mt-n3"><br>
                  <div *ngIf="f.birthdayDate.errors.required">Date of birth is required</div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-12">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Mobile number" aria-label="Username"
                      aria-describedby="basic-addon1" formControlName="phoneNumber" autocomplete="new-Username" />
                  </div>
                </div>
              </div>

              <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-text mt-n3"><br>
                <div *ngIf="f.phoneNumber.errors.required">Mobile Number is required</div>
                <div *ngIf="f.phoneNumber.errors.pattern">Enter Valid 10 digit Mobile Number</div>
              </div>


              <div class="input-group pt-4">
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password"
                  aria-label="Username" aria-describedby="basic-addon1" formControlName="password"
                  autocomplete="new-password" name="new-password" />


                <i class="show-hide-pass" [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  (click)="togglePassword()" aria-hidden="true"></i>
              </div>
              <div *ngIf="submitted && f.password.errors" class="invalid-text mt-n3"><br>
                <div *ngIf="f.password.errors.required"> Password is required</div>
                <div *ngIf="f.password.errors.minlength"> Password must at least 6 characters </div>
                <div *ngIf="f.password.errors.maxlength"> Password not more than 50 characters</div>
              </div>

              <div class="row pt-3">
                <div class="col-lg-12">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email id" aria-label="Username"
                      aria-describedby="basic-addon1" formControlName="email" autocomplete="new-email" />
                  </div>
                </div>
              </div>
              <div *ngIf="submitted && f.email.errors" class="invalid-text mt-n3"><br>
                <div *ngIf="f.email.errors.required"> Email id is required</div>
                <div *ngIf="f.email.errors.email">Enter Valid Email</div>
              </div>

              <!-- <div *ngIf="signUpError" class="invalid-text mt-1">
                  {{signUpError}}
                </div> -->

              <div class="row mt-4 action-btn">

                <div class="col-lg-12 mt-3">
                  <div class="list-button">
                    <button type="submit" [disabled]="signUpLoader" class="btn btn-custom btn-lg btn-block "
                      (click)="onSubmit()">
                      <i *ngIf="signUpLoader" class="fa fa-circle-o-notch fa-spin"></i>
                      <span *ngIf="signUpLoader"> &nbsp;Sign Up...</span>
                      <span *ngIf="!signUpLoader"> &nbsp; Sign Up</span>
                    </button>
                    <div class="list-item">
                      <button (click)="loginSignUP()" type="button" class="btn btn-custom ">Login</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-5 col-sm-5 col-xs-5 add-list" style="background-color:#fffbef; border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;">
            <img src="assets/images/login/image2.png" style="height: 490px;" class="background" alt="" />
            <div class="background-header" style="top:270px;">
              <h3>Hello, Friend!</h3>
              <p>Enter your personal details and start<br> journey with us</p>

            </div>
          </div>
        </div>
        <!----------- End sign up ----------------------->

        <!----------- sign In ----------------------->
        <div class="row ml-0 mt-25" *ngIf="!signInSingUp && !loginwithMobile">
          <div class="col-lg-7 col-sm-7  ptb-4">
            <div class="row align-center pt-4 pb-4">
              <div class="col-lg-4">
                <h4>Login</h4>
              </div>
              <div class="col-lg-8 text-right">
                <!-- <a href="#" (click)="loginWithFacebooks()" class="fa fa-facebook"></a> -->
                <a (click)="loginWithGoogles()" class="fa fa-google"></a>
              </div>
            </div>
            <p style="line-height: 1.3;">
              Or use your Email ID / Mobile Number
            </p>
            <form [formGroup]="form2" class="pt-20 pb-4" autocomplete="off">
              <div class="row pt-3">
                <div class="col-lg-12">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Enter mobile no (or) email id"
                      aria-label="Username" aria-describedby="basic-addon1" formControlName="userName"
                      autocomplete="new-userName" />
                  </div>

                  <div *ngIf="submitted2 && f2.userName.errors" class="invalid-text">
                    <div *ngIf="f2.userName.errors.required">Email id/Mobile Number is required</div>
                  </div>

                </div>
              </div>
              <div class="input-group pt-4">
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password"
                  aria-label="Username" aria-describedby="basic-addon1" formControlName="password"
                  autocomplete="new-password" />


                <i class="show-hide-pass" [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  (click)="togglePassword()" aria-hidden="true"></i>
              </div>

              <div *ngIf="submitted2 && f2.password.errors" class="invalid-text">
                <div *ngIf="f2.password.errors.required">Password is required</div>
              </div>



              <div class="form-control-file pt-4 forgot-password">
                <a style="cursor: pointer;" (click)="forgotPassword()">Forgot Password?</a>
              </div>
              <div class="row action-btn">
                <div class="col-lg-12 mt-2">
                  <button type="submit" [disabled]="loginLoader" class="btn btn-custom btn-lg btn-block"
                    (click)="onSubmit2()">
                    <i *ngIf="loginLoader" class="fa fa-circle-o-notch fa-spin"></i>
                    <span *ngIf="loginLoader"> &nbsp;Logging In ...</span>
                    <span *ngIf="!loginLoader"> &nbsp; Login</span>
                  </button>
                  <button (click)="loginwithmobile()" type="button"
                    class="btn btn-outline-custom btn-lg btn-block mt-14">Login
                    with
                    Mobile</button>
                  <div class="add-item">
                    <button (click)="loginSignUP()" type="button" class="btn btn-custom ">Sign Up</button>
                  </div>
                </div>
              </div>
            </form>


          </div>
          <div class="col-lg-5 col-sm-5 ">
            <img src="assets/images/login/image2.png" style="height: 100%;" class="background" alt="" />
            <div class="background-header">
              <h3>Hello, Friend!</h3>
              <p>Enter your personal details and start<br> journey with us</p>

            </div>
          </div>
        </div>
        <!-----------End sign In ----------------------->
        <!----------- sign In with mobile ----------------------->
        <div class="row ml-0" *ngIf="!loginwithEmail && !signInSingUp">
          <div class="col-lg-7 col-sm-7  ptb-4">
            <div class="row align-center pt-4 pb-4">
              <div class="col-lg-4">
                <h4>Login</h4>
              </div>
              <div class="col-lg-8 text-right">
                <!-- <a href="#" (click)="loginWithFacebooks()" class="fa fa-facebook"></a> -->
                <a (click)="loginWithGoogles()" class="fa fa-google"></a>
              </div>
            </div>
            <p style="line-height: 1.3;">
              Or Use Mobile Number
            </p>
            <form [formGroup]="form3" class="pt-20 pb-4" autocomplete="off">
              <div class="row pt-3">
                <div class="col-lg-12">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Enter mobile number" aria-label="Username"
                      formControlName="mobileNumber" aria-describedby="basic-addon1" autocomplete="new-mobileNumber" />
                  </div>

                  <div *ngIf="submitted3 && f3.mobileNumber.errors" class="invalid-text">
                    <div *ngIf="f3.mobileNumber.errors?.required">Mobile number is required</div>
                    <div *ngIf="f3.mobileNumber.errors?.pattern && f3.mobileNumber.touched">Enter Valid 10 digit Mobile
                      Number</div>

                  </div>
                </div>
              </div>

              <!-- <div *ngIf="loginError" class="invalid-text mt-1">
                  {{loginError}}
                </div> -->

              <div class="form-control-file forgot-password text-right">
                <a (click)="loginwithemail()">Back</a>
              </div>
              <div class="row action-btn">
                <div class="col-lg-12 mt-43">
                  <button type="submit" [disabled]="loginLoader" class="btn btn-custom btn-lg btn-block"
                    (click)="getotp()">
                    <i *ngIf="loginLoader" class="fa fa-circle-o-notch fa-spin"></i>
                    <!-- <span *ngIf="loginLoader"> &nbsp;Logging In ...</span> -->
                    <span *ngIf="!loginLoader"> &nbsp; Get OTP</span>
                  </button>
                  <button (click)="loginwithemail()" type="button"
                    class="btn btn-outline-custom btn-lg btn-block mt-14">Login
                    with
                    Email</button>
                </div>
              </div>
            </form>


          </div>
          <div class="col-lg-5 col-sm-5 ">
            <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
            <div class="background-header" style="top:260px;">
              <h3>Hello, Friend!</h3>
              <p>Enter your personal details and start<br> journey with us</p>
              <button (click)="loginSignUP()" type="button" class="btn btn-custom ">Sign Up</button>
            </div>
          </div>
        </div>
        <!-----------End sign In ----------------------->
      </div>
    </div>
  </div>
</div>