import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../../services/http/http.service";
declare var $: any;
import { AppConstants } from "src/app/configuration/app.constants";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { sweetAlert } from "src/app/shared/function/toaster";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { UserService } from "src/app/services/memory-services/user.service";

@Component({
  selector: "app-addons-popup",
  templateUrl: "./addons-popup.component.html",
  styleUrls: ["./addons-popup.component.less"],
})
export class AddonsPopupComponent implements OnInit {
  imgPth;
  cartData: any = [];
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  mainProductPrice: any;
  deliveryCharge: any;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public addCartService: AddCartService,
    private router: Router,
    public analyticsService: GoogleAnalyticsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.addons();
    this.analyticsService.pageView("View addons", `addons Popup`, "Popup");
    //get data on service
    this.addCartService.getCart().subscribe((data: any) => {
      console.log(data);

      this.cartData = [];
      this.cartData.push(data);

      let charge = JSON.parse(localStorage.getItem("deliveryPin"));
      this.deliveryCharge =
        this.cartData[0]?.attribute?.door == true ? charge?.deliveryCharge : 0;

      if (this.cartData[0] != null) {
        $("#addonsPopupModal").modal("show");
        this.refreshPrice();
        this.quantitySet();
        this.deliveryTime = "";
      }
    });
  }

  //get addons product List
  getdata;
  addons() {
    this.http.get("/gift").subscribe(
      (data) => {
        this.getdata = data;

        this.getdata.map((x) => {
          x.quantity = 0;
          let type = typeof x.productDetails;
          if (type == "string") x.productDetails = JSON.parse(x.productDetails);
          if (x.productImages) {
            x.productImages = x.productImages[0].split(",")[0];
          } else {
            x.productImages = "";
          }
          //calculate discount price
          x.productDetails.pricing.map((y) => {
            let decPercentage = y.discount / 100;
            let save = y.price * decPercentage;
            y.save = save;
            y.disPrice = y.price - save;
          });
        });
        //refresh slider after 200ms
        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      (error) => {}
    );
  }

  //set quantity to zero on all product

  quantitySet() {
    this.getdata.map((x) => {
      x.quantity = 0;
    });
  }

  //quantity plus icon
  clickCrtPlus(data) {
    this.getdata.map((x) => {
      if (x.productId == data.productId) {
        x.quantity = data.quantity + 1;
        let objIndex = this.cartData.findIndex(
          (x) => x.productId === data.productId
        );
        if (objIndex >= 1) {
          //remove
          this.cartData.splice(objIndex, 1);
        }
        this.addToCart(data, x.quantity);
      }
    });
  }

  //quantity Minus icon
  clickCrtMinus(data) {
    this.getdata.map((x) => {
      if (x.productId == data.productId) {
        if (x.quantity != 0) {
          x.quantity = data.quantity - 1;
          let objIndex = this.cartData.findIndex(
            (x) => x.productId === data.productId
          );
          if (objIndex >= 1) {
            //remove
            this.cartData.splice(objIndex, 1);
          }
          if (x.quantity != 0) {
            this.addToCart(data, x.quantity);
          } else {
            this.refreshPrice();
          }
        }
      }
    });
  }

  //////////////////////add to cart section////////////////////////////////
  orderId;
  loading = false;
  form;
  addonPrice;
  totalPrice;
  addToCart(item, quantity) {
    this.form = this.formBuilder.group({
      productId: [item.productId],
      sku: [item.sku],
      price: [item?.productDetails?.pricing[0]?.disPrice * quantity],
      quantity: [quantity],
      pricing: new FormGroup({
        kg: new FormControl("1"),
        flavour: new FormControl(null),
      }),
      attribute: new FormGroup({
        message: new FormControl(""),
      }),
    });

    this.cartData.push(this.form.value);
    this.refreshPrice();
  }

  deliveryTime;
  storeAddressVar;
  //submit final data to server
  submitAddToCart() {
    let deliveryPin =
      localStorage.getItem("deliveryPin") == ""
        ? localStorage.getItem("deliveryPin")
        : JSON.parse(localStorage.getItem("deliveryPin"));

    //set product if main product or gift product
    let addonProducts = [];

    this.cartData.map((x) => {
      if (x.attribute.cartType == "main") {
        var cartType = "main";
        var eggType = this.cartData[0].attribute.eggType;
      } else {
        var cartType = "sub";
        var eggType = null;
      }

      x.attribute = {
        isPanIndia: false,
        message: x.attribute.message,
        deliveryIn: this.cartData[0].attribute.deliveryIn,
        door: this.cartData[0].attribute.door,
        store: this.cartData[0].attribute.store,
        deliveryId: deliveryPin?.deliveryId,
        pinCode: deliveryPin?.pincode,
        addressId: null,
        storeAddress: null,
        randomNumber: this.cartData[0].attribute.randomNumber,
        cartType: cartType,
        eggType: eggType,
        midDelivery: this.cartData[0].attribute.midDelivery,
        fromTime: this.cartData[0].attribute.fromTime,
        backingTime: this.cartData[0].attribute?.backingTime,
        toTime: this.cartData[0].attribute.toTime,
        cutOfTime: this.cartData[0].attribute.cutOfTime,
        anniversaryDate:
          this.cartData[0].attribute.anniversaryDate ??
          this.cartData[0].attribute.deliveryIn,
        anniversaryTypeId: this.cartData[0].attribute.isAnniversary?.couponType,
      };
      if (this.cartData.length > 0) {
        let productDetails = this.getdata.find(
          (y) => String(y.productId) == String(x.productId)
        );
        addonProducts.push({
          id: x.productId,
          name: productDetails?.productName,
          list_name: "Addons to My Cart",
          brand: "Puppy's",
          category: productDetails?.categoryName,
          variant: x.pricing.flavour,
          quantity: x.quantity,
          price: Number(x.price) / Number(x.quantity),
        });
      }
    });

    console.log(this.cartData, "in the add on popup");

    if (this.cartData.length > 0) {
      this.analyticsService.addToCart({ currency: "INR", items: addonProducts });
    }
    this.loading = true;
    this.orderId = localStorage.getItem("orderId");
    //api to add all product to cart
    this.http
      .post(
        "/order/" + (this.orderId ? this.orderId : 0) + "/lineItem",
        this.cartData
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.addCartService.cartValue.next("SomeValue");
          sweetAlert("success", "Added to Cart");
          this.userService.setCartItem();
          this.redirectCheckout();
        },
        (error) => {
          this.loading = false;
          this.userService.setCartItem();
          sweetAlert("error", error.message ?? "try again");
        }
      );
  }

  //click image redirect to product
  redirectProduct(id) {
    //  $("#addonsPopupModal").modal('hide');
    //   this.router.navigate(['/details', id]);
  }

  //redirect to check out
  redirectCheckout() {
    $("#addonsPopupModal").modal("hide");
    this.router.navigate(["/checkout"]);
  }

  //Refresh price after changing quantity
  refreshPrice() {
    this.addonPrice = 0;
    this.cartData.map((x, index) => {
      this.mainProductPrice = this.cartData[0]?.price;
      if (index != 0) {
        this.addonPrice = this.addonPrice + x.price;
      }
    });
    this.totalPrice = this.addonPrice + this.cartData[0]?.price;
  }

  //refresh slider if after data is loaded
  refreshSlider() {
    $("#owl-frequently2").owlCarousel({
      items: 3,
      dots: false,
      nav: false,
      responsive: {
        0: {
          items: 2,
          navigation: true,
          margin: 0,
          nav: true,
          navText: [
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
          ],
        },
        801: {
          items: 3,
          navigation: true,
          nav: true,
          margin: 0,
          navText: [
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;position:absolute;right:20px'>",
          ],
        },
      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true,
    });
  }
}
