import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import Swal from "sweetalert2";
import { HttpService } from "src/app/services/http/http.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { MemoryService } from "src/app/services/memory-services/memory.service";
declare var $: any;

@Component({
  selector: "app-need-help",
  templateUrl: "./need-help.component.html",
  styleUrls: ["./need-help.component.less"],
})
export class NeedHelpComponent implements OnInit {
  @ViewChild("openModal") openModal: ElementRef;
  @ViewChild("needHelpData") myForm: NgForm;

  constructor(
    public http: HttpService,
    private authenticationService: AuthenticationService,
    public analyticsService: GoogleAnalyticsService,
    public memoryService: MemoryService
  ) {}
  currentUser;
  userName;
  mobile;

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser) {
      this.userName = this.currentUser.firstName + this.currentUser.lastName;
      this.mobile = this.currentUser.phoneNumber;
    } else {
      this.userName = "";
      this.mobile = "";
    }

    this.memoryService.memoryEmitter$.subscribe((res) => {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.userName = this.currentUser.firstName + this.currentUser.lastName;
      this.mobile = this.currentUser.phoneNumber;
    });
  }

  public submitLoading: Boolean = false;

  async doNewNeedHelp(value) {
    // Swal alert config
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    let data = {
      name: value.name,
      phoneNumber: value.mobileNo,
    };

    this.submitLoading = true;
    this.http.post("/need-help", data).subscribe(
      (data) => {
        Toast.fire({
          icon: "success",
          title: "Thank You For Reaching Us. Our Team Will Get Back To You",
        });
        this.submitLoading = false;
        this.openModal.nativeElement.click();
        this.closeNeedHelp();
      },
      (error) => {
        this.submitLoading = false;
        Toast.fire({
          icon: "error",
          title: "Try Again",
        });
      }
    );
  }

  /**
   * closeNeedHelp function prevent the need help model open again and again
   */
  closeNeedHelp() {
    localStorage.setItem("_neHe_", "1");
  }

  /**
   * keyPress function validating the mobile number
   * @param event
   */
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  logout() {
    let logoutVale: any = localStorage.getItem("loginIN");
    // this.analyticsService.commonEvents('Onclick',{'event_category': 'Logout My Account',
    // 'event_label': 'Logout My Account',
    // 'value': 'Logout My Account'});
    if (logoutVale == 2) {
      $("#needHelp").modal("show");
      this.authenticationService.googlelogout();
      localStorage.removeItem("loginIN");
    } else {
      // $('#needHelp').modal('show');
      this.authenticationService.logout();
    }
  }
}
