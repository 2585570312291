import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpService } from "../../services/http/http.service";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import {
  AppConstants,
  StoreAddress,
  PaymentGateway,
} from "../../configuration/app.constants";
import { sweetAlert } from "src/app/shared/function/toaster";
import { AddCartService } from "src/app/services/http/add-cart.service";
declare var Razorpay: any;
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
declare var $: any;
import { ORDER, StatesDistrictsService } from "./service/states-districts.service";
import moment from "moment";
import {
  checkNull,
  checkValidDeliveryTime,
} from "../product-details/date-and-time-validations";
import { UserService } from "src/app/services/memory-services/user.service";
import { SampleDelivery, ShipRocketService } from "./service/ship-rocket.service";
import { Calculation } from "./service/calculation";
import { LoaderService } from "src/app/services/memory-services/loader.service";
import { debounceTime, map } from "rxjs/operators";

class Coupon {
  couponId;
  couponType;
  couponName;
  couponCode;
  discountAmount;
  discountType;
  minimum;
  maximum;
  discount;
  constructor(data) {
    this.couponId = data?.couponNewId;
    this.couponType = data?.couponTypeId;
    this.couponName = data?.couponName;
    this.couponCode = data?.couponCode;
    this.discountAmount =
      data?.discountTypeId == true
        ? `₹ ${data?.discountAmount}`
        : `${data?.discountAmount}%`;
    this.discountType = data?.discountTypeId;
    this.minimum = data?.minimumSpend;
    this.maximum = data?.maximumSpend;
  }
}
function adaptCoupon(data: any) {
  return data?.length > 0 ? data.map((ele: any) => new Coupon(ele)) : [];
}
@Component({
  selector: "app-checkout-page",
  templateUrl: "./checkout-page.component.html",
  styleUrls: ["./checkout-page.component.less"],
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  loading = false;
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  orderId: any;
  deliveryTime = "";
  imgPth: any;
  cartItemCount: any;
  deliveryType = "";
  getCouponList2: any;
  getCouponList: any;
  getCartData: any;
  getCartData2: any;
  newList = [];
  customCartData = [];
  maxDeliverySpeed = 0;
  getCartData3: any;
  insertCartItem = [];
  updataCartItem = [];
  form: any;
  addonPrice = 0;
  totalPrice = 0;
  mainProductPrice = 0;
  cartData = [];
  randomNumber: any;
  getdata: any;
  totalcst: any;
  updateForm: any;
  quantityChange = [];
  storeaddress = StoreAddress;
  addresslane1: void;
  midNightDelCharge: number = 0;
  finalAmount: any;
  HomeLinerIds: any[] = [];
  StoreLinerIds: any[] = [];
  GST: number;
  NetAmount: number;
  couponCode: any;
  checkoutData: any[] = [];
  states: any = [];
  districts: any = [];
  pinCodeData: any = [];

  public pinCodeKeyword = "searchKey";
  patchId: any;
  ids: any;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public addCartService: AddCartService,
    private router: Router,
    public zones: NgZone,
    public analyticsService: GoogleAnalyticsService,
    public stateService: StatesDistrictsService,
    public userService: UserService,
    private shiprocketService: ShipRocketService,
    private loader: LoaderService
  ) {
    this.getAllPincodes();
  }
  textColor: string = "blue";
  changeTextColor(color: string) {
    this.textColor = color;
  }

  cartType: "zeroLength" | "hasnoPan" | "hasPan" | "hasboth" | "error";
  ngOnInit(): void {
    this.panControl = new FormControl("", [
      Validators.required,
      Validators.pattern(/^[1-9][0-9]{5}$/),
    ]);
    this.getStates();
    this.searchPinCodeData("");

    this.deliveryType = "door";
    this.getAddress(true);
    this.emptyAddressForm();
    this.price = "";
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.addons();
    this.getCoupon();
    this.cartItemCount = 0;
    this.analyticsService.pageView(
      "View Add to Cart/Checkout page",
      `checkout`,
      window.location.origin
    );
    this.getAllUserCoupon();
  }

  allStates: any;
  getStates() {
    this.allStates = this.stateService.getStatesandDistricts();
    this.states = this.stateService
      .getStatesandDistricts()
      .map((ele: any) => ele?.state);
  }

  couponList: any[] = [];
  searchedCouponList: any = [];
  searchControl = new FormControl("");
  getAllUserCoupon() {
    this.http
      .get("/admin/getAllUserCoupon")
      .pipe(map((ele) => adaptCoupon(ele)))
      .subscribe((res: any) => {
        this.couponList = res;
        this.searchedCouponList = this.couponList;
        this.searchValues();
        console.log(this.couponList, "coupo list");
      });
  }

  searchValues() {
    this.searchControl.valueChanges.pipe(debounceTime(250)).subscribe((res: any) => {
      this.searchedCouponList = this.couponList.filter(
        (ele: any) =>
          String(ele?.couponCode)
            .toLocaleLowerCase()
            .includes(String(res).toLocaleLowerCase().trim()) ||
          String(ele?.couponName)
            .toLocaleLowerCase()
            .includes(String(res).toLocaleLowerCase().trim())
      );
    });
  }

  stateChanged(data: any) {
    this.addressForm.get("city").setValue("");
    this.addressForm.get("city").updateValueAndValidity();
    let dst = this.allStates.find((ele: any) => String(ele?.state) === String(data));
    this.districts = dst?.districts;
  }

  //============================COUPON SECTION=======================================//
  getCoupon() {
    this.http.get("/order/getCoupon").subscribe(
      (data) => {
        this.getCouponList2 = data;
        this.getCouponList = this.getCouponList2;
      },
      (error) => {
        sweetAlert("error", error.message);
      }
    );
  }

  applyCoupon(code: any, initial?: any) {
    this.couponCode = code;
    if (!code) {
      sweetAlert("error", "Coupon Required");
      return;
    }
    this.loading = true;
    if (code == "delete_coupon") {
      this.analyticsService.commonEvents("Remove Coupon", {
        event_category: `Remove Coupon Code`,
        event_label: `Remove Coupon Code`,
        value: `Remove Coupon Code`,
      });
    } else {
      this.analyticsService.commonEvents("Apply Coupon", {
        event_category: `Apply Coupon Code : ${code} `,
        event_label: `Apply Coupon Code : ${code} `,
        value: `Apply Coupon Code : ${code} `,
      });
    }

    this.http
      .post("/order/addCoupon", JSON.parse('{ "coupon" : "' + code + '"}'))
      .subscribe(
        (data) => {
          $(`#couponPopup`).modal("hide");
          this.loading = false;
          if (!initial) {
            sweetAlert("success", "Success");
            this.getCart();
          }
        },
        (error) => {
          // $(`#couponPopup`).modal("hide");
          this.loading = false;
          if (!initial) {
            sweetAlert("error", error.message);
          }
        }
      );
  }

  ngOnDestroy(): void {
    // this.applyCoupon("delete_coupon");
  }
  //============================COUPON SECTION END=======================================//

  /////////////////////////////////////////////// CART  SECTION ///////////////////////////////////////////////////

  //get all cart list
  deliveryData: any;
  getCart(bool?: any) {
    this.deliveryControl = new FormControl("");
    this.loading = true;
    this.http.get("/order?delivery=" + this.deliveryType).subscribe(
      (data: ORDER) => {
        this.getCartData3 = data.data.lineItemsInfo;

        if (bool && checkNull(data.data?.coupon)) {
          this.applyCoupon("delete_coupon");
        }
        this.getCartType(data);

        const self = this;

        // function setPANDelivery(){

        // }

        // this.getAllDeliveryPartners();
        this.deliveryData = data;
        this.customCartData = [];
        this.getCartData = [];
        this.HomeLinerIds = [];
        this.StoreLinerIds = [];
        let viewCartdata = [];
        // this.loading = false;
        this.getCartData2 = data;
        if (
          this.addressList.length == 1 &&
          this.getCartData2?.homeAddress?.id == undefined &&
          this.getCartData2.data.lineItemsInfo.length > 0 &&
          this.addressList[0]?.pinCode ==
            data?.data?.lineItemsInfo[0]?.attribute.pinCode
        ) {
          this.addressNodeAdd("", this.addressList[0]);
          setTimeout(() => {
            this.onSelectAddress(false);
          }, 500);
          console.log("comes in", "hits here", this.addressList[0]);
        } else {
          this.loading = false;
        }

        this.getCartData = data.data;

        this.midNightDelCharge = this.getCartData2.midnightCharge;
        this.price = this.getCartData2.cost + this.midNightDelCharge;
        this.maxDeliverySpeed = 0;
        // if (this.price) {
        //   this.optionfun(); //razorpay
        // }
        if (this.getCartData2.data.lineItemsInfo) {
          setTimeout(() => {
            $("#datetimepicker").datetimepicker({
              minDate: new Date(),
              format: "d/M/Y H:i",
            });
          }, 200);
          this.cartItemCount = this.getCartData2.data.lineItemsInfo.length;
          this.getCartData.lineItemsInfo.map((x, index) => {
            //calculate delivery speed

            // if (x.product.deliverySpeed) {
            //   let spd = x.product.deliverySpeed;
            //   let spdValue = 0;
            //   if (spd == "2hour") spdValue = 2;
            //   if (spd == "6hour") spdValue = 6;
            //   if (spd == "sameDay") spdValue = 24;
            //   if (this.maxDeliverySpeed < spdValue)
            //     this.maxDeliverySpeed = spdValue;
            // }
            if (x.attribute.door == true) {
              this.HomeLinerIds.push(x.lineItemId);
            }
            if (x.attribute.store == true) {
              this.StoreLinerIds.push(x.lineItemId);
            }

            if (x.product.productImages) {
              x.product.productImages = x.product.productImages.split(",")[0];
            } else {
              x.productImages = "";
            }

            if (x.attribute.cartType == "main") {
              this.customCartData.push(x);
            }
          });

          this.customCartData.map((x, index) => {
            this.totalcst = 0;
            x.subItem = this.getCartData.lineItemsInfo.filter(
              (val) =>
                val.attribute.randomNumber == x.attribute.randomNumber &&
                val.attribute.cartType == "sub"
            );
            this.totalcst = x.cost;
            let subitm = this.getCartData.lineItemsInfo.filter(
              (val) =>
                val.attribute.randomNumber == x.attribute.randomNumber &&
                val.attribute.cartType == "sub"
            );
            subitm.map((y) => {
              this.totalcst = this.totalcst + y.cost;
            });
            x.totalCst = this.totalcst;
            viewCartdata.push({
              id: x.product.productId,
              name: x.product.productName,
              brand: "Puppy's",
              category: x.product.categoryName,
              variant: x.pricing.flavour,
              quantity: x.quantity,
              price: x.totalCst,
            });
          });
          this.checkDates();
          this.analyticsService.viewCart({ currency: "INR", items: viewCartdata });
          console.log(this.addressNode, "in the get cart button");
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  selectedAddress(data: ORDER) {
    let hasHome = data.data.lineItemsInfo.some((ele) => ele.attribute.door == true);
    let hasStore = data.data.lineItemsInfo.some(
      (ele) => ele.attribute.store == true
    );

    if (hasHome && checkNull(data.homeAddress.deliveryId)) {
      this.addressNode = data.homeAddress;
      console.log("let me check here");
    }

    if (hasStore && !hasHome && checkNull(data.storeAddress?.name)) {
    }
  }
  ShowHomeAndStore(type) {
    if (type == "home") {
      return this.getCartData.lineItemsInfo.find(
        (x) => x?.attribute?.door == true
      ) != undefined
        ? true
        : false;
    } else {
      return this.getCartData.lineItemsInfo.find(
        (x) => x?.attribute?.store == true
      ) != undefined
        ? true
        : false;
    }
  }
  //change order quantity
  changeQuantity(quantity, item) {
    this.quantityChange = [];

    if (item.attribute.cartType == "main" && quantity == 0) {
      this.quantityChange = this.getCartData2.data.lineItemsInfo.filter(
        (val) => val.attribute.randomNumber == item.attribute.randomNumber
      );
      this.quantityChange.map((x) => {
        x.quantity = 0;
      });
    } else if (item.attribute.cartType == "sub" || quantity != 0) {
      this.updateForm = this.formBuilder.group({
        sku: [item.sku],
        quantity: [quantity],
        lineItemId: [item.lineItemId],
        pricing: new FormGroup({
          kg: new FormControl(item.pricing.kg),
          flavour: new FormControl(null),
        }),
        attribute: new FormGroup({}),
      });
      this.quantityChange.push(this.updateForm.value);
    } else {
      return;
    }
    this.changeQuantityFun(this.quantityChange);
  }

  //change order quantity api
  changeQuantityFun(quantityChange) {
    if (quantityChange.length == 0) {
      this.loading = false;
      this.getCart();
      return;
    }
    this.loading = true;
    this.orderId = localStorage.getItem("orderId");
    let alreadyCartData = this.getCartData2.data.lineItemsInfo.find(
      (res) => String(quantityChange[0].lineItemId) == String(res.lineItemId)
    );

    if (alreadyCartData != undefined) {
      if (Number(quantityChange[0].quantity) <= 0) {
        this.analyticsService.removeFromCart({
          currency: "INR",
          items: [
            {
              id: alreadyCartData?.product?.productId,
              name: alreadyCartData?.product?.productName,
              list_name: "My Cart",
              brand: "Puppy's",
              variant: alreadyCartData?.pricing.flavour,
              quantity: Number(alreadyCartData?.quantity),
              category: alreadyCartData?.product?.categoryName,
              price: alreadyCartData?.cost / Number(alreadyCartData?.quantity),
            },
          ],
        });
      } else {
        if (Number(alreadyCartData?.quantity) > Number(quantityChange[0].quantity)) {
          let removeQuanty =
            Number(alreadyCartData?.quantity) - Number(quantityChange[0].quantity);
          this.analyticsService.removeFromCart({
            currency: "INR",
            items: [
              {
                id: alreadyCartData?.product?.productId,
                name: alreadyCartData?.product?.productName,
                list_name: "My Cart",
                brand: "Puppy's",
                variant: alreadyCartData?.pricing.flavour,
                quantity: removeQuanty,
                category: alreadyCartData?.product?.categoryName,
                price: alreadyCartData?.cost / Number(alreadyCartData?.quantity),
              },
            ],
          });
        } else {
          let removeQuanty =
            Number(quantityChange[0].quantity) - Number(alreadyCartData?.quantity);
          this.analyticsService.addToCart({
            currency: "INR",
            items: [
              {
                id: alreadyCartData?.product?.productId,
                name: alreadyCartData?.product?.productName,
                list_name: "My Cart",
                brand: "Puppy's",
                variant: alreadyCartData?.pricing.flavour,
                quantity: removeQuanty,
                category: alreadyCartData?.product?.categoryName,
                price: alreadyCartData?.cost / Number(alreadyCartData?.quantity),
              },
            ],
          });
        }
      }
    }

    this.http
      .post(
        "/order/" + (this.orderId ? this.orderId : 0) + "/lineItem",
        quantityChange
      )
      .subscribe(
        (data: any) => {
          this.addCartService.cartValue.next("SomeValue");
          this.loading = false;
          sweetAlert("success", "Success");
          this.getCart();
          this.userService.setCartItem();
        },
        (error) => {
          this.loading = false;
          if (error.message) {
            sweetAlert("error", error.message);
            this.userService.setCartItem();
          } else {
            sweetAlert("error", "Try Again");
            this.userService.setCartItem();
          }
        }
      );
  }
  ////////////////////////////////// END ADDONS SECTION  /////////////////////////////////////////

  //////////////////////////////////  ADDONS SECTION  /////////////////////////////////////////

  //get all addons gift
  addons() {
    this.http.get("/gift").subscribe(
      (data) => {
        this.getdata = data;
        this.getdata.map((x) => {
          x.quantity = 0;
          let type = typeof x.productDetails;
          if (type == "string") x.productDetails = JSON.parse(x.productDetails);
          if (x.productImages) {
            x.productImages = x.productImages[0].split(",")[0];
          } else {
            x.productImages = "";
          }
          //calculate discount price
          x.productDetails.pricing.map((y) => {
            let decPercentage = y.discount / 100;
            let save = y.price * decPercentage;
            y.save = save;
            y.disPrice = y.price - save;
          });
        });
        setTimeout(() => {
          this.refreshSlider();
        }, 400);
      },
      (error) => {}
    );
  }

  //open and autofill addons
  openAddonPopup(item) {
    this.cartData = [];
    this.quantitySet();
    this.randomNumber = item.attribute.randomNumber;
    this.getCartData2.data.lineItemsInfo.map((x) => {
      x.productId = x.product.productId;
      if (x.attribute.randomNumber == item.attribute.randomNumber) {
        this.cartData.push(x);
        let objIndex = this.getdata.findIndex(
          (y) => y.productId === x.product.productId
        );
        if (objIndex >= 0) {
          this.getdata[objIndex].quantity = x.quantity;
          this.getdata[objIndex].attribute = x.attribute;
        }
      }
    });

    this.refreshPrice();
    $("#addonsPopupModal2").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Addon Product list Popup",
      event_label: "Open Addon Product list Popup",
      value: "Open Addon Product list Popup",
    });
  }

  quantitySet() {
    this.getdata.map((x) => {
      x.quantity = 0;
      x.attribute = {
        randomNumber: null,
      };
    });
  }

  //addons plus btn
  clickCrtPlus(data) {
    this.getdata.map((x) => {
      if (x.productId == data.productId) {
        x.quantity = data.quantity + 1;
        let objIndex = this.cartData.findIndex((x) => x.productId == data.productId);
        if (objIndex >= 1) {
          //remove
          this.cartData.splice(objIndex, 1);
        }
        this.addToCart(data, x.quantity);
      }
    });
  }

  //addons minus btn
  clickCrtMinus(data) {
    this.getdata.map((x) => {
      if (x.productId == data.productId) {
        if (x.quantity >= 0) {
          x.quantity = data.quantity - 1;
          let objIndex = this.cartData.findIndex(
            (x) => x.productId === data.productId
          );
          if (objIndex >= 1) {
            //remove
            this.cartData.splice(objIndex, 1);
          }
          this.addToCart(data, x.quantity);
        }
      }
    });
  }

  //refresh addons price
  refreshPrice() {
    this.addonPrice = 0;
    this.mainProductPrice = 0;
    this.cartData.map((x, index) => {
      if (x.attribute.cartType == "main") {
        if (x.price) {
          this.mainProductPrice = x.price;
        } else {
          this.mainProductPrice = x.cost;
        }
      } else {
        if (x.price) {
          this.addonPrice = this.addonPrice + x.price;
        } else {
          this.addonPrice = this.addonPrice + x.cost;
        }
      }
    });
    this.totalPrice = this.addonPrice + this.mainProductPrice;
  }

  //add or remove & edit addons
  addToCart(item, quantity?) {
    if (quantity == 0) {
      if (item.attribute.randomNumber) {
        var price = 0;
      } else {
        this.refreshPrice();
        return;
      }
    } else {
      var price = item?.productDetails?.pricing[0]?.disPrice * quantity;
    }

    this.form = this.formBuilder.group({
      productId: [item.productId],
      sku: [item.sku],
      price: [price],
      cost: [price],
      quantity: [quantity],
      pricing: new FormGroup({
        kg: new FormControl("1"),
        flavour: new FormControl(null),
      }),
      attribute: new FormGroup({
        message: new FormControl(""),
        randomNumber: new FormControl(item.attribute.randomNumber),
      }),
    });

    this.cartData.push(this.form.value);
    this.refreshPrice();
  }

  //submit addons button => add or remove addons
  submitAddToCart() {
    this.insertCartItem = [];
    this.updataCartItem = [];
    let mainItem = this.cartData.find((x) => x.attribute.cartType === "main");
    delete mainItem["subItem"];
    let addonProducts = [];
    this.cartData.map((x) => {
      if (x.attribute.cartType != "main" && x.attribute.randomNumber != null) {
        this.updataCartItem.push(x);
      }

      if (x.attribute.cartType != "main" && x.attribute.randomNumber == null) {
        x.attribute = mainItem.attribute;
        x.attribute.cartType = "sub";
        this.insertCartItem.push(x);
      }
      if (this.cartData.length > 0) {
        let productDetails = this.getdata.find(
          (y) => String(y.productId) == String(x.productId)
        );

        if (productDetails?.sku.includes("GIFT")) {
          addonProducts.push({
            id: x.productId,
            name: productDetails?.productName,
            list_name: "Addons to My Cart",
            brand: "Puppy's",
            category: productDetails?.categoryName,
            variant: x.pricing.flavour,
            quantity: x.quantity,
            price: Number(x.cost) / Number(x.quantity),
          });
        }
      }
    });
    if (this.cartData.length > 0) {
      this.analyticsService.addToCart({ currency: "INR", items: addonProducts });
    }
    $("#addonsPopupModal2").modal("hide");
    if (this.insertCartItem.length != 0) {
      this.loading = true;
      this.orderId = localStorage.getItem("orderId");
      this.http
        .post(
          "/order/" + (this.orderId ? this.orderId : 0) + "/lineItem",
          this.insertCartItem
        )
        .subscribe(
          (data) => {
            this.changeQuantityFun(this.updataCartItem);
          },
          (error) => {
            this.loading = false;
            sweetAlert("error", "error.message");
          }
        );
    } else {
      this.changeQuantityFun(this.updataCartItem);
    }
  }
  /////////////////////////////////////////////////  END ADDONS SECTION  //////////////////////////////////////////////////////////

  /////////////////////////////////////////////////  ADDRESS SECTION  //////////////////////////////////////////////////////////
  addressList: any = [];
  radioControl = new FormControl();
  addressNode;
  addressFormDiv = false;
  showPickUpStorediv = false;
  addressListDiv = true;
  isAddressEdited;
  submitted2 = false;
  addressForm: FormGroup;

  //Get user address
  getAddress(bool) {
    this.loading = true;
    this.http.get("/user/" + this.currentUser.id + "/shipping-info").subscribe(
      (data) => {
        this.addressList = data;
        this.loading = false;
        console.log("hitting node2");
        bool ? (this.addressNode = "") : "";
        bool ? this.getCart(bool) : "";
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Try Again");
        bool ? this.getCart(bool) : "";
      }
    );
  }

  showChangeAddress(id?) {
    console.log("hitting node3");
    this.addressNode = "";
    this.addressListDiv = true;
    if (id == 0) {
      this.addressFormDiv = false;
      this.showPickUpStorediv = false;
      this.deliveryType = "door";
      return;
    }
    this.addressFormDiv = !this.addressFormDiv;
    this.showPickUpStorediv = false;
    if (this.addressFormDiv == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Door Delivery Address List Popup",
        event_label: "Open Door Delivery Address List Popup",
        value: "Open Door Delivery Address List Popup",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Close Door Delivery Address List Popup",
        event_label: "Close Door Delivery Address List Popup",
        value: "Close Door Delivery Address List Popup",
      });
    }
  }

  showPickUpStore(id?) {
    console.log("hitting node4");
    this.addressNode = "";
    this.addressFormDiv = false;
    if (id == 0) {
      this.addressListDiv = false;
      this.showPickUpStorediv = true;
      this.deliveryType = "store";
      return;
    }
    this.showPickUpStorediv = !this.showPickUpStorediv;
    if (this.showPickUpStorediv == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Store Delivery Address List Popup",
        event_label: "Open Store Delivery Address List Popup",
        value: "Open Store Delivery Address List Popup",
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Close Store Delivery Address List Popup",
        event_label: "Close Store Delivery Address List Popup",
        value: "Close Store Delivery Address List Popup",
      });
    }
  }

  addressNodeAdd(event, item) {
    this.addressNode = item;
  }

  validData: any[] = [];
  panProducts: any[] = [];
  validDeliveryData: any[] = [];
  checkDates() {
    this.validData = [];
    this.customCartData.filter((item: any, inx: any) => {
      this.validData.push(
        checkValidDeliveryTime(
          moment(item?.attribute?.deliveryIn).format("yyyy-MM-DD"),
          moment(item?.attribute?.deliveryIn).format("HH:mm"),
          item?.attribute
        ).res || !checkNull(item?.attribute?.deliveryIn)
        // false
      );

      function validateDeliveryStatus(data) {
        console.log(data, "this is data");

        return {
          status: data?.product?.deliveryStatus,
          message:
            data?.product?.deliveryStatus != true
              ? `Sorry kindly remove this order from the cart. Delivery is not available for ${data?.product?.productName}`
              : "",
        };
      }

      this.panProducts.push({
        product: item?.product,
        index: inx,
        isPanProduct: !checkNull(item?.attribute?.deliveryIn),
        hideAddOns: !item?.attribute?.isPanIndia,
        status: validateDeliveryStatus(item).status,
        message: validateDeliveryStatus(item).message,
      });
    });
    console.log(
      this.panProducts,
      "validateDeliveryStatus(item).status",
      this.validData
    );
  }

  checkDate(item: any) {
    // checkValidDeliveryTime(item?.attribute?.deliveryIn, moment(item?.attribute?.deliveryIn).format("HH:mm"), item?.attribute).res == false ? true : false;
  }

  deleteAddress(id) {
    this.loading = true;
    this.analyticsService.commonEvents("Onclick", {
      event_category: `Delete Single Delivery Address`,
      event_label: `Delete Single Delivery Address`,
      value: `Delete Single Delivery Address`,
    });
    this.http
      .delete("/user/" + this.currentUser.id + "/shipping-info/" + id)
      .subscribe(
        (data) => {
          sweetAlert("success", "Address Deleted Successfully");
          this.emptyAddressForm();
          this.getAddress(false);
          this.loading = false;
        },
        (error) => {
          sweetAlert("error", "Try Again2");
          this.loading = false;
        }
      );
  }

  randomAddressId;
  openAddAddressModal(deliveryType) {
    $("#addAddressModal").modal({ backdrop: "static", keyboard: false });
    $('input[type="radio"]').prop("checked", false);
    if (deliveryType == "store") {
      this.showPickUpStore("0");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Store Delivery Address Popup",
        event_label: "Open Store Delivery Address Popup",
        value: "Open Store Delivery Address Popup",
      });
    } else {
      this.showChangeAddress("0");
      this.setradiobutton();
      console.log(this.radioControl.value, "radio control valueeee");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Door Delivery Address Popup",
        event_label: "Open Door Delivery Address Popup",
        value: "Open Door Delivery Address Popup",
      });
    }
  }
  setradiobutton() {
    this.patchId = checkNull(this.getCartData2?.homeAddress?.id)
      ? this.getCartData2?.homeAddress?.id
      : 0;
    this.ids =
      this.patchId != 0
        ? this.addressList.findIndex((res) => res?.id == this.patchId)
        : null;

    setTimeout(() => {
      if (checkNull(this.ids)) {
        this.addressNodeAdd("", this.addressList[this.ids]);
      }
    }, 500);
  }
  addNewAddress() {
    this.panControl = new FormControl("", [
      Validators.required,
      Validators.pattern(/^[1-9][0-9]{5}$/),
    ]);
    this.isAddressEdited = false;
    this.addressForm.reset();

    $("#addNewAddressModal").modal({ backdrop: "static", keyboard: false });
    $("#addAddressModal").modal("hide");

    this.analyticsService.commonEvents("Onclick", {
      event_category: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      event_label: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      value: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
    });
  }

  backListAddress() {
    $("#addNewAddressModal").modal("hide");
    $("#addAddressModal").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: `Close ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      event_label: `Close ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      value: `Close ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
    });
  }

  multiLineItem = [];
  addbody = null;
  //add address inside order
  onSelectAddress(bool?) {
    // this.deliveryType = deliveryType;
    console.log(this.addressNode, "while submitting");

    this.addbody = null;
    if (!this.addressNode) {
      if (this.deliveryType == "door") {
        Swal.fire({
          title: "Select Delivery Address",
          confirmButtonColor: "#fe9ab3",
        });
      } else {
        Swal.fire({
          title: "Select Pickup Store Address",
          confirmButtonColor: "#fe9ab3",
        });
      }
      return;
    }

    if (
      this.addressNode?.pinCode?.length == 6 ||
      this.addressNode?.type == "storeAddress"
    ) {
    } else {
      Swal.fire({
        title: "Kindly, Add Delivery Address",
        confirmButtonColor: "#fe9ab3",
      });
      return;
    }

    this.analyticsService.commonEvents("Onclick", {
      event_category: `${
        this.deliveryType == "door"
          ? "Select Delivery Address"
          : "Select Pickup Store Address"
      } Form Order Pay`,
      event_label: `${
        this.deliveryType == "door"
          ? "Select Delivery Address"
          : "Select Pickup Store Address"
      } Form Order Pay`,
      value: `${
        this.deliveryType == "door"
          ? "Select Delivery Address"
          : "Select Pickup Store Address"
      } Form Order Pay`,
    });
    bool ? (this.loading = true) : "";
    this.orderId = localStorage.getItem("orderId");

    if (this.addressNode.type == "storeAddress") {
      this.addbody = {
        orderId: this.orderId,
        addressId: this.addressNode.id,
        lineItemIds: this.StoreLinerIds,
        addressType: "store",
        address: this.addressNode,
      };
    } else {
      let address = this.addressNode;

      const foundAddress = this.pincodes1.filter(
        (ele) => String(ele?.pincode) == String(address?.pinCode)
      )[0];

      console.log(foundAddress, "foundAddress", this.pincodes1, address);

      if (checkNull(foundAddress?.deliveryCharge)) {
        address.deliveryCharge = String(foundAddress?.deliveryCharge);
      }

      this.addbody = {
        orderId: this.orderId,
        addressId: this.addressNode.id,
        lineItemIds: this.HomeLinerIds,
        addressType: "home",
        address: address,
      };
      console.log(this.addbody, foundAddress, address);
    }

    // write validation for midnight

    var mainItemDeliveryDate = this.quantityChange
      .filter((i) => i.attribute.cartType == "main")
      .map((x) => x.attribute.deliveryIn)
      .join(",");
    var dtime = mainItemDeliveryDate.substr(12, 5);

    //api to add address inside order
    this.http.put("/order/address", this.addbody).subscribe(
      (data) => {
        this.loading = false;
        bool ? sweetAlert("success", "Successfully Address Added") : "";
        this.getCart();
        $("#addAddressModal").modal("hide");
      },
      (error) => {
        this.loading = false;
        if (error.message) {
          sweetAlert("error", error.message);
        } else {
          sweetAlert("error", "Try Again");
        }
      }
    );
  }

  openShipping() {
    if (
      (this.ShowHomeAndStore("store") &&
        this.getCartData2?.storeAddress?.id == undefined) ||
      (this.ShowHomeAndStore("home") &&
        this.getCartData2?.homeAddress?.id == undefined)
    ) {
      sweetAlert("error", `Please select the home address`);
      return;
    } else {
      $(`#exampleModal`).modal("show");
    }
  }

  /***************** Check Deliveryy time is expiered ************/
  checkDeliveryTime(value) {
    let isexpired: boolean = false;
    if (!value?.attribute.midDelivery) {
      if (
        moment(value?.attribute.deliveryIn).format("DD-MM-YYYY") ==
        moment(new Date()).format("DD-MM-YYYY")
      ) {
        if (
          moment(
            `${moment(new Date()).format("DD-MM-YYYY")} ${moment(new Date()).format(
              "HH:mm"
            )}:00`,
            "DD-MM-YYYY HH:mm:ss"
          ).isAfter(
            moment(
              `${moment(new Date()).format("DD-MM-YYYY")} ${
                value?.attribute.cutOfTime
              }:00`,
              "DD-MM-YYYY hh:mm:ss"
            )
          )
        ) {
          isexpired = true;
        }
      } else if (moment(value?.attribute.deliveryIn).isBefore(moment(new Date()))) {
        isexpired = true;
      }
    } else {
      if (
        moment(value?.attribute.deliveryIn).isAfter(
          moment(
            `${moment(new Date()).format("DD-MM-YYYY")} ${
              value?.attribute.toTime
            }:00`
          )
        )
      ) {
        isexpired = true;
      }
    }
    return isexpired;
  }

  addresserror;
  //Continue to pay button
  checkPincode() {
    this.checkDates();
    if (!this.panProducts.every((ele) => ele.status == true)) {
      sweetAlert(
        "error",
        "Sorry kindly remove the orders which delivery was not available."
      );
      return;
    }
    if (!this.validData.every((ele) => ele == true)) {
      sweetAlert(
        "error",
        "Sorry kindly remove orders with expired the delivery time."
      );
      return;
    }

    this.analyticsService.commonEvents("OnClick", {
      event_category: `Check PinCode For Continue to Pay`,
      event_label: `Check PinCode For Continue to Pay`,
      value: `Check PinCode For Continue to Pay`,
    });
    this.addresserror = "";
    let PaymentData: any = [];
    this.customCartData.map((x) => {
      if (!x.attribute?.addressId) {
        this.addresserror = x.product.productName;
      }
      PaymentData.push({
        id: x.product.productId,
        name: x.product.productName,
        brand: "Puppy's",
        category: x.product.categoryName,
        variant: x.pricing.flavour,
        quantity: x.quantity,
        price: Number(x.totalCst) / Number(x.quantity),
      });
    });

    // if (this.addresserror != "") {
    //   sweetAlert(
    //     "error",
    //     "Address is required for product " + this.addresserror
    //   );
    //   return;
    // }
    if (
      (this.ShowHomeAndStore("store") &&
        this.getCartData2?.storeAddress?.id == undefined) ||
      (this.ShowHomeAndStore("home") &&
        this.getCartData2?.homeAddress?.id == undefined)
    ) {
      sweetAlert(
        "error",
        `Address is required for  ${
          this.ShowHomeAndStore("store") &&
          this.getCartData2?.storeAddress?.id == undefined
            ? "store"
            : "home"
        }`
      );
      return;
    }
    this.initPay(PaymentData);
  }

  ////RazorPay Payment gateway integration
  options;
  price;
  optionfun() {
    var angular = this;
    this.options = {
      key: PaymentGateway.paymentId,
      amount:
        Number(
          (this.calculations?.grandTotal > 0
            ? this.calculations?.grandTotal
            : 0
          ).toFixed(2)
        ) * 100,
      currency: "INR",
      name: "Puppy's Bakery",
      description: "Welcome to Puppy's Bakery",
      image: "../../../assets/images/logo/logo_1.png",
      handler: function (response) {
        angular.paymentSuccess(response);
        console.log(response, "handler");
      },
      modal: {
        escape: false,
        ondismiss: function (response) {
          angular.http
            .patch(`/updateRazorPaymentStatus/${angular.orderId}`)
            .subscribe(
              (res: any) => {
                console.log(res);
              },
              (err) => {
                console.log(err);
              }
            );
        },
      },
      prefill: {
        name: this.currentUser.firstName,
        email: this.currentUser.email,
        contact: this.currentUser.phoneNumber,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#fe9ab3",
      },
    };
  }
  initPay(payData) {
    this.checkoutData = [];
    this.checkoutData = payData ? payData : [];

    if (String(this.couponCode) != "delete_coupon" || this.couponCode == undefined) {
      this.analyticsService.beginCheckout({
        value: Number(
          Math.round(
            this.getCartData2?.data?.costDetails?.delivery +
              this.subTotal(this.getCartData2.data) +
              this.GSTAmount(this.getCartData2.data) +
              this.getCartData2?.midnightCharge
          ).toFixed(2)
        ),
        shipping: this.getCartData2.data?.costDetails?.delivery,
        currency: "INR",
        items: this.checkoutData,
        checkout_step: 1,
        checkout_option: "Init Pay to Razorpay Payment GateWay",
      });
    } else {
      this.analyticsService.beginCheckout({
        value: Number(
          Math.round(
            this.getCartData2?.data?.costDetails?.delivery +
              this.subTotal(this.getCartData2.data) +
              this.getCartData2?.midnightCharge
          ).toFixed(2)
        ),
        shipping: this.getCartData2.data?.costDetails?.delivery,
        currency: "INR",
        items: this.checkoutData,
        checkout_step: 1,
        checkout_option: "Init Pay to Razorpay Payment GateWay",
        coupon: this.couponCode,
      });
    }

    if (
      this.calculations.isPanIdia &&
      (String(this.calculations.delivery) == "NaN" ||
        String(this.calculations.delivery) == "0")
    ) {
      sweetAlert("error", "Delivery is not available to this area");
      return;
    }
    this.orderId = localStorage.getItem("orderId");
    const angular = this;
    this.loader.open();
    this.http
      .post("/v1/orders", {
        grandTotal: Number(Number(this.calculations.grandTotal * 100).toFixed(2)),
        orderId: this.orderId,
      })
      .subscribe(
        async (res: any) => {
          console.log(res);
          this.loader.close();
          if (!checkNull(res.message)) {
            this.options.order_id = res.id ?? "";
            var rzp1 = await new Razorpay(this.options);
            await rzp1.open();
          } else {
            // Sorry, We were facing some technical issues while payment. Kindly contact us through +91 8122210222 or mailbox@puppys.co.in
            Swal.fire({
              title: "<strong style='color:#ff9966'>CONTACT US</strong>",
              html: ` <b style="text-transform: uppercase;">You have already attempted the payment, Please contact support team</b> `,
              icon: "info",
              showCancelButton: false,
              confirmButtonText: `OKAY`,
              allowOutsideClick: false,
              confirmButtonColor: "#fc98b2",
              cancelButtonColor: "#fc98b2",
              cancelButtonText: "Cancel",
            });
          }
        },
        (err) => {
          this.loader.close();
          sweetAlert("error", "Internal server error");
        }
      );
  }

  //after payment success
  payForm;
  responseOrderId;
  paymentSuccess(payId) {
    this.zones.run(() => {
      this.orderId = localStorage.getItem("orderId");
      const value = this.deliveryControl.value;
      let address = this.addressNode;
      if (!checkNull(value?.deliveryCharge)) {
        address.deliveryCharge = this.calculations.delivery ?? 0;
      }
      this.payForm = this.formBuilder.group({
        orderId: [this.orderId],
        paymentId: [payId.razorpay_payment_id],
        orderDetails: [address],
        panDeliveryPartner: value.companyName ?? null,
        panDeliveryCharge: value?.deliveryCharge ?? null,
        panExpectedDate: value?.expectedOn ?? null,
        panCourierCompanyId: value?.companyId ? Number(value?.companyId) : null,
        panMidNightCharge:
          this.calculations.midNight > 0 ? this.calculations.midNight : 0,
      });
      this.loading = true;
      if (
        String(this.couponCode) != "delete_coupon" ||
        this.couponCode == undefined
      ) {
        this.analyticsService.checkoutProgress({
          transaction_id: payId.razorpay_payment_id,
          value:
            this.getCartData2?.data?.costDetails?.delivery +
            this.subTotal(this.getCartData2.data) +
            this.getCartData2?.midnightCharge,
          shipping: this.getCartData2.data?.costDetails?.delivery,
          currency: "INR",
          items: this.checkoutData,
          checkout_step: 2,
          checkout_option: "Payment Success And Progress Order Insert In Database",
        });
      } else {
        this.analyticsService.checkoutProgress({
          transaction_id: payId.razorpay_payment_id,
          value:
            this.getCartData2?.data?.costDetails?.delivery +
            this.subTotal(this.getCartData2.data) +
            this.getCartData2?.midnightCharge,
          shipping: this.getCartData2.data?.costDetails?.delivery,
          currency: "INR",
          items: this.checkoutData,
          checkout_step: 2,
          checkout_option: "Payment Success And Progress Order Insert In Database",
          coupon: this.couponCode,
        });
      }
      this.http.put("/user/order/confirm", this.payForm.value).subscribe(
        (data) => {
          this.userService.setCartItem();
          this.loading = false;
          localStorage.removeItem("orderId");
          this.addCartService.cartValue.next("SomeValue");
          sweetAlert("success", "Successfully Order Placed");
          this.router.navigate(["/profile/order"]);
          if (
            String(this.couponCode) != "delete_coupon" ||
            this.couponCode == undefined
          ) {
            this.analyticsService.purchase({
              transaction_id: payId.razorpay_payment_id,
              value:
                this.getCartData2?.data?.costDetails?.delivery +
                this.subTotal(this.getCartData2.data) +
                this.getCartData2?.midnightCharge,
              shipping: this.getCartData2.data?.costDetails?.delivery,
              currency: "INR",
              items: this.checkoutData,
              checkout_step: 3,
              checkout_option: "Order Placed Successfully.",
            });
          } else {
            this.analyticsService.purchase({
              transaction_id: payId.razorpay_payment_id,
              value:
                this.getCartData2?.data?.costDetails?.delivery +
                this.subTotal(this.getCartData2.data) +
                this.getCartData2?.midnightCharge,
              shipping: this.getCartData2.data?.costDetails?.delivery,
              currency: "INR",
              items: this.checkoutData,
              checkout_step: 3,
              checkout_option: "Order Placed Successfully.",
              coupon: this.couponCode,
            });
          }
        },
        (error) => {
          this.loading = false;
          sweetAlert("error", "Problem ContactUs");
        }
      );
    });
  }

  //refresh addons slider
  refreshSlider() {
    $("#owl-frequently3").owlCarousel({
      items: 3,
      dots: false,
      nav: false,
      responsive: {
        0: {
          items: 2,
          navigation: true,
          margin: 0,
          nav: true,
          navText: [
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
          ],
        },
        801: {
          items: 3,
          navigation: true,
          nav: true,
          margin: 0,
          navText: [
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
          ],
        },
      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true,
    });
  }

  /* get delivery address from main item in the lineitem */

  // getDeliveryAddres(item) {

  //   let address = [];
  //   address = item
  //     .filter((i) => i.attribute.cartType == "main")
  //     .map((x) => x.attribute.cartAddress);
  //   return address.length == 1 ? address[0] : {};
  // }

  getDeliveryAddres(attribute, type) {
    var el = attribute.cartType == "main" ? attribute?.cartAddress ?? {} : {};

    let addressValues = [];
    let mobileNumbers = [];

    Object.keys(el).forEach(function (property) {
      if (el[property] != null) {
        var value = el[property];

        // address
        if (property == "line1") addressValues.push(value);
        if (property == "line2") addressValues.push(value);
        if (property == "city") addressValues.push(value);
        if (property == "state") addressValues.push(value);

        // phone numbers
        if (property == "phoneNumber") {
          mobileNumbers.push(value);
        }

        if (property == "alternativePhoneNumber") {
          mobileNumbers.push(value);
        }
      }
    });

    return type == "mobile" ? mobileNumbers.join(", ") : addressValues.join(", ");
  }

  subTotal(item) {
    let subTotal: any = 0;
    subTotal =
      this.totalPrices(item) - this.coupon(item) - Number(this.discount(item));
    return Number(subTotal.toFixed(2));
  }

  totalPrices(item) {
    let discount: any = 0;
    let Total: any = 0;
    let coupon: any = 0;
    item?.lineItemsInfo.filter((res) => {
      Total += res?.costDetails?.price;
    });
    return Number(Number(Total).toFixed(2));
  }

  GSTAmount(data) {
    var constVal = 1 + (data?.costDetails?.taxPercentage / 100) * 1;
    let excGST = this.subTotal(data) / constVal;
    this.GST = this.subTotal(data) - excGST;
    return Number(this.GST.toFixed(2));
  }

  netAmount(data) {
    this.NetAmount =
      (data.costDetails.price * 100) / (100 + data.costDetails?.taxPercentage);
    return Number(this.NetAmount.toFixed(2));
  }

  discount(item) {
    let discount: any = 0;
    let coupon: any = 0;
    item?.lineItemsInfo.filter((res) => {
      discount += res?.costDetails?.discount;
    });
    return Number(discount + coupon).toFixed(2);
  }

  coupon(item) {
    let coupon = 0;
    coupon += item?.costDetails?.discount;
    return coupon;
  }
  addnumber(cost, midnightCharge) {
    return Number(cost) + Number(midnightCharge ? midnightCharge : 0.0);
  }
  total(total) {
    return Number(total);
  }

  getTotalAmount(data) {
    return Math.round(
      Number(
        (
          this.getCartData2?.data?.costDetails?.delivery +
          this.subTotal(data) +
          this.getCartData2?.midnightCharge
        ).toFixed(2)
      )
    );
  }

  roundoff(data) {
    return (
      Math.round(
        Number(
          (
            this.getCartData2?.data?.costDetails?.delivery +
            this.subTotal(data) +
            this.getCartData2?.midnightCharge
          ).toFixed(2)
        )
      ) -
      Number(
        (
          this.getCartData2?.data?.costDetails?.delivery +
          this.subTotal(data) +
          this.getCartData2?.midnightCharge
        ).toFixed(2)
      )
    );
  }

  onChangePinCodeSearch(val) {
    this.notAvailable = false;
    if (val.length > 0) this.searchPinCodeData(val);
  }

  searchPinCodeData(val) {
    let data = {
      value: val,
    };
    this.http.post("/user/delivery", data).subscribe(
      (data: any) => {
        this.pinCodeData = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.pinCodeData.push({
              ...element,
              searchKey: element.city + " - " + element.pincode,
            });
          }
        }
      },
      (error) => {}
    );
  }

  // Functions for the Address for PAN India

  //autofill address for edit
  editAddress(item) {
    // this.searchPinCodeData("");
    // this.addressListDiv = false;
    // this.showPickUpStorediv = false;
    this.isAddressEdited = true;
    // this.submitted2 = false;
    // this.addressFormDiv = true;
    this.addressForm.reset();

    this.stateChanged(item?.state);
    const self = this;

    function setValue() {
      if (self.pinCodes.some((ele) => String(ele) == item.pinCode)) {
        return item.pinCode;
      } else {
        self.panControl.setValue(item.pinCode);
        self.panControl.updateValueAndValidity();
        return item.pinCode;
      }
    }

    this.addressForm = this.formBuilder.group({
      alternativePhoneNumber: [item.alternativePhoneNumber],
      city: [item.city, Validators.required],
      email: [item.email, [Validators.email]],
      id: [item.id],
      line1: [item.line1, Validators.required],
      name: [item.name, Validators.required],
      phoneNumber: [
        item.phoneNumber,
        [Validators.required, Validators.pattern("[0-9]{10,10}$")],
      ],
      pinCode: [item.pinCode, [Validators.required]],
      //
      deliveryId: [setValue(), Validators.required],
      saveAddress: [true],
      state: [item.state, Validators.required],
    });

    $("#addNewAddressModal").modal({ backdrop: "static", keyboard: false });
    $("#addAddressModal").modal("hide");
    this.analyticsService.commonEvents("Onclick", {
      event_category: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      event_label: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
      value: `Open ${
        this.isAddressEdited == true ? "Edit Address" : "Add Address"
      } Form Popup`,
    });
  }

  //empty address form
  emptyAddressForm() {
    this.panControl = new FormControl("", [
      Validators.required,
      Validators.pattern(/^[1-9][0-9]{5}$/),
    ]);
    this.isAddressEdited = false;
    this.submitted2 = false;
    this.addressForm = this.formBuilder.group({
      alternativePhoneNumber: [null],
      city: new FormControl("", Validators.required),
      email: ["", [Validators.email]],
      id: [""],
      line1: ["", Validators.required],
      name: ["", Validators.required],
      phoneNumber: ["", [Validators.required, Validators.pattern("[0-9]{10,10}$")]],
      deliveryId: ["", [Validators.required]],
      pinCode: [""], //Validators.pattern("^[0-9]{6,6}$")]
      saveAddress: [true],
      state: new FormControl("", Validators.required),
    });

    let deliveryPin = JSON.parse(localStorage.getItem("deliveryPin"));
    if (deliveryPin) {
      this.addressForm.controls["pinCode"].setValue(deliveryPin.pincode);
      this.addressForm.controls["deliveryId"].setValue(deliveryPin.deliveryId);
    }
  }

  get a() {
    return this.addressForm.controls;
  }

  //save or edit address
  saveAddress() {
    this.submitted2 = true;
    if (this.addressForm.invalid) {
      return;
    }

    this.loading = true;
    //check if this address pincode is available
    if (this.isAddressEdited == true) {
      this.analyticsService.commonEvents("Onclick", {
        event_category: `Edit Delivery Address Details`,
        event_label: `Edit Delivery Address Details`,
        value: `Edit Delivery Address Details`,
      });
    } else {
      this.analyticsService.commonEvents("Onclick", {
        event_category: `Created Delivery Address Details`,
        event_label: `Created Delivery Address Details`,
        value: `Created Delivery Address Details`,
      });
    }
    if (this.panControl.valid && checkNull(this.panControl.value)) {
      this.panDelivery();
    } else {
      this.localDelivery();
    }
  }

  localDelivery() {
    let val = this.addressForm.controls["deliveryId"].value;
    let deliveryPin = typeof val == "string" ? val : String(val?.pincode);

    let value = this.addressForm.value;
    switch (true) {
      case typeof val == "string":
        {
          value.deliveryId = this.pincodes1.find(
            (ele) => String(ele.pincode) == val
          ).deliveryId;
        }
        break;
      case typeof val == "object":
        {
          value.deliveryId = value?.deliveryId?.deliveryId;
          // value.pincode = value?.deliveryId?.deliveryId;
        }
        break;
    }

    this.http.get("/user/delivery/" + deliveryPin).subscribe(
      (data) => {
        if (this.isAddressEdited == true) {
          this.http
            .put(
              "/user/" +
                this.currentUser.id +
                "/shipping-info/" +
                this.addressForm.controls["id"].value,
              value
            )
            .subscribe(
              (data) => {
                this.loading = false;
                sweetAlert("success", "Successfully Submitted");
                $("#addNewAddressModal").modal("hide");
                $("#addAddressModal").modal("show");
                this.emptyAddressForm();
                this.addressFormDiv = false;
                //   this.addressListDiv = true;
                this.getAddress(false);
                this.setradiobutton();
                this.submitted2 = false;
              },
              (error) => {
                sweetAlert("error", "Try Again");
                this.loading = false;
              }
            );
        } else {
          this.loading = true;
          //create user address

          this.http
            .post("/user/" + this.currentUser.id + "/shipping-info", value)
            .subscribe(
              (data) => {
                this.loading = false;
                sweetAlert("success", "Address Added Successfully");
                $("#addNewAddressModal").modal("hide");
                $("#addAddressModal").modal("show");
                this.addressFormDiv = false;
                this.emptyAddressForm();
                this.getAddress(false);
                this.setradiobutton();
                this.submitted2 = false;
              },
              (error) => {
                sweetAlert("error", "Try Again");
                this.loading = false;
              }
            );
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire({
          title: "Delivery Not Available To This Address",
          confirmButtonColor: "#fe9ab3",
        });
        return;
      }
    );
  }

  checkServiceAbility() {
    this.shiprocketService.getDeliveryPartners({
      deliveryPostcode: this.panControl.value,
      weight: 1,
    });
  }

  notAvailable = false;
  panDelivery() {
    // this.loader.openLogo();
    this.shiprocketService
      .getDeliveryPartners(this.mathForAddress(this.deliveryData))
      .subscribe(
        (res) => {
          if (res.length > 0) {
            this.notAvailable = false;
            // this.loader.closeLogo();
            let form: any = this.addressForm.value;
            if (this.isAddressEdited == true) {
              //update user address
              form.deliveryId = "0";
              form.pinCode = this.panControl.value;

              this.http
                .put(
                  "/user/" +
                    this.currentUser.id +
                    "/shipping-info/" +
                    this.addressForm.controls["id"].value,
                  this.addressForm.value
                )
                .subscribe(
                  (data) => {
                    this.loading = false;
                    sweetAlert("success", "Successfully Submitted");
                    $("#addNewAddressModal").modal("hide");
                    $("#addAddressModal").modal("show");
                    this.emptyAddressForm();
                    this.addressFormDiv = false;
                    //   this.addressListDiv = true;
                    this.getAddress(false);
                    this.setradiobutton();
                    this.submitted2 = false;
                    this.panControl.reset();
                  },
                  (error) => {
                    sweetAlert("error", "Try Again");
                    this.loading = false;
                  }
                );
            } else {
              this.loading = true;

              form.deliveryId = Number(0);
              form.pinCode = this.panControl.value;
              this.http
                .post(
                  "/user/" + this.currentUser.id + "/shipping-info",
                  this.addressForm.value
                )
                .subscribe(
                  (data) => {
                    this.loading = false;
                    sweetAlert("success", "Address Added Successfully");
                    $("#addNewAddressModal").modal("hide");
                    $("#addAddressModal").modal("show");
                    this.addressFormDiv = false;
                    this.emptyAddressForm();
                    this.getAddress(false);
                    this.setradiobutton();
                    this.submitted2 = false;
                    this.panControl.reset();
                  },
                  (error) => {
                    sweetAlert("error", "Try Again");
                    this.loading = false;
                  }
                );
            }
          } else {
            this.loading = false;
            this.notAvailable = true;
            // this.loader.closeLogo();
            sweetAlert("error", "Delivery is not available to this area");
          }
        },
        (err) => {
          this.loading = false;
          // this.loader.closeLogo();
        }
      );
  }

  /**
   * @description This section is for the PAN Products validations
   */

  panControl: FormControl;

  setPanPin(event) {
    this.notAvailable = false;
    if (
      RegExp(/^[1-9][0-9]{5}$/).test(event) &&
      !this.pinCodes.some(
        (ele) =>
          String(ele) ==
          (checkNull(event?.pincode) ? String(event?.pincode) : String(event))
      )
    ) {
      this.panControl.setValue(
        checkNull(event?.pincode) ? String(event?.pincode) : String(event)
      );
      this.panControl.updateValueAndValidity();
    } else {
      this.panControl.setValue("");
      this.panControl.updateValueAndValidity();
    }
  }

  pinCodes: any[];
  pincodes1: any[];
  getAllPincodes() {
    this.http.post("/user/delivery", { value: "" }).subscribe(
      (res: any) => {
        this.pinCodes = res.map((ele: any) => ele?.pincode);
        this.pincodes1 = res;
      },
      (err) => {
        this.pinCodes = [];
        this.pincodes1 = [];
      }
    );
  }

  resetControl(event) {
    this.notAvailable = false;
    this.panControl.setValue("");
    this.panControl.updateValueAndValidity();
  }

  checkNull(data) {
    return checkNull(data);
  }

  /**
   * @description This section is for the shiprocket service;
   *
   */

  deliveryControl: FormControl = new FormControl("");

  deliveryPartners: any[] = [];
  getAllDeliveryPartners(weight, pincode) {
    this.loader.open();
    this.shiprocketService
      .getDeliveryPartners({
        weight: String(weight),
        deliveryPostcode: String(pincode),
      })
      .subscribe(
        (res) => {
          this.loader.close();
          this.deliveryPartners = res.data ?? [];
          this.deliveryControl.setValue(this.findMaxAmountObject(res.data));
          this.deliveryControl.updateValueAndValidity();
          this.calculate();
        },
        (err) => {
          this.deliveryControl.setValue("");
          this.deliveryControl.updateValueAndValidity();
          this.loader.close();
          sweetAlert("error", "Delivery is not available to this area");
          this.deliveryPartners = [];
        }
      );
  }

  findMaxAmountObject(delivery: any) {
    if (delivery.length === 0) {
      return null; // Handle the case where the array is empty
    }

    // Use reduce to find the object with the maximum amount
    const maxAmountObject = delivery.reduce((maxObject: any, transaction: any) => {
      return Number(transaction.deliveryCharge) > Number(maxObject.deliveryCharge)
        ? transaction
        : maxObject;
    }, delivery[0]);

    return maxAmountObject;
  }

  mathForAddress(data: any) {
    function checkNull(data: any) {
      return data != "" && data != null && data != undefined;
    }

    function notZero(data: any, net: any) {
      console.log(data, "this is information");

      switch (true) {
        case checkNull(net) && net > 0:
          return Number(net);
        case checkNull(data.net) && data.net > 0:
          return Number(data.net);
        default:
          return Number(data.kg);
      }
    }

    let weight: any[] = data.data.lineItemsInfo
      .map((items) => {
        return {
          weight: notZero(items.pricing, items?.attribute?.netWeight ?? 0).toFixed(
            2
          ),
          quantity: Number(items.quantity),
          net:
            Number(
              notZero(items.pricing, items?.attribute?.netWeight ?? 0).toFixed(2)
            ) * Number(items.quantity),
        };
      })
      .map((ele) => ele.net);

    let net = weight.reduce((a, b) => a + b, 0);
    return {
      weight: String(net),
      deliveryPostcode: checkNull(this.panControl.value)
        ? String(this.panControl.value)
        : data.homeAddress.pinCode,
    };
  }

  mathShiprocket(data) {
    function checkNull(data: any) {
      return data != "" && data != null && data != undefined;
    }

    function notZero(data: any, net: any) {
      console.log(data, "this is information");

      switch (true) {
        case checkNull(net) && net > 0:
          return Number(net);
        case checkNull(data.net) && data.net > 0:
          return Number(data.net);
        default:
          return Number(data.kg);
      }
    }

    let weight: any[] = data.data.lineItemsInfo
      .map((items) => {
        return {
          weight: notZero(items.pricing, items?.attribute?.netWeight ?? 0).toFixed(
            2
          ),
          quantity: Number(items.quantity),
          net:
            Number(
              notZero(items.pricing, items?.attribute?.netWeight ?? 0).toFixed(2)
            ) * Number(items.quantity),
        };
      })
      .map((ele) => ele.net);

    let net = weight.reduce((a, b) => a + b, 0);
    this.getAllDeliveryPartners(net, data?.homeAddress?.pinCode);
  }

  getCartType(data?: any) {
    this.userService.setPanCounts().subscribe((res) => {
      this.cartType = res;
      this.calculate();
      if (res == "hasPan") {
        this.mathShiprocket(data);
      }
    });
  }
  selectPartner() {
    const value = this.deliveryControl.value;
    if (checkNull(value)) {
      this.calculate();
      $(`#exampleModal`).modal("hide");
    } else {
      sweetAlert("error", "Kindly select a delivery partner");
    }
  }

  calculations: Calculation;
  calculate() {
    if (this.deliveryData.data?.lineItemsInfo.length > 0) {
      this.calculations = new Calculation(
        this.deliveryData,
        this.cartType,
        this.deliveryControl.value,
        this.pincodes1
      );
      this.optionfun(); //razorpay
    }
  }
}
