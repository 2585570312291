import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/configuration/app.constants";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { HttpService } from "src/app/services/http/http.service";
import { paginationDTO } from "src/app/shared/paginationDTO";

@Component({
  selector: "app-blog-view",
  templateUrl: "./blog-view.component.html",
  styleUrls: ["./blog-view.component.less"],
})
export class BlogViewComponent implements OnInit {
  getData: any = [];
  dataOnScroll: any;
  paginationDTO: paginationDTO;
  pageNo: number = 1;
  noScroll: boolean = false;
  loader: boolean = false;

  constructor(
    private http: HttpService,
    private router: Router,
    public analyticsService: GoogleAnalyticsService
  ) {
    this.paginationDTO = new paginationDTO();
  }
  imgPth;
  ngOnInit(): void {
    this.analyticsService.pageView(
      "Blog list View",
      "blog-list",
      window.location.origin
    );
    // this.getallBlogs();
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.getInfiniteScroll();
  }

  getallBlogs() {
    this.http.get("/admin/blogs").subscribe((res: any) => {
    
      let result: any = res;
      this.getData = result.content;
  
    
      
    });
  }
  onScroll() {
    if (!this.noScroll) {
      this.pageNo++;
      this.getInfiniteScroll();
    }
  }

  getInfiniteScroll() {
    this.paginationDTO.pageNo = this.pageNo;
    this.paginationDTO.pageSize = 3;
    this.loader = true;
    this.http
      .getList("/admin/blogs/Pagination", this.paginationDTO)
      .subscribe((result: any) => {
        this.dataOnScroll = result.content;
        this.getData = [...this.getData, ...this.dataOnScroll];
        this.loader = false;
    
        if (this.getData.length >= result.totalElements) {
          this.noScroll = true;
        }
      });
  }

  
}
