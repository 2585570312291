<app-loader *ngIf="loading"></app-loader>

<div class="modal fade" id="email-verify" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="resetPasswordLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="row">
                    <div class="col-lg-7">
                        <div class="row pt-4 px-3 pb-4">

                            <!--email verify success-->
                            <div class="col-lg-12 text-center" *ngIf="emailVerify">
                                <br><br>
                                <h4>Your Email is Verified Successfully</h4>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" (click)="login()"
                                            class="btn btn-custom btn-lg btn-block">Login</button>
                                    </div>
                                </div>
                            </div>

                            <!--email verify failure-->
                            <div class="col-lg-12 text-center" *ngIf="failed">
                                <br><br><br>
                                <h4>{{failedMsg}} </h4>
                            </div>

                            <div class="col-lg-12 text-center change-pswd" *ngIf="changePassword">
                                <h4>Change Password</h4>
                                <br><br>
                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" [(ngModel)]="newPassword"
                                                placeholder="New password" autocomplete="new-password">
                                        </div>
                                    </div>
                                </div>

                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" [(ngModel)]="confirmPassword"
                                                placeholder="Confirm password" autocomplete="new-password">
                                        </div>
                                    </div>
                                </div>

                                <p style="color:red;" *ngIf="errorPassword">{{errorPassword}}</p>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="changePasswordBtn()">Change Password</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" *ngIf="resendEmail">
                                <h4>Token Expired</h4>
                                <br><br>
                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="text" class="form-control" [(ngModel)]="resendEmailValue"
                                                placeholder="Enter email Id" autocomplete="new-email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" (click)="resendFun()"
                                            class="btn btn-custom btn-lg btn-block">Resend
                                            Email</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 custom-card">
                        <img src="assets/images/login/image1.png" class="background" alt="">
                        <div class="background-header">
                            <h3>
                                Hello, Friend!
                            </h3>
                            <p>
                                Enter your personal details and start journey with us
                            </p>
                            <button type="button" class="btn btn-outline-custom " (click)="login()">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>