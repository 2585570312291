import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/configuration/app.constants';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';
import { HttpService } from 'src/app/services/http/http.service';
import { BlogService } from './blog-service/blog-service';
import { LoaderService } from 'src/app/services/memory-services/loader.service';
import { checkNull } from '../product-details/date-and-time-validations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent implements OnInit {
  title: any;
  blog: any
  public skeletonLoader: BehaviorSubject<any> = new BehaviorSubject(false)
  constructor(
    public http: HttpService,
    private actRoute: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    private service: BlogService,
    private loader: LoaderService
  ) { }

  public imgPth
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      this.getSinglePost(id)
    });

    this.actRoute.queryParams.subscribe((res: any) => {
      this.title = res?.title ?? 'Blog'
    })
  }



  getSinglePost(id) {
    this.skeletonLoader.next(true)
    this.service.getSingleBlog(id).subscribe((res: any) => {
      this.blog = res ?? undefined;
      this.skeletonLoader.next(false)
    }, (err) => {
      this.skeletonLoader.next(false)
      this.blog = undefined
    })
  }

  getSingleSample(id) {
    this.service.getSingleSampleBlog(id).subscribe((res: any) => {
      this.loader.close()
      this.blog = res?.default.find((ele: any) => ele.id == id) ?? undefined
    }, (err) => {
      this.loader.close();
      this.blog = undefined
    })
  }


  checkNull(data) {
    return checkNull(data)
  }




}
