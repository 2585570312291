<!-- Banner -->
<section
  id="classicCakes"
  *ngIf="isBannerImgAvailable && bannerImg && getdata.length > 0"
>
  <!-- top circle border -->
  <div class="top-rounded-border"></div>
  <!--banner image-->
  <div class="banner-image">
    <img src="{{ bannerImg }}" class="layer-1" alt="" srcset="" />
    <h4 *ngIf="getdata[0]?.parentCategory?.categoryName">
      {{ getdata[0]?.parentCategory?.categoryName }}
    </h4>
  </div>
</section>

<section *ngIf="loader">
  <ngx-skeleton-loader
    [theme]="{ height: '375px', width: '100%', 'margin-bottom': '0' }"
    count="1"
    appearance="line"
  >
  </ngx-skeleton-loader
  ><br /><br />

  <div class="row mt-3">
    <!--========================skeleton loader start=======================================-->
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-lg-3 mt-4">
      <div class="card mb-3 box-shadow">
        <ngx-skeleton-loader
          [theme]="{
            height: '255px',
            width: '100%',
            'border-top-right-radius': '25px',
            'border-top-left-radius': '25px'
          }"
          count="1"
          appearance="line"
        >
        </ngx-skeleton-loader>
        <div class="card-body">
          <ngx-skeleton-loader count="2" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <!--========================skeleton loader End=======================================-->
</section>
<div *ngFor="let itemMain of getdata; let index = index">
  <section
  class="forest"
  *ngIf="itemMain?.products?.length>=1"
  [ngClass]="0 === index % 2 ? 'bg-pink' : 'bg-yellow'"
>
  <div class="container-fluid" >
    <h4 class="text-center" >
      <img src="assets/images/icons/Layer_10.png" alt="" />
      {{ itemMain?.category?.categoryName }}
      <img src="assets/images/icons/Layer_11.png" alt="" />
    </h4>
    <div class="row mt-5 pxd-5" >
      <div
        id="owl-whiteForest"
        class="owl-carousel owl-whiteForest"
        *ngIf="getdata"
      >
        <div class="item" *ngFor="let item of itemMain.products">
          <div class="card mb-3 box-shadow"  >
            <a
              routerLink="/details/{{ item.seoUrl }}"
              (click)="
                analyticsService.commonEvents('Onclick', {
                  event_category: 'Route Product Detail Page',
                  event_label: 'Product Detail',
                  value: 'Route Product Detail page'
                })
              "
            >
              <img
                class="card-img-top"
                alt="Thumbnail [100%x225]"
                src="{{ imgPth + item.productImages }}"
                data-holder-rendered="true"
              />
            </a>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <p class="name">{{ item.productName }}</p>
                </div>
                <small *ngIf="(userService.loggedIn$|async)" class="text-muted">
                  <a (click)="like(item.productId, $event.target)">
                    <i *ngIf="!item.like" class="fa fa-heart-o"></i>
                    <i *ngIf="item.like == true" class="fa fa-heart"></i>
                  </a>
                </small>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <!--Price Section-->
                <div class="btn-group">
                  <p class="price" style="font-size: 16px">
                    Rs.{{ item?.productDetails?.pricing[0]?.disPrice }}
                    <del style="color: gray;  margin-left: 2px">
                      Rs.{{ item?.productDetails?.pricing[0]?.price }}
                    </del>
                    <span style=" margin-left: 2px">
                      Rs.{{
                        item?.productDetails?.pricing[0]?.discount
                      }}%off</span
                    >
                  </p>
                </div>
                <small class="text-muted d-none">
                  <i
                    *ngIf="item.paymentDetails.cod == true"
                    class="fa fa-shopping-cart"
                    aria-hidden="true"
                  ></i>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <h5 class="pull-right" *ngIf="itemMain?.products?.length > 4">
          <a
            [routerLink]="['/category', itemMain.category.categoryId]"
            (click)="
              analyticsService.commonEvents('Onclick', {
                event_category: 'Route Product List Page',
                event_label: 'Show All Product List',
                value: 'Route Product List page'
              })
            "
            style="text-decoration: none"
          >
            Show All
          </a>
        </h5>
      </div>
    </div>
  </div>
  <div class="whatsapp-icon" style="background-color: #22ce5a">
    <a
      href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
      target="_blank"
    >
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
          fill="white"
        />
        <path
          d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
          fill="white"
        />
      </svg>
    </a>
  </div>
</section>
</div>

