import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BlogViewComponent } from "./components/blog-view/blog-view.component";
import { TestimonialViewComponent } from "./components/testimonial-view/testimonial-view.component";
import { HomeComponent } from "./home.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  { path: "testimonial/:id", component: TestimonialViewComponent },
  { path: "blog-list", component: BlogViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
