import { Component, OnInit, ViewChild } from "@angular/core";
import { AppConstants } from "../../configuration/app.constants";
import { HttpService } from "src/app/services/http/http.service";
import { sweetAlert } from "../../shared/function/toaster";
declare var $: any;
import { AddCartService } from "src/app/services/http/add-cart.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, NgForm } from "@angular/forms";
// import * as moment from "moment";
import { OwlOptions } from "ngx-owl-carousel-o";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import moment from "moment";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";
import { UserService } from "src/app/services/memory-services/user.service";
import { GlobalSearchService } from "./global-search.service";
import { Observable } from "rxjs";
import { debounceTime, distinct, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.less"],
})
export class NavComponent implements OnInit {
  @ViewChild("dateTImeData") myForm: NgForm;
  public currentUser = "";
  public keyword = "productName";
  public data = [];
  public headerNotifyDetail: any;
  public pinCodeKeyword = "searchKey";
  public pinCodeData = [];
  public headerNotify: any;
  public loading: boolean = true;
  public cartItemCount = 0;
  public wishListItemCount: any;
  public deliveryPin =
    localStorage.getItem("deliveryPin") == ""
      ? localStorage.getItem("deliveryPin")
      : JSON.parse(localStorage.getItem("deliveryPin"));
  public pincodeVal = "";
  public disableDate: any;
  @ViewChild("auto") public auto;
  initialValue = 'cake'
  constructor(
    public http: HttpService,
    public addCartService: AddCartService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: GoogleAnalyticsService,
    private memoryService: MemoryService,
    private authService: AuthenticationService,
    public userService: UserService,
    private globalService: GlobalSearchService
  ) {
    //Get desktop notification Data
    this.http.get("/admin/home/header").subscribe((data) => {
      this.headerNotify = data;
      this.headerNotifyDetail = this.headerNotify.content.details;
    });
    this.throwVisitorCount();

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.cartItemCount = 0;
    this.wishListItemCount = 0;
    //refresh  addToCart product count
    this.addCartService.getVal().subscribe((data) => {
      this.getCart();
    });
    //refresh wishlist count
    this.addCartService.getWishList().subscribe((data) => {
      this.getWishList();
    });

    this.addCartService.getProfile().subscribe((data) => {
      if (data) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      }
    });

    //disable past date in textbox
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    var mm = month < 10 ? "0" + month : month;
    var dd = day < 10 ? "0" + day : day;
    this.disableDate = year + "-" + mm + "-" + dd;
  }

  checkNull(data) {
    return checkNull(data);
  }

  userImage;

  ngOnInit() {
    this.memoryService.memoryEmitter$.subscribe((res: any) => {
      this.userService.setCurrentUser();
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let fncts = ["clickWishlist", "clickCartBtn"];
      if (checkNull(res?.functionName) || fncts.includes(res?.functionName)) {
        console.log(res,"response in navvvvvv")
        this[res?.functionName]();
      }
    });

    this.userService.currentUser$.subscribe((res: any) => {
      console.log(res, "this is current user->");
      this.userImage = res?.imageUrl ?? "";
      console.log(this.userImage, "user image");
    });

    this.authService.loggedOut$.subscribe((res) => {
      this.userService.setCurrentUser();
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.cartItemCount = 0;
      this.wishListItemCount = 0;
      this.deliveryPin = "";
      this.pincodeVal = "";
      this.auto.clear();
    });

    this.imagUrl = AppConstants.SERVER_CONFIG.PRODUCTION;
    $(".screen-overlay").click(function (e) {
      $(".screen-overlay").removeClass("show");
      $(".mobile-offcanvas").removeClass("show");
      $("body").removeClass("offcanvas-active");
    });
    $(".closeSideNav").click(function (e) {
      $(".screen-overlay").removeClass("show");
      $(".mobile-offcanvas").removeClass("show");
      $("body").removeClass("offcanvas-active");
    });
    $(".city-menu a").on("click", function () {
      $(this)
        .parent()
        .parent()
        .prev()
        .html(
          '<img src="assets/images/icons/location.png" alt="" style="width:17px" srcset="">&nbsp; ' +
          $(this).html() +
          '<span class="caret ml-2"></span>'
        );
    });

    this.addCartService.getPinCode().subscribe((data) => {
      //add pincode on textbox

      this.deliveryPin =
        localStorage.getItem("deliveryPin") == ""
          ? localStorage.getItem("deliveryPin")
          : JSON.parse(localStorage.getItem("deliveryPin"));
      if (this.deliveryPin && this.deliveryPin.pincode) {
        this.pincodeVal = this.deliveryPin.city;
      }
    });

    this.getAllCategory();

    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("token") && params.hasOwnProperty("type")) {
        this.openOffer = false;
      }
    });

    this.refreshSlider();
  }

  resetMemory() {
    this.memoryService.setMemory(null);
  }

  handle() {
    let url: string = this.userImage;
    if (url.includes("downloadFile")) {
      if (url.includes("https://api.puppysbakery.com")) {
        this.userImage = url.replace(
          "https://api.puppysbakery.com",
          AppConstants.SERVER_CONFIG[AppConstants.SERVER_CONFIG.USE_URL]
        );
      } else {
        this.userImage =
          AppConstants.SERVER_CONFIG[AppConstants.SERVER_CONFIG.USE_URL] + url;
      }
    }
  }

  //redirect to index page
  indexPage() {
    this.router.navigate(["/"]);
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Home Page",
      event_label: "Open Home Page",
      value: "Open Home Page",
    });
  }

  getProfile() {
    this.addCartService.getProfile().subscribe((data) => {
      if (data) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      }
    });
  }
  //
  //redirect to cart page
  clickCartBtn() {
    if (checkNull(this.currentUser) || Number(localStorage.getItem("loginIN")) == 1) {
      this.router.navigate(["/checkout"]);
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Add To Cart/Checkout Page",
        event_label: "Open Add To Cart/Checkout Page",
        value: "Open Add To Cart/Checkout Page",
      });
    } else {
      this.memoryService.setMemory({ functionName: "clickCartBtn" });
      $("#loginSignup").modal("show");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open SignIn/SignUp Popup Form",
        event_label: "Open SignIn/SignUp Popup Form",
        value: "Open SignIn/SignUp Popup Form",
      });
    }
  }

  //

  //redirect to wishlist
  clickWishlist() {
    if (checkNull(this.currentUser) || Number(localStorage.getItem("loginIN")) == 1) {
      this.router.navigate(["/category/wishList"]);
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open WishList Page",
        event_label: "Open WishList Page",
        value: "Open WishList Page",
      });
    } else {
      this.memoryService.setMemory({ functionName: "clickWishlist" });
      $("#loginSignup").modal("show");
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open SignIn/SignUp Popup Form",
        event_label: "Open SignIn/SignUp Popup Form",
        value: "Open SignIn/SignUp Popup Form",
      });
    }
  }

  getCartData;
  getCartData2;
  newList = [];
  customCartData = [];
  //get cart product count
  getCart() {
    if (this.currentUser) {
      this.http.get("/order").subscribe(
        (data) => {
          this.getCartData2 = data;

          localStorage.setItem(
            "orderId",
            this.getCartData2?.orderId != null ? this.getCartData2?.orderId : "-"
          );
          this.customCartData = [];
          this.getCartData2.data.lineItemsInfo.map((x) => {
            if (x.attribute.cartType == "main") {
              this.customCartData.push(x);
            }
          });
          this.cartItemCount = this.customCartData.length;
        },
        (error) => {
          localStorage.removeItem("orderId");
        }
      );
    }
  }

  //get wishlist product count
  getWishListData;
  getWishList() {
    if (this.currentUser) {
      this.http.get("/user/wishlist").subscribe((data) => {
        this.getWishListData = data;
        this.wishListItemCount = this.getWishListData.content.length;
      });
    }
  }

  // for the visitor count for admin dashboard
  throwVisitorCount() {
    this.http.get("/visitHistory").subscribe((res: any) => { });
  }

  public imagUrl;
  public selectDelivery;
  public openOffer: Boolean;

  closeDropDownMenu(e) {
    $(e).closest(".dropdown-menu").prev().dropdown("toggle");
  }

  //open side navbar
  async openSideNav(e) {
    e.preventDefault();
    e.stopPropagation();
    var offcanvas_id = document.getElementById("navbar_main");
    $(offcanvas_id).toggleClass("show");
    $("body").toggleClass("offcanvas-active");
    $(".screen-overlay").toggleClass("show");
  }

  //open or close dropdown menu
  async openAccordion(e) {
    var $current = $(e).find(".accordion-arrow");
    $current.toggleClass("fa-angle-right fa-angle-down");
  }

  ////////////////////////global search textbox////////////////////////////////////////////
  selectEvent(item) {
    this.router.navigate([
      `/details/${item.productName.replace(/\s+/g, "-").toLowerCase() + "-" + item.productId
      }`,
    ]);
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Route Single Product Detail Page",
      event_label: "Single Product Detail Page",
      value: "Route Single Product Detail Page",
    });
  }

  selectEventEnter(item) {
    this.router.navigate(["/category"], {
      queryParams: { key: this.searchValue },
    });
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Category Based Product List Page",
      event_label: "Open Category Based Product List Page",
      value: "Open Category Based Product List Page",
    });
  }

  onChangeSearch(val: string) {
    if (val.length > 0) {
      this.searchProducts(val);
    } else {
      this.searchProducts("cake");
    }
  }

  onFocused(e) {
    console.log('focussed');
    e.stopPropagation();
    this.globalAutoComplete.clear()
    this.searchProducts("cake")
  }

  closePanel(e): void {
    e.stopPropagation();
    this.auto.close();
  }

  searchValue;
  searchData(val) {
    let data = {
      productName: val,
    };
    this.searchValue = val;
    this.http.post("/admin/product/search", data).subscribe(
      (data: any) => {
        this.data = [...data];
        console.log(this.data, "product data");
      },
      (error) => { }
    );
  }

  @ViewChild('globalAutoComplete') public globalAutoComplete
  searchControl = new FormControl('')

  searchedData: any
  searchProducts(value) {
    new Observable((observer) => {
      return observer.next(value)
    }).pipe(debounceTime(400), distinctUntilChanged()).subscribe((word) => {
      this.globalService.getSearchedData(word).subscribe(
        (data: any) => {
          this.searchedData = data
          console.log(this.data, "product data");
        },
        (error) => { }
      );
    })


  }


  eventEmitted(event) {
    console.log(event);
    console.log(this.searchControl.value, 'value');
    let ele = document.getElementById('some-element') as HTMLElement;
    ele.click()
  }
  ////////////////////////End global search textbox////////////////////////////////////////////

  ////////////////////////Set Delivery Pincode////////////////////////////////////////////
  onChangePinCodeSearch(val) {
    if (val.length > 0) this.searchPinCodeData(val);
  }

  selectPinCode(val) {
    sweetAlert("success", "Delivery added");
    $("#locationModal").modal("toggle");
    localStorage.setItem("deliveryPin", JSON.stringify(val));
    this.pincodeVal = val.city;
    this.addCartService.setPincode("someValue");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Delivery Select Popup",
      event_label: "Open Delivery Select Popup",
      value: "Open Delivery Select Popup",
    });
  }

  searchPinCodeData(val) {
    let data = {
      value: val,
    };
    this.http.post("/user/delivery", data).subscribe(
      (data: any) => {
        this.pinCodeData = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.pinCodeData.push({
              ...element,
              searchKey: element.city + " - " + element.pincode,
            });
          }
        }
      },
      (error) => { }
    );
  }
  ////////////////////////End Set Delivery Pincode////////////////////////////////////////////

  deliveryIn(val) {
    this.selectDelivery = val;
    this.router.navigate(["/delivery-filter"], {
      queryParams: { deliveryIn: "0" },
    });
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Delivery Time Based View Product List Page",
      event_label: "Open Delivery Time Based View Product List Page",
      value: "Open Delivery Time Based View Product List Page",
    });
    // if (val == '2hour') this.router.navigate(['/delivery-filter'], { queryParams: { deliveryIn: '2hour' } });
    // if (val == '6hour') this.router.navigate(['/delivery-filter'], { queryParams: { deliveryIn: '2hour,6hour' } });
    // if (val == 'sameDay') this.router.navigate(['/delivery-filter'], { queryParams: { deliveryIn: '2hour,6hour,sameDay' } });
  }

  public allCategory = [];
  public category = [];
  public leftCategory = [];
  public rightCategory = [];

  async getAllCategory() {
    let leftCategory = [];
    let rightCategory = [];
    this.http.get("/admin/category/all").subscribe(
      (data: any) => {
        for (const key in data.content) {
          if (Object.prototype.hasOwnProperty.call(data.content, key)) {
            const element = data.content[key];
            //seperate all category to => parent, sub, child Category
            if (
              element.parentCategoryId == undefined ||
              element.parentCategoryId == 0
            ) {
              //////////////all category to sub category////////////////////
              if (Number(element.formType) == 0) {
                this.leftCategory.push({
                  id: element.categoryId,
                  categoryId: element.categoryId,
                  seoUrl:
                    element.categoryName.replace(/\s+/g, "-") +
                    "-" +
                    element.categoryId,
                  name: element.categoryName,
                  categoryName: element.categoryName,
                  img: element.imagePath,
                  formType: element.formType,
                  isSubCategory:
                    data.content.filter(
                      (val) => val.parentCategoryId == element.categoryId
                    ).length == 0
                      ? false
                      : true,
                  subCategory: data.content.filter(
                    (val) => val.parentCategoryId == element.categoryId
                  ),
                });
              } else {
                if (Number(element.formType) != 0) {
                  this.rightCategory.push({
                    id: element.categoryId,
                    categoryId: element.categoryId,
                    seoUrl:
                      element.categoryName.replace(/\s+/g, "-") +
                      "-" +
                      element.categoryId,
                    name: element.categoryName,
                    categoryName: element.categoryName,
                    img: element.imagePath,
                    formType: element.formType,
                    isSubCategory:
                      data.content.filter(
                        (val) => val.parentCategoryId == element.categoryId
                      ).length == 0
                        ? false
                        : true,
                    subCategory: data.content.filter(
                      (val) => val.parentCategoryId == element.categoryId
                    ),
                  });
                }
              }

              // this.allCategory.push({
              //   id: element.categoryId,
              //   categoryId: element.categoryId,
              //   seoUrl:
              //     element.categoryName.replace(/\s+/g, "-") +
              //     "-" +
              //     element.categoryId,
              //   name: element.categoryName,
              //   categoryName: element.categoryName,
              //   img: element.imagePath,
              //   formType: element.formType,
              //   isSubCategory:
              //     data.content.filter(
              //       (val) => val.parentCategoryId == element.categoryId
              //     ).length == 0
              //       ? false
              //       : true,
              //   subCategory: data.content.filter(
              //     (val) => val.parentCategoryId == element.categoryId
              //   ),
              // });
            }
          }

          var firstRemoval = this.allCategory.filter((objFromA) => {
            return !this.leftCategory.find((objFromB) => {
              return objFromA.id === objFromB.id;
            });
          });
          this.category = firstRemoval.filter((objFromA) => {
            return !this.rightCategory.find((objFromB) => {
              return objFromA.id === objFromB.id;
            });
          });
          //////////////all sub category to subCategory2////////////////////
          this.leftCategory.map((x) => {
            if (x.isSubCategory == true) {
              x.subCategory.map((y) => {
                if (y.categoryId) {
                  y.subCategory2 = data.content.filter(
                    (val) => val.parentCategoryId == y.categoryId
                  );
                }
              });
            }
          });

          this.rightCategory.map((x) => {
            if (x.isSubCategory == true) {
              x.subCategory.map((y) => {
                if (y.categoryId) {
                  y.subCategory2 = data.content.filter(
                    (val) => val.parentCategoryId == y.categoryId
                  );
                }
              });
            }
          });

          this.allCategory = this.leftCategory;
          this.allCategory = [...this.allCategory, ...this.rightCategory];
          this.allCategory.map((x) => {
            if (x.isSubCategory == true) {
              x.subCategory.map((y) => {
                if (y.categoryId) {
                  y.subCategory2 = data.content.filter(
                    (val) => val.parentCategoryId == y.categoryId
                  );
                }
              });
            }
          });
        }

        this.loading = false;
      },
      (error) => { }
    );
  }

  //redirect to category & set seo url
  redirectCategory(data) {
    if (data.formType != 0) {
      this.redirectForm(data);
      return;
    }
    let seoUrl = data.categoryName.replace(/\s+/g, "-") + "-" + data.categoryId;
    if (!data.subCategory2) {
      this.router.navigate(["/category", seoUrl]);
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Category Based Product List Page",
        event_label: "Open Category Based Product List Page",
        value: "Open Category Based Product List Page",
      });
      return;
    }
    if (data.subCategory?.length != 0) {
      this.router.navigate(["/category", seoUrl]);
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Category Based Product List Page",
        event_label: "Open Category Based Product List Page",
        value: "Open Category Based Product List Page",
      });
    } else {
      this.router.navigate(["/cakes", seoUrl]);
      this.analyticsService.commonEvents("Onclick", {
        event_category: "Open Classic cakes Based Product List Page",
        event_label: "Open Classic cakes Based Product List Page",
        value: "Open Classic cakes Based Product List Page",
      });
    }
  }

  //redirect to enquiry forms
  redirectForm(data) {
    if (data.formType) {
      if (data.formType == 4) {
        this.router.navigate(["/customise/themed"], {
          queryParams: { id: data.categoryId },
        });
        this.analyticsService.commonEvents("Onclick", {
          event_category: "Open Customise Themed Cakes Product List Page",
          event_label: "Open Customise Themed Cakes Product List Page",
          value: "Open Customise Themed Cakes Product List Page",
        });
        return;
      } else {
        this.router.navigate(["/enquiry-form"], {
          queryParams: { f: data.formType },
        });
        this.analyticsService.commonEvents("Onclick", {
          event_category: "Open Enquiry Form Page",
          event_label: "Open Enquiry Form Page",
          value: "Open Enquiry Form Page",
        });
      }
    }
  }

  //onclick category set background image if category have img
  setImage(img) {
    if (img) {
      localStorage.setItem("_Bimg_", img);
    } else {
      localStorage.setItem("_Bimg_", "");
    }
    $(".screen-overlay").removeClass("show");
    $(".mobile-offcanvas").removeClass("show");
    $("body").removeClass("offcanvas-active");
  }

  loginStatus: Boolean = false;
  login() {
    this.loginStatus = true;
  }

  //set delivery time
  getDateTime(value) {
    const format = "DD/MMM/YYYY HH:mm";
    const format1 = "DD/MMM/YYYY";
    let val = value.time == "" ? value.date : value.date + " " + value.time;
    var date = new Date(val);
    var dateTime = moment(date).format(value.time == "" ? format1 : format);
    this.selectDelivery = dateTime;
    localStorage.setItem("_deliveryIn_", dateTime);
    $("#dateTimeModal").modal("hide");
  }
  refreshSlider() {
    $(".owl-header #owl-testimonial").owlCarousel({
      items: 1,
      nav: true,
      dots: false,
      loop: true,
      touchDrag: true,
      pullDrag: true,
      // autoplay:true,
      // autoplayTimeout:10000,
      // smartSpeed:4000,

      responsive: {
        0: {
          items: 1,
          // navigation: true,
          margin: 5,
          nav: true,
        },
        801: {
          items: 1,
          // navigation: true,
          // nav: true,
          margin: 50,
        },
        1025: {
          items: 1,
        },
      },
    });
  }

  closelocationModal(e: any) {
    $("#locationModal").modal("hide");
    this.auto.clear();
  }

  inputCleared(e: any) { }

  mainCategory(name: any, data: any) {
    this.router.navigate([`/category/${data?.categoryName}-${data?.categoryId}`]);
  }
}
