import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/configuration/app.constants';
import { HttpService } from 'src/app/services/http/http.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  constructor(
    public http: HttpService,
    private router: Router,
    public analyticsService:GoogleAnalyticsService,
  ) { }

  imagUrl
  ngOnInit(): void {
    this.imagUrl = AppConstants.SERVER_CONFIG.IMG_URL;
    this.getAllCategory()
  }

  public allCategory = [];
  public leftCategory = []
  //get all category
  async getAllCategory() {
    this.http.get("/admin/category/all")
      .subscribe(
        (data: any) => {
          for (const key in data.content) {
            if (Object.prototype.hasOwnProperty.call(data.content, key)) {
              const element = data.content[key];

              //seperate all category to subcategory
              if (element.parentCategoryId == undefined || element.parentCategoryId == 0) {
                if (Number(element.formType) == 0) {
                this.leftCategory.push(
                  {
                    id: element.categoryId,
                    categoryId: element.categoryId,
                    seoUrl: element.categoryName.replace(/\s+/g, '-') + '-' + element.categoryId,
                    name: element.categoryName,
                    categoryName: element.categoryName,
                    img: element.imagePath,
                    formType: element.formType,
                    isSubCategory: data.content.filter(val => val.parentCategoryId == element.categoryId).length == 0 ? false : true,
                    subCategory: data.content.filter(val => val.parentCategoryId == element.categoryId)
                  }
                )
                }
              }
            }
          }
        },
        error => {
        
        });
  }

  //onclick category set background image if category have img
  setImage(img) {
    localStorage.setItem("_Bimg_", img)
  }

  //redirect to category 
  redirectCategory(data) {
    if (data.formType != 0) {
      this.redirectForm(data);
      return;
    }

    let seoUrl = data.categoryName.replace(/\s+/g, '-') + '-' + data.categoryId;
    if (!data.subCategory2) {
      this.router.navigate(['/category', seoUrl])
      this.analyticsService.commonEvents('Onclick',{'event_category': 'Open Category Based Product List Page',
      'event_label': 'Open Category Based Product List Page',
      'value': 'Open Category Based Product List Page'});
      return;
    }

    if (data.subCategory2.length == 0) {
      this.router.navigate(['/category', seoUrl])
      this.analyticsService.commonEvents('Onclick',{'event_category': 'Open Category Based Product List Page',
      'event_label': 'Open Category Based Product List Page',
      'value': 'Open Category Based Product List Page'});
    } else {
      this.router.navigate(['/cakes', seoUrl])
      this.analyticsService.commonEvents('Onclick',{'event_category': 'Open Classic cakes Based Product List Page',
      'event_label': 'Open Classic cakes Based Product List Page',
      'value': 'Open Classic cakes Based Product List Page'});
    }
  }

  //redirect to enquiry forms
  redirectForm(data) {
    if (data.formType) {
      if (data.formType == 4) {
        this.router.navigate(['/customise/themed'], { queryParams: { id: data.categoryId } });
        this.analyticsService.commonEvents('Onclick',{'event_category': 'Open Customise Themed Cakes Product List Page',
        'event_label': 'Open Customise Themed Cakes Product List Page',
        'value': 'Open Customise Themed Cakes Product List Page'});
        return;
      } else {
        this.router.navigate(['/enquiry-form'], { queryParams: { f: data.formType } })
        this.analyticsService.commonEvents('Onclick',{'event_category': 'Open Enquiry Form Page',
        'event_label': 'Open Enquiry Form Page',
        'value': 'Open Enquiry Form Page'});
      }
    }
  }


}
