import { Injectable,NgZone } from '@angular/core';
declare var window: any;
export interface Action {
  transaction_id?:	string;
  affiliation?: string;
  value?:	number;
  currency?: string;
  tax?:	number;
  shipping?: number;
  items?:	any[];
  checkout_step?:	number;
  checkout_option?:	string;
  coupon?: any;
}
@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {


    constructor(private zone: NgZone) {
    }

    public event(action: string, params?: any): Promise<void> {
      // Wraps the event call into a Promise
      return this.zone.runOutsideAngular( () =>  new Promise( (resolve, reject) => {
        try { 
          // Triggers a 1s time-out timer 
          const tmr = setTimeout( () => reject(new Error('gtag call timed-out')), 3000 );
          // Performs the event call resolving with the event callback
          window.gtag('event', action, { ...params, event_callback: () => { clearTimeout(tmr); resolve(); }}); } 
        // Rejects the promise on errors
        catch(e) { reject(e); }
      }));
    }
  
    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
    public pageView(page_title?: string, page_path?: string, page_location?: string) { 
      return this.event('page_view', { page_title, page_location, page_path }); 
    }
  
    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions */
    public exception(description?: string, fatal?: boolean) { 
      return this.event('exception', { description, fatal }); 
    }
  
    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings */
    public timingComplete(name: string, value: number, event_category?: string, event_label?: string) { 
      return this.event('timing_complete', { name, value, event_category, event_label }); 
    }
  
    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/screens */
    public screenView(app_name: string, screen_name: string, app_id?: string, app_version?: string, app_installer_id?: string) { 
      return this.event('screen_view', { app_name, screen_name, app_id, app_version, app_installer_id }); 
    }
  
    public login(method?: string) {
      return this.event('login', { method });
    }
  
    public signUp(method?: string) {
      return this.event('sign_up', { method });
    }
  
    public search(search_term?: string) {
      return this.event('search', { search_term });
    }
  
    public selectContent(content?: any) {
      return this.event('select_content', content);
    }
  
    public share(method?: string, content?: any) {
      return this.event('share', { method, ...content });
    }
  
     public generateLead(action?: any) {
      return this.event('generate_lead', action);
    }
  
    /* product view */
    public viewItem(items?: any[]) {
      return this.event('view_item', { items });
    }

    /* product list */
    public viewItemList(items?: any[]) {
      return this.event('view_item_list', { items });
    }
  
     /* view promotion */
    public viewPromotion(promotions?: any[]) {
      return this.event('view_promotion', { promotions });
    }
  
    public viewSearchResults(search_term?: string) {
      return this.event('view_search_results', { search_term });
    }
  
    public addPaymentInfo() {
      return this.event('add_payment_info');
    }
  
     /* Add To Cart data added */
    public addToCart(action?: Action) {
      return this.event('add_to_cart', action);
    }
  
     /* Add To Wishlist data added */
    public addToWishlist(action?: Action) {
      return this.event('add_to_wishlist', action);
    }
  
    public beginCheckout(action?: Action) {
      return this.event('begin_checkout', action);
    }
  
    public checkoutProgress(action?: Action) {
      return this.event('checkout_progress', action);
    }
  
    public purchase(action?: Action) {
      return this.event('purchase', action);
    }
  
    public refund(action?: Action) {
      return this.event('refund', action);
    }
  
    /* Remove Add To Cart data */
    public removeFromCart(action?: Action) {
      return this.event('remove_from_cart', action);
    }

    public viewCart(action?: Action) {
      return this.event('view_cart', action);
    }
  
    public setCheckoutOption(checkout_step?: number, checkout_option?: string) {
      return this.event('set_checkout_option', { checkout_step, checkout_option });
    }

    public commonEvents(action?: string, option?:any) {
      window.gtag('event',action,option);
    }
  }