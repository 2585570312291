<section class="testimonials" style="background-image: url(assets/images/testimonials.jpg);">
    <!-- <div class="top-rounded-border">
    </div> -->
    <div class="container-fluid ">
        <h4 class="text-center">
            <!-- <img src="assets/images/icons/Layer_10.png" alt=""> -->
            Wedding Cakes
            <!-- <img src="assets/images/icons/Layer_11.png" alt=""> -->
        </h4>
        <div class="row pxd-5 test-1">
                <div id="owl-testimonial"  class="owl-carousel" *ngIf="wedding">
                    <div class="item" *ngFor="let item of wedding">
                        <article>
                            <img class="card-img-top" class="rounded-circle" src="{{imgPth+item.image}}"
                                data-holder-rendered="true">
                        </article>
                        <p>
                            {{
                            item.message.length > 250 ? (item.message | slice: 0:250) + "..." : item.message }}
                        </p>
    
                        <h5>- {{item.name}}</h5>
                    </div>
                </div>                       
        </div>
        <a (click)="toViewPage()" class="view_more" style="text-decoration: none;" >Show All
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </a>
    </div>

    <!-- <div *ngIf="type != 'enquiry'" class="bottom-rounded-border">
    </div> -->
</section>