<section class="pt-4">
  <!-- top circle border -->
  <div class="top-rounded-border"></div>

  <div class="container-fluid px-5">
    <ol class="breadcrumb px-0">
      <li class="breadcrumb-item">
        <a href="#"><i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
      </li>
      <li class="breadcrumb-item active">Our Story</li>
    </ol>
  </div>
  <div class="container-fluid px-5 py-4 new_effect">
    <h3 class="py-3">Our Story</h3>

    


    <!-- <div class="row">
      <div class="col-lg-8 offset-lg-2">

        <div class="mt-4 ">
  
          <img class="img-fluid" src="assets/images/press-release/ceo.jpg" alt="">
        </div>
  
  
  
  
  
  
      </div>
    </div>







    <div class="content py-4">
      <p>A passionate venture of Ms.Vichitra Rajasingh, Puppy’s Bakery was culminated into a
        business enterprise in the year 2008. Entrepreneurship requires passion, dedication
        and enterprise , our CEO. Ms. Vichitra Rajasingh, has all three in ovenloads. Add to
        this a charismatic disposition and… voila! We have the recipe for a successful
        entrepreneur.</p>

      <p>In the words of our dear CEO, “When I was a little girl, I used to watch my grandma bake.
        She used to make some of the most mouth-watering cakes I have ever had. Every Christmas
        my grandmother bakes the most delicious plum cakes and sells it to her closest friend and
        family , the proceeds of which are donated to charity. I wanted to bake like her. ” And
        that’s how it all began. What started as an interest in baking soon became a passion and
        her baking skills were honed and enhanced as the years went by. Vichitra, who is
        affectionately called as “Puppy” by everyone who knows her, decided to turn the quirky
        nickname into a brand.</p>

      <p>Today, Puppy’s Bakery specializes in a variety of sinfully delicious cakes, desserts,
        breads, shakes, pizzas and pastas, which have earned her the moniker “Dessert Queen”.
        Some have even gone so far as to credit her with the increased traffic in fitness centres ! </p>

      <p>Puppy’s Bakery is best known for its custom designed cakes for birthdays, weddings and parties.
        Getting creative with themes, colours, shapes and sizes, a bespoke unique work of art
        is made to cater to the whims and fancies of every customer. From 12 foot tall
        extravagant wedding cakes to giant replicas of Disneyland and the latest cartoon
        characters for children and edible designer handbags and shoes for fashionistas,
        Puppy’s Bakery has a cake to make everyone’s celebration uniquely special.</p>

      <p>Puppy’s Bakery has expanded to 5 stores in prime locations in Madurai, Sivakasi
        & Dindigul. Every store is conceptualised to reflect the values of the brand with
        customised and handcrafted desserts being the USP of these stores. Every Puppy’s
        Bakery outlet provides a dreamily unique experience to its customers with its warm
        pink interiors, delightful desserts and a relaxing atmosphere to celebrate happiness
        and good times for people of all age groups.</p>

      <p>Our signature pure milk cream ice creams, the first of its kind in Madurai,
        combine both our passions- cakes and ice creams through which various out
        of the box flavours are curated to spread happiness.</p>

      <p>We believe desserts are a perfect gateway to happy moments. The Puppy’s Bakery
        team strives to provide the best of culinary treats ranging from desserts,
        ice creams to shakes and savouries ensuring there is something delicious for
        everyone. We can’t wait to light up your celebrations with our creations.</p>
    </div> -->


    
    <div class="row">
      <div class="col-lg-4">

        <div class=" h-100 py-lg-4">
  
          <img class="img-fluid h-100 img_cover" src="assets/images/press-release/ceo_1.jpg" alt="">
        </div>
      </div>
      <div class="col-lg-8">

        <div class="content py-4 pl-lg-4">
          <p>A passionate venture of Ms.Vichitra Rajasingh, Puppy’s Bakery was culminated into a
            business enterprise in the year 2008. Entrepreneurship requires passion, dedication
            and enterprise , our CEO. Ms. Vichitra Rajasingh, has all three in ovenloads. Add to
            this a charismatic disposition and… voila! We have the recipe for a successful
            entrepreneur.</p>
    
          <p>In the words of our dear CEO, “When I was a little girl, I used to watch my grandma bake.
            She used to make some of the most mouth-watering cakes I have ever had. Every Christmas
            my grandmother bakes the most delicious plum cakes and sells it to her closest friend and
            family , the proceeds of which are donated to charity. I wanted to bake like her. ” And
            that’s how it all began. What started as an interest in baking soon became a passion and
            her baking skills were honed and enhanced as the years went by. Vichitra, who is
            affectionately called as “Puppy” by everyone who knows her, decided to turn the quirky
            nickname into a brand.</p>
    
          <p>Today, Puppy’s Bakery specializes in a variety of sinfully delicious cakes, desserts,
            breads, shakes, pizzas and pastas, which have earned her the moniker “Dessert Queen”.
            Some have even gone so far as to credit her with the increased traffic in fitness centres ! </p>
    
          <p>Puppy’s Bakery is best known for its custom designed cakes for birthdays, weddings and parties.
            Getting creative with themes, colours, shapes and sizes, a bespoke unique work of art
            is made to cater to the whims and fancies of every customer. From 12 foot tall
            extravagant wedding cakes to giant replicas of Disneyland and the latest cartoon
            characters for children and edible designer handbags and shoes for fashionistas,
            Puppy’s Bakery has a cake to make everyone’s celebration uniquely special.</p>
    
          <p>Puppy’s Bakery has expanded to 5 stores in prime locations in Madurai, Sivakasi
            & Dindigul. Every store is conceptualised to reflect the values of the brand with
            customised and handcrafted desserts being the USP of these stores. Every Puppy’s
            Bakery outlet provides a dreamily unique experience to its customers with its warm
            pink interiors, delightful desserts and a relaxing atmosphere to celebrate happiness
            and good times for people of all age groups.</p>
    
          <p>Our signature pure milk cream ice creams, the first of its kind in Madurai,
            combine both our passions- cakes and ice creams through which various out
            of the box flavours are curated to spread happiness.</p>
    
          <p>We believe desserts are a perfect gateway to happy moments. The Puppy’s Bakery
            team strives to provide the best of culinary treats ranging from desserts,
            ice creams to shakes and savouries ensuring there is something delicious for
            everyone. We can’t wait to light up your celebrations with our creations.</p>
        </div>

      </div>


    </div>















  </div>
 
</section>




<!--modal for show full size image-->
<!-- <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ currentImage?.newsPaperName | textDecorator }} - {{
          currentImage?.date }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ngx-skeleton-loader *ngIf="skeletonLoader" [theme]="{ height: '450px', width: '100%', margin: '0' }" count="1"
          appearance="line">
        </ngx-skeleton-loader>
        <img id="img" (load)="checkImageLoad()" [src]="currentImage?.img" alt="" />
      </div>
    </div>
  </div>
</div> -->
<!--end modal-->

<div class="whatsapp-icon" style="background-color: #22ce5a">
  <a
    href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
    target="_blank"
  >
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
        fill="white"
      />
      <path
        d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
        fill="white"
      />
    </svg>
  </a>
</div>