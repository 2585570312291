<!-- <section *ngIf="allReview.length > 0">
  <div class="container-fluid">
    
    <h4 class="text-center">
      <img src="assets/images/icons/Layer_10.png" alt="" />
      Reviews
      <img src="assets/images/icons/Layer_11.png" alt="" />
    </h4>

    <div class="container">
      <div class="card mx-5 mt-4">
        <div class="card-body">
          <div class="row" *ngFor="let data of allReview">
            <div class="card card-inner  mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2 col-3">
                    <img *ngIf="data.imageUrl" [src]="imgPth + data.imageUrl" class="img img-rounded img-fluid" />
                    <img *ngIf="!data.imageUrl" src="/assets/images/avatar.jpg" class="img img-rounded img-fluid" />
                    <p class="text-secondary text-center">{{data.createdOn | dateAgo}}</p>
                  </div>
                  <div class="col-md-10 col-9">
                    <p><a class="name"><strong>{{data.userName}} </strong>
                        <span class="float-right"><i class="text-warning fa fa-star"
                            [ngClass]="{'add-start-active': data.stars >= 5}"></i></span>
                        <span class="float-right"><i class="text-warning fa fa-star"
                            [ngClass]="{'add-start-active': data.stars >= 4}"></i></span>
                        <span class="float-right"><i class="text-warning fa fa-star"
                            [ngClass]="{'add-start-active': data.stars >= 3}"></i></span>
                        <span class="float-right"><i class="text-warning fa fa-star"
                            [ngClass]="{'add-start-active': data.stars >= 2}"></i></span>
                        <span class="float-right"><i class="text-warning fa fa-star"
                            [ngClass]="{'add-start-active': data.stars >= 1}"></i></span>
                      </a></p>
                    <p>{{data.comments}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->


<section *ngIf="allReview.length > 0">
  <div class="container-fluid custom-review-fluid">
    <h4 class="text-center">
      <img src="assets/images/icons/Layer_10.png" alt="" />
      Reviews
      <img src="assets/images/icons/Layer_11.png" alt="" />
    </h4>


    <div class="review-carousel" >
      <!-- *ngFor="let data of allReview" -->
        <div class="" >
          <div class="row">

            <!-- <div class="col-lg-3 review-profile" >
              <div class="review-image">
                
                <img *ngIf="data?.imageUrl" [src]="imgPth + data?.imageUrl" alt="avatar" />
                <img *ngIf="!data?.imageUrl" src="/assets/images/avatar.jpg" alt="avatar" />
              </div>
              <p>{{data?.userName}}</p>
            </div>
            <div class="col-lg-3 review-reply">
          
              <h6>{{data?.comments}}</h6>
              <p>
                <span class="review-rate">{{data?.stars}}</span>
                <a href="#">
                  <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 5}"></i></span>
                  <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 4}"></i></span>
                  <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 3}"></i></span>
                  <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 2}"></i></span>
                  <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 1}"></i></span>
                </a>
              </p>
              <h6>{{data?.createdOn | dateAgo}}</h6>
            </div> -->
           
            <div class="col-lg-4" *ngFor="let data of allReview">
              <div class="card">
               <div class="card-details">

                <div class="card-image ">
                  <img src="{{(data?.imageUrl!='https://api.puppysbakery.com'&&data?.imageUrl!=''&&data?.imageUrl!=null&&data?.imageUrl!=undefined)?data?.imageUrl:'/assets/images/avatar.jpg'}}" class="review-imag">
                   </div>

                   <div class="reviw-details ms-2">
                    <h2 class="mb-0 user-name">{{data?.userName}}</h2>
                    <span><i  class="fa fa-star" [ngClass]="{'add-start-active': data?.stars >= 1}" ></i></span>
                    <span><i  class="fa fa-star" [ngClass]="{'add-start-active': data?.stars >= 2}"></i></span>
                    <span><i  class="fa fa-star" [ngClass]="{'add-start-active': data?.stars >= 3 }"></i></span>
                    <span><i  class="fa fa-star" [ngClass]="{'add-start-active': data?.stars >= 4}"></i></span>
                    <span><i  class="fa fa-star" [ngClass]="{'add-start-active': data?.stars >= 5}"></i></span>
                    <!-- <p>
                      <span class="review-rate">{{data?.stars}}</span>
                      <a href="#">
                        <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 5}"></i></span>
                        <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 4}"></i></span>
                        <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 3}"></i></span>
                        <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 2}"></i></span>
                        <span><i class="fa fa-star" [ngClass]="{'add-start-active': data.stars >= 1}"></i></span>
                      </a>
                    </p> -->

                   </div>
              



               </div>
               <div class="user-review" >
                <p class="reviews">{{data?.comments}}</p>
                <h6>{{data?.createdOn | dateAgo}}</h6>
               </div>



              </div>



            </div>



          </div>
      
        </div>

 
   
    </div>
  </div>
</section>


<!--  -->