import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SubCategoryRoutingModule } from "./sub-category-routing.module";
import { SubCategoryComponent } from "./sub-category.component";
import { Ng5SliderModule } from "ng5-slider";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SharedModule } from "src/app/shared/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LightboxModule } from "ngx-lightbox";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
@NgModule({
  declarations: [SubCategoryComponent],
  imports: [
    CommonModule,
    SubCategoryRoutingModule,
    Ng5SliderModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    LightboxModule,
    InfiniteScrollModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class SubCategoryModule {}
