<section>
    <div class="container-fluid ">
        <div class="row">

            <!--fist image-->
            <div class="col-lg-6 col-md-6" *ngIf="data1">
                <div class="card-body" *ngIf="data1.bannerDetails">
                    <h4 class="card-title" *ngIf="data1.bannerDetails.heading">
                        {{data1.bannerDetails.heading | titlecase}}</h4>
                    <p class="card-text mt-4" *ngIf="data1.bannerDetails.content">{{data1.bannerDetails.content }}</p>
                    <a routerLink="/enquiry-form" [queryParams]="{f: '2'}" class="btn  mt-4 mb-3" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Enquiry Form Page',
                    'event_label': 'Enquiry Form Page',
                    'value': 'Route Enquiry Form page'})"
                        *ngIf="data1.bannerDetails.buttonName">{{data1.bannerDetails.buttonName | titlecase}}</a>
                </div>
                <img src="{{data1.image}}" alt="">
            </div>

             <!--Second image-->
            <div class=" col-lg-6 col-md-6" *ngIf="data2">
                <div class="card-body" *ngIf="data2.bannerDetails">
                    <h4 class="card-title" *ngIf="data2.bannerDetails.heading">
                        {{data2.bannerDetails.heading | titlecase}}</h4>
                    <p class="card-text mt-4" *ngIf="data2.bannerDetails.content">{{data2.bannerDetails.content }}</p>
                    <a routerLink="/enquiry-form" [queryParams]="{f: '3'}" class="btn  mt-4 mb-3" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Enquiry Form Page',
                    'event_label': 'Enquiry Form Page',
                    'value': 'Route Enquiry Form page'})"
                        *ngIf="data1.bannerDetails.buttonName">{{data1.bannerDetails.buttonName | titlecase}}</a>
                </div>
                <img src="{{data2.image}}" alt="">
            </div>

        </div>
    </div>
</section>