
<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" *ngIf="getdata">
  <div class="top-rounded-border">
  </div>

  <div class="icon-container">
    <div class="row py-2 align-items-center">
      <div class="col-12 col-lg-12 col-xl-5">
        <div class="row">
          <div class="col-6  text-center">
            <h6 class="hotline pull-left">
              Cakes Hotline
            </h6>
          </div>
          <div class="col-6 text-center">
              <h6  class="mobile mb-0">
                <a style="color: #fff;text-decoration: none;" href="tel:8122210222">
                <i class="fa fa-phone" aria-hidden="true"></i>
                +91 8122210222
              </a>
              </h6>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <p class="content mb-1 mb11">
          Celebration Cakes, Desserts, Goodies & everything delightfully sweet now a Call away !
      
        </p>
      </div>
    </div>
  </div>
  
  <div class="carousel-inner">
    <ngx-skeleton-loader *ngIf="skeletonLoader" [theme]="{ height: '465px', width: '100%', 'margin-bottom': '0' }"
      count="1" appearance="line"> </ngx-skeleton-loader>
    <div class="carousel-item"  *ngFor="let item of getdata; let index = index" [class.active]="index == 0">
      <a target="_blank" href="{{item?.bannerUrl ? item?.bannerUrl : ''}}"> <img src="{{item.image}}" (load)="checkImageLoad()" class="d-block w-100"
        ></a>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev" (click)="prev()">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>


 

</div>
