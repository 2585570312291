<app-loader *ngIf="loading"></app-loader>

<section id="sub-category">
  <!-- top circle border -->
  <div class="top-rounded-border"></div>



  <div class="container-fluid" id="top-main">
    <div id="main" [ngClass]="{'main-mrgnLeft': isOpened === 1}" class="row grid-margin stretch-card">
      <span (click)="openNav()" id="filter-button" class="filter-button filtr-close"><img
          src="assets/images/icons/ads.svg" alt="" /></span>
      <div class="col-md-12">

        <div class="row" *ngIf="!noProduct">
          <div class="col-lg-8">
            <ol class="breadcrumb px-0 m-0 pb-0" *ngIf="categoryName">
              <li class="breadcrumb-item">
                <a href="#"><i class="fa fa-home">Home</i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ categoryName }}</li>
            </ol>
            <h3 *ngIf="showLoader" class="mt-4">
              <ngx-skeleton-loader [theme]="{ height: '30px', width: '250px'}" count="1" appearance="line">
              </ngx-skeleton-loader>
            </h3>
          </div>
        </div>


        <!--=====================Skeleton Loader======================-->
        <div class="row mt-3" *ngIf="showLoader">
          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>
              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>
              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>
              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>
              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>
              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-3 mt-4">
            <div class="card mb-3 box-shadow">
              <ngx-skeleton-loader
                [theme]="{ height: '255px', width: '100%', 'border-top-right-radius': '25px', 'border-top-left-radius': '25px' }"
                count="1" appearance="line">
              </ngx-skeleton-loader>

              <div class="card-body">
                <ngx-skeleton-loader count="2" appearance="line"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
        <!--=======================Skeleton Loader end==============================-->



        <ol class="breadcrumb px-0 m-0 pb-0" *ngIf="!categoryName">
          <li class="breadcrumb-item">
            <a href="#"><i class="fa fa-home">Home</i></a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of breadcrumb">
            <a routerLink="/category/{{item.item.categoryName.split(' ').join('-')}}-{{item.item.categoryId}}">{{
              item.item.categoryName }}
            </a>
          </li>
          <li class="breadcrumb-item " *ngFor="let item of getdata?.categories">{{item.categoryName}}</li>
        </ol>






        <h3 class="title" *ngIf="!showLoader">{{ categoryName }}</h3>

        <div class="row" *ngIf="!showLoader">

          <div class="col-lg-6"></div>
          <!-- {{categoryName}} -->
          <div class="col-lg-6 pull-right" *ngIf="categoryName != 'WishList'">
            <select type="text" #deliveryFilter
              [ngModel]="deliveryInCategory ? deliveryInCategory : deliveryIn ? deliveryIn : ''"
              (change)="deliveryFilterFun(deliveryFilter.value)" class="form-control filtr-time">
              <option value="" selected disabled hidden>Select Delivery Time</option>
              <option value="0">All</option>
              <option value="{{deliveryTime?.deliverytimeId}}" *ngFor="let deliveryTime of deliveryTimes">
                {{deliveryTime?.deliveryName}}</option>
            </select>
            <select type="text" #priceFilter id="mySelect" [ngModel]="priceSorting ? priceSorting : ''"
              (change)="priceFilterFun(priceFilter.value)" class="form-control filtr-time">
              <option value="" disabled>Sort By</option>
              <option value="{{priceSort?.value}}" *ngFor="let priceSort of priceSortBy">
                {{priceSort?.displayValue}}</option>
            </select>
          </div>

        </div>

        <!--Infinite Scroll Start-->
        <div infiniteScroll [infiniteScrollDistance]="0.1" (scrolled)="onScroll()" [alwaysCallback]='true'>
          <div class="row mt-3" *ngIf="!showLoader">
            <ng-container *ngIf="!noProduct">
              <!--Product Loop Start-->
              <div [ngClass]="isOpened == 1 ? 'col-lg-4 col-sm-12':'col-lg-3 col-sm-6'" class="col-lg-3 mt-4"
                *ngFor="let data of product2">
                <div class="card mb-3 box-shadow">

                  <div class="ribbon" *ngIf="data?.isPanIndiaProduct"><span>PAN India</span></div>

                  <a [routerLink]="['/details', data.seoUrl]" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Single Product Detail Page',
                  'event_label': 'Single Product Detail Page',
                  'value': 'Route Single Product Detail Page'})">
                    <img *ngIf="data.productImages !='' && !data.productImages.includes('mp4') " class="card-img-top"
                      [src]="imagUrl + data.productImages" />
                    <video *ngIf="data.productImages.includes('mp4')" class="card-img-top"
                      [src]="imagUrl + data.productImages"> </video>
                  </a>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                        <a [routerLink]="['/details', data.seoUrl]" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Single Product Detail Page',
                        'event_label': 'Single Product Detail Page',
                        'value': 'Route Single Product Detail Page'})">
                          <p class="name">{{ data.productName }}</p>
                        </a>
                      </div>
                      <small class="text-muted" *ngIf="(userService?.loggedIn|async)"> <span style="cursor: pointer;"
                          (click)="like(data.productId, $event.target)">
                          <i *ngIf="!data.like" class="fa fa-heart-o"></i>
                          <i *ngIf="data.like == true" class="fa fa-heart"></i> </span>
                      </small>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <!--pricing Section-->
                      <div class="btn-group">
                        <p class="price" style="font-size: 16px;">
                          Rs.{{data?.productDetails?.pricing[0]?.disPrice.toFixed(2)}}
                          <del style="color:gray;font-size:12px;margin-left: 2px;"
                            *ngIf="data?.productDetails?.pricing[0]?.discount > 0">
                            Rs.{{data?.productDetails?.pricing[0]?.price}} </del>
                          <span style="font-size: 12px;margin-left: 2px;"
                            *ngIf="data?.productDetails?.pricing[0]?.discount > 0">
                            {{data?.productDetails?.pricing[0]?.discount}}%off</span>
                        </p>
                      </div>
                      <small class="text-muted  d-none"> <i *ngIf="data.paymentDetails?.cod == true"
                          class="fa fa-shopping-cart" aria-hidden="true"></i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <!--Product Loop End-->
            </ng-container>

            <!--no product found-->
            <div *ngIf="noProduct == true && getId != 'WishList'" class="container-fluid px-5 py-4 text-center">
              <img class="no-product" src="assets/images/product_not_found.png" alt="" srcset="">
            </div>

            <!--if wishlist is Empty-->
            <div *ngIf="noProduct == true && getId == 'WishList' " class="container-fluid">

              <div class="empty-cart card">
                <h2>My Wishlist</h2>
                <div class="text-center">
                  <img class="empty-img" src="assets/images/empty-cart.png" style="width: 28%;" />
                  <h4 class="mt-5 mb-0 ">Your WishList is Empty</h4>
                  <h6 class="">You Haven’t Added Any Products</h6>
                  <button [routerLink]="''" type="button" class="btn btn-custom " (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Home Page to Shop Now',
                  'event_label': 'Home Page to Shop Now',
                  'value': 'Route Home Page to Shop Now'})">Shop Now</button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!--Infinite Scroll End-->

        <!--gif loader-->
        <div class="text-center" *ngIf="gifLoader">
          <img src="/assets/images/gif-infinite-scroll3.gif" style="height: 100px; width: auto;">
        </div>
      </div>
    </div>

    <!---------------sidenav bar---------------->
    <!-- static design -->

    <!-- static design -->
    <!---------------sidenav bar---------------->


    <!---------------sidenav bar---------------->
    <div id="mySidenav" class="sidenav" *ngIf="isOpened == 1" [ngClass]="{'mySidenav-width': isOpened === 1 }">
      <!----------------Filter Start-------------------->
      <div class="" (click)="closeNav()" style="cursor: pointer;">
        <h4 class="pull-left filter"><img src="assets/images/icons/ads-black.svg" alt="" /> Filter</h4>
        <a class="pull-right clear-data filtr-close" style="cursor: pointer;">close</a>
      </div>
      <div class="panel-group pb-0" id="accordion" role="tablist" aria-multiselectable="true"
        *ngIf="categoryName != 'Gifting'">

        <div class="btn-group">

          <h4>Category
            <button class="submit-btn1" (click)="reset(1)"
              *ngIf="(parentCategoryId != '' && parentCategoryId != null &&  parentCategoryId != undefined) && (subCategoryparentId != '' && subCategoryparentId != null &&  subCategoryparentId != undefined)">Reset</button>
            <span class="float-right" (click)="opencategory()" *ngIf="!showcategory"> <i
                class="accordion-arrow fa fa-angle-down pull-right"></i></span>
            <span class="float-right" (click)="closecategory()" *ngIf="showcategory"> <i
                class="accordion-arrow fa fa-angle-up pull-right"></i></span>
          </h4>

        </div>

        <div *ngIf="showcategory">
          <!----- Mat Treee ----->
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"
            *ngIf="dataSource?.data.length != 0">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
              [ngClass]='{"active": node?.item?.categoryId == subCategoryparentId }'
              (click)='treeControl.isExpanded(node) ? getActiveParent("0",node) : getActiveParent("1",node)'>
              {{node?.item?.categoryName}}
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
              <div class="mat-tree-node" [ngClass]='{"active": node?.item?.categoryId == parentCategoryId }'
                matTreeNodeToggle (click)="getActiveParent('0',node)">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node?.item?.categoryName"
                  (click)="getActiveParent('0',node)">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                {{node?.item?.categoryName}}
              </div>
              <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </div>


        <!-- {{node?.item?.categoryName}}
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <div class="mat-tree-node" [ngClass]='{"active": node?.item?.categoryId == parentCategoryId }'
            matTreeNodeToggle (click)="getActiveParent('0',node)">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node?.item?.categoryName"
              (click)="getActiveParent('0',node)">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node?.item?.categoryName}}
          </div>
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
        </mat-tree> -->
      </div>
      <p class="not-found" *ngIf="dataSource?.data.length == 0&& categoryName != 'Gifting'">Category Filter Not
        Available...!</p>

      <div *ngIf="getdata?.subcategories1&& categoryName != 'Gifting'">
        <div class="panel panel-default" *ngIf="getdata?.subcategories1.length > 0">
          <div class="panel-heading" role="tab" id="headingTwo">
            <h4 class="panel-title">
              <a class="collapsed" (click)="openAccordion($event.target)" role="button" data-toggle="collapse"
                data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Filter By
                <i class="accordion-arrow fa fa-angle-up pull-right"></i>
              </a>
            </h4>
          </div>
          <div id="collapseTwo" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingTwo">
            <div class="panel-body filter-with">
              <div class="form-check form-check-inline" *ngFor="let item of getdata?.subcategories1">
                <input class="form-check-input" (click)="changeCheckbox(item, $event)" type="checkbox"
                  id="inlineCheckbox1" value="option1" autocomplete="new-inlineCheckbox1" />
                <label class="form-check-label" for="inlineCheckbox1">{{item.categoryName}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Price Filter-->
      <form [formGroup]="filterForm">
        <div class="price-detail">
          <div class="btn-group">

            <h4>Price
              <button class="submit-btn1" (click)="reset(2)"
                *ngIf="(priceMin != '' && priceMin != null &&  priceMin != undefined) && (priceMax != '' && priceMax != null &&  priceMax != undefined)">Reset</button>

              <span class="float-right" (click)="openprice()" *ngIf="!showprice"> <i
                  class="accordion-arrow fa fa-angle-down pull-right"></i></span>
              <span class="float-right" (click)="closeprice()" *ngIf="showprice"> <i
                  class="accordion-arrow fa fa-angle-up pull-right"></i></span>
            </h4>
          </div>
          <!-- <mat-radio-group class="radio-group"
            *ngIf="(getdata?.content?.priceRange?.max != null && getdata?.content?.priceRange?.min != null) && (getdata?.content?.priceRange?.max != getdata?.content?.priceRange?.min)">
            <mat-radio-button class="radio-button" *ngFor="let price of PriceFilterDatas" [value]="kg.id"
              (click)="filterProducts(3,price)">
              <span
                *ngIf="getdata?.content?.priceRange?.max >= price?.maxValue || price?.minValue <= getdata?.content?.priceRange?.max">{{price?.displayValue}}</span>
            </mat-radio-button>
          </mat-radio-group> -->
          <div *ngIf="showprice">
            <mat-radio-group class="radio-group" formControlName="price"
              *ngIf="(getdata?.content?.priceRange?.max != null && getdata?.content?.priceRange?.min != null) && (getdata?.content?.priceRange?.max != getdata?.content?.priceRange?.min)">
              <mat-radio-button class="radio-button" *ngFor="let price of PriceFilterDatas"
                value={{price?.displayValue}} (click)="filterProducts(3,price)">
                <span
                  *ngIf="getdata?.content?.priceRange?.max >= price?.maxValue || price?.minValue <= getdata?.content?.priceRange?.max">{{price?.displayValue}}</span>
              </mat-radio-button>
              <mat-radio-button class="radio-button" *ngIf="getdata?.content?.priceRange?.max > 20000">
                Above ₹{{getdata?.content?.priceRange?.max}}
              </mat-radio-button>
            </mat-radio-group>
            <!-- <ul
            *ngIf="(getdata?.content?.priceRange?.max != null && getdata?.content?.priceRange?.min != null) && (getdata?.content?.priceRange?.max != getdata?.content?.priceRange?.min)">
            <li *ngFor="let price of PriceFilterDatas">
              <a [ngClass]='{"priceactive":priceMax == price?.maxValue && priceMin == price?.minValue }'
                *ngIf="getdata?.content?.priceRange?.max >= price?.maxValue || price?.minValue <= getdata?.content?.priceRange?.max"
                (click)="filterProducts(3,price)">{{price?.displayValue}}</a>
            </li>
            <li><a *ngIf="getdata?.content?.priceRange?.max > 20000">Above ₹{{getdata?.content?.priceRange?.max}}</a>
            </li>
          </ul> -->

            <div *ngIf="(getdata?.content?.priceRange?.max != null && getdata?.content?.priceRange?.min != null)">
              <div class="form-row">
                <div class="form-group">
                  <input type="text" name="min" placeholder="Min" value="" formControlName="priceMin"
                    (keydown)="onlyNumber($event)" autocomplete="new-min" />

                </div>
                <div class="form-group">
                  <input type="text" name="max" placeholder="Max" value="" formControlName="priceMax"
                    (keydown)="onlyNumber($event)" autocomplete="new-max" />
                </div>
                <button class="submit-btn" (click)="filterProducts(2,'')"
                  [disabled]="filterForm.controls.priceMin.value == '' && filterForm.controls.priceMin.value == ''">Go</button>
              </div>
            </div>
          </div>
          <p class="not-found"
            *ngIf="(getdata?.content?.priceRange?.max == null && getdata?.content?.priceRange?.min == null)">Price
            Filter Not Available...!</p>
        </div>

        <!--Discount Filter-->
        <!-- <div class="discount-detail">
        <div class="btn-group" >

          <h4  >Discount %</h4>
         <button   class="submit-btn1" (click)="reset(3)" *ngIf="(filterForm?.controls.discount.value != '' && filterForm?.controls.discount.value != null &&  filterForm?.controls.discount.value != undefined)">Reset</button>
        </div>
        <mat-radio-group  class="radio-group" formControlName="discount">
          <mat-radio-button class="radio-button" *ngFor="let discount of discounts" [value]="discount.id" (click)="filterProducts(0,discount.id)">
         {{discount?.displayValue}}
         </mat-radio-button>
        </mat-radio-group> -->
        <!-- <ul>
          <li><input type="radio" value="1" /><span>0 - 10</span></li>
          <li><input type="radio" value="2" /><span>10 - 20</span></li>
          <li><input type="radio" value="3" /><span>20 - 30</span></li>
          <li><input type="radio" value="4" /><span>30 - 40</span></li>
          <li><input type="radio" value="1" /><span>50 - 60</span></li>
          <li><input type="radio" value="2" /><span>60 - 70</span></li>
          <li><input type="radio" value="3" /><span>70 - 80</span></li>
          <li><input type="radio" value="4" /><span>80 - 90</span></li>
        </ul> -->
        <!-- </div> -->
        <!--Kilograms Filter-->
        <div class="kilogram-detail" *ngIf="categoryName != 'Gifting'">
          <div class="btn-group">

            <h4>Kilograms
              <button class="submit-btn1" (click)="reset(4)"
                *ngIf="(filterForm?.controls.kilograms.value != '' && filterForm?.controls.kilograms.value != null &&  filterForm?.controls.kilograms.value != undefined)">Reset</button>
              <span class="float-right" (click)="openkilogram()" *ngIf="!showkilogram"> <i
                  class="accordion-arrow fa fa-angle-down pull-right"></i></span>
              <span class="float-right" (click)="closekilogram()" *ngIf="showkilogram"> <i
                  class="accordion-arrow fa fa-angle-up pull-right"></i></span>
            </h4>

          </div>
          <mat-radio-group class="radio-group" formControlName="kilograms" *ngIf="showkilogram">
            <mat-radio-button class="radio-button" *ngFor="let kg of kilograms" [value]="kg.id"
              (click)="filterProducts(1,kg.id)">
              {{kg?.displayValue}}
            </mat-radio-button>
          </mat-radio-group>
          <!-- <ul>
          <li><input type="radio" value="1" /><span>Upto 0.5kg</span></li>
          <li><input type="radio" value="2" /><span>0.5kg - 2.0kg</span></li>
          <li><input type="radio" value="3" /><span>2.0kg - 4.0kg</span></li>
          <li><input type="radio" value="4" /><span>4.0kg - 6.0kg</span></li>
          <li><input type="radio" value="2" /><span>6.0kg - 8.0kg</span></li>
          <li><input type="radio" value="3" /><span>8.0kg - 10.0kg</span></li>
        </ul> -->
        </div>
      </form>
      <!----------------Filter End-------------------->
    </div>
    <!----------------sidenav bar End-------------------->
  </div>
  <div class="whatsapp-icon" style="background-color: #22ce5a">
    <a href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
      target="_blank">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
          fill="white" />
        <path
          d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
          fill="white" />
      </svg>
    </a>
  </div>

</section>