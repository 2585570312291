
  
<section>

    <div class="top-rounded-border">
    </div>
    <div class="container-fluid">
    <h4 class="text-center"> <img src="assets/images/icons/Layer_10.png" alt="">
        What We Do!
        <img src="assets/images/icons/Layer_11.png" alt="">
    </h4>
    <div class="add-item">
        <hr>

        <div class="text-center delivery list-text mb-0" style="display:flex; align-items: center; justify-content: center; flex-wrap: wrap; margin: 0 50px;">
            <div class="text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/what-we-do/celebration-cakes-1.png" class="home-1">
                </article>
            
            </div>
            <div class="text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/what-we-do/dessert-table-1.png" class="home-1">
                </article>
            
            </div>
            <div class="text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/what-we-do/gift-baskets-1.png" class="home-1">
                </article>
             
            </div>
            <div class="text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/what-we-do/midnight-delivery-1.png" class="home-1">
                </article>
             
            </div>

            <div class="text-center p-0">
                <article class="rounded-circle">
                    <img src="assets/images/what-we-do/door-delivery-1.png" class="home-1">
                </article>
         
            </div>


        </div>
        
    </div>
</div>
    <div class="bottom-rounded-border">
    </div>

</section>
