import { HttpParams } from "@angular/common/http";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
declare var $: any;
import { ActivatedRoute } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { HttpService } from "../../../services/http/http.service";
import { sweetAlert } from "../../function/toaster";

@Component({
  selector: "app-email-verify",
  templateUrl: "./email-verify.component.html",
  styleUrls: ["./email-verify.component.less"],
})
export class EmailVerifyComponent implements OnInit {
  @ViewChild("loginSignupModal") loginSignupModal: ElementRef;
  constructor(
    private route: ActivatedRoute, 
    public http: HttpService) {}

  emailVerify = false;
  failed = false;
  changePassword = false;
  resendEmail = false;
  failedMsg = "";
  loading = false;
  parmData;
  newPassword = "";
  confirmPassword = "";
  token = "";
  email: any;
  ngOnInit(): void {
    $("#email-verify").modal("show");
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("token") && params.hasOwnProperty("type")) {
        this.parmData = params["type"];
        this.token = params["token"];
        this.email = params["email"];
      }
    });

    ///////verify email/////////
    if (this.parmData == "verify") {
      this.loading = true;
      this.http
        .post(
          "/user/email/verify",
          JSON.parse('{ "token" : "' + this.token + '" }')
        )
        .subscribe(
          (data) => {
            this.emailVerify = true;
            this.loading = false;
          },
          (error) => {
            //token expired
            if (error.status == "1050") {
              this.resendEmail = true;
              return;
            }
            this.failed = true;
            this.failedMsg = error.message;
            this.loading = false;
          }
        );
    }

    if (this.parmData == "forgot") {
      this.changePassword = true;
    }
  }

  login() {
    $("#email-verify").modal("hide");
    $("#loginSignup").modal("show");
  }

  //resend
  resendEmailValue = "";

  resendFun() {
    this.loading = true;
    var obj = { email: this.resendEmailValue };
    let params = new HttpParams();
    for (let key in obj) {
      params = params.set(key, obj[key]);
    }
    this.http.post("/user/confirm/resend", params).subscribe(
      (data) => {
        sweetAlert("success", "Email Resend Successfully");
        $("#email-verify").modal("hide");
        this.loading = false;
      },
      (error) => {
        sweetAlert("error", error.message);
        this.loading = false;
      }
    );
  }
  errorPassword;
  changePasswordBtn() {
    this.errorPassword = "";
    if (this.newPassword.length < 6 || this.confirmPassword.length < 6) {
      this.errorPassword = "Password must greater than 6 character";
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      this.errorPassword = "New Password and Confirm Password Must Match";
      return;
    }

    this.loading = true;
    this.http
      .post(
        "/user/password/reset",
        JSON.parse(
          '{ "token" : "' +
            this.token +
            '", "newPassword" :  "' +
            this.confirmPassword +
            '" , "email" : "' +
            this.email +
            '"}'
        )
      )
      .subscribe(
        (data) => {
          sweetAlert("success", "Password Changed Successfuly");
          this.login();
          this.loading = false;
        },
        (error) => {
          sweetAlert("error", error.message);
          this.loading = false;
        }
      );
  }
}
