<app-loader *ngIf="loading"></app-loader>

<!-- <button type="button" class="btn btn-primary" id="verificationModal" #verificationModal hidden="true"
    data-toggle="modal" data-target="#verification">
</button> -->

<!-- This popup is for signup -->
<div class="modal fade" id="verification" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="verificationLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

        <div class="modal-content">
            <div class="modal-body text-center">
                <button (click)="resetOTP()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 ptb-4">
                        <div class="row px-3 pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pt-4 px-3 pb-4">
                                    <div class="col-lg-6">
                                        <h4>Enter OTP </h4>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <!-- <a href="#" (click)="loginWithFacebooks()" class="fa fa-facebook"></a> -->
                                        <a href="#" (click)="loginWithGoogles()" class="fa fa-google"></a>
                                    </div>
                                </div>
                                <img src="../../../../assets/images/icons/phone1.svg" alt="" srcset="">

                                <p style="line-height: 1.3;">
                                    Please Enter the OTP sent to <br>{{mobileNumber}} <span class="forgot-password"><a
                                            href="javascript:;" (click)="changeNumber()">Change
                                            Number</a></span>
                                </p>

                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <ng-otp-input #ngOtpInput [config]="config"
                                            (onInputChange)="onOtpChange($event)" autocomplete="off"></ng-otp-input>
                                    </div>
                                </div>
                                <p *ngIf="count!=0" class="float-right">{{count}}</p>
                                <!-- <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p> -->
                                <div class="row mt-5 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" (click)="onSubmit()"
                                            class="btn btn-custom btn-lg btn-block">Verify</button>
                                    </div>
                                </div>
                                <p class="mt5" *ngIf="count==0">
                                    Not received your code?<span class="forgot-password"><a style="cursor: pointer;"
                                            (click)="resendOtp()"> Resend
                                            Code</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 ipda-imag">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header">
                            <h3>Hello, Friend!</h3>
                            <p class="hello">Enter your personal details and start journey with us</p>
                            <button type="button" class="btn btn-custom " href="javascript:;" (click)="
                            child.openModel('signInSingUp');
                            analyticsService.commonEvents('Onclick', {
                              event_category: 'Open SignIn/SignUp Popup Form',
                              event_label: 'Open SignIn/SignUp Popup Form',
                              value: 'Open SignIn/SignUp Popup Form'
                            })
                          ">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This popup is for signin -->
<div class="modal fade" id="verifications" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="verificationLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">

        <div class="modal-content">
            <div class="modal-body text-center">
                <button (click)="resetOTP()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 ptb-4">
                        <div class="row px-3 pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pt-4 pb-4">
                                    <div class="col-lg-6">
                                        <h4>Enter OTP </h4>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <!-- <a class="fa fa-facebook" (click)="loginWithFacebooks()"></a> -->
                                        <a class="fa fa-google" (click)="loginWithGoogles()"></a>
                                    </div>
                                </div>
                                <img src="../../../../assets/images/icons/phone1.svg" alt="" srcset="">

                                <p style="line-height: 1.3;">
                                    Please Enter the OTP sent to <br>{{mobileNumber}} <span class="forgot-password"><a
                                            href="javascript:;" (click)="changeNumber()">Change
                                            Number</a></span>
                                </p>
                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <ng-otp-input #ngOtpInput2 [config]="config"
                                            (onInputChange)="onOtpChange($event)" autocomplete="off"></ng-otp-input>
                                    </div>
                                </div>
                                <p *ngIf="count!=0" class="float-right" style="margin-bottom:0px;">{{count}}</p>
                                <!-- <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p> -->
                                <div class="row mt-5 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" (click)="onSubmit1()"
                                            class="btn btn-custom btn-lg btn-block">Verify</button>
                                    </div>
                                </div>
                                <p class="mt5" style="text-align:center;" *ngIf="count==0">
                                    Not received your code?<span class="forgot-password"><a style="cursor: pointer;"
                                            (click)="resendOtp()"> Resend
                                            Code</a></span>
                                </p>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header">
                            <h3>Hello, Friend!</h3>
                            <p>Enter your personal details and start<br> journey with us</p>
                            <button type="button" class="btn btn-custom " href="javascript:;" (click)="child.signInSingUp = !child.signInSingUp;
                            child.openModel('signInSingUp');
                            analyticsService.commonEvents('Onclick', {
                              event_category: 'Open SignIn/SignUp Popup Form',
                              event_label: 'Open SignIn/SignUp Popup Form',
                              value: 'Open SignIn/SignUp Popup Form'
                            });child.signInSingUp = true
                          ">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<app-login-signup #child></app-login-signup>