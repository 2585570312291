import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AppService } from "src/app/app.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { HttpService } from "src/app/services/http/http.service";
import { sweetAlert } from "../../../../shared/function/toaster";
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.less"],
})
export class ContactUsComponent implements OnInit {
  @ViewChild("contactUs") contactUs: NgForm; 

  constructor(
    public http: HttpService,
    public analyticsService:GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.analyticsService.pageView("View Contact us",`page/contactUs`,window.location.origin);
  }

  //submit contactus form
  public submitLoader: Boolean = false;
  doContactUs(value) {
    this.submitLoader = true;
    this.analyticsService.commonEvents("Onclick",{'event_category': 'Send Contactus Message',
    'event_label': 'Send Contactus Message',
    'value': 'Send Contactus Message'});
    this.http.post("/contactus", value).subscribe(
      (data: any) => {
        this.submitLoader = false;
     
        sweetAlert("success", data.message);
        this.contactUs.resetForm();
      },
      (error) => {
        this.submitLoader = false;
        sweetAlert("error", error.message);
      }
    );
  }
}
