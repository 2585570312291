import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { promise } from 'protractor';
import { promises } from 'dns';


@Injectable({
  providedIn: 'root'
})

export class AppService {

  httpOptions: { headers: any; };

  constructor(
    public http: HttpClient,
  ) { }


  /**
   * getApi function call api without auth header and for GET API call
   * @param url  API URL
   */
  getApi(url): Promise<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    }
    return this.http.get(url, this.httpOptions).toPromise();
  }

  /**
  * getApiWithAuth function call api with auth header and for GET API call
  * @param url API URL
  */
  getApiWithAuth(url): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': localStorage.getItem('token')
      }),
    }
    return this.http.get(url, this.httpOptions);
  }

  formdataApi(url, data): Observable<any> {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({ 'token': localStorage.getItem('token') }),
      observe: 'response'
    }
    return this.http.post(url, data, httpOptions)
  }

  /**
    * postApi function call api without auth header and for POST API call
    * @param url API URL
    * @param data post data
    */
  postApi(url, data): Promise<any> {
    this.httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data).toPromise();
  }


  /**
  * postApiWithAuth function call api with auth header and for POST API call
  * @param url API URL
  * @param data post data
  */
  postApiWithAuth(url, data): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': localStorage.getItem('token')
      })
    }
    return this.http.post(url, data, this.httpOptions);
  }

}
