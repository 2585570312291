import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { AppConstants } from "src/app/configuration/app.constants";
import { HttpService } from "src/app/services/http/http.service";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/memory-services/user.service";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { checkNull } from "../../date-and-time-validations";

declare var $: any;
@Component({
  selector: "app-recommended",
  templateUrl: "./recommended.component.html",
  styleUrls: ["./recommended.component.less"],
})
export class RecommendedComponent implements OnInit {
  @Input("Id") id: any;
  @Input("productid") productid: any;
  loading = false;
  loginIN;
  orderId;
  productId;

  constructor(
    public http: HttpService,
    public addCartService: AddCartService,
    public analyticsService: GoogleAnalyticsService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private memoryService: MemoryService
  ) {}

  imgPth;
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((res: any) => {
      this.productId = res.params?.id.slice(-36);
    });
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.get();
    this.orderId = localStorage.getItem("orderId");
    this.loginIN = localStorage.getItem("loginIN");

    this.memoryService.memoryEmitter$.subscribe((res: any) => {
      this.loginIN = localStorage.getItem("loginIN");
      this.getWishList();
    });
  }

  getdata: any = [];
  getdata2;
  //get recommended product Data
  get() {
    this.http
      .get("/product/recommended/" + this.id + "/" + this.productid)
      .subscribe(
        (data) => {
          this.getdata2 = data;
          this.getdata = this.getdata2.content;

          // .filter((ele:any)=>ele?.productId!=this.productId);

          this.getWishList();
          this.getdata.map((x) => {
            x.seoUrl =
              x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
            let type = typeof x.productDetails;
            if (type == "string") x.productDetails = JSON.parse(x.productDetails);
            let type2 = typeof x.paymentDetails;
            if (type2 == "string") x.paymentDetails = JSON.parse(x.paymentDetails);
            if (x.productImages) {
              x.productImages = x.productImages[0].split(",")[0];
            } else {
              x.productImages = "";
            }

            //calculate discount price
            x.productDetails.pricing.map((y) => {
              let decPercentage = y.discount / 100;
              let save = y.price * decPercentage;
              y.save = save;
              y.disPrice = y.price - save;
            });
          });
          setTimeout(() => {
            this.refreshSlider();
          }, 500);
        },
        (error) => {}
      );
  }

  wishList;
  getWishList() {
    if (this.loginIN == 1) {
      this.http.get("/user/wishlist").subscribe(
        (data) => {
          this.wishList = data;
          //map recommended cake productID to wishlist productID
          this.getdata.map((x) => {
            x.seoUrl =
              x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
            if (
              this.wishList.content.filter((s) => s.productID == x.productId)
                .length >= 1
            ) {
              x.like = true;
            } else {
              x.like = false;
            }
          });
        },
        (error) => {}
      );
    }
  }

  //add or remove wishlist
  likeData;
  like(id, e) {
    if (this.loginIN != 1) {
      $("#loginSignup").modal("show");
      return;
    }
    let addWishListData: any;
    $(e).addClass("fa-circle-o-notch fa-spin disabled");
    this.http.post("/user/wishlist/" + id, "").subscribe(
      (data) => {
        this.loading = false;
        this.likeData = data;
        this.addCartService.setWishList("Refresh Wishlist");
        if (
          this.likeData.status == 1034 ||
          this.likeData.message == "Wishlist Added "
        ) {
          this.getdata.map((x) => {
            x.seoUrl =
              x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
            if (x.productId == id) {
              x.like = true;
              addWishListData = {
                id: x.productId,
                name: x.productName,
                brand: "Puppy's",
                category: x.categoryName,
                quantity: 1,
              };
            }
          });
          this.cd.markForCheck();
        }
        if (
          this.likeData.status == 1036 ||
          this.likeData.message == "Wishlist removed "
        ) {
          this.getdata.map((x) => {
            x.seoUrl =
              x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
            if (x.productId == id) {
              x.like = false;
            }
          });
          this.cd.markForCheck();
        }
      },
      (error) => {
        this.loading = false;
        this.cd.markForCheck();
      }
    );
    if (this.likeData.status == 1034 || this.likeData.message == "Wishlist Added ") {
      this.analyticsService.addToWishlist({
        currency: "INR",
        items: addWishListData,
      });
    }
  }

  //refresh slider after data is loaded
  refreshSlider() {
    $("#owl-recommended").owlCarousel({
      items: 4,
      dots: false,
      nav: false,
      responsive: {
        0: {
          items: 2,
          navigation: true,
          margin: 10,
          nav: true,
          navText: [
            "<img src='assets/images/icons/left_arrow_cover.png' style='width: 10px;'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
          ],
        },
        801: {
          items: 4,
          navigation: true,
          nav: true,
          margin: 20,
          navText: [
            "<img src='assets/images/icons/left_arrow_cover.png' style='width: 10px;'>",
            "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
          ],
        },
      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true,
    });
  }
}
