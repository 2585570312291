<section>
    <div class="container">
        <div class="review-header">
            <h1>customer reviews & testimonials</h1>
            <h3 class="underline"></h3>
            <p>
                <span class="review-rate">4.4</span>
                <a class="name">
                    <span><i class="fa fa-star"></i></span>
                    <span><i class="fa fa-star"></i></span>
                    <span><i class="fa fa-star"></i></span>
                    <span><i class="fa fa-star"></i></span>
                    <span><i class="fa fa-star"></i></span>
                </a>
                <a href="#" class="total-review">3415 Reviews</a>
            </p>
        </div>
        <div class="review-body">
            <div class="custom-pagination">
                <div class="card text-center">
                    <h4 class="card-header">What our users say about us</h4>
                    <div class="card-footer pt-3">
                        <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                    </div>
                </div>
            </div>
            <div class="see-all-review">
                <div class="row pb-20">
                    <div class="col-lg-2 review-profile text-center">
                        <div class="review-image">
                            <img src="assets/images/icon-human- (1).png" alt="" />
                        </div>
                        <p>kavya</p>
                    </div>
                    <div class="col-lg-10 review-cmd">
                        <div class="review-cmd-body">
                            <p>
                                <span class="review-rate">4.4</span>
                                <a href="#">
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                </a>
                            </p>
                            <strong>"I would like to say that i am extremly satisfied with the order amidst highly recommended"</strong>
                            <h6 class="time">17/07/2021, 08:40 PM</h6>
                        </div>
                    </div>
                </div>
                <div class="row pb-20">
                    <div class="col-lg-2 review-profile text-center">
                        <div class="review-image">
                            <img src="assets/images/icon-human- (1).png" alt="" />
                        </div>
                        <p>kavya</p>
                    </div>
                    <div class="col-lg-10 review-cmd">
                        <div class="review-cmd-body">
                            <p>
                                <span class="review-rate">4.4</span>
                                <a href="#">
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                </a>
                            </p>
                            <strong>"I would like to say that i am extremly satisfied with the order amidst highly recommended"</strong>
                            <h6 class="time">17/07/2021, 08:40 PM</h6>
                        </div>
                    </div>
                </div>
                <div class="row pb-20">
                    <div class="col-lg-2 review-profile text-center">
                        <div class="review-image">
                            <img src="assets/images/icon-human- (1).png" alt="" />
                        </div>
                        <p>kavya</p>
                    </div>
                    <div class="col-lg-10 review-cmd">
                        <div class="review-cmd-body">
                            <p>
                                <span class="review-rate">4.4</span>
                                <a href="#">
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                </a>
                            </p>
                            <strong>"I would like to say that i am extremly satisfied with the order amidst highly recommended"</strong>
                            <h6 class="time">17/07/2021, 08:40 PM</h6>
                        </div>
                    </div>
                </div>
                <div class="row pb-20">
                    <div class="col-lg-2 review-profile text-center">
                        <div class="review-image">
                            <img src="assets/images/icon-human- (1).png" alt="" />
                        </div>
                        <p>kavya</p>
                    </div>
                    <div class="col-lg-10 review-cmd">
                        <div class="review-cmd-body">
                            <p>
                                <span class="review-rate">4.4</span>
                                <a href="#">
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                <span><i class="fa fa-star"></i></span>
                                </a>
                            </p>
                            <strong>"I would like to say that i am extremly satisfied with the order amidst highly recommended"</strong>
                            <h6 class="time">17/07/2021, 08:40 PM</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-pagination">
                <div class="card text-center">
                    <div class="card-footer">
                        <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
