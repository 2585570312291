import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BlogRoutingModule } from "./blog-routing.module";
import { BlogComponent } from "./blog.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { BlogListComponent } from './blog-list/blog-list.component';
import { PaginationComponent } from './pagination/pagination.component';

@NgModule({
  declarations: [BlogComponent, BlogListComponent, PaginationComponent],
  imports: [CommonModule, BlogRoutingModule, NgxSkeletonLoaderModule],
})
export class BlogModule {}
