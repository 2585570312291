import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpService } from '../../../../services/http/http.service';
declare var $: any;
import { AppConstants } from '../../../../configuration/app.constants';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

declare var $: any
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.less']
  
})
export class TestimonialComponent implements OnInit {

  @Input("type") type: any;
  @Input("home") homeid: any;
  @Input("id") desertid: any;
  @Input("wedding") weddings: any;

  getdata2
  getdata
  imgPth
  desert: any = [];
  custom: any = [];

  constructor(
    public http: HttpService,
    public router : Router,
    public analyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.gethome('home');
    this.getdesert('desert');

  }



  //get all testmonial
  gethome(value) {
    this.http.get(`/admin/testimonial/fetch/${value}`).subscribe(
      data => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content.filter((x) => x?.pageType == 'home');
      
        // this.desert = this.getdata2.content.filter((x) => x?.pageType == 'desert');
        // this.wedding = this.getdata2.content.filter((x) => x?.pageType == 'wedding');
        // this.custom = this.getdata2.content.filter((x) => x?.pageType == 'custom');
 
        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      error => {

      });
  }
  getdesert(value){
    this.http.get(`/admin/testimonial/fetch/${value}`).subscribe(
      data => {
        this.getdata2 = data;
      
        this.desert = this.getdata2.content.filter((x) => x?.pageType == 'desert');
        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      error => {
      });
  }

  //refresh owl slider after 200ms
  refreshSlider() {
    $(".test #owl-testimonial").owlCarousel({
      items: 3,
      nav: false,
      dots: false,
      loop: true,
      responsive: {
        0: {
          items: 1,
          navigation: true,
          margin: 5,
          nav: true,
        },
        801: {
          items: 1,
          navigation: true,
          nav: true,
          margin: 50,
        },
        1025: {
          items: 3,
        }
      },
    });
  }
  toViewPage(){
this.router.navigateByUrl('testimonial/home')
  }
}
