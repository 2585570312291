import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstants } from "src/app/configuration/app.constants";
import { HttpService } from "src/app/services/http/http.service";
import { sweetAlert } from "../../shared/function/toaster";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormControlName,
} from "@angular/forms";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { Title, Meta } from "@angular/platform-browser";
import { Observable, Subscription } from "rxjs";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { HttpParams } from "@angular/common/http";
import moment from "moment";
import {
  addHours,
  addMinutes,
  differenceInHours,
  format,
  isAfter,
  isBefore,
} from "date-fns";
import {
  checkNull,
  checkTimeForDate,
  checkValidDeliveryDate,
  checkValidDeliveryTime,
} from "./date-and-time-validations";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { UserService } from "src/app/services/memory-services/user.service";
import { ProductI } from "src/app/shared/models/product-detail.model";
import Swal from "sweetalert2";
import { ShipRocketService } from "../checkout-page/service/ship-rocket.service";
import { LoaderService } from "src/app/services/memory-services/loader.service";
import { first, map } from "rxjs/operators";
import { DeliveryDateService } from "./delivery-date.service";

function throwAlert(message) {
  Swal.fire({
    title: "<strong style='color:#ff9966'>Warning</strong>",
    // style="color:#fc98b2"
    html: `<b>${message}</b>
 
    `,
    // icon: "warning",
    showCancelButton: false,
    confirmButtonText: `OKAY`,
    allowOutsideClick: true,
    confirmButtonColor: "#fc98b2",
    cancelButtonColor: "#fc98b2",
    cancelButtonText: 'Okay <i class="fa fa-check" aria-hidden="true"></i>',
  });
}
declare var $: any;

class Occassions {
  occassionId;
  discountAmount;
  couponType;
  couponName;

  constructor(data) {
    this.occassionId = data?.couponOccasionId;
    this.discountAmount = data?.discountAmount;
    this.couponType = data?.couponTypeId;
    this.couponName = data?.couponTypeName;
  }
}
function adaptOccasions(data) {
  return data?.Content?.length > 0
    ? data?.Content.map((ele) => new Occassions(ele)).filter(
        (ele) => ele?.couponType != "4"
      )
    : [];
}

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.less"],
})
export class ProductDetailsComponent implements OnInit,OnDestroy {
  HelpHide: boolean = false;
  DateElement: any;
  imageHide: boolean;
  KeyWordLength: any;
  selectCity: any;
  showMidNightDeliveryInfo: boolean = false;
  isMidnightDelivery: any = false;
  midNIghtDeliveryCharge: Number = 0;
  selectOneCity: any;
  Finaldata: any;
  maxNumberOfCharacters = 30;
  counter = true;
  numberOfCharacters1 = 0;
  currentDate: string;
  BookedData: string;
  pinCode: any;
  id: string;
  deliveryCuttofTime: any = {};
  wishListId: string;

  // star rating
  rate: number = 2.7;

  @HostListener("contextmenu", ["$event"])

  //Prevent Right Click
  onRightClick(event) {
    event.preventDefault();
  }

  constructor(
    private actRoute: ActivatedRoute,
    public http: HttpService,
    private formBuilder: FormBuilder,
    public addCartService: AddCartService,
    private title: Title,
    private meta: Meta,
    public analyticsService: GoogleAnalyticsService,
    private memoryService: MemoryService,
    private userService: UserService,
    private router: Router,
    private shiprocketService: ShipRocketService,
    private loader: LoaderService,
    private deliveryDateService: DeliveryDateService
  ) {
    this.getAllPincodes();
    this.intialPanForm();
  }

  loading = false;
  public galleryImages = [];
  public placeHolderCount: Number = 5;
  public selectedItem: Number = 0;
  public preview: any;
  private currentView: any;
  public noProduct: Boolean = false;
  public href: any;
  public loginIN: any;
  @ViewChild("pincode") pincode: ElementRef;
  public deliveryPin: any;
  public orderId: any;
  public hrefWats: any;
  public pincodeVal = "";
  public form: FormGroup;
  public imgPth: any;
  public isFlavour = false;
  public gift = false;
  public startRating = 5;
  public productId: any = "0";
  public ProductDetails: any;
  public productPrice: any = {
    initialAmount: "",
    finalAmount: "",
    saveAmount: "",
    per: "",
  };
  public categoryName: any;
  public categoryId: any;
  public showLoader: Boolean = true;
  public recommended: Boolean = false;
  public ProductDetails2: any;
  public breadcrumb: any;
  public loopKg = [];
  public flavourSelect = "";
  public kgSelect = "";
  public productQuantity = 1;
  public pinCodeKeyword = "searchKey";
  public pinCodeData = [];
  public flavour = "";
  public getId: any;

  // gtm developed variables
  productData: ProductI;
  isFeedBackVisible: boolean = false;
  @ViewChild('feedBack', { static: true }) feedBack: ElementRef;
  ngOnInit(): void {
    this.getOccassionalCoupons();
    this.initialProductForm();
    this.showHidePinLocation = false;
    this.imageHide = false;
    this.href = encodeURI(window.location.href);
    this.hrefWats = encodeURIComponent(window.location.href);
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.loginIN = localStorage.getItem("loginIN");
    this.panControl = new FormControl("", [
      Validators.required,
      Validators.pattern(/^[1-9][0-9]{5}$/),
    ]);

    this.memoryService.memoryEmitter$.pipe().subscribe((res: any) => {
      console.log(res, "in the prod detail component", this.loginIN);
      this.loginIN = localStorage.getItem("loginIN");
      this.userService.setCurrentUser();
      if (res.component == "hitwish" && this.loginIN==1) {
        this.getWishList();
        if (checkNull(res?.functionName) && this.loginIN == 1) {
          console.log(res, "in the prod detail component", this.loginIN);
          setTimeout(() => {
            this[res?.functionName](res.params);
          }, 200);
        }
      } else {
        // this.getWishList();
        console.log(res,"res in productDetail else")
        if (checkNull(res?.functionName) && this.loginIN == 1) {
          this[res?.functionName](res.params ?? "");
        }
      }
     
    });

    this.addCartService.getPinCode().subscribe((data) => {
      //add pincode on textbox
      this.deliveryPin = JSON.parse(localStorage.getItem("deliveryPin"));
      this.resetDateTimePicker();
      if (this.deliveryPin && this.deliveryPin.pincode) {
        this.pincodeVal = this.deliveryPin.city + " - " + this.deliveryPin.pincode;
        this.pinCode = this.deliveryPin.pincode;
      }
    });

    // if (this.deliveryPin && this.deliveryPin.pincode) {
    //   this.selectPinCode(this.deliveryPin);
    // }

    this.getPinCode(this.pinCode);

    this.actRoute.paramMap.subscribe((params) => {
      let getId = params.get("id");
      // this.getId= getId

      this.id = getId.substr(getId.length - 36);
      this.throwProductid();

      this.analyticsService.pageView(
        "Product Detail",
        `details/${this.id}`,
        window.location.origin
      );
      this.wishListId = this.id;
      if (this.id == null) {
        this.noProduct = true;
      } else {
        window.scrollTo(0, 0);
        this.noProduct = false;
        this.getProductDetails(this.id);
      }
    });

    this.actRoute.paramMap.subscribe((res: any) => {
      this.showHidePinLocation = false;
    });
    // throw the product id when the users enter the product detail page
  }

  showFeedBack(event: Event): void {
    
    event.preventDefault(); 
    window.scrollTo({
      top: 1500,
      behavior: 'smooth'
    });
    
  }

  getType(value) {
    let val = value.split("/")[4].split(".")[1];
    return val == "mp4" ? true : false;
  }

  resetAnniversaryDate() {
    var $this = this;
    $("#datetimepicker3").datetimepicker({
      defaultDate: false,
      timepicker: false,
      format: "d-M-Y",
      minDate: this.reminderDays,
      step: 15,
      onSelectDate: function (ct, $i) {
        $this.productForm.get("anniversaryDate").setValue(ct);
        $this.productForm.get("anniversaryDate").updateValueAndValidity();
      },
    });
  }

  reminderDays: any = new Date();
  getOccassionalCoupons() {
    this.http
      .get(`/admin/viewAll`)
      .pipe(map((ele: any) => adaptOccasions(ele)))
      .subscribe(
        (res: any) => {
          this.occasionList = res;
        },
        (er) => {
          this.occasionList = [];
        }
      );
  }
  occasionList: any[] = [];

  /*****This is developed on 26-02-2024 for Impacts due to Produt Management for the Variants implementation (Starts)******/

  productForm: FormGroup;

  initialProductForm() {
    this.productForm = new FormGroup({
      price: new FormControl("", [Validators.required, Validators.min(1)]),
      discount: new FormControl("", Validators.required),
      weight: new FormControl("", Validators.required),
      cakeType: new FormControl("", Validators.required),
      flavour: new FormControl(""),
      netWeight: new FormControl("", [Validators.required]),
      grossWeight: new FormControl("", [Validators.required]),
      madeWith: new FormControl(""),
      anniversary: new FormControl(""),
      anniversaryDate: new FormControl(""),
    });

    this.resetAnniversaryDate();
  }

  weights: any;
  flavours: any;
  variants: any;
  product: any;

  elaborateProductDetails() {
    this.weights = [
      ...new Map(
        this.product.productDetails.pricing.map((obj) => [obj.kg, obj.kg])
      ).values(),
    ];
    this.flavours = [
      ...new Map(
        this.product.productDetails.pricing.map((obj) => [obj.flavour, obj.flavour])
      ).values(),
    ];

    let egg = this.product.productDetails?.egg;
    let eggLess = this.product.productDetails?.eggless;

    this.variants = this.product.productDetails.pricing.map((ele: any) => {
      ele.discount = checkNull(ele.discount) ? Number(ele.discount).toFixed(2) : 0;
      ele.price = checkNull(ele.price) ? Number(ele.price).toFixed(2) : 0;
      switch (true) {
        case (eggLess && egg) || ele?.flavourEgg == "both":
          {
            ele.egg = "3";
            ele.madeWith = ["1", "2"];
          }
          break;
        case (!eggLess && egg) || ele?.flavourEgg == "egg":
          {
            ele.egg = "1";
            ele.madeWith = ["1"];
          }
          break;
        case (eggLess && !egg) || ele?.flavourEgg == "eggless":
          {
            ele.egg = "2";
            ele.madeWith = ["2"];
          }
          break;
        case !eggLess && !egg:
          {
            ele.egg = "4";
            ele.madeWith = [];
          }
          break;
      }
      return ele;
    });

    this.setFirstVariant();
    console.log(
      this.weights,
      this.flavours,
      this.variants,
      "Variants--------------->"
    );
  }

  setFirstVariant() {
    const price = this.productForm.get("price") as FormControl;
    const discount = this.productForm.get("discount") as FormControl;
    const weight = this.productForm.get("weight") as FormControl;
    const cakeType = this.productForm.get("cakeType") as FormControl;
    const flavour = this.productForm.get("flavour") as FormControl;
    const madeWith = this.productForm.get("madeWith") as FormControl;
    const netWeight = this.productForm.get("netWeight") as FormControl;
    const grossWeight = this.productForm.get("grossWeight") as FormControl;

    // const storePickUp = this.productForm.get('storePickUp') as FormControl

    let variants = this.variants[0];
    let disCountVal =
      checkNull(variants?.discount) && Number(variants?.discount) > 0
        ? Number(variants?.discount)
        : 0;
    price.setValue(Number(variants.price));
    discount.setValue(Number(disCountVal));
    weight.setValue(variants?.kg);
    cakeType.setValue(
      variants?.egg != "4" ? (variants.madeWith?.includes("1") ? "1" : "2") : "1"
    );
    madeWith.setValue(variants.madeWith ?? []);
    this.egglessData =
      variants?.egg != "4" ? (variants.madeWith?.includes("1") ? "1" : "2") : "1";
    flavour.setValue(variants.flavour ?? "no-flavour");
    netWeight.setValue(
      checkNull(variants.net)
        ? Number(variants.net) > 0
          ? Number(variants.net)
          : 0
        : variants?.kg
    );
    grossWeight.setValue(
      checkNull(variants.gross)
        ? Number(variants.gross) > 0
          ? Number(variants.net)
          : 0
        : variants?.kg
    );
    this.productForm.updateValueAndValidity();
    if (this.product?.deliveryType == "store delivery") {
      this.showHidePinLocationFun({ target: { checked: true } });
      this.resetControl();
    }
  }

  async variantChange(type: "weight" | "cakeType" | "flavour", value: any) {
    const price = this.productForm.get("price") as FormControl;
    const discount = this.productForm.get("discount") as FormControl;
    const weight = this.productForm.get("weight") as FormControl;
    const cakeType = this.productForm.get("cakeType") as FormControl;
    const flavour = this.productForm.get("flavour") as FormControl;
    const netWeight = this.productForm.get("netWeight") as FormControl;
    const grossWeight = this.productForm.get("grossWeight") as FormControl;
    const madeWith = this.productForm.get("madeWith") as FormControl;

    if (type == "weight") {
      weight.setValue(value);
      weight.updateValueAndValidity();
    }

    const FW: any[] = this.variants.filter(
      (ele) => ele?.flavour == flavour.value && ele?.kg == weight.value
    );
    const variantsFW =
      FW.length > 0
        ? FW
        : this.variants.filter((ele) =>
            type == "flavour"
              ? ele?.flavour == flavour.value
              : type == "weight"
              ? ele?.kg == weight.value
              : ele?.flavour == flavour.value && ele?.kg == weight.value
          );

    const withEgg = variantsFW.filter((ele) => ele?.madeWith.includes("1"));

    const withoutEgg = variantsFW.filter((ele) => ele?.madeWith.includes("2"));

    const withBoth = variantsFW.filter((ele) =>
      ele?.madeWith.includes(cakeType.value)
    );

    let foundVariant;
    if (type != "cakeType") {
      foundVariant = withEgg.length > 0 ? withEgg : withoutEgg;
    } else {
      foundVariant = withBoth;
    }
    console.log("these are the variants", foundVariant[0]);

    const variants = foundVariant[0];

    if (foundVariant.length != 0) {
      let disCountVal =
        checkNull(variants?.discount) && Number(variants?.discount) > 0
          ? Number(variants?.discount)
          : 0;
      price.setValue(Number(variants.price));
      discount.setValue(Number(disCountVal));
      netWeight.setValue(Number(variants.net) > 0 ? Number(variants.net) : 0.5);

      if (type != "cakeType") {
        cakeType.setValue(
          variants?.egg != "4"
            ? variants.madeWith?.includes("1")
              ? "1"
              : "2"
            : variants?.egg
        );
      }

      if (type == "flavour") {
        weight.setValue(variants.kg);
      }
      if (type == "weight") {
        flavour.setValue(variants.flavour);
      }
      madeWith.setValue(variants.madeWith ?? []);
      netWeight.setValue(
        checkNull(variants.net)
          ? Number(variants.net) > 0
            ? Number(variants.net)
            : 0
          : variants?.kg
      );
      grossWeight.setValue(
        checkNull(variants.gross)
          ? Number(variants.gross) > 0
            ? Number(variants.net)
            : 0
          : variants?.kg
      );
    } else {
      price.setValue(0);
      discount.setValue(0);
    }
  }

  get form1() {
    return this.productForm.controls;
  }

  get showMadeWith() {
    // (checkNull(product?.productDetails?.egg)||checkNull(product?.productDetails?.eggless))
    let productDetails = this.product?.productDetails;
    const hasSome = productDetails?.pricing?.some((ele: any) =>
      checkNull(ele?.flavourEgg)
    );
    return (
      checkNull(productDetails?.egg) || checkNull(productDetails?.eggless) || hasSome
    );
  }

  get showEggless() {
    let productDetails = this.product?.productDetails;
    const hasSome = productDetails?.pricing?.some(
      (ele: any) =>
        checkNull(ele?.flavourEgg) &&
        (ele?.flavourEgg == "eggless" || ele?.flavourEgg == "both")
    );
    return checkNull(productDetails?.eggless) || hasSome;
  }

  get showEgg() {
    let productDetails = this.product?.productDetails;
    const hasSome = productDetails?.pricing?.some(
      (ele: any) =>
        checkNull(ele?.flavourEgg) &&
        (ele?.flavourEgg == "egg" || ele?.flavourEgg == "both")
    );
    return checkNull(productDetails?.egg) || hasSome;
  }

  // Anniversary  Changes

  anniversaryShow(event) {
    return;
    let control = this.productForm.get("anniversaryDate");
    control.setValue("");
    control.setValidators([Validators.required]);
    setTimeout(() => {
      this.resetAnniversaryDate();
    }, 1000);
    control.updateValueAndValidity();
  }

  resetAnniversary() {
    let anniversay = this.productForm.get("anniversary") as FormControl;
    let date = this.productForm.get("anniversaryDate") as FormControl;

    anniversay.setValue("");
    date.setValue("");
    date.clearValidators();
    date.updateValueAndValidity();
    anniversay.updateValueAndValidity();
  }

  markAsTouched(name) {
    this.productForm.get(name).markAsTouched();
    this.productForm.get(name).updateValueAndValidity();
  }

  /*****This is developed on 26-02-2024 for Impacts due to Produt Management for the Variants implementation (Ends)******/

  checkNull(data) {
    return checkNull(data);
  }

  // for the top seen products count for the admin dashboard

  throwProductid() {
    if (this.id.includes("-") && this.id != null && this.id != undefined) {
      let params = new HttpParams();
      params = params.append("productId", this.id);
      this.http
        .get(`/visitHistoryProduct?productId=${this.id}`)
        .subscribe((res: any) => {});
    }
  }

  pinCodes: any[];
  getAllPincodes() {
    this.http.post("/user/delivery", { value: "" }).subscribe(
      (res: any) => {
        this.pinCodes = res.map((ele: any) => ele?.pincode);
      },
      (err) => {
        this.pinCodes = [];
      }
    );
  }

  getPinCode(code) {
    if (code) {
      this.http.get(`/delivery/${code}`).subscribe((res: any) => {
        if (res.enablemidDelivery == true) {
          7;
          this.midNIghtDeliveryCharge = res.middeliveryCharge;
          this.showMidNightDeliveryInfo = true;
          this.isMidnightDelivery = true;
        } else {
          this.showMidNightDeliveryInfo = false;
          this.isMidnightDelivery = false;
        }
      });
    }
  }
  //set default seo titile on component destroy
  ngOnDestroy() {
    this.title.setTitle(" Puppy's Bakery ");
    this.meta.updateTag({
      name: "description",
      content:
        "At Puppy's Bakery Madurai/Sivakasi, we put uncompromising passion in the art of crust and crumbs to make our products fresh and simply the best!!!",
    });
  }

  checkIsMidDel() {
    return this.isMidnightDelivery;
  }

  getShareable(type: "insta" | "whatsapp" | "facebook" | "twitter") {
    let image = this.productData?.content.designImages[0];
    let id = this.productData.content.productId;
    let message = `Welcome to Puppy's Bakery. ${this.productData.content.productName}`;
    //let productURL = `https://puppysbakery.com/details/${id}`;
    let productURL = `${
      AppConstants.SERVER_CONFIG.USE_URL == "STAGING"
        ? AppConstants.SERVER_CONFIG.REDIRECTIONSTAGING
        : AppConstants.SERVER_CONFIG.REDIRECTIONPRODUCTION
    }/${id}`;
    // productURL = `https://testapipuppys.technogenesis.in/share/${this.productId}?redirectURL=${productURL}`;
    let name = this.productData.content.productName;
    let category = this.productData.content.categoryName;
    let hashTag = `Puppys`;

    let url = "";

    switch (type) {
      case "facebook":
        {
          url =
            "https://www.facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(productURL) +
            "&picture=" +
            encodeURIComponent(image);
        }
        break;
      case "insta":
        {
          url =
            "https://www.instagram.com/share?url=" +
            encodeURIComponent(image) +
            "&caption=" +
            encodeURIComponent(productURL);
        }
        break;
      case "whatsapp":
        {
          url =
            "https://wa.me?text=" +
            encodeURIComponent(productURL) +
            "&photos=" +
            encodeURIComponent(image);
        }
        break;
      case "twitter":
        {
          url = `https://twitter.com/intent/tweet?text=${name}&url=${productURL}&hashtags=${hashTag}&hastags=${category}&via=${hashTag}`;
        }
        break;
    }

    window.open(url, "_blank");
  }

  //get product detail data
  getProductDetails(id) {
    this.productId = id;
    this.showLoader = true;
    //fetch product data api
    this.http.get(`/admin/product/${id}`).subscribe(
      (getData: any) => {
        this.getWishList();
        this.Finaldata = getData.content;
        this.product = getData.content;
        const productData: ProductI = getData;
        this.elaborateProductDetails();
        this.productData = getData;

        // if(this.productData.content.isPanIndiaProduct==false){
        //   this.pincodeVal = this.deliveryPin.city + " - " + this.deliveryPin.pincode;

        // }

        if (
          this.Finaldata?.productDetails?.egg == true &&
          this.Finaldata?.productDetails?.eggless
        ) {
          this.egglessData = "";
        } else if (
          checkNull(this.Finaldata?.productDetails?.egg) &&
          !checkNull(this.Finaldata?.productDetails?.eggless)
        ) {
          this.egglessData = "egg";
        } else if (
          !checkNull(this.Finaldata?.productDetails?.egg) &&
          checkNull(this.Finaldata?.productDetails?.eggless)
        ) {
          this.egglessData = "eggless";
        } else {
          this.egglessData = "";
        }
        //  this.checkFun()

        this.deliveryCuttofTime = checkNull(getData.deliveryTime)
          ? getData.deliveryTime
          : {
              deliverytimeId: 0,
              deliveryName: "0hrs",
              fromTime: "10:00",
              toTime: "23:59",
              status: 0,
              cutOffTime: "",
              shortCode: "0H",
              backingTime: 0,
            };

        this.resetDateTimePicker();
        this.ProductDetails2 = getData;
        this.ProductDetails = this.ProductDetails2.content;
        this.breadcrumb = this.ProductDetails2.path;
        let data = this.ProductDetails;
        if (this.ProductDetails2?.startRating) {
          this.startRating = this.ProductDetails2?.startRating;
        }
        if (!this.ProductDetails.productDetails.notes) {
          this.ProductDetails.productDetails.notes = "No Record Found";
        }
        if (!this.ProductDetails.productDetails.description) {
          this.ProductDetails.productDetails.description = "No Record Found";
        }
        let list = this.ProductDetails?.productDetails?.pricing;
        this.loopKg = [];
        list.map((x) => {
          let temp = this.loopKg.find((e) => e.kg === x.kg);
          if (!temp) {
            this.loopKg.push(x);
          }
        });

        this.calPrice(
          data.productDetails.pricing[0].price,
          data.productDetails.pricing[0].discount,
          data.productDetails.pricing[0].kg,
          data.productDetails.pricing[0].flavour,
          data.productDetails.pricing[0]
        );
        this.categoryName = data.categoryName;
        this.categoryId = data.categoryId;

        this.getBannerImage(data.designImages, data.productImages);

        this.showLoader = false;
        this.recommended = true;
        //set seo title & description
        this.title.setTitle(data.productName);
        let description = data.productDetails.description.replace(
          /(<([^>]+)>)/gi,
          ""
        );
        this.meta.updateTag({
          name: "description",
          content: description.replace(/[&#10;,-]/g, ""),
        });
        if (this.ProductDetails.sku.replace(/[0-9,-]/g, "") == "GIFT") {
          this.gift = true;
        }
        let category: any;
        this.breadcrumb.forEach((element, index) => {
          if (index) {
            category = element.categoryName;
          } else {
            category = +"/" + element.categoryName;
          }
        });
        this.analyticsService.viewItem([
          {
            id: this.productId,
            name: this.Finaldata.productName,
            list_name: `Product View - ${this.Finaldata.categoryName}`,
            brand: "Puppy's",
            category: this.Finaldata.categoryName,
            variant: this.flavourSelect,
            price: this.productPrice.finalAmount,
          },
        ]);
      },
      (error) => {}
    );
  }

  getPrice(price, discount, kg, flavour?, details?: any) {
    this.calPrice(price, discount, kg, flavour, details);
  }

  sweetAlertMessage(type: string, message: any) {}

  //price calculation
  selectedVariant: any;
  calPrice(price, discount, kg, flavour?, details?: any) {
    this.flavourSelect = null;
    let finalAmount = (price - (discount / 100) * price).toFixed();
    let saveAmount = price - parseInt(finalAmount);
    this.productPrice.finalAmount = finalAmount;
    this.productPrice.saveAmount = saveAmount;
    this.productPrice.initialAmount = price;
    this.productPrice.per = discount;
    this.productPrice.selectKg = kg;
    this.selectedVariant = details;
    this.flavourListFun(kg, flavour);
  }

  //flavour selection
  flavourList = [];
  flavourListFun(kg, flavour) {
    if (!flavour) {
      this.isFlavour = false;
      this.flavourSelect = null;
    } else {
      this.isFlavour = true;
      this.flavourSelect = flavour;
    }

    this.kgSelect = kg;
    this.flavourList = [];
    this.ProductDetails?.productDetails?.pricing.map((x) => {
      if (x.kg == kg) {
        this.flavourList.push(x);
      }
    });
  }

  flavourChange(val) {
    this.ProductDetails?.productDetails?.pricing.map((x) => {
      if (x.kg == this.kgSelect && x.flavour == val) {
        this.calPrice(x.price, x.discount, x.kg, x.flavour);
      }
    });
  }

  //////////////////////////////Add to Cart Section/////////////////////////////////

  //change quantity
  selectQuantity(val) {
    if (val == "minus") {
      this.productQuantity = this.productQuantity - 1;
    }
    if (val == "plus") {
      this.productQuantity = this.productQuantity + 1;
    }

    if (this.productQuantity <= 1) {
      this.productQuantity = 1;
    }

    if (this.productQuantity >= 50) {
      this.productQuantity = 50;
    }
  }

  //show & hide pincode text box
  showHidePinLocation = false;
  showHidePinLocationFun(event) {
    if (event.target.checked === true) {
      this.pincodeVal = "";
      this.showHidePinLocation = true;
      this.imageHide = false; // hide call timing status label
      this.showMidNightDeliveryInfo = false;
      // localStorage.setItem("deliveryPin", ''); // reset Pincode
    } else {
      this.showHidePinLocation = false;
    }
  }

  deliveryTime;
  maxDeliverySpeed = 0;
  egglessData = "";
  EgglessFun(type) {
    this.egglessData = type;
  }

  panControl: FormControl;
  panForm: FormGroup;
  canDeliver: FormControl;

  intialPanForm() {
    this.canDeliver = new FormControl("");
  }

  get notInDB() {
    return this.pinCodes.includes(this.panControl.value);
  }

  setPanPin(event) {
    this.canDeliver.setValue("");
    if (
      RegExp(/^[1-9][0-9]{5}$/).test(event) &&
      !this.pinCodes.some((ele) => ele == event)
    ) {
      this.panControl.setValue(event);
      this.panControl.updateValueAndValidity();
      this.resetDateTimePicker();
    } else {
      this.panControl.setValue("");
      this.panControl.updateValueAndValidity();
      this.resetDateTimePicker();
    }
  }

  resetControl(event?: any) {
    this.panControl.setValue("");
    this.panControl.updateValueAndValidity();
    this.canDeliver.setValue("");
    this.resetDateTimePicker();
  }

  deliveryPartners: any[] = [];

  checkDelivery(str: "Check" | "Change") {
    if (str == "Change") {
      this.canDeliver.setValue("");
    } else {
      let weight =
        Number(this.productQuantity) *
        Number(
          this.selectedVariant?.net > 0
            ? this.selectedVariant?.net
            : this.selectedVariant.kg
        );
      let pincode = this.panControl.value;
      this.loader.open();
      this.shiprocketService
        .getDeliveryPartners({
          weight: String(weight),
          deliveryPostcode: String(pincode),
        })
        .subscribe(
          (res) => {
            this.loader.close();
            this.deliveryPartners = res.data ?? [];
            res.data.length > 0
              ? this.canDeliver.setValue("yes")
              : this.canDeliver.setValue("no");
            this.canDeliver.updateValueAndValidity();
          },
          (err) => {
            this.loader.close();
            this.canDeliver.setValue("no");
            this.canDeliver.updateValueAndValidity();
            sweetAlert("error", "No delivery partners available for this pincode");
            this.deliveryPartners = [];
          }
        );
    }

    /**
     *   kg: new FormControl(this.productPrice.selectKg),
        flavour: new FormControl(this.flavourSelect),
        net: new FormControl(Number(this.selectedVariant?.net) ?? 0),
        gross: new FormControl(Number(this.selectedVariant?.gross) ?? 0),
     */
  }

  resetDateTimePicker() {
    console.log(this.deliveryCuttofTime, "resetDateTimePicker function");

    var gtm = this;

    setTimeout(() => {
      var dtp = $("#datetimepicker2").datetimepicker({
        defaultDate: false,
        format: "d-M-Y H:i",
        step: 5,
        defaultTime: false,
        onShow: function (currentDateTime) {
       
          const validDate = gtm.deliveryDateService.validDate(
            gtm.deliveryCuttofTime
          );
          const validMinTime = gtm.deliveryDateService.validateTime(
            gtm.deliveryCuttofTime,
            moment(validDate).format("YYYY-MM-DD"),
            gtm.showMidNightDeliveryInfo
          );
          console.log(currentDateTime, "onShow",validDate,'validDate');
          this.setOptions({
            minDate: moment(validDate).format("YYYY-MM-DD"),
            minTime: validMinTime.minTime,
            maxTime: validMinTime.maxTime,
          });
        },

        onSelectDate: function (ct, $i) {
          console.log(ct, "onSelectDate");
          const validDate = gtm.deliveryDateService.validDate(
            gtm.deliveryCuttofTime
          );
          const validMinTime = gtm.deliveryDateService.validateTime(
            gtm.deliveryCuttofTime,
            moment(ct).format("YYYY-MM-DD"),
            gtm.showMidNightDeliveryInfo
          );
          this.setOptions({
            minDate: moment(validDate).format("YYYY-MM-DD"),
            minTime: validMinTime.minTime,
            maxTime: validMinTime.maxTime,
          });
        },
        onSelectTime: function (ct, $i) {
          const validDate = gtm.deliveryDateService.validDate(
            gtm.deliveryCuttofTime
          );
          const validMinTime = gtm.deliveryDateService.validateTime(
            gtm.deliveryCuttofTime,
            moment(ct).format("YYYY-MM-DD"),
            gtm.showMidNightDeliveryInfo
          );
          this.setOptions({
            minDate: moment(validDate).format("YYYY-MM-DD"),
            minTime: validMinTime.minTime,
            maxTime: validMinTime.maxTime,
          });
        },
        onClose: function (ct, $i) {
          if (
            gtm.deliveryDateService.validateDeliveryTime(
              gtm.deliveryCuttofTime?.backingTime,
              ct
            )
          ) {
            console.log(
              "its ok now",
              ct,
              gtm.deliveryCuttofTime?.backingTime,
              gtm.deliveryDateService.validateDeliveryTime(
                gtm.deliveryCuttofTime?.bakingTime,
                ct
              )
            );
          } else {
            $($i).val("");
          }
        },
      });
    }, 500);
  }

  resetDateTimePicker2() {
    let fromTime = this.deliveryCuttofTime?.fromTime;
    let toTime = this.deliveryCuttofTime?.toTime;
    let needToday: boolean = this.deliveryCuttofTime?.backingTime > 8 ? false : true;
    let days = Number(Math.ceil(this.deliveryCuttofTime?.backingTime / 24));
    let nextDay = new Date().setDate(new Date().getDate() + days);
    // datetimepicker3

    var $this = this;
    setTimeout(() => {
      var dtp = $("#datetimepicker2").datetimepicker({
        defaultDate: false,
        minDate: needToday == true ? new Date() : nextDay,
        minTime: fromTime,
        maxTime: toTime,
        // format: "d/M/Y H:i",
        format: "d-M-Y H:i",
        step: 5,
        onSelectDate: function (ct, $i) {
          console.info("Fourth", "onSelectDate-method");
          console.log(ct, "this is the current date and time ");

          $this.BookedData = moment(ct).format("DD-MM-YYYY");
          $this.currentDate = moment(new Date()).format("DD-MM-YYYY");
          if (!checkValidDeliveryDate(ct)) {
            throwAlert("Delivery date cannot be the past date!");
            $($i).val("");
          }
        },
        onSelectTime: function (ct, $i) {
          console.info("third", "onSelectTime-method");
          console.log(ct, "this is the current date and time ");

          let selectedTimeStr = moment(ct).format("HH:mm");
          $this.BookedData = moment(ct).format("DD-MM-YYYY");
          let result: any = checkValidDeliveryTime(
            ct,
            selectedTimeStr,
            $this.deliveryCuttofTime
          );
          if (result?.res == true) {
            return;
          } else {
            throwAlert(result.message);
            $($i).val("");
          }
        },
        onChangeDateTime: function (currentDateTime) {
          console.info("Second", "onChangeDateTime-method");
          console.log(currentDateTime, "this is the current date and time ");
          if (
            checkTimeForDate($this.deliveryCuttofTime?.backingTime, currentDateTime)
          ) {
            this.setOptions({
              minTime: fromTime,
              maxTime: toTime,
            });
          } else {
            this.setOptions({
              minTime: moment(new Date()).format("HH:mm"),
              maxTime: toTime,
            });
          }
        },
        onShow: function (currentDateTime) {
          console.info("First", "onShow-method");
          console.log(currentDateTime, "this is the current date and time ");
          this.setOptions({
            minTime: "20:25",
            maxTime: toTime,
          });
          return;
          if (
            checkTimeForDate($this.deliveryCuttofTime?.backingTime, currentDateTime)
          ) {
            this.setOptions({
              minTime: "20:25",
              maxTime: toTime,
            });
          } else {
            this.setOptions({
              minTime: moment(new Date()).format("HH:mm"),
              maxTime: toTime,
            });
          }
        },
      });
      // dtp.maxTime = this.deliveryCuttofTime?.toTime ? addMinutes(new Date(moment(`01-01-2021 ${this.deliveryCuttofTime?.toTime}:00`).format('YYYY-MM-DD HH:mm:ss')), 15) : "23:59";
      dtp.minTime = $this.deliveryCuttofTime?.fromTime
        ? format(
            addHours(
              new Date(
                moment(`01-01-2021 ${$this.deliveryCuttofTime?.fromTime}:00`).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              ),
              $this.deliveryCuttofTime?.backingTime
            ),
            "HH:mm"
          )
        : "00:00";
    });
  }

  //add data to cart
  addProduct(type) {
    if (this.loginIN != 1) {
      this.memoryService.setMemory({
        functionName: "addProduct",
        params: type,
      });
      $("#loginSignup").modal("show");
      return;
    }

    let value = this.productForm.value;
    console.log(value, "form value");

    if (value?.price <= 0) {
      sweetAlert("error", "Variant you have selected is not available");
      return;
    }

    if (this.product.sku.includes("GIFT") ? false : !checkNull(value?.cakeType)) {
      sweetAlert("error", "Choose Cake's preference Egg or Eggless!");
      return;
    }

    // if (
    //   !checkNull(value?.anniversary?.couponName)
    //     ? false
    //     : !checkNull(value?.anniversaryDate)
    // ) {
    //   sweetAlert(
    //     "error",
    //     `Kindly select the  ${value?.anniversary?.couponName} date`
    //   );
    //   return;
    // }
    if (this.panControl.valid) {
      if (this.canDeliver.value == "yes") {
        this.deliveryPin = this.panControl.value;
      } else {
        sweetAlert(
          "error",
          !checkNull(this.canDeliver.value)
            ? "Kindly check the delivery availability to this area"
            : "Delivery is not available to this area."
        );
        return;
      }
    }

    if (!checkNull(this.deliveryPin) && this.showHidePinLocation == false) {
      sweetAlert("error", "Add Delivery Pincode");
      return;
    }

    this.canProceed(type);
    return;
    this.userService.setDeliveryType().subscribe((res1) => {
      if (
        (res1 == "hasDoor" && this.showHidePinLocation == false) ||
        (res1 == "hasStore" && this.showHidePinLocation == true) ||
        res1 == "noOrders"
      ) {
        this.canProceed(type);
      } else if (res1 == "error") {
        sweetAlert("error", "Try again");
      } else {
        Swal.fire({
          title: "<strong style='color:#ff9966'>Warning</strong>",
          // style="color:#fc98b2"
          html: ` <b ></span>You can't proceed further with this order. Since you have <span style="color:#ff9966">${
            res1 == "hasDoor" ? "home delivery" : "store pickup"
          }</span> in your cart.
          </b>
          <hr>
          <b>Note</b> : <b>You can only place orders with  ${
            res1 != "hasDoor" ? "store pickup" : "home delivery"
          }</b> 
          `,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Go to cart <i class="fa fa-shopping-cart" aria-hidden="true"></i>`,
          allowOutsideClick: true,
          confirmButtonColor: "#fc98b2",
          cancelButtonColor: "#fc98b2",
          cancelButtonText: 'Okay <i class="fa fa-check" aria-hidden="true"></i>',
        }).then((result: any) => {
          if (result.isConfirmed) {
            this.router.navigate(["/checkout"]);
          }
        });
      }
    });
  }

  canProceed(type) {
    this.loader.open();
    this.userService.setPanCounts().subscribe(
      (res) => {
        this.loader.close();
        if (res == "error") {
        } else {
          const isPan = this.panControl.valid && checkNull(this.panControl.value);
          if (
            (isPan && (res == "hasPan" || res == "zeroLength")) ||
            (!isPan && (res == "hasnoPan" || res == "zeroLength"))
          ) {
            if (isPan) {
              if (this.canDeliver.value == "yes") {
                this.addPanIndia(type);
              } else {
                sweetAlert(
                  "error",
                  !checkNull(this.canDeliver.value)
                    ? "Kindly check the product availability in this area"
                    : "Currently out of stock in this area."
                );
              }
            } else {
              this.addcommon(type);
            }
          } else {
            Swal.fire({
              title: "<strong style='color:#ff9966'>Warning</strong>",
              // style="color:#fc98b2"
              html: ` <b ></span>You can't proceed further with this order. Since you have <span style="color:#ff9966">${
                res == "hasPan" ? "PAN India delivery" : "local delivery"
              }</span> in your cart.
            </b>
            <hr>
            <b>Note</b> : <b>You can only place orders with  ${
              isPan ? "local deliverables" : "PAN India deliverables"
            }</b> 
            `,
              // icon: "warning",
              showCancelButton: true,
              confirmButtonText: `Go to cart <i class="fa fa-shopping-cart" aria-hidden="true"></i>`,
              allowOutsideClick: true,
              confirmButtonColor: "#fc98b2",
              cancelButtonColor: "#fc98b2",
              cancelButtonText:
                'Okay <i class="fa fa-check" aria-hidden="true"></i>',
            }).then((result: any) => {
              if (result.isConfirmed) {
                this.router.navigate(["/checkout"]);
              }
            });
          }
        }
      },
      (err) => {
        this.loader.close();
      }
    );
  }

  addcommon(type) {
    let value = this.productForm.value;

    this.deliveryPin =
      String(localStorage.getItem("deliveryPin")).trim() != ""
        ? JSON.parse(localStorage.getItem("deliveryPin"))
        : null;
    if (!this.deliveryPin && this.showHidePinLocation == false) {
      sweetAlert("error", "Add Delivery Pincode");
      return;
    }

    this.deliveryTime = $("#datetimepicker2").val();
    if (!this.deliveryTime) {
      sweetAlert("error", "Add Delivery Time");
      return;
    }

    console.log(
      this.product.sku.includes("GIFT"),
      !checkNull(value?.cakeType),
      value?.cakeType
    );

    if (this.product.sku.includes("GIFT") ? false : !checkNull(value?.cakeType)) {
      sweetAlert("error", "Choose Cake's preference Egg or Eggless!");
      return;
    }

    let selectedTimeStr = moment(this.deliveryTime).format("HH:mm");
    let result: any = checkValidDeliveryTime(
      this.deliveryTime,
      selectedTimeStr,
      this.deliveryCuttofTime
    );
    if (result?.res != true) {
      sweetAlert("error", result.message);
      $("#datetimepicker2").val("");
      return;
    }

    //alert minimum order quantity
    if (this.ProductDetails.minimumOrder != 1) {
      if (this.productQuantity < this.ProductDetails.minimumOrder) {
        sweetAlert(
          "error",
          "Minimum Order Quantity is " + this.ProductDetails.minimumOrder
        );
        return;
      }
    }

    if (this.showHidePinLocation == true) {
      var store = true;
      var door = false;
    } else {
      var store = false;
      var door = true;
    }

    if (this.productQuantity) {
    } else {
      this.productQuantity = 1;
    }

    //add to cart form
    this.form = this.formBuilder.group({
      productId: [this.productId],
      sku: [this.ProductDetails.sku],
      price: [parseInt(this.productPrice.finalAmount)],
      quantity: [this.productQuantity],
      pricing: new FormGroup({
        kg: new FormControl(value?.weight ?? ""),
        flavour: new FormControl(value?.flavour ?? ""),
        net: new FormControl(Number(value?.netWeight ?? 0) ?? 0),
        gross: new FormControl(Number(value?.grossWeight ?? 0) ?? 0),
      }),
      attribute: new FormGroup({
        message: new FormControl(this.cakeText),
        randomNumber: new FormControl(Math.floor(100000 + Math.random() * 900000)),
        cartType: new FormControl("main"),
        deliveryIn: new FormControl(this.deliveryTime),
        deliveryId: new FormControl(this.deliveryPin?.deliveryId),
        door: new FormControl(door),
        store: new FormControl(store),
        eggType: new FormControl(value?.cakeType == "1" ? "egg" : "eggless"),
        midNightDeliveryCharge: new FormControl(""),
        midDelivery: new FormControl(this.checkIsMidDel()),
        fromTime: new FormControl(this.deliveryCuttofTime?.fromTime),
        toTime: new FormControl(this.deliveryCuttofTime?.toTime),
        cutOfTime: new FormControl(this.deliveryCuttofTime?.cutOffTime),
        backingTime: new FormControl(this.deliveryCuttofTime?.backingTime),
        isAnniversary: new FormControl(value?.anniversary ?? ""),
        anniversaryDate: new FormControl(
          moment(value?.anniversaryDate).format("YYYY-MM-DD")
        ),
      }),
    });

    if (this.ProductDetails?.isPanIndiaProduct) {
      this.submitPanAsLocal(this.form.value);
      return;
    }

    //add product & card data to service
    this.addCartService.setCart(this.form.value);
    let category: any;
    this.breadcrumb.forEach((element, index) => {
      if (index) {
        category = element.categoryName;
      } else {
        category = +"/" + element.categoryName;
      }
    });
    this.analyticsService.addToCart({
      currency: "INR",
      items: [
        {
          id: this.form.value.productId,
          name: this.Finaldata.productName,
          list_name: "My Cart",
          brand: "Puppy's",
          category: this.Finaldata.categoryName,
          variant: this.flavourSelect,
          quantity: this.productQuantity,
          price:
            Number(this.productPrice.finalAmount) / Number(this.productQuantity),
        },
      ],
    });
  }

  addPanIndia(type) {
    let value = this.productForm.value;
    this.deliveryPin = this.panControl.valid ? this.panControl.value : "";

    if (!checkNull(this.deliveryPin) && this.showHidePinLocation == false) {
      sweetAlert("error", "Add Delivery Pincode");
      return;
    }
    this.deliveryTime = "";

    if (this.product.sku.includes("GIFT") ? false : !checkNull(value?.cakeType)) {
      sweetAlert("error", "Choose Cake's preference Egg or Eggless!");
      return;
    }
    //alert minimum order quantity
    if (this.ProductDetails.minimumOrder != 1) {
      if (this.productQuantity < this.ProductDetails.minimumOrder) {
        sweetAlert(
          "error",
          "Minimum Order Quantity is " + this.ProductDetails.minimumOrder
        );
        return;
      }
    }

    if (this.showHidePinLocation == true) {
      var store = true;
      var door = false;
    } else {
      var store = false;
      var door = true;
    }

    if (this.productQuantity) {
    } else {
      this.productQuantity = 1;
    }

    //add to cart form
    this.form = this.formBuilder.group({
      productId: [this.productId],
      sku: [this.ProductDetails.sku],
      price: [parseInt(this.productPrice.finalAmount)],
      quantity: [this.productQuantity],
      pricing: new FormGroup({
        kg: new FormControl(value?.weight ?? ""),
        flavour: new FormControl(value?.flavour ?? ""),
        net: new FormControl(Number(value?.netWeight ?? 0) ?? 0),
        gross: new FormControl(Number(value?.grossWeight ?? 0) ?? 0),
      }),
      isPanIndia: new FormControl(true),
      attribute: new FormGroup({
        message: new FormControl(this.cakeText),
        randomNumber: new FormControl(Math.floor(100000 + Math.random() * 900000)),
        cartType: new FormControl("main"),
        deliveryIn: new FormControl(""),
        deliveryId: new FormControl(this.deliveryPin),
        door: new FormControl(door),
        store: new FormControl(store),
        eggType: new FormControl(this.egglessData),
        midNightDeliveryCharge: new FormControl(""),
        midDelivery: new FormControl(""),
        fromTime: new FormControl(""),
        toTime: new FormControl(""),
        cutOfTime: new FormControl(""),
        backingTime: new FormControl(""),
        netWeight: new FormControl(this.selectedVariant?.net * this.productQuantity),
        isAnniversary: new FormControl(value?.anniversary ?? false),
        anniversaryDate: new FormControl(
          moment(value?.anniversaryDate).format("YYYY-MM-DD")
        ),
      }),
    });

    //add product & card data to service
    // this.addCartService.setCart(this.form.value);
    this.submitPanProduct(this.form.value);
    let category: any;
    this.breadcrumb.forEach((element, index) => {
      if (index) {
        category = element.categoryName;
      } else {
        category = +"/" + element.categoryName;
      }
    });
  }

  submitPanAsLocal(data) {
    let cartData: any = [data];
    let deliveryPin = cartData[0].attribute;
    //set product if main product or gift product
    cartData.map((x) => {
      if (x.attribute.cartType == "main") {
        var cartType = "main";
        var eggType = cartData[0].attribute.eggType;
      } else {
        var cartType = "sub";
        var eggType = null;
      }

      x.attribute = {
        isPanIndia: true,
        message: x.attribute.message,
        deliveryIn: cartData[0].attribute.deliveryIn,
        door: cartData[0].attribute.door,
        store: cartData[0].attribute.store,
        deliveryId: deliveryPin?.deliveryId,
        pinCode: deliveryPin?.pincode ?? "",
        addressId: null,
        storeAddress: null,
        randomNumber: cartData[0].attribute.randomNumber,
        cartType: cartType,
        eggType: eggType,
        midDelivery: cartData[0].attribute.midDelivery,
        fromTime: cartData[0].attribute.fromTime,
        backingTime: cartData[0].attribute?.backingTime,
        toTime: cartData[0].attribute.toTime,
        cutOfTime: cartData[0].attribute.cutOfTime,
        isAnniversary: cartData[0].attribute.isAnniversary ?? false,
        anniversaryDate: cartData[0].attribute.anniversaryDate,
        anniversaryTypeId: cartData[0].attribute.isAnniversary?.couponType,
      };
    });

    this.loading = true;
    this.orderId = localStorage.getItem("orderId");
    //api to add all product to cart
    this.http
      .post("/order/" + (this.orderId ? this.orderId : 0) + "/lineItem", cartData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.addCartService.cartValue.next("SomeValue");
          sweetAlert("success", "Added to Cart");
          this.userService.setCartItem();
          this.redirectCheckout();
        },
        (error) => {
          this.loading = false;
          this.userService.setCartItem();
          sweetAlert("error", error.message ?? "try again");
        }
      );
  }

  submitPanProduct(data: any) {
    let cartData: any = [data];
    let deliveryPin = cartData[0].attribute?.deliveryId;
    //set product if main product or gift product
    let addonProducts = [];
    cartData.map((x) => {
      if (x.attribute.cartType == "main") {
        var cartType = "main";
        var eggType = cartData[0].attribute.eggType;
      } else {
        var cartType = "sub";
        var eggType = null;
      }

      x.attribute = {
        isPanIndia: true,
        message: x.attribute.message,
        deliveryIn: "",
        door: cartData[0].attribute.door,
        store: cartData[0].attribute.store,
        deliveryId: 0,
        pinCode: deliveryPin,
        addressId: null,
        storeAddress: null,
        randomNumber: cartData[0].attribute.randomNumber,
        cartType: cartType,
        eggType: eggType,
        midDelivery: false,
        fromTime: "",
        backingTime: "",
        toTime: "",
        cutOfTime: "",
        isAnniversary: cartData[0].attribute.isAnniversary ?? false,
        anniversaryDate: cartData[0].attribute.anniversaryDate,
        anniversaryTypeId: cartData[0].attribute.isAnniversary?.couponType,
      };
    });
    this.loading = true;
    this.orderId = localStorage.getItem("orderId");
    //api to add all product to cart
    this.http
      .post("/order/" + (this.orderId ? this.orderId : 0) + "/lineItem", cartData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.addCartService.cartValue.next("SomeValue");
          sweetAlert("success", "Added to Cart");
          this.userService.setCartItem();
          this.redirectCheckout();
        },
        (error) => {
          this.loading = false;
          this.userService.setCartItem();
          sweetAlert("error", error.message ?? "try again");
        }
      );
  }

  redirectCheckout() {
    $("#addonsPopupModal").modal("hide");
    this.router.navigate(["/checkout"]);
  }

  //Set cake text
  cakeText = "";
  textCake(val) {
    this.cakeText = val;
  }
  //////////////////////////////End Add to Cart Section/////////////////////////////////
  ////////////////////////////Pincode Selection Section///////////////////////////////////////////

  onChangePinCodeSearch(val) {
    this.imageHide = false;
    this.showMidNightDeliveryInfo = false;
    this.KeyWordLength = val.length;
    if (val.length > 0) this.searchPinCodeData(val);

    if (this.productData?.content?.isPanIndiaProduct) {
    }
  }

  localPincode = "";
  selectPinCode(val) {
    this.localPincode = val;
    this.selectOneCity = val;
    this.resetDateTimePicker();
    if (val.city) {
      this.selectCity = val;
      if (this.selectCity.enablemidDelivery == true) {
        this.showMidNightDeliveryInfo = true;
        this.isMidnightDelivery = true;
        this.midNIghtDeliveryCharge = this.selectCity.middeliveryCharge;
      } else {
        this.isMidnightDelivery = false;
      }
      if (this.selectCity == null) {
        this.showMidNightDeliveryInfo = false;
      }
      this.resetDatePicker(); // reset date selection
      sweetAlert("success", "Delivery  pincode added");
      localStorage.setItem("deliveryPin", JSON.stringify(val));
      this.pincodeVal = val.city + "" + val.pincode;
      this.addCartService.setPincode("someValue");
    }
  }

  resetDatePicker() {
    $("#datetimepicker2").val("");
  }

  clearPinCode() {
    this.imageHide = false;
    this.showMidNightDeliveryInfo = false;
    this.resetDateTimePicker(); // reset date selection
    // localStorage.setItem("deliveryPin", ""); // reset Pincode
    this.deliveryPin = "";
    this.pincodeVal = "";
  }

  hasPincode(val) {
    return this.pinCodes.some((ele) => this.pincodeVal.includes(ele));
  }

  searchPinCodeData(val) {
    let data = {
      value: val,
    };
    this.http.post("/user/delivery", data).subscribe(
      (data: any) => {
        this.DateElement = data;
        if (this.DateElement.length === 0 && this.KeyWordLength == 6) {
          this.imageHide = true;
        }
        if (this.DateElement.length > 0) {
          this.imageHide = false;
        }

        this.pinCodeData = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.pinCodeData.push({
              ...element,
              searchKey: element.city + " - " + element.pincode,
            });
          }
        }
      },
      (error) => {}
    );
  }

  ////////////////////////////End Pincode Selection Section///////////////////////////////////////////

  ////////////////////////////product image slider////////////////////////////////////////////
  getBannerImage(val, productImages) {
    const word = "mp4";

    this.galleryImages = [];
    this.galleryImages.push({
      thump: productImages[0],
      source: this.imgPth + productImages[0],
      type: "image",
    });

    for (const key in val) {
      if (Object.prototype.hasOwnProperty.call(val, key)) {
        const element = val[key];

        this.galleryImages.push({
          thump: element.includes(word)
            ? this.imgPth + element
            : this.imgPth + element,
          source: this.imgPth + element,
          type: element.includes(word) ? "video" : "image",
        });
      }
    }
    this.galleryImages = this.galleryImages.filter(
      (x) => x.source != "" && x.thump != ""
    );

    this.preview = this.galleryImages[0];
    this.currentView = 0;

    $("#owl-trending").trigger("destroy.owl.carousel");
    setTimeout(() => {
      this.refreshSlider();
    }, 200);
  }
  async capture(blob) {}

  imagePreview(payload, index) {
    this.preview = payload;
    this.currentView = index;
    $(`.owl-dot:nth(${index})`).trigger("click");
  }

  prev() {
    if (this.currentView == 0) {
      this.preview = this.galleryImages[this.galleryImages.length - 1];
      this.currentView = this.galleryImages.length - 1;
      this.selectedItem = this.currentView;
    } else {
      this.currentView = this.currentView - 1;
      this.preview = this.galleryImages[this.currentView];
      this.selectedItem = this.currentView;
    }
  }

  next() {
    let CheckPreview = this.galleryImages[this.currentView + 1];
    if (CheckPreview != undefined) {
      this.currentView = this.currentView + 1;
      this.preview = this.galleryImages[this.currentView];
      this.selectedItem = this.currentView;
    } else {
      this.currentView = 0;
      this.preview = this.galleryImages[0];
      this.selectedItem = this.currentView;
    }
  }

  ////////////////////////////End product image slider////////////////////////////////////////////

  ////////////////////////////////wishlist section ////////////////////////////////////////////
  public like: Boolean = false;
  wishListData;
  //get Current User Wishlist
  getWishList() {
    if (this.loginIN == 1) {
      this.http.get("/user/wishlist").subscribe(
        (data) => {
          this.wishListData = data;
          if (
            this.wishListData.content.filter(
              (s) => s.productID == this.ProductDetails.productId
            ).length >= 1
          ) {
            this.like = true;
          } else {
            this.like = false;
          }
        },
        (error) => {}
      );
    }
  }

  likeData;
  //Add or remove product in current user wishlist
  whishlists(id,e) {
    if (this.loginIN != 1) {
      this.memoryService.setMemory({
        functionName: "whishlists",
        params: {p1:id,p2:e},
        component: "hitwish",
        // date: Date.now(),
      });
      $("#loginSignup").modal("show");
      return;
    }
    let addWishListData: any;
    this.loading = true;
    this.http.post(`/user/wishlist/` + this.wishListId, "").subscribe(
      (data) => {
        this.loading = false;
        this.likeData = data;
        this.addCartService.setWishList("Refresh Wishlist");
        this.userService.setWishlist();
        if (
          this.likeData?.status == 1034 ||
          this.likeData?.message == "Wishlist Added "
        ) {
          this.like = true;
          let category: any;
          this.breadcrumb.forEach((element, index) => {
            if (index) {
              category = element.categoryName;
            } else {
              category = +"/" + element.categoryName;
            }
          });
          addWishListData = {
            id: this.productId,
            name: this.Finaldata.productName,
            brand: "Puppy's",
            category:
              this.breadcrumb.length <= 0
                ? this.Finaldata.categoryName
                : category + "/" + this.Finaldata.categoryName,
            quantity: this.productQuantity,
            price: this.productPrice.finalAmount,
          };
        }
        if (
          this.likeData?.status == 1036 ||
          this.likeData?.message == "Wishlist removed "
        ) {
          this.like = false;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
    if (
      this.likeData?.status == 1034 ||
      this.likeData?.message == "Wishlist Added "
    ) {
      this.analyticsService.addToWishlist({
        currency: "INR",
        items: addWishListData,
      });
    }
  }
  ////////////////////////////////End wishlist section ////////////////////////////////////////////

  //open & close product Description & text Area
  async openAccordion(e) {
    var $current = $(e).find(".accordion-arrow");
    $current.toggleClass("fa-angle-up fa-angle-down");
  }
  onKeyUp(event: any): void {
    this.numberOfCharacters1 = event.target.value.length;
    if (this.numberOfCharacters1 > this.maxNumberOfCharacters) {
      event.target.value = event.target.value.slice(0, this.maxNumberOfCharacters);
      this.numberOfCharacters1 = this.maxNumberOfCharacters;
    }
  }

  // rating
  onFaoRate(event: any) {}

  checkFun() {
    let data = this.Finaldata?.productDetails;
    let element = document.getElementById("membershipRadios22");
    if (data?.egg == true && data?.eggless == true) {
    }
  }

  //refresh slider

  refreshSlider() {
    $("#owl-trending").owlCarousel({
      items: 1,
      dots: true,
      nav: true,
      loop: false,
      touchDrag: true,
      pullDrag: true,
      autoplay: false,
      autoplayTimeout: 9000,
      margin: 20,

      navText: [
        "<i class='fa fa-chevron-left'></i>",
        "<i class='fa fa-chevron-right'></i>",
      ],
    });
    var $this = this;
    var owl = $("#owl-trending");
    owl.on("changed.owl.carousel", function (e) {
      $this.currentView = e.relatedTarget.current();
      $this.selectedItem = $this.currentView;
      $this.preview = $this.galleryImages?.filter(
        (res, inx) => inx == this.selectedItem
      )[0];
    });
  }
}
