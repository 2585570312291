<app-loader *ngIf="loading"></app-loader>

<section id="details" class="pt-4">
    <!-- top circle border -->
    <div class="top-rounded-border"></div>

    <!--breadcrumb-->
    <div *ngIf="!noProduct" class="container-fluid one px-5">

        <div class="d-flex align-items-center justify-content-between justify_end">

            <ngx-skeleton-loader *ngIf="showLoader" [theme]="{ height: '40px', width: '250px', 'margin-bottom': '0' }"
                count="1" appearance="line">
            </ngx-skeleton-loader>
            <ol *ngIf="!showLoader" class="breadcrumb px-0">
                <li class="breadcrumb-item">
                    <a href="#"> <i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
                </li>
                <li class="breadcrumb-item" *ngFor="let item of breadcrumb">
                    <a routerLink="/category/{{ item.categoryId }}">{{ item.categoryName }}
                    </a>
                </li>
                <li class="breadcrumb-item active">{{ ProductDetails?.productName }}</li>
            </ol>

            <aside *ngIf="productData?.content?.isPanIndiaProduct" class="ribbon3">
                PAN INDIA
            </aside>
        </div>

    </div>
    <div *ngIf="!noProduct" class="container-fluid px-5 py-4">
        <div class="row product">
            <!--=========================Left Section Start===========================================-->
            <div class="col-lg-6">
                <!--skeleton loader Start-->
                <section *ngIf="showLoader" id="fancy">
                    <div class="row">
                        <div class="thumbs col-lg-3">
                            <ngx-skeleton-loader [theme]="{
                  height: '100px',
                  width: '100%',
                  'margin-bottom': '0',
                  'border-radius': '8px'
                }" count="3" appearance="line">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-lg-9 main">
                            <div class="card">
                                <ngx-skeleton-loader [theme]="{
                    height: '350px',
                    width: '100%',
                    'margin-bottom': '0',
                    'border-radius': '8px'
                  }" count="1" appearance="line">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="row action-btn">
                                <div class="col-lg-6 mt-3">
                                    <ngx-skeleton-loader [theme]="{
                      height: '55px',
                      width: '100%',
                      'margin-bottom': '0',
                      'border-radius': '35px'
                    }" count="1" appearance="line">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="col-lg-6 mt-3">
                                    <ngx-skeleton-loader [theme]="{
                      height: '55px',
                      width: '100%',
                      'margin-bottom': '0',
                      'border-radius': '35px'
                    }" count="1" appearance="line">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!--skeleton loader End-->
                <!-------------------image & video Section----------------------------->
                <section id="fancy">
                    <div class="row">
                        <div class="thumbs col-lg-3 col-sm-3">
                            <ng-container *ngIf="galleryImages.length > 1">
                                <ng-container *ngFor="
                    let galleryImages of galleryImages
                      | slice: 0:placeHolderCount;
                    let i = index
                  ">
                                    <div class="holder card" (click)="imagePreview(galleryImages, i); selectedItem = i"
                                        [ngClass]="{ active: selectedItem === i }" *ngIf="
                                        !galleryImages.source.includes('mp4') && galleryImages.thump != ''
                    ">
                                        <img *ngIf="galleryImages.thump != ''" [src]="galleryImages.thump"
                                            title="The description goes here" />
                                    </div>

                                    <div class="video" (click)="imagePreview(galleryImages, i); selectedItem = i"
                                        [ngClass]="{ active: selectedItem === i }" *ngIf="
                      galleryImages.source.includes('mp4') && galleryImages.thump != ''
                    ">
                                        <img class="play" src="assets/images/product-details/play-button.png" alt=""
                                            srcset="" style="height: 38px" />


                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="col-lg-9 col-sm-9 main">

                            <div id="owl-trending" class="owl-carousel new_navigation_carousal">
                                <div class="item" *ngFor="
                                    let galleryImages of galleryImages
                                      | slice: 0:placeHolderCount;
                                    let i = index
                                  ">
                                    <img *ngIf="!getType(galleryImages.thump)" [src]="galleryImages.thump" alt="">
                                    <video style="width: 414px;height: 395px;" *ngIf="getType(galleryImages.thump)"controls #videoPlayer>
                                        <source src="{{galleryImages.thump}}" type="video/mp4" />
                                    
                                    </video>
                                </div>

                            </div>

                            <div class="row text-center indicator">
                                <div class="col-lg-12">

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <!--=========================Left Section End===========================================-->
            <!--=========================Right Section Start===========================================-->
            <div class="col-lg-6 desc">
                <h3 *ngIf="showLoader">
                    <ngx-skeleton-loader [theme]="{ height: '40px', width: '250px', 'margin-bottom': '0' }" count="2"
                        appearance="line">
                    </ngx-skeleton-loader>
                </h3>
                <h3 *ngIf="!showLoader">
                    {{ ProductDetails?.productName }}
                    <!--------------share button--------------->
                    <i data-toggle="dropdown" *ngIf="!showLoader" class="fa fa-share-alt share-icon ml-2"
                        aria-hidden="true"></i>
                    <span class="hearts" (click)="whishlists(id,$event.target)">
                        <i *ngIf="!like" class="fa fa-heart-o"></i>
                        <i *ngIf="like == true" class="fa fa-heart"></i> </span>
                    <div class="dropdown-menu drp-menu share-sm-dropdown" aria-labelledby="dropdownMenuButton">
                        <a class="share-sm fb-btn mx-1" (click)="getShareable('facebook')">
                            <i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                        <!-- <a class="share-sm insta-btn mx-1" (click)="getShareable('insta')">
                            <i class="fa fa-instagram" aria-hidden="true"></i></a> -->
                        <!-- <a class="share-sm twitter-btn mx-1" (click)="getShareable('twitter')" target="_blank">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a> -->
                        <!-- 
                        <a class="share-sm linkedin-btn mx-1" href="https://www.linkedin.com/sharing/share-offsite/?url={{
                href
              }}" target="_blank">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a> -->
                        <a class="share-sm whatsapp-btn mx-1" (click)="getShareable('whatsapp')"
                            data-action="share/whatsapp/share" target="_blank">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                        </a>

                       
                    </div>
                   
                    
                    <!--------------share button End--------------->
                </h3>
                <!--start rating-->
                <div *ngIf="!showLoader" class="rating row">
                    <div class="col-lg-12">
                        <p>
                            <span class="review-rate">{{ ProductDetails2?.startRating }}
                            </span>
                            <!-- <bar-rating [(rate)]="rate" class="star" [max]="5" [readOnly]="true"></bar-rating> -->
                            <a class="name">
                                <span><i class="text-w fa fa-star"
                                        [ngClass]="{ 'add-start-active': startRating >= 1 }"></i></span>
                                <span><i class="text-w fa fa-star"
                                        [ngClass]="{ 'add-start-active': startRating >= 2 }"></i></span>
                                <span><i class="text-w fa fa-star"
                                        [ngClass]="{ 'add-start-active': startRating >= 3 }"></i></span>
                                <span><i class="text-w fa fa-star"
                                        [ngClass]="{ 'add-start-active': startRating >= 4 }"></i></span>
                                <span><i class="text-w fa fa-star"
                                        [ngClass]="{ 'add-start-active': startRating >= 5 }"></i></span>
                            </a>
                            <a href="#feedBack" (click)="showFeedBack($event)" *ngIf="ProductDetails2?.totalRatings > 0" class="total-review">{{
                                ProductDetails2?.totalRatings }}
                                Reviews</a>
                            <a *ngIf="ProductDetails2?.totalRatings == 0" class="total-review">{{
                                ProductDetails2?.totalRatings }}
                                Reviews</a>
                        </p>
                    </div>
                </div>
                <!--start rating End-->
                <!--pricing Section-->
                <div class="price row">
                    <div *ngIf="!showLoader" class="col-lg-12 justify-content-start">
                        <span class="amount" *ngIf="form1.price?.value>0">₹
                            {{form1.price?.value|actual:form1.discount?.value}}</span>
                        <span *ngIf="form1.discount?.value!=0">
                            <del class="mx-1">₹ {{form1.price?.value}}</del>
                            <span class="offer">
                                {{form1.discount?.value}}% off (Save
                                ₹ {{form1.price?.value|discount:form1.discount?.value}})
                            </span>
                        </span>
                        <p class="mb-0" *ngIf="form1.price?.value!=0">Inclusive of all taxes</p>
                        <p *ngIf="form1.price?.value==0" class="mb-0" style="color: red;">Selected variant is not
                            available</p>

                    </div>
                    <div *ngIf="showLoader" class="col-lg-12 justify-content-start">
                        <ngx-skeleton-loader *ngIf="showLoader"
                            [theme]="{ height: '40px', width: '250px', 'margin-bottom': '0' }" count="1"
                            appearance="line">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <!--pricing Section End-->

                <div class="flavour row">
                    <div class="col-lg-12">
                        <div class="form-group row justify-content-start">
                            <div class="px-3">
                                <ngx-skeleton-loader *ngIf="showLoader" [theme]="{
                    height: '40px',
                    width: '250px',
                    'margin-bottom': '0'
                  }" count="1" appearance="line">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>

                <form [formGroup]="productForm">
                    <!--product KG-->
                    <div class="size row">
                        <div class="col-lg-12">
                            <ul>
                                <li *ngIf="showLoader">
                                    <ngx-skeleton-loader [theme]="{
                    height: '40px',
                    width: '250px',
                    'margin-bottom': '0'
                  }" count="1" appearance="line">
                                    </ngx-skeleton-loader>
                                </li>
                                <!-- <ng-container *ngIf="!showLoader && !gift">
                                <li *ngFor="let option of loopKg; let i = index">
                                    <label class="form-check-label radio">
                                        <input type="radio" [checked]="i == 0"
                                            (change)="getPrice(option.price,option.discount, option.kg, option?.flavour)"
                                            class="form-check-input" name="maritalStatus" id="membershipRadios1"
                                            value="1" />

                                        <span class="checkround"></span>
                                    </label>
                                </li>
                            </ng-container> -->
                                <ng-container *ngIf="!showLoader && !product?.sku?.includes('GIFT')">
                                    <li class="weight-kg" *ngFor="let weight of weights; let i = index">
                                        <input formControlName="weight" (change)="variantChange('weight',weight)"
                                            [placeholder]="weight+''+(product?.isKg==true ? (weight>1 ?'Kg':'Kg') :(weight>1 ?'Nos':'No')) "
                                            type="radio" id="kg" autocomplete="new-kg" [value]="weight" />
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <!--product KG End-->
                    <div class="row align-center" *ngIf="!product?.sku?.includes('GIFT')">
                        <div class="col-lg-6 col-sm-6 flvr-t mb-15" *ngIf="isFlavour">
                            <label>Select Flavour</label>
                            <select (change)="variantChange('flavour',productForm.value.flavour)" class="form-control"
                                formControlName="flavour">
                                <option *ngFor="let flav of flavours" [value]="flav">
                                    {{ flav|titlecase }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6 size mb-0" *ngIf="!showLoader">
                            <p class="" style="margin-top: 9px;">
                                Type</p>
                            <ul>
                                <li *ngIf="!showLoader" class="row">


                                    <span class="d-flex" *ngIf="showMadeWith">
                                        <label *ngIf="showEgg&&form1?.madeWith?.value?.includes('1')"
                                            class="form-check-label radio ml-3">
                                            <input
                                                (change)="variantChange('cakeType',form1.cakeType?.value);EgglessFun(form1.cakeType?.value)"
                                                formControlName="cakeType" type="radio" id="membershipRadios22"
                                                value="1" autocomplete="new-egg" />
                                            With Egg
                                            <span class="checkround"></span>
                                        </label>

                                        <label *ngIf="showEggless&&form1?.madeWith?.value?.includes('2')"
                                            class="form-check-label radio ml-3">
                                            <input
                                                (change)="variantChange('cakeType',form1.cakeType?.value);EgglessFun(form1.cakeType?.value)"
                                                formControlName="cakeType" type="radio" id="membershipRadios22"
                                                value="2" autocomplete="new-egg" />
                                            Eggless
                                            <span class="checkround"></span>
                                        </label>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row details mt-3 ">

                        <!-- <div class="col-lg-6" *ngIf="!showLoader&&productData?.content?.isPanIndiaProduct">
                        <div>
                            <p>Enter delivery pincode</p>
                            <input #panPinode [formControl]="panControl" numbersonly
                                placeholder="Enter delivery pincode" maxlength="6" class="form-control" />
                            <div style="padding-top: 10px;
                                color: #fc98b2;" *ngIf="panControl.touched&&panControl.invalid">
                                <b>
                                    <h6 *ngIf="panControl.errors?.required">
                                        Delivery Pincode is required
                                    </h6>

                                    <h6 *ngIf="panControl.errors?.pattern&&!panControl.errors?.required">
                                        Kindly provide a valid pincode
                                    </h6>
                                </b>
                            </div>
                        </div>
                    </div> -->

                        <div class="col-lg-6 col-sm-6 mb-3" *ngIf="!showLoader">
                            <!--Auto Complete Pincode-->
                            <!-- {{pincodeVal|json}} -->
                            <div class="ng-autocomplete"
                                *ngIf="!showHidePinLocation&&(product?.deliveryType=='both'||product?.deliveryType=='home delivery'||!checkNull(product?.deliveryType))"
                                style="width: 110%; border: 1px solid #ced4da">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>

                                <ng-autocomplete [data]="pinCodeData" #selectVal [searchKeyword]="pinCodeKeyword"
                                    placeholder="Enter pincode or city" (selected)="selectPinCode($event)"
                                    (inputCleared)="clearPinCode($event);resetControl($event)" notFoundText="Not found"
                                    (inputChanged)="onChangePinCodeSearch($event);productData?.content?.isPanIndiaProduct?setPanPin($event):''"
                                    [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate1"
                                    [(ngModel)]="pincodeVal">
                                </ng-autocomplete>
                                <ng-template #itemTemplate1 let-item>
                                    <a [innerHTML]="item.searchKey"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate1 let-notFound
                                    *ngIf="!productData?.content?.isPanIndiaProduct">
                                    <div style="text-align: center;font-weight: 600;">Delivery not available</div>
                                </ng-template>

                            </div>


                            <div style="margin-top: 5px;"
                                *ngIf="panControl.valid&&checkNull(panControl.value)&&productData?.content?.isPanIndiaProduct&&checkNull(canDeliver.value)">

                                <span class="withindays" *ngIf="canDeliver.value=='yes'">
                                    Delivery is available to this area.
                                </span>

                                <span class="outofstock" *ngIf="canDeliver.value=='no'">
                                    Delivery is not available to this area.
                                </span>


                            </div>
                            <div class="need-help" *ngIf="imageHide&&!productData?.content?.isPanIndiaProduct">
                                <h6>
                                    Need Help? <i class="fa fa-phone" aria-hidden="true"></i> Call
                                    us 8124-047-576
                                </h6>
                                <p>Call timings: 09:00AM - 09:00PM. All days.</p>
                            </div>

                            <!--Auto Complete Pincode End-->

                            <!--Pick up from store button-->
                            <ng-container
                                *ngIf="(product?.deliveryType=='both'||product?.deliveryType=='store delivery')||!checkNull(product?.deliveryType)">

                                <input *ngIf="product?.deliveryType=='store delivery'" [checked]="true"
                                    [disabled]="true" type="checkbox" id="pickupStore1" (change)="resetControl($event)"
                                    (click)="showHidePinLocationFun($event)" class="storeCheckRdio ml-2 mr-1 mt-2"
                                    autocomplete="pickupStore" />


                                <input *ngIf="product?.deliveryType!='store delivery'" type="checkbox" id="pickupStore"
                                    (change)="resetControl($event)" (click)="showHidePinLocationFun($event)"
                                    class="storeCheckRdio ml-2 mr-1 mt-2" autocomplete="pickupStore" />
                                <label for="pickupStore" class="storeCheckLbl" role="button">Pick up from store</label>

                            </ng-container>
                        </div>
                        <div class="col-lg-6 col-sm-6"
                            *ngIf="panControl.valid&&checkNull(panControl.value)&&productData?.content?.isPanIndiaProduct">
                            <button class="check-btn btn" (click)="checkDelivery((canDeliver.value=='no'||!checkNull(canDeliver.value))
                            ? 'Check' :'Change')">{{(canDeliver.value=='no'||!checkNull(canDeliver.value))
                                ? 'Check' :'Change'}}</button>
                        </div>
                        <!--delive time Data & Time Picker-->
                        <div class="col-lg-6 col-sm-6  mb-3"
                            *ngIf="!showLoader&&(productData?.content?.isPanIndiaProduct ? ((panControl.valid&&checkNull(panControl.value))?false:(checkNull(pincodeVal.deliveryId)||pincodeVal.includes('-')||showHidePinLocation)) : true)">

                            <input placeholder="Enter delivery time" readonly="readonly" type="text"
                                class="form-control del-time" id="datetimepicker2" autocomplete="datetimepicker2" />

                            <div style="padding-top: 10px;
                        color: #fc98b2;"
                                *ngIf="deliveryCuttofTime?.backingTime != 0 && deliveryCuttofTime?.backingTime != '' && deliveryCuttofTime?.backingTime != null">
                                <b>
                                    <h6>
                                        A minimum of {{ deliveryCuttofTime?.backingTime }} {{
                                        deliveryCuttofTime?.backingTime >
                                        1 ? 'Hrs' : 'Hr'}} is required to bake your order

                                        <!-- We need a {{ deliveryCuttofTime?.backingTime }} {{ deliveryCuttofTime?.backingTime >
                                    1 ? 'Hrs' : 'Hr'}} to bake this cake from you ordered -->

                                    </h6>
                                </b>
                            </div>
                            <!-- <div class="input-group mb-3">
                                <input placeholder="Enter Delivery Time" readonly="readonly" type="text" class="form-control del-time"
                                id="datetimepicker2" />
                                <div class="input-group-append">
                                  <span class="input-group-text" id="datapickicon"  ><i class="fa fa-calendar"></i></span>
                                </div>
                              </div> -->


                        </div>

                        <!--skeleton loader-->
                        <div class="col-lg-12">
                            <table>
                                <tr *ngIf="showLoader">
                                    <th>
                                        <ngx-skeleton-loader [theme]="{
                      height: '40px',
                      width: '250px',
                      'margin-bottom': '0'
                    }" count="1" appearance="line">
                                        </ngx-skeleton-loader>
                                    </th>
                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                      height: '40px',
                      width: '250px',
                      'margin-bottom': '0'
                    }" count="1" appearance="line">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!--skeleton loader Ends-->
                    </div>

                    <div class="cake-text nightdelivery mb-3 row" *ngIf="!showLoader && showMidNightDeliveryInfo">

                        <p>
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>

                            Delivery charges of Rs.{{midNIghtDeliveryCharge}} applicable for deliveries between 9pm to
                            12am.

                            <!-- Mid-night delivery available. Book after 9PM to 12PM. Rs. 
                        will be added. -->
                        </p>


                    </div>

                    <div class="row" *ngIf="!productData?.content?.isPanIndiaProduct">
                        <!-- <div class="col-lg-6 col-sm-6 mb-3">
                            <div class="d-flex align-items-center">
                                <ng-container *ngFor="let data of occasionList">

                                    <input #occasionCheckbox (click)="anniversaryShow($event)"
                                        formControlName="anniversary" type="radio" [value]="data" id="anniversary_cake"
                                        class="storeCheckRdio ml-2 mr-1 mt-0" autocomplete="anniversary_cake" />
                                    <label for="anniversary_cake" class="storeCheckLbl mb-0" role="button">Check if you
                                        want
                                        {{data?.couponName}} cake</label>
                                </ng-container>
                            </div>
                            <div *ngIf="checkNull(form1?.anniversary?.value?.couponName)" (click)="resetAnniversary()"
                                class="storeCheckLbl mt-3 ml-3" style="display: flex;justify-content: flex-start;">
                                <span style="cursor: pointer;text-decoration: underline;">Reset Anniversary</span>
                            </div>
                        </div> -->
                        <!-- <div *ngIf="checkNull(form1?.anniversary?.value?.couponName)" class="col-lg-6 col-sm-6 mb-3">
                            <p>Enter {{form1?.anniversary?.value?.couponName}} Date</p>

                            <input (click)="markAsTouched('anniversaryDate')" placeholder="Enter Anniversary time"
                                readonly="readonly" type="text" class="form-control del-time" id="datetimepicker3"
                                autocomplete="datetimepicker3" />
                            <div (click)="resetAnniversary()" class="storeCheckLbl" style="display: flex;justify-content: flex-end;">
                                <span style="cursor: pointer;text-decoration: underline;">Reset Anniversary</span>
                            </div>
                        </div> -->
                    </div>




                    <!--message on cake, Egg or Eggless , Quantity fields-->
                    <div class="cake-text row mt-2">
                        <div *ngIf="!showLoader && !gift" class="col-lg-12 size ">
                            <div class="row">
                                <div *ngIf="!showLoader" class="col-lg-6 col-sm-6 productQuantity mb-5">
                                    <p>Quantity</p>
                                    <i class="fa fa-minus minus" (click)="selectQuantity('minus')"
                                        [ngClass]="{ 'disbl-minus': productQuantity === 1 }"></i>
                                    <input type="text" class="form-control" value="{{ productQuantity }}"
                                        style="padding: 3px;" autocomplete="off" />
                                    <i class="fa fa-plus plus" (click)="selectQuantity('plus')"></i>
                                </div>

                                <div class="col-lg-6">
                                    <div *ngIf="this.ProductDetails.enableWelcomeText == true ">
                                        <p>Enter Message on the cake</p>
                                        <input type="text" name="" #textOnCake (input)="textCake(textOnCake.value)"
                                            placeholder="Type your text" maxlength="30" class="form-control"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-skeleton-loader *ngIf="showLoader" [theme]="{ height: '40px', width: '250px' }" count="2"
                            appearance="line">
                        </ngx-skeleton-loader>
                    </div>
                    <!--End message on cake, Egg or Eggless , Quantity fields-->


                    <div class="row action-btn mt-2" *ngIf="product?.deliveryStatus==true">
                        <div class="col-lg-6 col-sm-6">
                            <button (click)="addProduct('addToCart')" type="button"
                                class="btn btn-outline-custom btn-lg btn-block">Add to
                                Cart</button>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <button (click)="addProduct('buyNow')" type="button"
                                class="btn btn-custom btn-lg btn-block">Buy
                                Now</button>
                        </div>
                        <!--<div class="col-lg-4 text-center">
                    </div>-->
                    </div>

                    <div class="" *ngIf="product?.deliveryStatus==false">
                        <span class="delivery-off">Currently delivery is not available for this product</span>
                    </div>
                </form>
                <!--End addToCart & buyNow Button-->
                <!--Product Description & Notes Text Area-->
                <div *ngIf="!showLoader" class="row accordian-option mt-3">
                    <div class="col-lg-12">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <h4 class="panel-title">
                                        <a role="button" data-toggle="collapse" (click)="openAccordion($event.target)"
                                            data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Product Description
                                            <i class="accordion-arrow fa fa-angle-up pull-right"></i>
                                        </a>
                                    </h4>
                                    <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel"
                                        aria-labelledby="headingOne">
                                        <div class="panel-body pb-0">
                                            <div [innerHTML]="
                          ProductDetails?.productDetails?.description
                        "></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingThree">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" (click)="openAccordion($event.target)"
                                            data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                            Notes
                                            <i class="accordion-arrow fa fa-angle-up pull-right"></i>
                                        </a>
                                    </h4>
                                    <div id="collapseThree" class="panel-collapse collapse show" role="tabpanel"
                                        aria-labelledby="headingThree">
                                        <div class="panel-body pb-0">
                                            <div [innerHTML]="ProductDetails?.productDetails?.notes"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showLoader" class="row accordian-option mt-5">
                    <div class="col-lg-12">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <h4 class="panel-title">
                                        <a role="button" data-toggle="collapse" (click)="openAccordion($event.target)"
                                            data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            <ngx-skeleton-loader *ngIf="showLoader" [theme]="{
                          height: '40px',
                          width: '250px',
                          'margin-bottom': '0'
                        }" count="1" appearance="line">
                                            </ngx-skeleton-loader>
                                            <i class="accordion-arrow fa fa-angle-up pull-right"></i>
                                        </a>
                                    </h4>
                                    <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel"
                                        aria-labelledby="headingOne">
                                        <div class="panel-body pb-0">
                                            <ngx-skeleton-loader *ngIf="showLoader"
                                                [theme]="{ height: '40px', width: '250px' }" count="1"
                                                appearance="line">
                                            </ngx-skeleton-loader>
                                            <br />
                                            <ngx-skeleton-loader *ngIf="showLoader" [theme]="{
                          height: '40px',
                          width: '250px',
                          'margin-bottom': '0'
                        }" count="1" appearance="line">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ngx-skeleton-loader *ngIf="showLoader" [theme]="{ height: '40px', width: '250px'}" count="2"
                    appearance="line"> </ngx-skeleton-loader>

            </div>

            <!--End message on cake, Egg or Eggless , Quantity fields-->




            <!--Skelrton Loader End-->
        </div>
        <!--=====================Right Section End==============================-->
    </div>

    <div *ngIf="noProduct" class="container-fluid px-5 py-4 text-center">
        <img style="width: 15%" src="assets/images/product_not_found.png" alt="" srcset="" />
    </div>
</section>

<!-- Recommended -->
<app-recommended *ngIf="recommended" [productid]="id" [Id]="categoryId"></app-recommended>

<!-- user Feed Back -->
<app-feed-back [productId]="wishListId" id="feedBack" #feedBack></app-feed-back>

<!--review page-->