import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { checkNull } from "../../product-details/date-and-time-validations";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ShipRocketService {
  constructor(private service: HttpService) {}

  getDeliveryPartners(dto: { deliveryPostcode: any; weight: any }) {
    return this.service
      .getList("/shiprocket/serviceability", dto)
      .pipe(map((res) => new Response(res)));
  }

  adaptResponse(res) {}
}

class Response {
  status;
  data;
  length;
  message;
  constructor(res: any) {
    this.status = res?.available_courier_companies?.length > 0;
    this.length = res?.available_courier_companies?.length ?? 0;
    this.data = res?.available_courier_companies
      ? res?.available_courier_companies.map((ele) => new DeliveryBy(ele))
      : [];
    this.message =
      res?.available_courier_companies?.length > 0
        ? res.recommended_by
        : "No delivery partners available";
  }
}

class DeliveryBy {
  companyId;
  companyName;
  expectedOn;
  deliveryCharge;
  constructor(data) {
    this.companyId = data?.courier_company_id;
    this.companyName = data?.courier_name;
    this.expectedOn = data?.etd;
    this.deliveryCharge = Math.round(data?.freight_charge).toFixed(2);
  }
}

export const SampleDelivery = new Response({
  available_courier_companies: [
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 6,
      city: "THIRUPARANKUNDRAM",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 25,
      courier_name: "Xpressbees 5kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 80,
      freight_charge: 210.54,
      id: 399832708,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others:
        '{"cancel_real_time": true, "prefetch_enabled": 0, "allow_postcode_auto_sync": 1}',
      pickup_availability: "0",
      pickup_performance: 5,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 210.54,
      rating: 5,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 174.24,
      rto_performance: 5,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "9.00",
      tracking_performance: 5,
      volumetric_max_weight: null,
      weight_cases: 4.5,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "Madurai",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 39,
      courier_name: "Delhivery Surface 5 Kgs",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.6,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 84,
      freight_charge: 274.84,
      id: 400408738,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true, "allow_postcode_auto_sync": 1}',
      pickup_availability: "0",
      pickup_performance: 5,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 274.84,
      rating: 4.2,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 279.72,
      rto_performance: 3.8,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "Tamil Nadu",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "9.00",
      tracking_performance: 3.3,
      volumetric_max_weight: null,
      weight_cases: 4.5,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 19,
      courier_name: "Ecom Express Surface 2kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 72,
      freight_charge: 214.4,
      id: 397993211,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 2,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true, "allow_postcode_auto_sync": 1}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 214.4,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 317.52,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "30.00",
      tracking_performance: 4.5,
      volumetric_max_weight: null,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 4,
      courier_name: "Amazon Shipping 5Kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "09:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "4",
      etd: "Dec 05, 2023",
      etd_hours: 86,
      freight_charge: 251.58,
      id: 396254644,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: false,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 251.58,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 252,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: {
        action_on: "2023-11-11 20:06:15",
        delay_remark: "Festival",
        delivery_delay_by: 2065762,
        delivery_delay_days: "1",
        delivery_delay_from: "2023-11-13",
        delivery_delay_to: "2023-11-13",
        pickup_delay_by: 2065762,
        pickup_delay_days: "1",
        pickup_delay_from: "2023-11-13",
        pickup_delay_to: "2023-11-13",
      },
      surface_max_weight: "7.00",
      tracking_performance: 4.5,
      volumetric_max_weight: null,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 0,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 146,
      courier_name: "Kerry Indev 2kg Surface",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 80,
      freight_charge: 251.84,
      id: 397299281,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 2,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 251.84,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 329.52,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "20.00",
      tracking_performance: 4.5,
      volumetric_max_weight: 20,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 171,
      courier_name: "Ekart 5Kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "10:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 82,
      freight_charge: 266.9,
      id: 400148941,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 266.9,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 174.36,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "12.00",
      tracking_performance: 4.5,
      volumetric_max_weight: 12,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "20.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 0,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 154,
      courier_name: "Kerry Indev Express",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 79,
      freight_charge: 312.1,
      id: 396123590,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: false,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 1,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 312.1,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 445.2,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: {
        action_on: "2023-11-10 15:20:05",
        delay_remark: "Festival",
        delivery_delay_by: 2065762,
        delivery_delay_days: "1",
        delivery_delay_from: "2023-11-13",
        delivery_delay_to: "2023-11-13",
        pickup_delay_by: 2065762,
        pickup_delay_days: "1",
        pickup_delay_from: "2023-11-13",
        pickup_delay_to: "2023-11-13",
      },
      surface_max_weight: "0.00",
      tracking_performance: 4.5,
      volumetric_max_weight: 20,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 0,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 69,
      courier_name: "Kerry Indev Express Surface",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 79,
      freight_charge: 316,
      id: 397295765,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 316,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 492,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "20.00",
      tracking_performance: 4.5,
      volumetric_max_weight: 20,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 170,
      courier_name: "Ekart 2Kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "10:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "4",
      etd: "Dec 05, 2023",
      etd_hours: 87,
      freight_charge: 348.8,
      id: 400133266,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 2,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 348.8,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 288.96,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "12.00",
      tracking_performance: 4.5,
      volumetric_max_weight: 12,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Not Available",
      charge_weight: 10,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 181,
      courier_name: "Amazon Shipping 10Kg",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "09:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "4",
      etd: "Dec 05, 2023",
      etd_hours: 85,
      freight_charge: 356,
      id: 396305460,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: false,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 10,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 356,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 356,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: {
        action_on: "2023-11-11 20:10:00",
        delay_remark: "Festival",
        delivery_delay_by: 2065762,
        delivery_delay_days: "1",
        delivery_delay_from: "2023-11-13",
        delivery_delay_to: "2023-11-13",
        pickup_delay_by: 2065762,
        pickup_delay_days: "1",
        pickup_delay_from: "2023-11-13",
        pickup_delay_to: "2023-11-13",
      },
      surface_max_weight: "19.00",
      tracking_performance: 4.5,
      volumetric_max_weight: null,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "7.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 48,
      courier_name: "Ekart Logistics",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "10:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 3.8,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "4",
      etd: "Dec 05, 2023",
      etd_hours: 85,
      freight_charge: 541.8,
      id: 400086241,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: false,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 1,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 5,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 541.8,
      rating: 3.9,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 541.8,
      rto_performance: 5,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "0.00",
      tracking_performance: 2,
      volumetric_max_weight: 23,
      weight_cases: 4.6,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 54,
      courier_name: "Ekart Logistics Surface",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "10:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 1,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "4",
      etd: "Dec 05, 2023",
      etd_hours: 96,
      freight_charge: 541.8,
      id: 400101916,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 5,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "On Request",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 541.8,
      rating: 3.2,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 541.8,
      rto_performance: 5,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "12.00",
      tracking_performance: 2,
      volumetric_max_weight: null,
      weight_cases: 4.5,
      zone: "z_b",
    },
    {
      air_max_weight: "0.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 14,
      courier_name: "Ecom Express Surface",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 80,
      freight_charge: 582.87,
      id: 397965630,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true, "allow_postcode_auto_sync": 1}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 582.87,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 658.44,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "30.00",
      tracking_performance: 4.5,
      volumetric_max_weight: null,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "30.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "MADURAI",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 60,
      courier_name: "Ecom Premium and ROS",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "2",
      etd: "Dec 03, 2023",
      etd_hours: 48,
      freight_charge: 602.24,
      id: 398561306,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: true,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 0,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true}',
      pickup_availability: "0",
      pickup_performance: 4.4,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 602.24,
      rating: 4.4,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 876.36,
      rto_performance: 4.4,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "TAMIL NADU",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "30.00",
      tracking_performance: 4.5,
      volumetric_max_weight: null,
      weight_cases: 4.2,
      zone: "z_b",
    },
    {
      air_max_weight: "25.00",
      assured_amount: 0,
      base_courier_id: null,
      base_weight: "",
      blocked: 0,
      call_before_delivery: "Available",
      charge_weight: 6,
      city: "Madurai",
      cod: 1,
      cod_charges: 0,
      cod_multiplier: 0,
      cost: "",
      courier_company_id: 10,
      courier_name: "Delhivery",
      courier_type: "0",
      coverage_charges: 0,
      cutoff_time: "11:00",
      delivery_boy_contact: "Not Available",
      delivery_performance: 4.5,
      description: "",
      edd: "",
      entry_tax: 0,
      estimated_delivery_days: "3",
      etd: "Dec 04, 2023",
      etd_hours: 81,
      freight_charge: 706.52,
      id: 400220152,
      is_custom_rate: 0,
      is_hyperlocal: false,
      is_international: 0,
      is_rto_address_available: true,
      is_surface: false,
      local_region: 0,
      metro: 0,
      min_weight: 0.5,
      mode: 1,
      odablock: false,
      other_charges: 0,
      others: '{"cancel_real_time": true, "allow_postcode_auto_sync": 1}',
      pickup_availability: "0",
      pickup_performance: 5,
      pickup_priority: "",
      pickup_supress_hours: 0,
      pod_available: "Instant",
      postcode: "625005",
      qc_courier: 0,
      rank: "",
      rate: 706.52,
      rating: 4.2,
      realtime_tracking: "Real Time",
      region: 2,
      rto_charges: 725.88,
      rto_performance: 2.5,
      seconds_left_for_pickup: 0,
      secure_shipment_disabled: false,
      ship_type: 1,
      state: "Tamil Nadu",
      suppress_date: "",
      suppress_text: "",
      suppression_dates: [],
      surface_max_weight: "0.00",
      tracking_performance: 4.6,
      volumetric_max_weight: null,
      weight_cases: 4.5,
      zone: "z_b",
    },
  ],
  child_courier_id: null,
  is_recommendation_enabled: 1,
  recommendation_advance_rule: 0,
  recommended_by: { id: 6, title: "Recommendation By Shiprocket" },
  recommended_courier_company_id: 25,
  shiprocket_recommended_courier_id: 25,
});
