import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { sweetAlert } from "../../function/toaster";
declare var $: any;
import { HttpService } from "../../../services/http/http.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.less"],
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild("forgotPasswordModal") forgotPasswordModal: ElementRef;
  constructor(
    public http: HttpService,
    public analyticsService:GoogleAnalyticsService,) {}
  loading = false;
  resetEmail;
  ngOnInit(): void {
    this.analyticsService.pageView("View Forgot Password",`Forgot Password Form`,'Popup');
  }

  //submit form
  errorMsg = "";
  submit() {
    $("#resetPassword").modal("show");
    this.errorMsg = "";
    let regex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
    if (!regex.test(this.resetEmail)) {
      this.errorMsg = "Invalid Email";
      return;
    }
    this.analyticsService.commonEvents('Onclick',{'event_category': `Forgot Password Send to ${this.resetEmail} this Email`,
    'event_label': `Forgot Password Send to ${this.resetEmail} this Email`,
    'value': `Forgot Password Send to ${this.resetEmail} this Email`});
    this.loading = true;
    this.http
      .post(
        "/user/password/forget",
        JSON.parse('{ "email" : "' + this.resetEmail + '" }')
      )
      .subscribe(
        (data) => {
          sweetAlert("success", "Password Reset Link Sent Successfully");
          this.loading = false;
          $("#resetPassword").modal("show");
        },
        (error) => {
          sweetAlert("error", error.message);
          this.loading = false;
        }
      );
  }
}
