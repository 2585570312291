import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "../authentication/authentication.service";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";
import { WordpressConfig } from "src/app/configuration/app.constants";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && checkNull(currentUser.accessToken)) {
      switch (true) {

        // for wordpress
        case request.url.includes("/wp-json/wp/v2/posts"):
          {
            console.log('Basic ' + btoa(`${WordpressConfig.username}:${WordpressConfig.password}`));

            request = request.clone({
              setHeaders: {
                'Authorization': 'Basic ' + btoa(`${WordpressConfig.username}:${WordpressConfig.password}`)
              },
            });
          }
          break;
        // for serviceability
        case (!request.url.includes("shiprocket/serviceability") && !request.url.includes("user/newsletter")):
          {
            request = request.clone({
              setHeaders: {
                authorization: `Bearer ${currentUser.accessToken}`,
              },
            });
          }
          break;
        default:
          {
            request = request;
          }
          break;
      }

      // request = request.clone({
      //   setHeaders: {
      //     authorization: `Bearer ${currentUser.accessToken}`,
      //   },
      // });
    } else {
      if (request.url.includes("/wp-json/wp/v2/posts")) {
        console.log(('Basic ' + btoa(`${WordpressConfig.username}:${WordpressConfig.password}`)));

        request = request.clone({
          setHeaders: {
            'Authorization': 'Basic ' + btoa(`${WordpressConfig.username}:${WordpressConfig.password}`)
          },
        });
      }
    }
    return next.handle(request);
  }
}
