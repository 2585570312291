import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EnquiryFormComponent } from "./enquiry-form/enquiry-form.component";
import { EnquiryFormRoutingModule } from "./enquiry-form-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { SliderComponent } from "./slider/slider.component";
import { HomeModule } from "src/app/modules/home/home.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { GalleryModule } from "@ngx-gallery/core";
import { LightboxModule } from "@ngx-gallery/lightbox";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
  imports: [
    CommonModule,
    EnquiryFormRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HomeModule,
    NgxSkeletonLoaderModule,
    MatTabsModule,
    MatFormFieldModule,
    GalleryModule,
    LightboxModule,
    InfiniteScrollModule,
  ],
  declarations: [EnquiryFormComponent, SliderComponent],
})
export class EnquiryFormModule {}
