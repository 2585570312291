<app-loader *ngIf="loading"></app-loader>
<section id="profile" class="py-4">
   <!-- top circle border -->
   <div class="top-rounded-border"></div>
   <!--breadcrumb-->
   <!-- <div class="container-fluid px-5">
      <ol class="breadcrumb px-0">
         <li class="breadcrumb-item">
            <a href="#"><i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
         </li>
         <li class="breadcrumb-item active" aria-current="page">Profile</li>
      </ol>
      </div> -->
   <!--main Container & Rows-->
   <div class="container-fluid px-5">
      <div class="row">
         <!--Side menu list Start-->
         <div class="col-lg-3 bg-white br-10 p-3">
            <div class="row">
               <div class="col-lg-12 col-5 d-flex user-detail flex-wrap">
                  <div class="profile-image mb-4">
                     <img *ngIf="imgUrl!='https://api.puppysbakery.com'" (error)="handle()" src="{{ imgUrl }}"
                        class="rounded-circle" alt="" />
                     <img *ngIf="imgUrl=='https://api.puppysbakery.com'||imgUrl==''" src="{{getdata?.gender == 'male' ? '/assets/images/avatar.jpg':'/assets/images/fAvatar.png'}}"
                        class="rounded-circle" alt="" />
                     <a (click)="openImgModal()"><img src="assets/images/icons/Group44410.svg" alt="" srcset="" /></a>
                  </div>
                  <div *ngIf="getdata" class="pl-3 sm-pb-4">
                     <h4 class="">{{ getdata.firstName }} {{ getdata.lastName }}</h4>
                     <p class="" *ngIf="getdata.phoneNumber">+91 {{getdata.phoneNumber}}</p>
                     <p class="">{{getdata.email }}</p>
                  </div>
               </div>
               <div class="col-lg-12 mb-4">
                  <!--menu list-->
                  <div class="list-group">
                     <a id="profile-2" (click)="accordionOpen('profile')" class="list-group-item list-group-item-action"
                        [ngClass]="{ active: currentWindow === 'profile' }">
                        My Profile
                        <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                     <a id="address-2" (click)="accordionOpen('address')"
                        [ngClass]="{ active: currentWindow === 'address' }"
                        class="list-group-item list-group-item-action">My Addresses <i class="fa fa-angle-right"
                           aria-hidden="true"></i></a>
                     <a id="order-2" (click)="accordionOpen('order')" [ngClass]="{ active: currentWindow === 'order' }"
                        class="list-group-item list-group-item-action">My Orders <i class="fa fa-angle-right"
                           aria-hidden="true"></i></a>
                     <a id="loyalty-2" (click)="accordionOpen('loyalty')"
                        [ngClass]="{ active: currentWindow === 'loyalty' }"
                        class="list-group-item list-group-item-action">My Reward Points <i class="fa fa-angle-right"
                           aria-hidden="true"></i></a>
                     <a id="remainder-2" (click)="accordionOpen('remainder')"
                        [ngClass]="{ active: currentWindow === 'remainder' }"
                        class="list-group-item list-group-item-action">
                        My Reminder <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                  </div>
               </div>
            </div>
         </div>
         <!--Side menu list end-->
         <!--col-9 starts-->
         <div class=" col-lg-9 custom-accordion">
            <!--======================My Profile Start================================-->
            <form [formGroup]="form" autocomplete="off">
               <div id="profile-1" class="collapse" [ngClass]="{ show: currentWindow === 'profile' }">
                  <div class="card-body">
                     <h3>Personal Information</h3>
                     <div class="row mt-4">
                        <div class="col-lg-9">
                           <div class="row mb-2">
                              <div class="col-lg-5">
                                 <div class="input-group mb-4">
                                    <input type="text" class="form-control" placeholder="First name"
                                       formControlName="firstName" autocomplete="new-firstName" />
                                 </div>
                                 <div *ngIf="submitted && f.firstName.errors" class="invalid-text mt-n3">
                                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                    <div *ngIf="f.firstName.errors.minlength">Minimum 2 characters is required</div>
                                    <div *ngIf="f.firstName.errors.maxlength">Maximum characters is 25</div>
                                 </div>
                              </div>
                              <div class="col-lg-5">
                                 <div class="input-group mb-4">
                                    <input type="text" class="form-control" placeholder="Last name"
                                       formControlName="lastName" aria-label="Username" aria-describedby="basic-addon1"
                                       autocomplete="new-lastName" />
                                 </div>
                                 <div *ngIf="submitted && f.lastName.errors" class="invalid-text">
                                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                    <div *ngIf="f.lastName.errors.minlength">Minimum 5 characters is required</div>
                                 </div>
                              </div>
                           </div>
                           <div class="row justify-content-start your-gender">
                              <div class="col-md-12">
                                 <label for="id_gender" class="control-label mr-4 mright label requiredField"> Your
                                    Gender </label>
                                 <label class="radio-inline label-gender mr-4 mright ">
                                    <input type="radio" formControlName="gender" class="gender" id="id_gender_1"
                                       value="male" autocomplete="new-gender" />Male</label>
                                 <label class="radio-inline label-gender">
                                    <input type="radio" formControlName="gender" class="gender" id="id_gender_2"
                                       value="female" autocomplete="new-gender" />Female
                                 </label>
                                 <div *ngIf="submitted && f.gender.errors" class="invalid-text mt-n1">
                                    <div *ngIf="f.gender.errors.required">Gender is required</div>
                                 </div>
                              </div>
                           </div>
                           <div class="row mt-4">
                              <div class="col-lg-10">
                                 <div class="relative">
                                    <input disabled="true" placeholder="Enter DOB"  type="text" formControlName="birthdayDate"
                                    class="form-control del-time" id="datepicker2" autocomplete="datepicker2" />
                                    <i class="fa fa-calendar calender_icon" aria-hidden="true"></i>
                                 </div>
                     
                              </div>
                              
                           </div>
                           <div class="row mt-4">
                              <div class="col-sm-2 col-4">
                                 <select name="country" class="form-control">
                                    <option data-code="+91" value="ind">+91</option>
                                 </select>
                              </div>
                              <div class="col-sm-5 col-8">
                                 <input readonly type="text" name="phone[1][number]" formControlName="phoneNumber"
                                    class="ml-2 form-control" placeholder="Mobile number" disabled
                                    style="background: white" autocomplete="new-phoneNumber" />
                                 <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-text mt-n3">
                                    <br />
                                    <div *ngIf="f.phoneNumber.errors.required">Mobile Number is required</div>
                                    <div *ngIf="f.phoneNumber.errors.pattern">Enter Valid 10 digit Mobile Number</div>
                                 </div>
                              </div>
                           </div>
                           <div class="row mt-4">
                              <div class="col-sm-7">
                                 <div class="input-group">
                                    <input readonly type="text" class="form-control" placeholder="Email id"
                                       style="background: white" formControlName="email" aria-describedby="basic-addon1"
                                       autocomplete="new-email" />
                                 </div>
                              </div>
                           </div>
                           <div class="row mt-4">
                              <div class="col-sm-7">
                                 <a (click)="showChangePassword()" class="change-password">Change Password</a>
                              </div>
                           </div>
                           <div class="row mt-4 mb-5" *ngIf="changePassord">
                              <div class="col-sm-7">
                                 <div class="input-group">
                                    <input type="password" class="form-control" formControlName="oldPassword"
                                       placeholder="Old password" aria-describedby="basic-addon1"
                                       autocomplete="new-password" />
                                 </div>
                                 <div *ngIf="submitted && f.oldPassword.errors" class="invalid-text">
                                    <br />
                                    <div *ngIf="f.oldPassword.errors.minlength">Password must at least 6 characters
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-7 mt-4">
                                 <div class="input-group">
                                    <input type="password" formControlName="newPassword" class="form-control"
                                       placeholder="New password" aria-describedby="basic-addon1"
                                       autocomplete="new-password" />
                                 </div>
                                 <div *ngIf="submitted && f.newPassword.errors" class="invalid-text">
                                    <br />
                                    <div *ngIf="f.newPassword.errors.minlength">Password must at least 6 characters
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-7 mt-4">
                                 <div class="input-group">
                                    <input type="password" formControlName="confirmPassword" class="form-control"
                                       placeholder="Confirm new password" aria-describedby="basic-addon1"
                                       autocomplete="new-password" />
                                 </div>
                                 <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-text">
                                    <br />
                                    <div *ngIf="f.confirmPassword.errors.minlength">Password must at least 6 characters
                                    </div>
                                 </div>
                                 <div *ngIf="PasswordChangeError" class="invalid-text">New Password and Conform Password
                                    Must Match
                                 </div>
                              </div>
                           </div>
                           <div class="row mt-4">
                              <div class="col-sm-6">
                                 <a class="save" (click)="onSubmit()">Save</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
            <!--======================My Profile End================================-->
            <!--====================================================================-->
            <!--======================My Address Start==============================-->


            <div id="address-1" class="collapse" [ngClass]="{ show: currentWindow === 'address' }">
               <div class="card-body">
                  <h3>My Addresses</h3>
                  <div class="row mt-1" *ngIf="addressListDiv">
                     <div class="col-lg-12 mt-3" *ngFor="let item of addressList">
                        <div class="card">
                           <i (click)="editAddress(item)" class="fa fa-pencil address-edit-icon"></i>
                           <i (click)="deleteAddress(item.id)" class="fa fa-trash"
                              style="cursor: pointer; color: red; right: 25px; position: absolute; font-size: 18px"></i>
                           <h5>
                              {{ item.name }}<small *ngIf="item.phoneNumber" class="verticalLine"></small> {{
                              item.phoneNumber }}
                              <small class="verticalLine" *ngIf="item.alternativePhoneNumber"></small> {{
                              item.alternativePhoneNumber }}
                           </h5>
                           <p>
                              {{item.email}}<br *ngIf="item.email">
                              {{ item.line1 }}, {{ item.city }} <br />
                              {{ item.state }} - {{ item.pinCode }}
                           </p>
                        </div>
                     </div>
                  </div>
                  <button *ngIf="!addressFormDiv" class="add-address-btn btn-lg" (click)="showAddAddress()">Add
                     Address</button>
               </div>
               <ng-container *ngIf="addressFormDiv">
                  <form [formGroup]="addressForm" autocomplete="off">
                     <div class="card address-book mb-4" style="background: #fff">
                        <h4>Add Delivery Address</h4>
                        <div class="row">
                           <div class="col-lg-6">
                              <div class="input-group">
                                 <input type="text" formControlName="name" class="form-control" placeholder="Name"
                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="new-name" />
                              </div>
                              <div *ngIf="submitted2 && a.name.errors" style="color: red">
                                 <div *ngIf="a.name.errors.required">Name is required</div>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="row">
                                 <div class="col-lg-4 col-4 col-4">
                                    <select name="country" class="form-control">
                                       <option data-code="+91" value="ind">+91</option>
                                    </select>
                                 </div>
                                 <div class="col-lg-8 col-8">
                                    <input type="text" formControlName="phoneNumber" placeholder="Mobile number"
                                       class="form-control" autocomplete="new-phoneNumber" />
                                    <div *ngIf="submitted2 && a.phoneNumber.errors" style="color: red">
                                       <div *ngIf="a.phoneNumber.errors.required">PhoneNumber is required</div>
                                       <div *ngIf="a.phoneNumber.errors.pattern">Enter Valid 10 digit Mobile Number
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">
                              <div class="input-group">
                                 <input type="text" formControlName="email" class="form-control" placeholder="Email"
                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="new-email" />
                              </div>
                              <div *ngIf="submitted2 && a.email.errors" style="color: red">
                                 <div *ngIf="a.email.errors.required">Email is required</div>
                                 <div *ngIf="a.email.errors.email">Enter Valid Email</div>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <!-- <div class="input-group">
                                 <input type="text" class="form-control" formControlName="city" placeholder="City"
                                    aria-describedby="basic-addon1" />
                              </div>
                              <div *ngIf="submitted2 && a.city.errors" style="color: red">
                                 <div *ngIf="a.city.errors.required">City is required</div>
                              </div> -->
                              <input type="text" formControlName="alternativePhoneNumber"
                                 placeholder="Alternative mobile number (optional)" class="form-control"
                                 autocomplete="alternativePhoneNumber" />
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-12">
                              <label for="exampleFormControlTextarea1">Address</label>
                              <textarea name="" formControlName="line1" placeholder="Type your text"
                                 class="form-control" id="" cols="3" rows="3" autocomplete="new-line1"></textarea>
                              <div *ngIf="submitted2 && a.line1.errors" style="color: red">
                                 <div *ngIf="a.line1.errors.required">Address is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">


                              <!-- <div class="input-group">
                                 <input type="text" formControlName="state" class="form-control" placeholder="State"
                                    aria-describedby="basic-addon1" />
                              </div>
                              <div *ngIf="submitted2 && a.state.errors" style="color: red">
                                 <div *ngIf="a.state.errors.required">State is required</div>
                              </div> -->
                              <label for="state">State</label>
                              <div class="input-group">
                                 <select class="form-control placeholder" [value]="" placeholder="State" #state
                                    formControlName="state" (change)="stateChanged(state?.value)"
                                    aria-describedby="basic-addon1">
                                    <option hidden value="" disabled selected>Select State</option>
                                    <!-- <option value="" selected>Your Placeholder Text</option> -->
                                    <option [value]="sts" *ngFor="let sts of states">{{sts|titlecase}}</option>
                                 </select>
                              </div>
                              <div *ngIf="submitted2 && a.state.errors" style="color: red">
                                 <div *ngIf="a.state.errors.required">State is required</div>
                              </div>

                           </div>
                           <div class="col-lg-6">
                              <label for="district"> District/City</label>
                              <div class="input-group">
                                 <select class="form-control placeholder" title="Select city/district"
                                    formControlName="city" aria-describedby="basic-addon1">
                                    <option hidden value="" disabled selected>Select District/City</option>
                                    <option [value]="dst" *ngFor="let dst of districts">{{dst|titlecase}}</option>
                                 </select>
                              </div>
                              <div *ngIf="submitted2 && a.city.errors" style="color: red">
                                 <div *ngIf="a.city.errors.required">City is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">


                              <div class="input-group">
                                 <input numbersonly minlength="6" (keyup)="checkPincode()" maxlength="6"
                                    class="form-control" formControlName="pinCode" placeholder="Pincode"
                                    aria-describedby="basic-addon1" autocomplete="new-pinCode" />
                              </div>
                              <div *ngIf="submitted2 && a.pinCode.errors" style="color: red">
                                 <div *ngIf="a.pinCode.errors.required">Pincode is required</div>
                                 <div *ngIf="a.pinCode.errors.pattern">Enter Valid 6 digit pincode</div>
                              </div>
                           </div>
                        </div>
                        <div class="row mt-4">
                           <div class="col-sm-6">
                              <a class="save" (click)="addAddress()">Add Address</a>
                              <a (click)="emptyAddressForm();showAddAddress();" class="p-2 ml-3">Close</a>
                           </div>
                        </div>
                     </div>
                  </form>
               </ng-container>
            </div>
            <!--======================My Address End================================-->
            <!--====================================================================-->
            <!--======================My Order Start================================-->
            <div id="order-1" class="collapse" [ngClass]="{ show: currentWindow === 'order' }">
               <div class="card-body">
                  <h3>My Orders</h3>
                  <div class="row mt-1">
                     <div class="col-lg-12 mt-3 p-0">
                        <h3 class="text-center" *ngIf="myOrderList?.length == 0">No Order Found</h3>
                        <div class="card" *ngFor="let item of myOrderList; let index = index">
                           <div class="col-lg-12">
                              <div class="row my-20">
                                 <div class="col-lg-6  col-md-12 pl-0">
                                    <div class="d-flex">
                                       <div class="border-right">
                                          <h5 class="order-value">
                                             {{ item.createdOn | date: 'dd-MM-yyyy, h:mm a'
                                             }}
                                          </h5>
                                          <label class="order-label">{{item.delayDeliveryTime == null ? " Order Date" :
                                             "Order Commited Date"}}</label>
                                          <h5 class="order-value" *ngIf="item.delayDeliveryTime != null">
                                             {{ item.delayDeliveryTime |
                                             date: 'dd-MM-yyyy, h:mm a'
                                             }}
                                          </h5>
                                          <label *ngIf="item.delayDeliveryTime != null" class="order-label">Delay Date &
                                             Time</label>
                                       </div>
                                       <div class="ml-4">
                                          <h5 class="order-value">
                                             {{ item.orderId }} <span *ngIf="item?.calculation?.isPanIdia"
                                                class="pan-india">PAN INDIA</span>
                                          </h5>
                                          <label class="order-label"> Order ID</label>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-lg-6 col-md-12 pr-0 md-pl-0">
                                    <div class="d-flex flex-end track-status-sec">
                                       <div class="border-right">
                                          <h5 class="order-label">Order Status:
                                             <label class="status-show"
                                                [ngClass]="{'Accepted': item.statusDisplayName === 'Accepted', 'Delivered': item.statusDisplayName === 'Delivered', 'Baking': item.statusDisplayName === 'Cake is Baking', 'Shipped': item.statusDisplayName === 'On Transit', 'Cancelled': item.statusDisplayName === 'Cancelled'}">
                                                {{ item.statusDisplayName }} </label>
                                             <label
                                                *ngIf="item.delayDeliveryTime != '' && item.delayDeliveryTime != null"
                                                class="delay-status-show"> Delay </label>
                                          </h5>
                                       </div>
                                       <div>
                                          <button (click)="openTrackOrder(item)" *ngIf="item?.calculation?.isPanIdia"
                                             type="button" class="tract_order">Track Order</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <div class="row m-0 mt-1 product-content">
                                 <div class="col-lg-7 col-sm-12 col-xs-12 col-md-12 px-20">
                                    <div class="row align-items-start product"
                                       *ngFor="let item2 of item.data.lineItemsInfo; let i2 = index">
                                       <div class="col-lg-3 col-sm-3  p-0">
                                          <img *ngIf="!item2.product?.productImages.includes('mp4')"
                                             class="ord-product-img" src="{{ imgPth + item2.product?.productImages }}"
                                             alt="" />
                                          <video *ngIf="item2.product?.productImages.includes('mp4')"
                                             [src]="imgPth + item2.product?.productImages"> </video>
                                       </div>
                                       <div class="col-lg-9 col-sm-9 pl-3 sm-pl-0 ">
                                          <h4>{{ item2.product.productName }}</h4>
                                          <b class="cake-price">Rs.{{ item2.costDetails.total | number:
                                             '1.2-2'}}</b> <b *ngIf="item2.costDetails.offer > 0" class="fnt"> ( <strike
                                                style="font-size: 12px;"
                                                class="fnt">Rs.{{item2.costDetails.itemPrice}}</strike> -
                                             {{item2.costDetails.offer}}% )</b>
                                          <div class="d-flex mb-3">
                                             <div class="pr-2">
                                                <p class="classes fs-m-12">{{item2?.product?.isKg==true ? 'Weight'
                                                   :'Peices'}}:
                                                   <b>{{ item2.pricing.kg }} {{item2?.product?.isKg==true ? 'KG'
                                                      :(item2.pricing.kg<=1 ?'No':'Nos')}}</b><br>
                                                         Quantity: <b>{{ item2.quantity}}</b><br>
                                                         <label *ngIf="item2.product.skuType == 'DESIGN'">Type :
                                                            <b>Design My
                                                               Cake</b></label>
                                                </p>
                                             </div>
                                             <small class="verticalLine"></small>
                                             <div class="pl-2">
                                                <p class="classes fs-m-12">Flavour:
                                                   <b>{{ item2.pricing.flavour ? item2.pricing.flavour : 'NA' }}</b><br>
                                                   Made with:
                                                   <b>{{ item2.attribute.eggType ? (item2.attribute.eggType | titlecase)
                                                      : '' }}</b>
                                                </p>
                                             </div>
                                          </div>
                                          <p class="classes fs-m-12">

                                          </p>
                                          <div class="review-btn-sec" *ngIf="item.statusDisplayName == 'Delivered'">
                                             <button (click)="addReviewModal(item2)"
                                                class="add-address-btn btn-lg add-report"
                                                *ngIf="item2?.rating == null">Add
                                                Review</button>
                                             <button (click)="viewReviewModal(item2)"
                                                class="add-address-btn btn-lg add-report"
                                                *ngIf="item2?.rating != null">View
                                                Review</button>
                                             <button class="float-right added-report ml-1"
                                                *ngIf="item2?.rating != null"><img src="assets/images/tick-mark.svg"
                                                   width="20px" height="20px" /><label>Review Added</label></button>
                                          </div>
                                       </div>

                                    </div>
                                 </div>
                                 <div class="col-lg-5 col-sm-12 col-xs-12 col-md-12 price-content status">
                                    <div class="col-12 pricingDiv">
                                       <table>
                                          <tr>
                                             <td>No of Items</td>
                                             <td class="pull-right"><b>{{item?.calculation?.quantity}}</b>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td>Total Price</td>
                                             <td class="pull-right"><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.total?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td>Discount</td>
                                             <td class="pull-right"><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.discount?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr *ngIf="item?.calculation?.coupon>0">
                                             <td>Coupon</td>
                                             <td><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.coupon?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td>Net Amount</td>
                                             <td><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.netAmount?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr *ngIf="item?.calculation?.delivery>0">
                                             <td>Delivery Charge</td>
                                             <td><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.delivery?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr *ngIf="item?.calculation?.midNight>0">
                                             <td>Mid-Night Delivery Charge</td>
                                             <td><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.midNight?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td>Grand Total</td>
                                             <td><b><i class="fa fa-inr"
                                                      aria-hidden="true"></i>{{item?.calculation?.grandTotal?.toFixed(2)}}</b>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12">
                              <div class="d-flex justify-between flex-wrap mt-4 mb-3">

                                 <!-- Address Section -->
                                 <div class="address-col">
                                    <div *ngIf="item?.hasHome" >
                                       <h3 class="cursor" id="homeaddress" data-toggle="dropdown">
                                          Home Address <i class="fa fa-angle-down ml-2 three-dots color_add"></i>
                                       </h3>
                                       <div class="dropdown-menu" aria-labelledby="homeaddress">
                                          <div class="address-tooltip" *ngIf="item?.homeAddress?.id != undefined">
                                             <h5>Home Address</h5>
                                             <table>
                                                <tr>
                                                   <th>Name</th>
                                                   <td>{{ item?.homeAddress?.name }}</td>
                                                </tr>
                                                <tr>
                                                   <th>Phone No</th>
                                                   <td>{{ item?.homeAddress?.phoneNumber }}</td>
                                                </tr>
                                                <tr *ngIf="item?.homeAddress?.alternativePhoneNumber">
                                                   <th>Alter Phone No</th>
                                                   <td>{{ item?.homeAddress?.alternativePhoneNumber }}</td>
                                                </tr>
                                                <tr>
                                                   <th>Email ID</th>
                                                   <td>{{ item?.homeAddress?.email }}</td>
                                                </tr>
                                                <tr *ngIf="item?.homeAddress?.id != undefined">
                                                   <th>Address</th>
                                                   <td>{{ item?.homeAddress?.line1 }},
                                                      {{ item?.homeAddress?.city }} , {{ item?.homeAddress?.state }} -
                                                      {{
                                                      item?.homeAddress?.pinCode }}
                                                   </td>
                                                </tr>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="item?.hasStore">
                                       <h3 class="ml-3 mr-3 cursor" id="storeaddress" data-toggle="dropdown">
                                          Store Address <i class="fa fa-angle-down ml-2"></i>
                                       </h3>
                                       <div class="dropdown-menu" aria-labelledby="storeaddress">
                                          <div class="address-tooltip">
                                             <h5>Store Address</h5>
                                             <table>
                                                <tr>
                                                   <th>Name</th>
                                                   <td>{{ item?.storeAddress?.name }}</td>
                                                </tr>
                                                <tr>
                                                   <th>Phone No</th>
                                                   <td>{{ item?.storeAddress?.phone }}</td>
                                                </tr>
                                                <tr>
                                                   <th>Address</th>
                                                   <td>{{ item?.storeAddress?.address }}
                                                   </td>
                                                </tr>
                                             </table>
                                          </div>
                                       </div>
                                    </div>

                                    <h3 *ngIf="item?.orderDetails?.deliveryIn">
                                       Delivery In: <strong class="ml-2">{{ item?.orderDetails?.deliveryIn }}</strong>
                                    </h3>

                                 </div>
                                 <!--Report, Review, Shipping detail-->
                                 <div class="d-flex align-center flex-wrap">
                                    <!-- <div class="" *ngIf="item.statusDisplayName == 'Delivered'">
                                       <button (click)="addReviewModal(item2)" class="add-address-btn btn-lg add-report"
                                          *ngIf="item2?.rating == null">Add Review</button>
                                       <button (click)="viewReviewModal(item2)"
                                          class="add-address-btn btn-lg view-report" *ngIf="item2?.rating != null">View
                                          Review</button>
                                       <button class="float-right added-report ml-1" *ngIf="item2?.rating != null"><img
                                             src="assets/images/tick-mark.svg" width="20px"
                                             height="20px" /><label>Review Added</label></button>
                                    </div> -->
                                    <button *ngIf="item.status == 'Shipped' "
                                       (click)="showShippingInfo(item.shippingDetails)"
                                       class="float-right add-report">Shipping Details</button>
                                    <button *ngIf="item.issueRaised == false"
                                       class="float-right add-address-btn add-report ml-1"
                                       (click)="reportOrder(item)">Report</button>
                                    <button *ngIf="item.issueRaised == true"
                                       class="float-right add-address-btn view-report ml-1 mr-1"
                                       (click)="reportOrder(item)">View Report</button>
                                    <button *ngIf="item.issueRaised == true" class="float-right added-report ml-1"><img
                                          src="assets/images/tick-mark.svg" width="20px" height="20px" /><label>Report
                                          Added</label></button>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!--======================My Order End==================================-->
            <!--====================================================================-->
            <!--======================My Reward Points Start=========================-->
            <div id="loyalty-1" class="collapse" [ngClass]="{ show: currentWindow === 'loyalty' }">
               <div class="card-body" *ngIf="getLoyaltyData">
                  <h3>Reward Points</h3>
                  <div class="row mt-3">
                     <div class="col-lg-6 mb-3">
                        <div class="card">
                           <div class="row p-2">
                              <div class="col-4">
                                 <img class="img img-fluid" src="/assets/images/icons/referal-point.png">
                              </div>
                              <div class="col-8 text-center">
                                 <label class=" mt-3 ern-points-txt">Total Earnings</label>
                                 <strong style="font-size: 30px;">{{getLoyaltyData?.totalEarned}}</strong><br>
                                 <div>
                                    <button class="redeem-btn" data-toggle="dropdown">Redeem</button>
                                    <div class="dropdown-menu">
                                       <a class="dropdown-item" (click)="redeemFun('Coupon', '')"
                                          style="text-decoration: none;">Coupon</a>
                                       <a class="dropdown-item" (click)="openloyaltyModal('redeem')"
                                          style="text-decoration: none;">Gift
                                          Card</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <div class="card">
                           <div class="row p-2">
                              <div class="col-4">
                                 <img class="img img-fluid" src="/assets/images/icons/refer-share.png">
                              </div>
                              <div class="col-8 text-center">
                                 <label class="mb-2 mt-2 ref-friend-text">Refer and Earn</label>
                                 <div class="input-group ref-friend-textbox">
                                    <input type="text" class="form-control tb-ref" value="{{referralLink}}"
                                       autocomplete="off">
                                    <div class="input-group-append">
                                       <label class="input-group-text ref-friend-btn"
                                          (click)="copyToClipBoard(referralLink)">Copy</label>
                                    </div>
                                 </div>
                                 <label *ngIf="getAllPointsDate" class="ref-notes"><i class="fa fa-hand-o-right"></i>
                                    Friends get
                                    {{getAllPointsDate.content.referral}} Points when they sign up.</label>
                                 <label *ngIf="getAllPointsDate" class="ref-notes"><i class="fa fa-hand-o-right"></i>
                                    You get
                                    {{getAllPointsDate.content.referrer}} Points when they sign up</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <br><br>
                  <div class="table-responsive">
                     <h4>Redeem History</h4>
                     <table class="table table-bordered text-center" *ngIf="getLoyaltyData?.redeemHistory.length > 0">
                        <thead style="background:#fe99b3;color: #fff;">
                           <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Redeem Points</th>
                              <th scope="col">Equivalent Amount</th>
                              <th scope="col">Redeem Status</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let item of getLoyaltyData?.redeemHistory">
                              <td>{{item.createdOn | date: 'dd/MM/yyyy' }}</td>
                              <td>{{item.points}}</td>
                              <td> <i class="fa fa-inr"></i> {{ item.points * getLoyaltyData?.equivalentAmount}}</td>
                              <td>
                                 <label *ngIf="item.applyStatusNew == false && item.type == 'Coupon' ">{{item.coupon}}
                                    <i (click)="copyToClipBoard(item.coupon)" class="fa fa-clone copy-icon"></i>
                                 </label>
                                 <label *ngIf="item.applyStatusNew == false && item.type == 'GiftCard' && item.coupon"
                                    class="theme-color" (click)="openloyaltyModal('resend', item.couponId)">Resend Gift
                                    Card
                                 </label>
                                 <label *ngIf="item.applyStatusNew != false">Applied</label>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <h5 *ngIf="getLoyaltyData?.redeemHistory.length == 0" class="text-center mt-5">No History Found
                     </h5>
                  </div>
               </div>
            </div>
            <!--======================My Reward Points End================================-->
            <!--==============================================================-->
            <!--======================My Remainder Start================================-->
            <div id="remainder-1" class="collapse" [ngClass]="{ show: currentWindow === 'remainder' }">
               <div class="card-body">
                  <h3>My Reminder</h3>
                  <div class="row mt-1">
                     <div class="col-lg-12 mt-3" *ngFor="let data of allRemainder">
                        <div class="card">
                           <i class="fa fa-trash" (click)="deleteRemainder(data.id)"
                              style="cursor: pointer; color: red; right: 10px; position: absolute; font-size: 18px"></i>
                           <h5>
                              {{data.name | titlecase}} <small class="verticalLine"></small> <label
                                 class="remind-txt">Reminder
                                 Schedule On</label> {{data.date | date : "dd-MM-yyyy"}}
                           </h5>
                           <p>
                              {{data.description}}
                           </p>
                           <i *ngIf="data.type=='email'" class="rmd-type">Get reminder via email</i>
                           <i *ngIf="data.type=='mobile'" class="rmd-type">Get reminder via mobile sms</i>
                        </div>
                     </div>
                  </div>
                  <button *ngIf="!remainderFormDiv" class="add-address-btn btn-lg" (click)="showSetRemainder()">Set
                     Reminder</button>
               </div>
               <ng-container *ngIf="remainderFormDiv">
                  <form [formGroup]="remainderForm" autocomplete="off">
                     <div class="card address-book mb-4" style="background: #fff">
                        <h4>Reminder</h4>
                        <div class="row">
                           <div class="col-lg-4 mt-2">
                              <div class="input-group">
                                 <input type="text" formControlName="title" class="form-control"
                                    placeholder="Event title" autocomplete="new-title" />
                              </div>
                              <div *ngIf="submitted3 && b.title.errors" style="color: red">
                                 <div *ngIf="b.title.errors.required">Title is required</div>
                              </div>
                           </div>
                           <div class="col-lg-4 mt-2">
                              <input type="date" formControlName="date" class="form-control" placeholder="dd/mm/yyyy"
                                 autocomplete="new-date" />
                              <div *ngIf="submitted3 && b.date.errors" style="color: red">
                                 <div *ngIf="b.date.errors.required">Date is required</div>
                              </div>
                           </div>
                           <div class="col-lg-4 mt-2">
                              <div class="input-group">
                                 <select class="form-control" formControlName="type">
                                    <option value="">Select reminder option </option>
                                    <option value="email">Reminder via Email</option>
                                    <option value="mobile">Reminder via Mobile SMS</option>
                                 </select>
                              </div>
                              <div *ngIf="submitted3 && b.type.errors" style="color: red">
                                 <div *ngIf="b.type.errors.required">Reminder option is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-12">
                              <label for="exampleFormControlTextarea1">Event Description</label>
                              <textarea name="" formControlName="description" placeholder="Type your text"
                                 class="form-control" id="" cols="3" rows="3" autocomplete="new-description"></textarea>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-12">
                              <input type="checkbox" formControlName="remaindEveryYear" id="remaind-every-year"
                                 autocomplete="new-remaindEveryYear"> <label class="ml-2"
                                 for="remaind-every-year">Remind Every Year</label>
                              <p>I accept to receive reminders through email and sms</p>
                           </div>
                        </div>
                        <div class="row mt-4">
                           <div class="col-sm-6">
                              <a class="save" (click)="saveRemainder()">Add Reminder</a>
                              <a (click)="showSetRemainder()" class="p-2 ml-3">Close</a>
                           </div>
                        </div>
                     </div>
                  </form>
               </ng-container>
            </div>
            <!--======================My Remainders End================================-->
            <!--==============================================================-->
         </div>
         <!--col-9 end-->
      </div>
      <!--main row end-->
   </div>
   <!--main Container end-->
   <!--======================Bootstrap Modals================================-->
   <!-------------crop and save image modal------------------>
   <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel">Image</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <!-- <p>{{croppedImage}}-{{imgUrl}}</p> -->
               <div *ngIf="getdata">
                  <i (click)="deleteImage()" *ngIf="imgUrl!=''&&imgUrl!='https://api.puppysbakery.com'&&imgUrl!=null"
                     class="fa fa-trash del-icon"></i>
               </div>
               <input type="file" id="upload-img" accept="image/*" style="display: none"
                  (change)="fileChangeEvent($event)" autocomplete="off" />
               <label for="upload-img" *ngIf="!croppedImage">
                  <span style="background-color:#fe99b3;border:none" class="btn btn-primary btn-sm">Choose
                     Image</span><br />
                  <small style="color: grey; margin-left: 8px"><i class="fa fa-info-circle"></i> Upto 2 MB</small>
               </label>
               <div>
                  <br /><br />
                  <div *ngIf="getdata?.imageUrl!='https://api.puppysbakery.com'">
                     <img *ngIf="getdata?.imageUrl!='https://api.puppysbakery.com'" src="{{ imgUrl }}"
                        class="img img-fluid" />
                  </div>
                  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                     [aspectRatio]="4 / 4" [resizeToWidth]="128" [imageQuality]="70" format="jpeg"
                     (imageCropped)="imageCropped($event)">
                  </image-cropper>
               </div>
               <img [src]="croppedImage" *ngIf="croppedImage" />
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
               <button type="button" class="btn btn-primary" style="background-color:#fe99b3;border:none"
                  (click)="uploadImage()">Save</button>
            </div>
         </div>
      </div>
   </div>
   <!-------------End crop and save image modal------------------>
   <!-------------Shipping Details modal------------------>


   <div class="modal fade" id="shipping" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel">Shipping Details</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <table class="table table-bordered">
                  <tbody>
                     <tr>
                        <td> Agent Name </td>
                        <td> {{agentName}} </td>
                     </tr>
                     <tr>
                        <td> Tracking Id </td>
                        <td> {{trackingId}} </td>
                     </tr>
                     <tr>
                        <td> Tracking Url </td>
                        <td>
                           <a target="_blank" href="{{trackingUrl}}" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Tracking Shipping Detail',
                           'event_label': 'Tracking Shipping Detail',
                           'value': 'Tracking Shipping Detail'})"
                              *ngIf="getClickableLink(trackingUrl) == 1">{{trackingUrl}}</a>
                           <a target="_blank" href="http://{{trackingUrl}}" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Tracking Shipping Detail',
                           'event_label': 'Tracking Shipping Detail',
                           'value': 'Tracking Shipping Detail'})"
                              *ngIf="getClickableLink(trackingUrl) == 0">{{trackingUrl}}</a>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <div class="modal-footer">
               <button type="button" style="background: #FD3568;" class="btn btn-secondary"
                  data-dismiss="modal">Close</button>
            </div>
         </div>
      </div>
   </div>
   <!-------------End Shipping Details modal------------------>
   <!--------------show gift card Modal---------------------------->
   <div class="modal fade" id="giftCardModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document" style="width: 335px;">
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title" id="exampleModalLabel">Gift Card</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div #dynamicImgHtml style="width: 300px; height: 200px; background-size: 300px 200px;
                  background-image: url('/assets/images/gift/gift-poster2.png'); ">
                  <label class="gift-code">Code : {{printGiftCode}}</label>
               </div>
               <div>
                  <a href="" id="anchorImg" download="gift.png"><i class="fa fa-download down-icon"></i></a>
               </div>
               <br><br>
            </div>
            <div class="modal-footer">
               <button type="button" style="background: #fe99b3;" class="btn btn-secondary"
                  data-dismiss="modal">Close</button>
            </div>
         </div>
      </div>
   </div>
   <!--------------End show gift card Modal---------------------------->
   <!------------------ Add Review Modal ---------------->
   <div class="modal fade" id="addReview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title" id="exampleModalLabel">Add Review</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <form #reviewForm="ngForm" (ngSubmit)="reviewForm.form.valid && addReview(reviewForm.value)">
               <div class="modal-body">
                  <div class="row add-rating">
                     <div class="col-lg-12">
                        <div class="form-group custom-center">
                           <bar-rating name="rating" ngModel #rating="ngModel" [max]="5" required></bar-rating>
                           <div id="error" *ngIf="reviewForm.submit && rating.invalid" class="invalid-feedback d-block">
                              <div *ngIf="rating.errors.required" class="invalid-feedback d-block">Name is required
                              </div>
                           </div>
                        </div>
                        <div class="form-group">
                           <textarea class="form-control" name="review" ngModel #review="ngModel" required
                              placeholder="Enter review" rows="3" autocomplete="new-review"></textarea>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="submit" class="btn btn-primary add-review">Save</button>
               </div>
            </form>
         </div>
      </div>
   </div>
   <!------------------End Add Review Modal ---------------->
   <!---------- View Review Modal -------------->
   <div class="modal fade" id="viewReview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title" id="exampleModalLabel">Review</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="form-group text-center">
                  <span><i class="text-warning fa fa-star"
                        [ngClass]="{'add-start-active': viewReview?.rating?.stars >= 1}"></i></span>
                  <span><i class="text-warning fa fa-star"
                        [ngClass]="{'add-start-active': viewReview?.rating?.stars >= 2}"></i></span>
                  <span><i class="text-warning fa fa-star"
                        [ngClass]="{'add-start-active': viewReview?.rating?.stars >= 3}"></i></span>
                  <span><i class="text-warning fa fa-star"
                        [ngClass]="{'add-start-active': viewReview?.rating?.stars >= 4}"></i></span>
                  <span><i class="text-warning fa fa-star"
                        [ngClass]="{'add-start-active': viewReview?.rating?.stars >= 5}"></i></span>
               </div>
               <div class="form-group">
                  <p>
                     {{viewReview?.rating?.comments}}
                  </p>
               </div>
            </div>
            <div class="modal-footer">
               <button type="type" (click)="deleteReview(viewReview?.rating?.id)" class="btn btn-danger">Delete</button>
            </div>
         </div>
      </div>
   </div>
   <!----------End View Review Modal -------------->
   <!-------------------Generate Gift card Confirmation modal ------------------>
   <div class="modal fade" id="loyaltyModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg box-resize" role="document" style="width: 400px;">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel">Redeem</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div *ngIf="loyaltyModalMsg">
                  <p style="font-size: 20px;line-height: 1.2;">{{loyaltyModalMsg}}</p>
               </div>
               <div *ngIf="loyaltyModalEmail == 'email' ">
                  <form>
                     <input type="text" #loyaltyNewEmail class="form-control" placeholder="Enter email"
                        autocomplete="new-email">
                     <button style="background: #FD3568;float: right;" class="btn btn-secondary btn-sm mt-3"
                        (click)="redeemFun('giftCard',loyaltyNewEmail.value)">Generate GiftCard</button>
                  </form>
               </div>
               <div *ngIf="loyaltyModalEmail == 'resend' ">
                  <form>
                     <input type="text" #loyaltyResendEmail class="form-control" placeholder="Enter email"
                        autocomplete="new-resend">
                     <button style="background: #FD3568;float: right;" class="btn btn-secondary btn-sm mt-3"
                        (click)="giftGenerateFun('resend',loyaltyResendEmail.value)">Resend GiftCard</button>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-------------------End Generate Gift card Confirmation modal ------------------>
   <!------------------------- Add report Modal------------------->
   <div class="modal fade" id="reportOrderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h4 class="modal-title" id="exampleModalLabel">Report</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="row add-rating">
                  <div class="col-lg-12">
                     <div class="form-group">
                        <input type="text" class="form-control dis-txt" value="{{reportOrderId?.orderId}} (Order Id) "
                           autocomplete="off">
                     </div>
                     <div class="form-group">
                        <textarea class="form-control" #report
                           [ngClass]="{'dis-txt': reportOrderId?.issueRaised === true }" placeholder="Enter text"
                           rows="3" autocomplete="off">{{reportOrderId?.orderIssue?.comments}}</textarea>
                     </div>
                     <div class="form-group " *ngIf="reportOrderId?.issueRaised === true">
                        <textarea class="form-control d is-txt" style="width: 85%;margin-left: 15%;"
                           placeholder="Admin will reply soon..." rows="3" class="dis-txt"
                           autocomplete="off">{{reportOrderId?.orderIssue?.adminComments}}</textarea>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer" *ngIf="reportOrderId?.issueRaised === false">
               <button type="submit" class="btn btn-primary add-review" (click)="sendReport(report.value)">Send
                  Report</button>
            </div>
         </div>
      </div>
   </div>
   <!-------------------------End Add report Modal------------------->
   <!-------------------------Track order Modal------------------->
   <track-order [trackOrder]="trackOrder" [random]="random"></track-order>
   <!-------------------------End Track order Modal------------------->
</section>
<div class="whatsapp-icon" style="background-color: #22ce5a">
   <a href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
      target="_blank">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
         <path
            d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
            fill="white" />
         <path
            d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
            fill="white" />
      </svg>
   </a>
</div>