import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BlogComponent } from "./blog.component";
import { BlogListComponent } from "./blog-list/blog-list.component";

const routes: Routes = [
  {
    path: ":id",
    component: BlogComponent,
  },
  {
    path: "",
    component: BlogListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogRoutingModule {}
