import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { sweetAlert } from "../../function/toaster";
declare var $: any;
import { HttpService } from "../../../services/http/http.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.less"],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild("resetPasswordModal") resetPasswordModal: ElementRef;
  resetForm: FormGroup;
  submitted: boolean;
  constructor(
    public http: HttpService, 
    public formbuilder: FormBuilder,
    public analyticsService:GoogleAnalyticsService,) {
    //resetForm
    this.resetForm = this.formbuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }
  loading = false;
  resetEmail;

  reseterrorMsg = "";
  ngOnInit(): void {
    this.submitted=false;
    this.resetForm.reset()
    this.analyticsService.pageView("View Reset Password",`Reset Password Form`,'Popup');
  }
  get form() {
    return this.resetForm.controls;
  }
  // reset() {
 
  //   if (this.resetForm.valid) {
  //     $("#forgotPassword").modal("hide");
  //     $("#resetPassword").modal("show");
  //   }
  // }
  reset() {
    this.submitted=true;
 
    if (this.resetForm.valid) {
      this.analyticsService.commonEvents('Onclick',{'event_category': `Reset Password For this user ${this.resetForm.value.email}`,
      'event_label': `Reset Password For this user ${this.resetForm.value.email}`,
      'value': `Reset Password For this user ${this.resetForm.value.email}`});
      this.loading = true;
      var obj = { email: this.resetForm.value.email };
      let params = new HttpParams();
      for (let key in obj) {
        params = params.set(key, obj[key]);
      }
      this.http.post("/user/password/forget", params).subscribe(
        (data) => {
          sweetAlert("success", "Password Reset Link Sent Successfully");
          this.loading = false;
          $("#forgotPassword").modal("hide");
        },
        (error) => {
          sweetAlert("error", error.message);
          this.loading = false;
        }
      );
    }
  }
  loginemail() {
    $("#forgotPassword").modal("hide");
    $("#resetPassword").modal("hide");
    $("#loginSignup").modal("show");
    this.analyticsService.commonEvents('Onclick',{'event_category': 'Open SignIn Popup Form',
                            'event_label': 'Open SignIn Popup Form',
                            'value': 'Open SignIn Popup Form'})
  }

  signInSingUp() {
    $("#forgotPassword").modal("hide");
    $("#resetPassword").modal("hide");
    $("#loginSignup").modal("show");
    this.analyticsService.commonEvents('sign_up',{'event_category': 'Open SignIn Popup Form',
    'event_label': 'Open SignIn Popup Form',
    'value': 'Open SignIn Popup Form'});
  }
  //submit form
  errorMsg = "";
  submit() {
    this.errorMsg = "";
    // let regex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
    // if (!regex.test(this.resetEmail)) {
    //   this.errorMsg = "Invalid Email";
    //   return;
    // }

    this.loading = true;
    this.http
      .post(
        "/user/password/forget",
        JSON.parse('{ "email" : "' + this.resetEmail + '" }')
      )
      .subscribe(
        (data) => {
          sweetAlert("success", "Password Reset Link Sent Successfully");
          this.loading = false;
          $("#resetPassword").modal("hide");
        },
        (error) => {
          sweetAlert("error", error.message);
          this.loading = false;
        }
      );
  }
}
