import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { AppConstants } from 'src/app/configuration/app.constants';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

declare var $: any
@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.less']
})
export class OurStoryComponent implements OnInit {

  constructor(
    public http: HttpService,
    public analyticsService:GoogleAnalyticsService) { }

  public imgPth
  public skeletonMainLoader: Boolean = true

  ngOnInit(): void {
    this.skeletonMainLoader = false
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.filterSelection("all")
    this.analyticsService.pageView("View Our Story",`page/ourStory`,window.location.origin);
    //this.getAllImages()
  }

  filterSelection(c) {
    var x, i;
    x = document.getElementsByTagName("img");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
      this.RemoveClass(x[i], "show");
      if (x[i].className.indexOf(c) > -1) this.AddClass(x[i], "show");
    }
  }

  AddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) { element.className += " " + arr2[i]; }
    }
  }

  RemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  }


  ////get all pressRelease image
  public pressRelease: any = []
  getAllImages() {
    this.http.get('/getPressRelease').subscribe(
      (data: any) => {
        this.imgPth = data.url
        for (const key in data.pressRelease) {
          if (Object.prototype.hasOwnProperty.call(data.pressRelease, key)) {
            const element = data.pressRelease[key];
            this.pressRelease.push({
              newsPaperName: element.split("/")[1].split("_")[0],
              button: element.split("/")[1].split("_")[0] + " " + element.split("/")[1].split("_")[1],
              date: element.split("/")[1].split("_")[1],
              class: element.split("/")[1].split("_")[0] + element.split("/")[1].split("_")[1],
              img: this.imgPth + "" + element,
              thumImg: this.imgPth + "thumbnail/" + element.split("/")[1]
            })
          }
        }
        this.skeletonMainLoader = false
      },
      error => {
        this.skeletonMainLoader = false
      });
  }

  public buttonKey: any = []
  getButtonValue(data) {
    this.buttonKey = data.reduce((acc, current) => {
      const x = acc.find(item => item.class === current.class);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  public skeletonLoader: Boolean = true
  checkImageLoad() {
    this.skeletonLoader = false
  }

  public currentImage
  viewImage(val) {
    this.analyticsService.commonEvents("Onclick",{'event_category': 'View Our story Images',
    'event_label': 'View Our story Images',
    'value': 'View Our story Images'});
    this.skeletonLoader = true
    $("#imageModal").modal('show');
    this.currentImage = val
    var image: any = document.getElementById('img');
    var isLoaded = image.complete && image.naturalHeight !== 0;
    if (isLoaded) this.skeletonLoader = false
  }

}
