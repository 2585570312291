<section class="">
  <!-- top circle border -->
  <div class="top-rounded-border"></div>
  <div class="top-banner">
    <div class="banner-overlay">
      <h3>Contact Us</h3>
    </div>
  </div>
  <!--breadcrumb-->
  <div class="container-fluid px-5">
    <ol class="breadcrumb px-0">
      <li class="breadcrumb-item">
        <a href="#"><i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
      </li>
      <li class="breadcrumb-item active">Contact Us</li>
    </ol>
  </div>


  <!--container starts-->

  <div class="container">
    <div class="sub-contain">
      <div class="row main-contact-row">
        <div class="col-lg-4 col-md-6 contact-add-column">
          <div class="contact-address">
            <h4 class="">Contact Information</h4>
            <p class="">Fill up the form and our Team will get back to you within 24 hours.</p>
            <ul>
              <li>
                <a href="tel:8122210222">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>+91 8122210222</span>
                </a>
              </li>
              <li>
                <a href="mailbox@puppys.co.in">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>mailbox@puppys.co.in</span>
                </a>
              </li>
              <!-- <li>
                <a href="#">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>Madurai main road</span>
                </a>
              </li> -->
            </ul>
            <div class="row social-media-sec">
              <div class="col-lg-7 col-md-7 social-media">
                <a href="https://www.facebook.com/puppysbakery" target="_blank"><img
                    src="assets/images/connect_with_us/icons8_facebook.png"></a>
                <a href="https://www.instagram.com/puppysbakery/" target="_blank"><img
                    src="assets/images/connect_with_us/icons8_instagram.png"></a>
                <a href="https://www.linkedin.com/company/puppy's-bakery" target="_blank"><img
                    src="assets/images/connect_with_us/icons8_linkedin.png"></a>
                <a href="https://in.pinterest.com/puppys_bakery/" target="_blank"><img
                    src="assets/images/connect_with_us/icons8_pinterest.png"></a>
                <a href="https://www.youtube.com/channel/UCYxPuKbnslCVpx6iS1ZQhNQ" target="_blank"><img
                    src="assets/images/connect_with_us/youtube.png"></a>
              </div>
              <div class="col-lg-5 col-md-5 media-column">
                <div class="media-image">
                  <img src="./assets/images/circle.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 contact-detail-column">
          <form #contactUs="ngForm" (ngSubmit)="contactUs.form.valid && doContactUs(contactUs.value)">
            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 form-height">
                  <label for="">Your Name <span style="color: red;">*</span></label>
                  <input type="text" class="form-control" [ngClass]="{
                    'is-invalids': contactUs.submitted && name.invalid
                  }" name="name" required ngModel #name="ngModel" placeholder="Name" autocomplete="new-name" />
                  <div id="error" *ngIf="contactUs.submitted && name.invalid" class="invalid-feedback d-block">
                    <div *ngIf="name.errors.required" class="invalid-feedback d-block">Name is required</div>
                  </div>
                </div>
                <div class="col-lg-6 form-height">
                  <label for="">Your Email <span style="color: red;">*</span></label>
                  <input type="email" class="form-control" [ngClass]="{
                  'is-invalids': contactUs.submitted && email.invalid
                }" name="email" ngModel pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #email="ngModel"
                    placeholder="Email" autocomplete="new-email" />
                  <div id="error" *ngIf="contactUs.submitted && email.invalid" class="invalid-feedback d-block">
                    <div *ngIf="email.errors.required" class="invalid-feedback d-block">Email is required</div>
                  </div>
                  <div *ngIf="email.errors && (email.touched || email.dirty)" class="invalid-feedback d-block">
                    <div [hidden]="!email.errors?.pattern" class="invalid-feedback d-block">
                      Invalid Mail ID
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 form-height">
                <div class="form-group">
                  <label for="">Subject </label>
                  <input type="text" class="form-control" [ngClass]="{
              'is-invalids': contactUs.submitted && subject.invalid
            }" name="subject" ngModel #subject="ngModel" placeholder="Subject" autocomplete="new-subject" />
                  <div id="error" *ngIf="contactUs.submitted && subject.invalid" class="invalid-feedback d-block">
                    <div *ngIf="subject.errors.required" class="invalid-feedback d-block">Subject is required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 form-height">
                <label for="">Mobile Number <span style="color: red;">*</span></label>
                <input type="text" class="form-control" [ngClass]="{
                'is-invalids': contactUs.submitted && mobileNumber.invalid
              }" name="mobileNumber" required ngModel #mobileNumber="ngModel" placeholder="Enter mobile number"
                  autocomplete="new-mobileNumber" />
                <div id="error" *ngIf="contactUs.submitted && mobileNumber.invalid" class="invalid-feedback d-block">
                  <div *ngIf="mobileNumber.errors.required" class="invalid-feedback d-block">Mobile Number is required
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group form-height">
              <label for="">Your Message <span style="color: red;">*</span></label>
              <textarea style="resize: none;" class="form-control" placeholder="Message" [ngClass]="{
              'is-invalids': contactUs.submitted && message.invalid
            }" name="message" required ngModel #message="ngModel" placeholder="Message" maxlength="500" rows="3"
                autocomplete="new-message"></textarea>
              <div class="the-count">
                <span id="current"> {{
                  message?.viewModel == ""
                  ? 0
                  : message?.viewModel?.length
                  }}</span>
                <span id="maximum"> / 500</span>
              </div>
              <div id="error" *ngIf="contactUs.submitted && message.invalid" class="invalid-feedback d-block">
                <div *ngIf="message.errors.required" class="invalid-feedback d-block">Message is required</div>
              </div>
            </div>
            <div class="form-group text-right sub-btn">
              <button type="submit" [disabled]="submitLoader" class="btn btn-custom btn-lg ">
                <i *ngIf="submitLoader" class="fa fa-circle-o-notch fa-spin"></i>
                <span *ngIf="submitLoader"> &nbsp;Submitting ...</span>
                <span *ngIf="!submitLoader"> &nbsp; Send Message</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>





  <!--<div class="container px-5 py-4">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10 col-md-8 ">
        <form #contactUs="ngForm" (ngSubmit)="contactUs.form.valid && doContactUs(contactUs.value)">
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label for="">Your Name</label>
                <input type="text" class="form-control" [ngClass]="{
                    'is-invalids': contactUs.submitted && name.invalid
                  }" name="name" required ngModel #name="ngModel" placeholder="Name" />
                <div id="error" *ngIf="contactUs.submitted && name.invalid" class="invalid-feedback d-block">
                  <div *ngIf="name.errors.required" class="invalid-feedback d-block">Name is required</div>
                </div>
              </div>
              <div class="col-lg-6">
                <label for="">Your Email</label>
                <input type="email" class="form-control" [ngClass]="{
                  'is-invalids': contactUs.submitted && email.invalid
                }" name="email" ngModel pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #email="ngModel"
                  placeholder="Email" />
                <div id="error" *ngIf="contactUs.submitted && email.invalid" class="invalid-feedback d-block">
                  <div *ngIf="email.errors.required" class="invalid-feedback d-block">Email is required</div>
                </div>
                <div *ngIf="email.errors && (email.touched || email.dirty)" class="invalid-feedback d-block">
                  <div [hidden]="!email.errors?.pattern" class="invalid-feedback d-block">
                    Invalid Mail ID
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group mb-4">
                <label for="">Subject</label>
                <input type="text" class="form-control" [ngClass]="{
              'is-invalids': contactUs.submitted && subject.invalid
            }" name="subject" ngModel #subject="ngModel" placeholder="Name" />
                <div id="error" *ngIf="contactUs.submitted && subject.invalid" class="invalid-feedback d-block">
                  <div *ngIf="subject.errors.required" class="invalid-feedback d-block">Subject is required</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <label for="">Mobile Number</label>
              <input type="text" class="form-control" [ngClass]="{
                'is-invalids': contactUs.submitted && mobileNumber.invalid
              }" name="mobileNumber" required ngModel #mobileNumber="ngModel" placeholder="Enter Mobile Number" />
              <div id="error" *ngIf="contactUs.submitted && mobileNumber.invalid" class="invalid-feedback d-block">
                <div *ngIf="mobileNumber.errors.required" class="invalid-feedback d-block">Mobile Number is required
                </div>
              </div>
            </div>
          </div>


          <div class="form-group">
            <label for="">Your Message</label>
            <textarea class="form-control" placeholder="Message" [ngClass]="{
              'is-invalids': contactUs.submitted && message.invalid
            }" name="message" required ngModel #message="ngModel" placeholder="Message" maxlength="500"
              rows="3"></textarea>
            <div class="the-count">
              <span id="current"> {{
                message?.viewModel == ""
                ? 0
                : message?.viewModel?.length
                }}</span>
              <span id="maximum"> / 500</span>
            </div>
            <div id="error" *ngIf="contactUs.submitted && message.invalid" class="invalid-feedback d-block">
              <div *ngIf="message.errors.required" class="invalid-feedback d-block">Message is required</div>
            </div>
          </div>
          <div class="form-group text-center">
            <button type="submit" [disabled]="submitLoader" class="btn btn-custom btn-lg ">
              <i *ngIf="submitLoader" class="fa fa-circle-o-notch fa-spin"></i>
              <span *ngIf="submitLoader"> &nbsp;Submitting ...</span>
              <span *ngIf="!submitLoader"> &nbsp; Submit</span>
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>-->
  <!--container ends-->

</section>
<div class="whatsapp-icon" style="background-color: #22ce5a">
  <a href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
    target="_blank">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
        fill="white" />
      <path
        d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
        fill="white" />
    </svg>
  </a>
</div>


<!--branch list-->
<app-branch-details></app-branch-details>