import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NeedHelpComponent } from "./components/need-help/need-help.component";
import { OfferComponent } from "./components/offer/offer.component";
import { LoginSignupComponent } from "./components/login-signup/login-signup.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { VerificationComponent } from "./components/verification/verification.component";
import { NgOtpInputModule } from "ng-otp-input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { EmailVerifyComponent } from "./components/email-verify/email-verify.component";
import { AddonsPopupComponent } from "./components/addons-popup/addons-popup.component";
import { RouterModule } from "@angular/router";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ActualPipe, DiscountPipe } from "./pipes/discount.pipe";
import { TrimText } from "./pipes/trim.pipe";
@NgModule({
  declarations: [
    NeedHelpComponent,
    OfferComponent,
    LoginSignupComponent,
    ResetPasswordComponent,
    VerificationComponent,
    LoaderComponent,
    EmailVerifyComponent,
    AddonsPopupComponent,
    ForgotPasswordComponent,
    DiscountPipe,
    ActualPipe,
    TrimText,
  ],
  imports: [
    CommonModule,
    NgOtpInputModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    NeedHelpComponent,
    OfferComponent,
    LoginSignupComponent,
    ResetPasswordComponent,
    VerificationComponent,
    LoaderComponent,
    AddonsPopupComponent,
    ForgotPasswordComponent,
    DiscountPipe,
    ActualPipe,
    TrimText,
  ],
  providers: [LoginSignupComponent],
})
export class SharedModule {}
