import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnInit {
  title
  getdata2
  @Input("type") type: any

  constructor(
    public http: HttpService,
  ) { }
  getdata

  ngOnInit(): void {
    //get all image for slider
    this.http.get('/getFormSlider').subscribe(
      (data: any) => {
        this.getdata2 = data;
        this.ngOnChanges();
      });
  }

  ngOnChanges() {
    if (this.type == 1) this.title = 'Cake Enquiry Form';
    if (this.type == 2) this.title = 'Dessert Table Enquiry Form';
    if (this.type == 3) this.title = 'Wedding Cake Enquiry Form';

    //filter all image to specific form
    if (this.getdata2) {
      this.getdata = this.getdata2.filter(element => element.type == this.type);
    }
  }

  //stop skeleton loader
  public skeletonLoader: Boolean = true
  checkImageLoad() {
    this.skeletonLoader = false
  }

}
