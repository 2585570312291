<app-loader *ngIf="loading"></app-loader>
<section class="">
  <div class="newsletter" style="background-image: url('assets/images/subscribe.png');">


    <div class="container">
      <h1>SUBSCRIBE NEWSLETTER</h1>
      <form #newsLetterData="ngForm" id="myForm"
        (ngSubmit)="newsLetterData.form.valid && SendLetter(newsLetterData.value)">
        <div class="mt-3 pl-230">
          <div class=" d-flex list-add">
            <div class="d-flex align-items-center heading">
              <!-- <p class="mail-p">STAY UPDATED ON OUR DELICIOUS CREATIONS</p> -->
            </div>
            <div class="mx-3 ml-4 d-flex align-items-center coustom-input w575">
              <div class="input-group mb-3 ma-top-10">
                <div class="input-group-prepend">
                  <!-- <span class="input-group-text" id="basic-addon1">
                  <i class="fa fa-envelope"></i>
                </span> -->
                </div>
                <input class="form-control color-add" type="email" name="email" ngModel #email="ngModel"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required
                  placeholder="Enter your email address for newsletter" autocomplete="new-email">
                <br>

                <div id="error" *ngIf="email.touched">
                  <small *ngIf="email.errors?.required"> Mail ID is required</small>
                  <small *ngIf="email.errors?.pattern">Invalid Mail ID</small>
                </div>

              </div>

            </div>

          </div>

          <div class="ssss">
            <button type="submit" class="btn form-submit-btn">Submit</button>
          </div>

        </div>

      </form>


    </div>
  </div>

  <!-- <div class="row">
       <div class="col-lg-4 pr-0 layer-1">
        <div>
          <img src="assets/images/newsletter/OBJECTS.svg" alt="" srcset="" />
          <img src="assets/images/newsletter/Group_43958.svg" class="poster" alt="" srcset="" />
          <div class="radius-1"></div>
          <div class="radius-2"></div>
          <div class="radius-3"></div>
        </div>
      </div> 
      <div class="col-lg-8 text-center form-content">
        <div class="quick_newsletter">
           <div class="newsletter-info">
            <h3>SUBSCRIBE NEWSLETTER</h3>
          </div> 
          <div class="newsletter-element">
          </div>
        </div>
      </div>
    </div> -->

  <!--  <div class="" style="background-image: url('assets/images/subscribe.jpg');">
    <form #newsLetterMobData="ngForm" id="myForm1"
      (ngSubmit)="newsLetterMobData.form.valid && SendLetterMob(newsLetterMobData.value)">

      <div class="row text-center mobile-view">
        <div class="col-lg-6 content py-3 d-flex justify-content-between">
          <div>
            <p class="mb-0">Join Our Mailing List</p>
          </div>
        </div>

        <div class="col-lg-6 body px-5">
          <div class="input-group">

            <input type="email" class="form-control" [ngClass]="{
            'is-invalids': newsLetterMobData.submitted && email.invalid
          }" #email="ngModel" name="email" ngModel pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required
              placeholder="Enter your email address for newsletter" />
            <div id="error" *ngIf="newsLetterMobData.submitted && email.invalid" class="invalid-feedback d-block">
              <div *ngIf="email.errors.required" class="invalid-feedback d-block text-center">
                Mail ID is required
              </div>
            </div>
            <div *ngIf="email.errors && (email.touched || email.dirty)" class="invalid-feedback d-block">
              <div [hidden]="!email.errors?.pattern" class="invalid-feedback d-block text-center">
                Invalid Mail ID
              </div>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="text-center mt-3">
            <button type="submit" class="btn">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div> -->
  <!--End Container Mobile-->

</section>