import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReviewsRoutingModule } from "./reviews-routing.module";
import { ReviewsComponent } from "./reviews.component";
import { Ng5SliderModule } from "ng5-slider";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SharedModule } from "src/app/shared/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { JwPaginationModule } from 'jw-angular-pagination';
@NgModule({
  declarations: [ReviewsComponent],
  imports: [
    CommonModule,
    ReviewsRoutingModule,
    Ng5SliderModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    JwPaginationModule,
    InfiniteScrollModule,
  ],
})
export class ReviewsModule {}
