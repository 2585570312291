import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';
import { HttpService } from '../../../../services/http/http.service';
import { sweetAlert } from '../../../../shared/function/toaster';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.less']
})
export class NewsLetterComponent implements OnInit {

  @ViewChild('newsLetterData') myForm: NgForm;
  @ViewChild('newsLetterMobData') myForm1: NgForm;
  loading = false;
  constructor(
    public http: HttpService,
    public analyticsService:GoogleAnalyticsService,
  ) { }

  ngOnInit(): void {
  }

  SendLetter(value) {
    this.loading = true
  
    
    this.http.post('/user/newsletter', JSON.parse(' { "email" : "' + value.email + '" } ')).subscribe(
      data => {
        this.loading = false
        sweetAlert('success', 'Thank You For Subscribing');
        this.myForm.reset();
        this.analyticsService.commonEvents('Onclick',{'event_category': 'Subscribing News Letter',
            'event_label': 'Subscribing News Letter',
            'value': 'Subscribing News Letter'});
      },
      error => {
        this.loading = false
        sweetAlert('error', error.message);
      });
  }

  SendLetterMob(value) {
    this.loading = true
    this.http.post('/user/newsletter', JSON.parse(' { "email" : "' + value.email + '" } ')).subscribe(
      data => {
        this.loading = false
        sweetAlert('success', 'Thank You For Subscribing');
        // this.myForm.reset();
      },
      error => {
        this.loading = false
        sweetAlert('error', error.message);
      });
  }

}