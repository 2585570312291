import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomiseRoutingModule } from './customise-routing.module';
import { ThemedCakesComponent } from './themed-cakes/themed-cakes.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    ThemedCakesComponent
  ],
  imports: [
    CommonModule,
    CustomiseRoutingModule,
    NgxSkeletonLoaderModule
  ]
})
export class CustomiseModule { }
