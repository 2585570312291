import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Observable, Subscription } from "rxjs/Rx";
import "rxjs/add/observable/interval";
import { HttpService } from "../../../services/http/http.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { sweetAlert } from "src/app/shared/function/toaster";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { HttpParams } from "@angular/common/http";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from "angularx-social-login";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import Swal from "sweetalert2";
import { LoginSignupComponent } from "../login-signup/login-signup.component";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { debounceTime } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
declare var $: any;
@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.less"],
})
export class VerificationComponent implements OnInit {
  @ViewChild("verificationModal") verificationModal: ElementRef;
  @ViewChild("ngOtpInput2") ngOtpInput2: any;
  @ViewChild("ngOtpInput") ngOtpInput: any;

  public signUpLoader: Boolean = false;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: "",
    inputStyles: {
      width: "45px",
      height: "30px",
      "border-bottom": "1px solid #ced4da",
      "border-top": 0,
      "border-right": 0,
      "border-left": 0,
      "border-radius": 0,
    },
  };

  loading = false;
  mobileNumber: string;
  faceBookSub!: Subscription;
  googleSub!: Subscription;
  signUpError = "";
  loginError = "";
  socialUser: SocialUser;
  isLoggedin: boolean;
  users: Object;
  public loginIN: any = localStorage.getItem("loginIN") == "1" ? true : false;
  public Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  otpControl: FormControl;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public addCartService: AddCartService,
    private socialAuthService: SocialAuthService,
    public analyticsService: GoogleAnalyticsService,
    public loginSignupComponent: LoginSignupComponent,
    private memoryService: MemoryService,
    private authenticationService: AuthenticationService
  ) {
    this.otpControl = new FormControl("");
  }

  ngOnInit(): void {
    this.addCartService.getTimer().subscribe((data) => {
      if (data) {
        this.myTimer();
        this.mobileNumber = sessionStorage.getItem("otpMobile");
      }
    });
    this.timer = false;

    this.authenticationService.loggedOut$.subscribe((res) => {
      this.loginIN = null;
      this.ngOtpInput2.setValue("");
    });
  }

  resetOTP() {
    this.ngOtpInput2.setValue("");
    this.ngOtpInput.setValue("");
    this.timer = false;
    this.countDown.unsubscribe();
    this.count = 30;
  }

  countDown;
  count;
  timer = true;
  myTimer() {
    this.count = 30;
    this.timer = true;
    this.countDown = Observable.timer(0, 1000).subscribe((x) => {
      console.log(x);

      this.count = this.count - 1;
      if (this.count == 0) {
        this.timer = false;
        this.countDown.unsubscribe();
      }
    });
  }

  loginSignUP() {
    localStorage.removeItem("loginIN");
    $("#verification").modal("hide");
    $("#verifications").modal("hide");
    $("#loginSignup").modal("show");
  }
  loginSignUP1() {
    localStorage.removeItem("loginIN");
    $("#verifications").modal("hide");
    $("#loginSignup").modal("show");
  }
  resendOtp() {
    this.loading = true;
    this.http
      .get("/auth/login/resend?phoneNumber=" + sessionStorage.getItem("otpMobile"))
      .subscribe(
        (data) => {
          this.loading = false;
          sweetAlert("success", "Success");
          this.timer = true;
          this.myTimer();
        },
        (error) => {
          this.loading = false;
          if (error.message) {
            sweetAlert("error", error.message);
          } else {
            sweetAlert("error", "Try Again");
          }
        }
      );
  }

  otp;
  onOtpChange(otp) {
    this.otp = otp;
  }

  form;
  user;
  onSubmit() {
    if (this.otp.length != 6) {
      sweetAlert("error", "Enter valid six digit otp number");
      return;
    }
    this.form = this.formBuilder.group({
      userName: [sessionStorage.getItem("otpMobile")],
      password: [this.otp],
    });
    this.loading = true;
    this.http.post("/auth/signup/confirm", this.form.value).subscribe(
      (data) => {
        this.loading = false;
        this.user = data;
        sweetAlert("success", "OTP Verified Successfully");
        localStorage.setItem("loginIN", "1");
        localStorage.setItem("currentUser", JSON.stringify(this.user));
        this.memoryService.memorydata
          .pipe(debounceTime(300))
          .subscribe((res: any) => {
            $("#verification").modal("hide");
            $("#verifications").modal("hide");
            if (checkNull(res)) {
              this.memoryService.memoryEmitter$.emit(res);
            } else {
              this.memoryService.memoryEmitter$.emit("");
            }
          });
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Invalid OTP");
      }
    );
  }
  onSubmit1() {
    if (this.otp.length != 6) {
      sweetAlert("error", "Enter valid six digit otp number");
      return;
    }
    this.form = this.formBuilder.group({
      userName: [sessionStorage.getItem("otpMobile")],
      password: [this.otp],
    });
    this.loading = true;
    let obj = {
      otp: this.otp,
      phoneNumber: sessionStorage.getItem("otpMobile"),
    };
    let params = new HttpParams();
    for (let key in obj) {
      params = params.set(key, obj[key]);
    }
    this.http.post("/auth/otpVerify", params).subscribe(
      (data) => {
        this.loading = false;
        this.user = data;
        this.loginIN = true;
        sweetAlert("success", "OTP Verified Successfully");
        localStorage.setItem("loginIN", "1");
        localStorage.setItem("currentUser", JSON.stringify(this.user));
        this.memoryService.memorydata
          .pipe(debounceTime(300))
          .subscribe((res: any) => {
            console.log(res, "in the verification component");
            this.ngOtpInput2.setValue("");
            $("#verifications").modal("hide");
            this.memoryService.memoryEmitter$.emit(res ?? "");
          });
      },
      (error) => {
        this.loading = false;
        sweetAlert("error", "Invalid OTP");
      }
    );
  }

  loginWithGoogles() {
    this.analyticsService.commonEvents("login", {
      event_category: "Login With Google",
      event_label: "Login With Google",
      value: "Login With Google",
    });
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.loading = true;
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(() => {
        this.googleSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;
            this.isLoggedin = User != null;
            if (this.socialUser != undefined) {
              let obj = {
                emailOrMobile: this.socialUser?.email,
                provider: (this.socialUser?.provider).toLowerCase(),
                providerAccessToken: this.socialUser?.authToken,
              };

              let params = new HttpParams();
              for (let key in obj) {
                params = params.set(key, obj[key]);
              }
              this.http.post("/auth/socialLogin", params).subscribe(
                (data) => {
                  this.users = data;
                  localStorage.setItem("loginIN", "1");
                  this.loginIN = true;
                  localStorage.setItem("currentUser", JSON.stringify(this.users));
                  // location.reload();
                  this.memoryService.memorydata
                    .pipe(debounceTime(300))
                    .subscribe((res: any) => {
                      if (checkNull(res)) {
                        this.memoryService.memoryEmitter$.emit(res);
                      }
                    });
                  this.loading = false;
                },
                (error) => {
                  this.signUpError = error.message;
                  this.Toast.fire({
                    icon: "error",
                    title: this.signUpError,
                  });
                  this.signUpLoader = false;
                  this.loading = false;
                }
              );
              this.addCartService.setTimer("someValue");
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  loginWithFacebooks() {
    if (this.faceBookSub) {
      this.faceBookSub.unsubscribe();
    }
    if (this.googleSub) {
      this.googleSub.unsubscribe();
    }
    this.analyticsService.commonEvents("login", {
      event_category: "Login With Face Book",
      event_label: "Login With Face Book",
      value: "Login With Face Book",
    });
    this.loading = true;
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(() => {
        this.faceBookSub = this.socialAuthService.authState.subscribe(
          (User) => {
            this.socialUser = User;
            this.isLoggedin = User != null;
            if (this.socialUser != undefined) {
              let obj = {
                emailOrMobile: this.socialUser?.email,
                provider: (this.socialUser?.provider).toLowerCase(),
                providerAccessToken: this.socialUser?.authToken,
              };

              let params = new HttpParams();
              for (let key in obj) {
                params = params.set(key, obj[key]);
              }
              this.http.post("/auth/socialLogin", params).subscribe(
                (data) => {
                  this.users = data;
                  this.loginIN = true;
                  localStorage.setItem("loginIN", "1");
                  localStorage.setItem("currentUser", JSON.stringify(this.users));
                  // location.reload();
                  this.memoryService.memorydata
                    .pipe(debounceTime(300))
                    .subscribe((res: any) => {
                      if (checkNull(res)) {
                        this.memoryService.memoryEmitter$.emit(res);
                      }
                    });
                  this.loading = false;
                  this.addCartService.setTimer("someValue");
                },
                (error) => {
                  this.signUpError = error.message;
                  this.Toast.fire({
                    icon: "error",
                    title: this.signUpError,
                  });
                  this.signUpLoader = false;
                  this.loading = false;
                }
              );
              this.addCartService.setTimer("someValue");
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  }
  changeNumber() {
    $("#verifications").modal("hide");
    $("#verification").modal("hide");
    $("#loginSignup").modal("show");
    this.analyticsService.commonEvents("Onclick", {
      event_category: "Open Login With Mobile No Popup Form",
      event_label: "Open Login With Mobile No Popup Form",
      value: "Open Login With Mobile No Popup Form",
    });
  }
}
