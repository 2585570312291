<section class="customise">
    <div class="container-fluid ">
        <div class="row pxd-5">
            <div id="owl-customise" class="owl-carousel" *ngIf="getdata">
                <div class="item " *ngFor="let item of getdata">
                    <!--banner desktop view-->
                    <div class="desktop-view">
                        <div class="d-flex justify-content-between row">
                            <div class="col-lg-6 col-md-6 text-center">
                                <img src="{{item.image}}" width="350" style="border-radius: 10px;" alt="" srcset="">
                            </div>
                            <div class="col-lg-6 col-md-6 content" *ngIf="item.bannerDetails">
                                <h3 *ngIf="item.bannerDetails.heading"  >
                                    {{item.bannerDetails.heading | titlecase }}
                                </h3>
                                <p *ngIf="item.bannerDetails.content">
                                    {{item.bannerDetails.content}}
                                </p>
                                <a *ngIf="item.bannerDetails.buttonName && item.isClickable == true"
                                target="_blank" href="{{item?.bannerUrl ? item?.bannerUrl : ''}}" class="btn">
                                    {{item.bannerDetails.buttonName | titlecase }}
                                </a>
                          
                              
                            </div>
                        </div>
                    </div>
                    <!--banner mobile view-->
                    <div class="mobile-view ">
                        <div class=" row text-center">
                            <div class="col-lg-12" *ngIf="item.bannerDetails">
                                <h3 class="text-center" *ngIf="item.bannerDetails.heading">
                                    {{item.bannerDetails.heading | titlecase }}
                                </h3>
                                <p *ngIf="item.bannerDetails.content">
                                    {{item.bannerDetails.content}}
                                </p>
                              
                                <img src="{{item.image}}" class="mt-4 " style="border-radius: 10px;" alt="" srcset="">
                                <a *ngIf="item.bannerDetails.buttonName && item.isClickable == true"
                                target="_blank" href="{{item?.bannerUrl ? item?.bannerUrl : ''}}" class="btn " style=" margin-top: 20px;">
                                {{item.bannerDetails.buttonName | titlecase }}
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>