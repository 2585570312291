<!--==================== Disktop notification ==============================-->
<span id="some-element"></span>
<div *ngIf="!(userService?.loggedIn$|async) && headerNotify?.content?.header" id="notification"
   class="alert alert-primary mb-0 text-center alert-color py- 3 line-height" role="alert">
   <!--Welcome to PUPPY's BAKERY, Signup and Get Exciting Welcome offer-->
   {{ headerNotify?.content?.header }}
   <a href="javascript:;" *ngIf="headerNotify?.content?.details?.showDefaultButton == false" (click)="
   child.openModel('signUp');;
   analyticsService.commonEvents('Onclick', { 
   event_category: 'Open SignIn/SignUp Popup Form',
   event_label: 'Open SignIn/SignUp Popup Form',
   value: 'Open SignIn/SignUp Popup Form'
   })
   " class="ml-4">Signup Now</a>
   <a href="{{ headerNotifyDetail.Link }}" target="_blank" *ngIf="
   headerNotifyDetail?.showDefaultButton == false &&
   headerNotifyDetail?.ButtonName &&
   headerNotifyDetail?.Link
   " class="ml-4" (click)="
   analyticsService.commonEvents('Onclick', {
   event_category: 'Open' + headerNotifyDetail.ButtonName + 'page',
   event_label: 'Open' + headerNotifyDetail.ButtonName + 'page',
   value: 'Open' + headerNotifyDetail.ButtonName + 'page'
   })
   ">{{ headerNotifyDetail.ButtonName }}</a>
   <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      &times;
   </button>
</div>


<!--===================== End Disktop notification ===============================-->
<!-- ----Scroll------------->
<!-- for Future use. Its for the pan India cake section -->
<!-- <div class="row pxd-5 owl-header">
   <div id="owl-testimonial" class="owl-carousel">
     <div class="item header-title" style="text-align: center">
       <a
         routerLink="/category/Pan_India"
         (click)="
           analyticsService.commonEvents('Onclick', {
             event_category: 'View Pan India Product List',
             event_label: 'View Pan India Product List',
             value: 'View Pan India Product List'
           })
         "
         ><h5>
           Our cream cakes are available for delivery within Madurai, Sivakasi &
           Dindigul; All other goodies, from brownies to plum cakes, ships Pan
           India
         </h5>
       </a>
     </div>
     <div class="item header-title" style="text-align: center">
       <a
         routerLink="/category/Pan_India"
         (click)="
           analyticsService.commonEvents('Onclick', {
             event_category: 'View Pan India Product List',
             event_label: 'View Pan India Product List',
             value: 'View Pan India Product List'
           })
         "
         ><h5>
           Our cream cakes are available for delivery within Madurai, Sivakasi &
           Dindigul; All other goodies, from brownies to plum cakes, ships Pan
           Indiap
         </h5>
       </a>
     </div>
   </div>
   </div> 
   
   <!------Scroll----------- -->
<nav class="navbar header-nav navbar-expand-xl navbar-light bg-custom">
   <!--========================== header mobile view port ==============================================-->
   <span class="menu mobile-view-only add_style">
      <i class="fa fa-bars" (click)="openSideNav($event)" aria-hidden="true"></i>
   </span>
   <a href="#" class="text-center mobile-view-only logo_header"><img src="assets/images/logo/logo_1.png"
         class="rounded-circle" alt="" style="height: 50px; width: 50px; object-fit: contain" /></a>
   <div class="navbar menu-nav mobile-view-only mobile-view-header">
      <div class="d-flex">
         <div class="cart   mb_view_cart">
            <a (click)="clickWishlist()">
               <img class="w18" src="assets/images/icons/cart.svg" alt="" srcset="" />
               <span class="badge badge-pill badge-primary">{{(userService?.wishList$|async)}} </span>
            </a>
         </div>
         <div class="wish-list position_unset mb_view_cart">
            <a (click)="clickCartBtn()" class="flex_set">
               <!--wishlist Mobile-->
               <!-- <img src="assets/images/icons/Wwishlist Newww.svg" alt="" srcset="" /> -->
               <img class="w18" src="assets/images/icons/wish_list.svg" alt="" srcset="" />
               <span class="badge badge-pill">{{(userService?.cartItem$|async)}}</span>
            </a>
         </div>
      </div>
   </div>
   <div class="
      input-group
      mt-1
      mobile-search
      custom-radius-input
      mobile-responce-hide mobile-view-only
      ">
      <div class="input-group custom-search">
         <div class="ng-autocomplete">
            <ng-autocomplete formControlName="searchControl" #globalAutoComplete [data]="data" [searchKeyword]="keyword"
               [initialValue]="initialValue" placeholder="Search cakes, desserts, gifts etc..." [focusFirst]="true"
               (inputChanged)="searchProducts($event)" (inputFocused)="onFocused($event)" [itemTemplate]="itemTemplate"
               [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <span class="input-group-append">
               <div class="input-group-text bg-transparent custom-radius-icon">
                  <i class="fa fa-search"></i>
               </div>
            </span>
            <ng-template #itemTemplate let-item>
               <div class="">
                  <a (keyup.enter)="selectEvent($event)" [innerHTML]="item.productName"></a>
               </div>

            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
               <!----------------  Static design for search dropdown based upon the product categories  ------------>

               <global-search-template (navigatedEvent)="eventEmitted($event)"
                  *ngIf="searchedData?.category?.length>0||searchedData?.product?.length>0"
                  [searchedData]="searchedData"></global-search-template>

               <div class='nodata' *ngIf="searchedData?.category?.length==0&&searchedData?.product?.length==0"
                  [innerHTML]="'No Cakes, Desserts, Gifts Found...!'"></div>
            </ng-template>
         </div>
      </div>
   </div>
   <!--==========================End header mobile view port ==============================================-->
   <!--==========================End header Desktop view port ==============================================-->
   <div class="collapse navbar-collapse navbar-pad desktop-header">
      <ul class="navbar-nav mr-auto nav-left active-index">
         <li class="nav-item color_red">
            <div class="input-group custom-search">
               <div class="ng-autocomplete">
                  <!-- (keyup.enter)="selectEventEnter($event)" -->
                  <ng-autocomplete [data]="data" [searchKeyword]="keyword" class="w50" [debounceTime]="400"
                     placeholder="Search cakes, desserts, gifts etc..." (selected)="selectEvent($event)"
                     (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)"
                     [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                  </ng-autocomplete>
                  <span class="input-group-append">
                     <div class="input-group-text bg-transparent custom-radius-icon">
                        <i class="fa fa-search"></i>
                     </div>
                  </span>
                  <ng-template #itemTemplate let-item>
                     <a (keyup.enter)="selectEvent($event)" [innerHTML]="item.productName"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                     <div [innerHTML]="'search website'"></div>
                     <div class='nodata' [innerHTML]="'No Data Found!...!'"></div>
                  </ng-template>
               </div>
            </div>
         </li>
         <li class="nav-item dropdown dropdown-bubble location-drop-down">
            <div class="btn-group new_btn_grp">
               <button *ngIf="!pincodeVal" type="button" class="form-control btn btn-default dropdown-toggle"
                  data-toggle="modal" data-target="#locationModal">
                  <img class="" src="assets/images/location_new.png" alt="" srcset="" />&nbsp; Select Delivery Location
                  &nbsp; <span class="caret"></span>
               </button>
               <button *ngIf="pincodeVal" type="button" class="form-control btn btn-default dropdown-toggle"
                  data-toggle="modal" data-target="#locationModal">
                  <img class="mb-2" src="assets/images/icons/location.png" alt="" srcset="" />&nbsp; {{ pincodeVal }}
                  &nbsp;
                  <span class="caret"></span>
               </button>
            </div>
         </li>
      </ul>
      <div class="logo">
         <img style="cursor: pointer" (click)="indexPage()" src="assets/images/logo/logo_1.png" class="rounded-circle"
            alt="" style="height: 110px; width: 110px; object-fit: contain" />
      </div>
      <ul class="navbar-nav ml-auto nav-right active-index">
         <li class="nav-item desktop-cart dp_cart">
            <a href="javascript:;" class="flex_set" (click)="clickCartBtn()">
               <img src="assets/images/icons/cart.svg" class="" alt="" srcset="" />
               <span class="badge badge-light">{{(userService?.cartItem$|async)}}</span>
               <!--Cart-->
            </a>
         </li>
         <li class="nav-item desktop-cart dp_cart">
            <!--wishlist Desktop-->
            <a (click)="clickWishlist()" class="wish-list flex_set">
               <img src="assets/images/icons/wish_list.svg" alt="" srcset="" />
               <span class="badge badge-pill">{{(userService?.wishList$|async)}}</span>
            </a>
         </li>
         <li class="position_relative" routerLink="/category/Pan_India" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'View Pan India Product List',
            event_label: 'View Pan India Product List',
            value: 'View Pan India Product List'
            })
            " class="pan_india nav-item">
            <img class="tag" src="assets/images/icons/tag.svg" alt="image">
            <img class="path_1" src="assets/images/path_1.png" alt=""><img class="path_2"
               src="assets/images/path_2.png">Pan
            India
         </li>
         <li class="nav-item nav-item dropdown dropdown-bubble need-drop-down">
            <div class="btn-group">
               <button type="button" class="form-control btn btn-default w166" (click)="deliveryIn('sameDay')">
                  <img class="" src="assets/images/clcok_new.png" alt="" /><span>Need Today ?</span>
               </button>
            </div>
         </li>
         <li class="nav-item dropdown dropdown-bubble-profile">
            <a class="profil_style" *ngIf="!child.loginIN" href="javascript:;" (click)="
            child.openModel('login');
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open SignIn/SignUp Popup Form',
            event_label: 'Open SignIn/SignUp Popup Form',
            value: 'Open SignIn/SignUp Popup Form'
            })
            ">
               <img src="assets/images/icons/awatar.svg" alt="">&nbsp; Login
            </a>
            <a style="display: flex; align-items: center; color: #2D2D2D; font-weight: 500; letter-spacing: 0.5px; height: 50px; margin-right: 30px;"
               *ngIf="(userService?.loggedIn$|async)" class="dropdown-toggle profile_view" id="navbarDropdown"
               role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <img style="width: 30px;" class="rounded-circle" (error)="handle()"
                  [src]="checkNull(userImage) ? userImage :(userService?.currentUser$|async).gender=='male' ? '/assets/images/avatar.jpg':'/assets/images/fAvatar.png'" alt="">&nbsp;
               {{checkNull((userService?.currentUser$|async).firstName)? (userService?.currentUser$|async)?.firstName <=
                  7 ? (userService?.currentUser$|async)?.firstName :
                  (userService?.currentUser$|async)?.firstName?.slice(0, 7):'Profile'}} </a>
                  <!------------------------------------ profile  dropdown------------------------------------------->
                  <div *ngIf="(userService?.loggedIn$|async)"
                     class="dropdown-menu dropdown-profile dropdown-menu-right">
                     <div class="profile-drop">
                        <div class="text-center" *ngIf="checkNull((userService?.currentUser$|async)?.firstName)">
                           <img (error)="handle()" [src]="checkNull(userImage) ?userImage :(userService?.currentUser$|async).gender=='male' ? '/assets/images/avatar.jpg':'/assets/images/fAvatar.png'"
                              class="rounded-circle" alt="" style="height: 70px; width: auto" />
                        </div>
                        <div class="mt-2 text-center" *ngIf="checkNull((userService?.currentUser$|async).firstName)">
                           <strong>{{(userService?.currentUser$|async).firstName}}</strong>
                        </div>
                        <div class="mt-2 text-left" *ngIf="checkNull((userService?.currentUser$|async).phoneNumber)">
                           <div class="dashed m-3">
                              <div class="mail-mobile">
                                 <p>
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;{{
                                    (userService?.currentUser$|async)?.email }}
                                 </p>
                              </div>
                              <div class="mail-mobile" *ngIf="(userService?.currentUser$|async).phoneNumber">
                                 <p class="m-0">
                                    <img src="assets/images/logo/phone-call.svg" class="mb-2" alt=""
                                       srcset="" />&nbsp;&nbsp;&nbsp;
                                    +91 {{ (userService?.currentUser$|async)?.phoneNumber }}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div>
                        <a class="dropdown-item" [routerLink]="['/profile']" href="#" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open My profile Page',
                  event_label: 'Open My profile Page',
                  value: 'Open My profile Page'
                  })
                  ">My profile</a>
                        <a class="dropdown-item" [routerLink]="['/profile', 'address']" href="#" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open My Address Page',
                  event_label: 'Open My Address Page',
                  value: 'Open My Address Page'
                  })
                  ">My Address</a>
                        <a class="dropdown-item" [routerLink]="['/profile', 'order']" href="#" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open My Order Page',
                  event_label: 'Open My Order Page',
                  value: 'Open My Order Page'
                  })
                  ">My Order</a>
                        <a class="dropdown-item" [routerLink]="['/profile', 'loyalty']" href="#" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open My Reward Points Page',
                  event_label: 'Open My Reward Points Page',
                  value: 'Open My Reward Points Page'
                  })
                  ">My Reward Points</a>
                        <a class="dropdown-item" [routerLink]="['/profile', 'remainder']" href="#" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open My Reminder Page',
                  event_label: 'Open My Reminder Page',
                  value: 'Open My Reminder Page'
                  })
                  ">My Reminder</a>
                        <a class="dropdown-item text-center logout" href="javascript:;" (click)="child.logout()"
                           (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Logout My Account',
                  event_label: 'Logout My Account',
                  value: 'Logout My Account'
                  })
                  ">Logout</a>
                     </div>
                  </div>
                  <!-------------------------------- End profile  dropdown---------------------------------------------------->
         </li>
      </ul>
   </div>
   <!--==========================End header Desktop view port ==============================================-->
</nav>
<div class="bg_overlay">
   <li class="position_relative" routerLink="/category/Pan_India" (click)="
      analyticsService.commonEvents('Onclick', {
      event_category: 'View Pan India Product List',
      event_label: 'View Pan India Product List',
      value: 'View Pan India Product List'
      })
      " class="pan_india mobile_paninida nav-item">
      <img class="me3" src="assets/images/logo/new_svg.svg">Pan India
   </li>
   <li class="nav-item nav-item marker_none dropdown dropdown-bubble need-drop-down">
      <div class="btn-group">
         <button type="button" class="mobileview_needtoday form-control btn btn-default"
            (click)="deliveryIn('sameDay')">
            <img class="" src="assets/images/clcok_new.png" alt="" /><span class="fs_14">Need Today ?</span>
         </button>
      </div>
   </li>
</div>
<b class="screen-overlay"></b>
<!--Nav bar Start-->
<nav id="navbar_main" class="
   mobile-offcanvas
   plrtb
   ml-50
   navbar navbar-expand-xl navbar-light
   bg-white-main3
   ">
   <!--=================================== Mobile View Side Nav bar Start ===============================================-->
   <div class="accordion-menu-header mobile-view-only">
      <i class="fa fa-user-circle-o"></i>
      <span class="pl-3" *ngIf="checkNull((userService?.currentUser$|async)?.firstName)">Hi {{
         (userService?.currentUser$|async)?.firstName
         }}</span>
      <span *ngIf="!checkNull((userService?.currentUser$|async)?.firstName)" class="pl-3">Hi Guest</span>
   </div>
   <div class="accordion-menu-sub-header mobile-view-only">
      <div class="row m-0">
         <div class="col-6 location">
            <a class="dropdown-toggle nav-link need " href="#" *ngIf="!pincodeVal" data-toggle="modal"
               data-target="#locationModal">
               <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; Select
               Delivery
            </a>
            <a class="dropdown-toggle nav-link location-text" href="#" *ngIf="pincodeVal" data-toggle="modal"
               data-target="#locationModal">
               <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
               {{ pincodeVal }}
            </a>
         </div>
         <div class="col-6 needtody">
            <a class="nav-link closeSideNav need" (click)="deliveryIn('sameDay')" href="javascript: void(0)"
               id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
               <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
               <span>Need Today ?</span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
               <li><a class="time" (click)="deliveryIn('')"> Delivery In</a></li>
               <li>
                  <a class="time" (click)="deliveryIn('2hour')"> Delivery In 2Hrs</a>
               </li>
               <li>
                  <a class="time" (click)="deliveryIn('6hour')">Delivery In 6Hrs</a>
               </li>
               <li>
                  <a class="time" (click)="deliveryIn('sameDay')">Need Today ? </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
   <ul class="list-group mobile-list-group mobile-view-only" id="accordion">
      <li routerLinkActive="active" [routerLink]="['/']" class="panel closeSideNav panel-default list-group-item"
         (click)="
      analyticsService.commonEvents('Onclick', {
      event_category: 'Open Home Page',
      event_label: 'Open Home Page',
      value: 'Open Home Page'
      })
      ">
         Home
      </li>
      <a [routerLink]="['/profile']" routerLinkActive="active" class="closeSideNav" (click)="
      analyticsService.commonEvents('Onclick', {
      event_category: 'Open My Profile Page',
      event_label: 'Open My Profile Page',
      value: 'Open My Profile Page'
      })
      ">
         <li *ngIf="child.loginIN" class="panel panel-default list-group-item">
            My Profile
         </li>
      </a>
      <ng-container *ngFor="let data of allCategory; let i = index">
         <a *ngIf="data.isSubCategory" data-toggle="collapse"
            (click)="openAccordion($event.target); redirectCategory(data)" class="accordion-item"
            data-parent="#accordion" [attr.href]="'#collapse' + i">
            <li class="panel panel-default list-group-item">
               {{ data.name }}
               <span class="pull-right"><i class="accordion-arrow fa fa-angle-right"></i></span>
            </li>
         </a>
         <a *ngIf="!data.isSubCategory">
            <!--no sub only parent-->
            <li class="panel panel-default list-group-item">
               <a routerLinkActive="active" href="javascript:void(0)"
                  (click)="setImage(data.img); redirectCategory(data)">{{
                  data.name }}</a>
            </li>
         </a>
         <ul class="list-group collapse in" [attr.id]="'collapse' + i">
            <li class="list-group-item" *ngFor="let subData of data.subCategory">
               <div>
                  <a routerLinkActive="active" (click)="setImage(subData.imagePath); redirectCategory(subData)"
                     class="m-4">{{
                     subData.categoryName }}</a>
               </div>
            </li>
         </ul>
      </ng-container>
      <ng-container>
         <li routerLink="/category/Pan_India" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'View Pan India Product List',
            event_label: 'View Pan India Product List',
            value: 'View Pan India Product List'
            })
            " class="panel panel-default list-group-item"><a href="">Pan India</a></li>
      </ng-container>
      <a data-toggle="collapse" (click)="openAccordion($event.target)" data-parent="#accordion" class="accordion-item"
         href="#collapseContact" aria-expanded="true">
         <li class="panel panel-default list-group-item">
            About Us
            <span class="pull-right"><i class="accordion-arrow fa fa-angle-right"></i></span>
         </li>
      </a>
      <ul class="list-group in collapse" id="collapseContact">
         <li class="list-group-item">
            <a routerLinkActive="active" class="closeSideNav" [routerLink]="['/page/ourStory']" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Our Story Page',
            event_label: 'Open Our Story Page',
            value: 'Open Our Story Page'
            })
            ">
               Our Story</a>
         </li>
         <li class="list-group-item">
            <a routerLinkActive="active" class="closeSideNav" [routerLink]="['/page/pressRelease']" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Press Release Page',
            event_label: 'Open Press Release Page',
            value: 'Open Press Release Page'
            })
            ">
               Press Release</a>
         </li>
         <li class="list-group-item">
            <a routerLinkActive="active" class="closeSideNav" [routerLink]="['/page/contactUs']" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Contact Us Page',
            event_label: 'Open Contact Us Page',
            value: 'Open Contact Us Page'
            })
            ">
               Contact Us</a>
         </li>
         <li class="list-group-item">
            <a routerLinkActive="active" class="closeSideNav" [routerLink]="['/blogs']" (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Blog Page',
            event_label: 'Open Blog Page',
            value: 'Open Blog Page'
            })
            ">
               Blog</a>
         </li>
      </ul>
      <li class="panel panel-default" *ngIf="!checkNull((userService?.currentUser$|async)?.firstName)">
         <a href="javascript:;" (click)="
            child.openModel('login');;
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Login Popup Form',
            event_label: 'Open Login Popup Form',
            value: 'Open Login Popup Form'
            })
            ">
            Login
         </a>
         /
         <a href="javascript:;" (click)="
            child.openModel('signUp');;
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Signup Popup Form',
            event_label: 'Open Signup Popup Form',
            value: 'Open Signup Popup Form'
            })
            ">
            Signup</a>
      </li>
      <li class="panel panel-default" *ngIf="checkNull((userService?.currentUser$|async)?.firstName)">
         <a href="javascript:;" (click)="
            child.logout();
            analyticsService.commonEvents('Onclick', {
            event_category: 'Logout My Account',
            event_label: 'Logout My Account',
            value: 'Logout My Account'
            })
            ">Logout</a>
      </li>
   </ul>
   <!--=================================== Mobile View Side Nav bar End ===============================================-->
   <!--========================================Start Desktop Nav Bar View =======================================================-->
   <div class="collapse navbar-collapse navbar-pad desktop-view pb-0">
      <ul class="navbar-nav nav mr-auto">
         <li class="nav-item">
            <a class="pl-0" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']"
               (click)="
            analyticsService.commonEvents('Onclick', {
            event_category: 'Open Home page',
            event_label: 'Open Home page',
            value: 'Open Home page'
            })
            ">
               <!-- <i class="fa fa-home"></i> -->
               Home</a>
         </li>
         <ng-container *ngFor="let data of leftCategory; let i = index">
            <li *ngIf="data.isSubCategory" class="nav-item sub-categery">
               <div class="dropdown">
                  <a role="button" (click)="mainCategory(data?.name,data)">
                     {{ data.name }}&nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i></a>
                  <div class="dropdown-content">
                     <ng-container *ngFor="let subData of data.subCategory">
                        <div class="btn-group dropright" style="display: block;">
                           <a [routerLink]="['/category/' + subData.categoryName + '-' + data.categoryId ]"
                              routerLinkActive="active"
                              (click)="setImage(subData.imagePath); redirectCategory(subData);">
                              {{ subData.categoryName }}</a>
                        </div>
                     </ng-container>
                  </div>
               </div>
            </li>
            <li *ngIf="!data.isSubCategory" class="nav-item">
               <a routerLinkActive="active" [routerLink]="['/category/' ]"
                  (click)="setImage(data.img); redirectCategory(data)" href="javascript:void(0)">
                  {{ data.name
                  }}</a>
            </li>
            <!-- <li><a routerLink="/category/Cakes-3" routerLinkActive="active">Product</a></li>
               <li><a routerLink="/about" routerLinkActive="active">About Us</a></li>
               <li><a routerLink="/contact" routerLinkActive="active">Contect Us</a></li> -->
         </ng-container>
      </ul>
      <ul class="navbar-nav nav ml-auto">
         <!--more btn-->
         <ng-container *ngFor="let data of rightCategory; let i = index">
            <li *ngIf="data.isSubCategory" class="nav-item sub-categery">
               <div class="dropdown">
                  <a routerLinkActive="active" (click)="setImage(data.img); redirectCategory(subData)">
                     {{ data.name }}&nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i></a>
                  <div class="dropdown-content">
                     <ng-container *ngFor="let subData of data.subCategory">
                        <div class="btn-group dropleft" style="display: block">
                           <a routerLinkActive="active" (click)="
                              setImage(subData.imagePath); redirectCategory(subData)
                              ">
                              {{ subData.categoryName }}
                           </a>
                        </div>
                     </ng-container>
                  </div>
               </div>
            </li>
            <li *ngIf="!data.isSubCategory" class="nav-item">
               <a routerLinkActive="active" (click)="setImage(data.img); redirectCategory(data)"
                  href="javascript:void(0)">{{
                  data.name}}</a>
            </li>
         </ng-container>
         <li class="nav-item sub-categery" *ngIf="category.length">
            <div class="dropdown">
               <a [routerLink]="['/category']" href="#" (click)="
               analyticsService.commonEvents('Onclick', {
               event_category: 'Open Category Based Product page',
               event_label: 'Open Category Based Product page',
               value: 'Open Category Based Product page'
               })
               ">
                  More &nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i></a>
               <div class="dropdown-content sub-categery-2">
                  <ng-container *ngFor="let data of category; let i = index">
                     <div *ngIf="data.isSubCategory" class="dropdown">
                        <a [routerLink]="['/category']" href="#" (click)="
                        analyticsService.commonEvents('Onclick', {
                        event_category: 'Open Category Based Product page',
                        event_label: 'Open Category Based Product page',
                        value: 'Open Category Based Product page'
                        })
                        ">
                           {{ data.name }} &nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i></a>
                        <div class="dropdown-content-1">
                           <ng-container *ngFor="let subData of data.subCategory">
                              <a routerLinkActive="active" (click)="
                              setImage(subData.imagePath);
                              analyticsService.commonEvents('Onclick', {
                              event_category: 'Open Category Based Product page',
                              event_label: 'Open Category Based Product page',
                              value: 'Open Category Based Product page'
                              })
                              " [routerLink]="['/category', subData.categoryId]"
                                 *ngFor="let subData of data.subCategory">{{
                                 subData.categoryName }}
                              </a>
                           </ng-container>
                        </div>
                     </div>
         <li *ngIf="!data.isSubCategory" class="nav-item">
            <a routerLinkActive="active" (click)="setImage(data.img); redirectCategory(data)">{{ data.name }}</a>
         </li>
         </ng-container>
   </div>
   </div>
   </li>
   <li class="nav-item sub-categery-aboutUs">
      <div class="dropdown">
         <a routerLinkActive="active">
            About Us &nbsp;<i aria-hidden="true" class="fa fa-angle-down"></i>
         </a>
         <div class="dropdown-content dropdown-menu-right">
            <a routerLinkActive="active" [routerLink]="['/page/ourStory']" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open Our Story page',
                  event_label: 'Open Our Story page',
                  value: 'Open Our Story page'
                  })
                  ">
               Our Story</a>
            <a routerLinkActive="active" [routerLink]="['/page/pressRelease']" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open Press Release page',
                  event_label: 'Open Press Release page',
                  value: 'Open Press Release page'
                  })
                  ">
               Press Release</a>
            <a routerLinkActive="active" [routerLink]="['/page/contactUs']" (click)="
                  analyticsService.commonEvents('Onclick', {
                  event_category: 'Open Contact Us page',
                  event_label: 'Open Contact Us page',
                  value: 'Open Contact Us page'
                  })
                  ">
               Contact Us</a>
            <a routerLinkActive="active" [routerLink]="['/blogs']" (click)="
               analyticsService.commonEvents('Onclick', {
               event_category: 'Open Blog page',
               event_label: 'Open Blog page',
               value: 'Open Blog page'
               })
               ">Blog</a>
         </div>
      </div>
   </li>
   </ul>
   </div>
   <!--========================================End Desktop Nav Bar View =======================================================-->
</nav>
<!--End nav bar -->
<!-- get location Model -->
<div class="modal fade" id="locationModal" data-backdrop="static" tabindex="-1" role="dialog"
   aria-labelledby="locationModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="locationModalLabel">
               Select Your Delivery Location
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="closelocationModal($event)">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="input-group pincode-search">
               <div class="ng-autocomplete" style="border: 1px solid #ced4da">
                  <ng-autocomplete #auto [data]="pinCodeData" [searchKeyword]="pinCodeKeyword"
                     placeholder="Enter pincode or city" (selected)="selectPinCode($event)"
                     (inputCleared)="inputCleared($event)" (inputChanged)="onChangePinCodeSearch($event)"
                     [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate1">
                  </ng-autocomplete>
                  <ng-template #itemTemplate1 let-item>
                     <a [innerHTML]="item.searchKey"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate1 let-notFound>
                     <div [innerHTML]="notFound"></div>
                  </ng-template>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-custom" data-dismiss="modal" (click)="closelocationModal($event)">
               Close
            </button>
         </div>
      </div>
   </div>
</div>
<!--Modal get custom date and time -->
<div class="modal fade" id="dateTimeModal" tabindex="-1" role="dialog" aria-labelledby="dateTimeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="dateTimeModalLabel">
               Select Your Delivery Date And Time
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <form #dateTImeData="ngForm" id="myForm"
            (ngSubmit)="dateTImeData.form.valid && getDateTime(dateTImeData.value)">
            <div class="modal-body">
               <div class="form-group row">
                  <label for="staticEmail" class="col-sm-2 col-form-label">Date</label>
                  <div class="col-sm-10">
                     <input type="date" class="form-control" [ngClass]="{
                  'is-invalids': dateTImeData.submitted && date.invalid
                  }" name="date" required ngModel #date="ngModel" id="date" [min]="disableDate" placeholder="Date" />
                     <div id="error" *ngIf="dateTImeData.submitted && date.invalid" class="invalid-feedback d-block">
                        <div *ngIf="date.errors.required" class="invalid-feedback d-block">
                           Date is required
                        </div>
                     </div>
                  </div>
               </div>
               <div class="form-group row">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Time</label>
                  <div class="col-sm-10">
                     <input type="time" class="form-control" name="time" ngModel #time="ngModel" placeholder="Time" />
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="submit" class="btn btn-custom">Save</button>
            </div>
         </form>
      </div>
   </div>
</div>
<!-- login -->
<app-login-signup #child></app-login-signup>
<!--need help-->
<app-need-help></app-need-help>
<!-- GET OFFER -->
<app-offer *ngIf="!(userService?.loggedIn$|async)&& openOffer"></app-offer>
<!--reset pasword-->
<app-reset-password></app-reset-password>
<!--addons-->
<app-addons-popup></app-addons-popup>
<!--user verification-->
<app-verification></app-verification>