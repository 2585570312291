import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/configuration/app.constants';
import { HttpService } from 'src/app/services/http/http.service';
import { AddCartService } from 'src/app/services/http/add-cart.service'
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';
declare var $: any

@Component({
  selector: 'app-classic-cakes',
  templateUrl: './classic-cakes.component.html',
  styleUrls: ['./classic-cakes.component.less']
})
export class ClassicCakesComponent implements OnInit {

  loginIN
  imgPth
  constructor(
    private actRoute: ActivatedRoute,
    public http: HttpService,
    public addCartService: AddCartService,
    public analyticsService:GoogleAnalyticsService
  ) { }

  isBannerImgAvailable
  getId
  bannerImg
  orderId
  loader = true
  ngOnInit(): void {

    this.loginIN = localStorage.getItem("loginIN");
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.orderId = localStorage.getItem('orderId');

    this.actRoute.paramMap.subscribe(params => {
      this.getdata = []
      let getIdUrl = params.get('id')
      this.analyticsService.pageView("View classic cakes",`cakes/${getIdUrl}`,window.location.origin);
      let IsSeoUrl = getIdUrl.includes("-");
      if (IsSeoUrl) {
        this.getId = getIdUrl.split("-").splice(-1);
      } else {
        this.getId = params.get('id');   //get category id
      }

      this.bannerImg = localStorage.getItem("_Bimg_");
      this.bannerImg = "assets/images/classic_cakes/banner.jpg"
      this.isBannerImgAvailable = false;
      if (this.bannerImg) {
        this.isBannerImgAvailable = true;
      }
      this.get();
    });


  }

  getdata
  getdata2
  //get all product list under this category
  get() {
    this.loader = true
    this.http.get('/user/product/category/' + this.getId).subscribe(
      data => {
        this.loader = false
        this.getWishList()
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
      
        this.getdata.map(y => {
          y.products.map(x => {
            x.seoUrl = x.productName.replace(/\s+/g, '-').toLowerCase() + '-' + x.productId
            let type = typeof x.productDetails
            if (type == "string") x.productDetails = JSON.parse(x.productDetails)
            if (x.productImages) {
              x.productImages = x.productImages[0].split(',')[0]
            } else {
              x.productImages = '';
            }

            //calculate discount price
            x.productDetails.pricing.map(y => {
              let decPercentage = y.discount / 100
              let save = y.price * decPercentage; y.save = save; y.disPrice = y.price - save
            });
          });
        });
        setTimeout(() => {
          this.refreshSlider();
        }, 200);
      },
      error => {
        this.loader = false
      });
  }


  categoryName
  noProduct
  product
  productData(data) {
    this.product = data
    if (this.product.length != 0) {
      if (!this.categoryName) {
        this.categoryName = this.product[0].categoryName
      }
      this.noProduct = false;
    } else {
      this.noProduct = true
    }
  }

//get current user wishlist
  wishList
  getWishList() {
    if (this.loginIN == 1) {
      this.http.get('/user/wishlist').subscribe(
        data => {
          this.wishList = data;
          if (!this.wishList.content) {
            this.wishList = JSON.parse('{ contnt : [] }')
          }
          this.getdata.map(y => {
            y.products.map(x => {
              if (this.wishList.content.filter(s => s.productID == x.productId).length >= 1) {
                x.like = true;
              } else {
                x.like = false;
              }
            });
          });
        });
    }
  }


//add or remove wishlist product
  likeData
  like(id, e) {

    if (this.loginIN != 1) {
      $('#loginSignup').modal('show');
      return;
    }
    let addWishListData:any;
    $(e).addClass("fa-circle-o-notch fa-spin disabled");
    this.http.post('/user/wishlist/' + id, '').subscribe(
      data => {
        this.likeData = data;
        this.addCartService.setWishList('Refresh Wishlist');
        if (this.likeData.status == 1034 || this.likeData.message == 'Wishlist Added ') {
          this.getdata.map(y => {
            y.products.map(x => {
              if (x.productId == id) {
                x.like = true;
                addWishListData = {
                  id:x.productId,
                  name:x.productName,
                  brand:"Puppy's",
                  category:x.categoryName,
                  quantity:1
                }
              }
            });
          });
        }
        if (this.likeData.status == 1036 || this.likeData.message == 'Wishlist removed ') {
          this.getdata.map(y => {
            y.products.map(x => {
              if (x.productId == id) {
                x.like = false;
              }
            });
          });
        }
      },
      error => {

      });
      if(this.likeData.status == 1034 || this.likeData.message == 'Wishlist Added '){
        this.analyticsService.addToWishlist({currency:'INR',items:addWishListData});
      }
  }
 
//refresh slider
  refreshSlider() {
    $(".owl-whiteForest").owlCarousel({
      items: 4,
      dots: false,
      nav: false,
      responsive: {
        0: {
          items: 2,
          navigation: true,
          margin: 10,
          nav: true,
          navText: ['']
        },
        801: {
          items: 4,
          navigation: true,
          nav: true,
          margin: 20,
          navText: ['']
        }
      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true
    });
  }


}
