import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { AppConstants } from "../../../../configuration/app.constants";
import { HttpService } from "../../../../services/http/http.service";
import { AddCartService } from "src/app/services/http/add-cart.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { MemoryService } from "src/app/services/memory-services/memory.service";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";
import { UserService } from "src/app/services/memory-services/user.service";
declare var $: any;

@Component({
  selector: "app-tending",
  templateUrl: "./tending.component.html",
  styleUrls: ["./tending.component.less"],
})
export class TendingComponent implements OnInit {
  @Input() public wishList: any;
  loading = false;
  imgPth;
  orderId;
  loginIN;
  getdata: any = [];

  constructor(
    public http: HttpService,
    public addCartService: AddCartService,
    public analyticsService: GoogleAnalyticsService,
    private cd: ChangeDetectorRef,
    private memoryService: MemoryService,
    private auth: AuthenticationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.get();
    this.orderId = localStorage.getItem("orderId");
    this.loginIN = localStorage.getItem("loginIN");

    this.memoryService.memoryEmitter$.subscribe((res: any) => {
      this.loginIN = localStorage.getItem("loginIN");
      this.getWishList();
      if (res.component == "trending") {
        if (checkNull(res?.functionName)) {
          setTimeout(() => {
            this[res?.functionName](res.params.p1, res.params.p2);
          }, 1500);
        }
      }
    });

    this.auth.loggedOut$.subscribe((res) => {
      this.orderId = localStorage.getItem("orderId");
      this.loginIN = localStorage.getItem("loginIN");
       this.getWishList();
    });
  }

  //get trending cakes list

  get() {
    this.http
      .post("/admin/product/search", JSON.parse('{ "listing": "trendingCake" }'))
      .subscribe(
        (data:any) => {
          this.getdata = data.filter((ele) => ele.status == 1);
          this.getWishList();
          this.getdata = data;
          this.getdata.map((x) => {
            x.seoUrl =
              x.productName.replace(/\s+/g, "-").toLowerCase() + "-" + x.productId;
            let type = typeof x.productDetails;
            if (type == "string") x.productDetails = JSON.parse(x.productDetails);
            if (x.productImages) {
              x.productImages = x.productImages[0].split(",")[0];
            } else {
              x.productImages = "";
            }

            //calculate discount price
            x.productDetails.pricing.map((y) => {
              let decPercentage = y.discount / 100;
              let save = y.price * decPercentage;
              y.save = save;
              y.disPrice = y.price - save;
            });
          });

          //refresh slider
          setTimeout(() => {
            this.refreshSlider();
          }, 200);
        },
        (error) => {}
      );
  }

  //get wishlist data
  getWishList() {
    if (this.loginIN == 1) {
      this.http.get("/user/wishlist").subscribe((data) => {
        this.wishList = data;
        if (!this.wishList.content) {
          this.wishList = JSON.parse("{ contnt : [] }");
        }
        //map wishlist product id & trending product id
        this.getdata.map((x) => {
          if (
            this.wishList.content.filter((s) => s.productID == x.productId).length >=
            1
          ) {
            x.like = true;
          } else {
            x.like = false;
          }
        });
      });
    } else {
      console.log('emitted while logging out -----------> trending cakes');
      this.getdata.map((x) => 
      x.like = false
    );
    }
  }

  //add or remove wishlist
  likeData;
  like(id, e) {
    if (this.loginIN != 1) {
      this.memoryService.setMemory({
        functionName: "like",
        params: { p1: id, p2: e },
        component: "trending",
      });
      $("#loginSignup").modal("show");
      return;
    }
    let addWishListData: any;
    $(e).addClass("");
    this.http.post("/user/wishlist/" + id, "").subscribe(
      (data) => {
        this.loading = false;
        this.likeData = data;
        this.addCartService.setWishList("Refresh Wishlist");
        this.userService.setWishlist();
        if (
          this.likeData?.status == 1034 ||
          this.likeData?.message == "Wishlist Added "
        ) {
          this.getdata.map((x) => {
            if (x.productId == id) {
              x.like = true;
              addWishListData = {
                id: x.productId,
                name: x.productName,
                brand: "Puppy's",
                category: x.categoryName,
                quantity: 1,
              };
            }
          });
          this.cd.markForCheck();
        }
        if (
          this.likeData?.status == 1036 ||
          this.likeData?.message == "Wishlist removed "
        ) {
          this.getdata.map((x) => {
            if (x.productId == id) {
              x.like = false;
            }
          });
          this.cd.markForCheck();
        }
      },
      (error) => {
        this.loading = false;
        this.cd.markForCheck();
      }
    );
    if (this.likeData?.status == 1034 || this.likeData?.message == "Wishlist Added ") {
      this.analyticsService.addToWishlist({
        currency: "INR",
        items: addWishListData,
      });
    }
  }

  //refresh slider

  refreshSlider() {
    $("#owl-trending").owlCarousel({
      items: 4,
      dots: false,
      nav: false,
      loop: true,
      touchDrag: true,
      pullDrag: true,
      autoplay: true,
      autoplayTimeout: 9000,
      // smartSpeed:3000,
      responsive: {
        0: {
          items: 2,
          navigation: true,
          margin: 5,
          nav: true,
          navText: [""],
        },
        801: {
          items: 4,
          navigation: true,
          nav: true,
          margin: 20,
          navText: [""],
        },
      },
      navigation: true,
      lazyLoad: true,
      pagination: false,
      scrollPerPage: true,
    });
  }
}
