import { Component } from "@angular/core";
import { GoogleAnalyticsService } from "./services/google-analytics-ecommerce/google-analytics-ecommerce.service";
import { UserService } from "./services/memory-services/user.service";
import { NavigationError, NavigationStart, Router } from "@angular/router";
import { AppConstants } from "./configuration/app.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent {
  title = "puppys-web";

  constructor(
    private analyticsService: GoogleAnalyticsService,
    private userservice: UserService,
    private router: Router
  ) {}
  onActivate(e, outlet) {
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
    this.userservice.setCurrentUser();
    this.userservice.getAllPincodes();
    this.analyticsService.pageView("Puppy's Web Load", "", window.location.origin);
    // this.findRouter();
  }

  findRouter() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationError) {
        let params = event?.url.split("/");

        console.log(event.url, "this is the errrror URL");

        if (params[1] == "sharable") {
          let id = this.checkNull(params[2]) ? params[2]?.split("?")[0] : "";
          if (this.checkNull(id)) {
            let baseUrl =
              AppConstants.SERVER_CONFIG.USE_URL == "STAGING"
                ? AppConstants.SERVER_CONFIG.STAGING
                : AppConstants.SERVER_CONFIG.PRODUCTION;
            let url = `${baseUrl}/share/${id}?isBot=false`;
            console.log(
              url,
              "http://localhost:4200/sharable/008f5d22-60ae-405b-92e1-e99f8549e0f4"
            );

            location.href = url;
          } else {
            if (
              ["Android", "iOS", "Windows Phone"].includes(this.detectDeviceType())
            ) {
              location.href = "https://mblpuppysbakery.technogenesis.in/";
            } else {
              // this.router.navigate(['/']);
            }
          }
        }
      }
    });
  }

  checkNull(value?: any) {
    if (value != "" && value != undefined && value != null) {
      return true;
    } else {
      return false;
    }
  }

  detectDeviceType(): string {
    const userAgent = navigator.userAgent;

    // Check for common user agent strings to identify device type
    if (userAgent.match(/Android/i)) {
      return "Android";
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return "iOS";
    } else if (userAgent.match(/Windows Phone|Windows Mobile/i)) {
      return "Windows Phone";
    } else if (userAgent.match(/Windows NT/i)) {
      return "Windows PC";
    } else if (userAgent.match(/Macintosh/i)) {
      return "Mac";
    } else if (userAgent.match(/Linux/i)) {
      return "Linux";
    } else {
      return "Unknown";
    }
  }
}
