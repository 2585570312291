<app-loader *ngIf="loading"></app-loader>
<!-- Forgot password -->
<div class="modal fade" id="forgotPassword" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 ptb-4 pr-o" style="padding-right:0px;">
                        <div class="row  pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pt-4 pb-4">
                                    <div class="col-lg-7">
                                        <h4>Forgot Password</h4>
                                    </div>

                                </div>
                                <p style="line-height: 1.3;">
                                    Use your Email ID
                                </p>
                                <div class="row pt-3" [formGroup]="resetForm">
                                    <div class="col-lg-12">
                                        <div class="input-group text-item">
                                            <input type="text" class="form-control" formControlName="email"
                                                placeholder="Email id" autocomplete="new-email">
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="submitted && form.email.errors" class="invalid-text mt-n3"><br>
                                    <div style="color:red;" *ngIf="form.email.errors.required">Email id is required
                                    </div>
                                    <div style="color:red;" *ngIf="form.email.errors.email && form.email.touched">Enter
                                        Valid Email</div>
                                </div>

                                <!-- <p *ngIf="reseterrorMsg" style="color:red;">{{reseterrorMsg}}</p> -->
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12  mt-63">
                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="reset()">Continue</button>
                                        <button type="button" (click)="loginemail()"
                                            class="btn btn-outline-custom btn-lg btn-block mt-14">Login
                                            with
                                            Email</button>

                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="loginemail()">Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 image-reset">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header">
                            <h3>Hello, Friend!</h3>
                            <p>Enter your personal details and start <br>journey with us</p>
                            <button type="button" class="btn btn-custom " (click)="signInSingUp()">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reset password -->
<div class="modal fade" id="resetPassword" tabindex="-1" role="dialog" aria-labelledby="resetPasswordLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 col-sm-7 ptb-4">
                        <div class="row  pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pt-4 pb-4">
                                    <div class="col-lg-8">
                                        <h4>Forgot Password</h4>
                                    </div>
                                    <div class="col-lg-5 text-right">
                                        <a href="#" class="fa fa-facebook"></a>
                                        <a href="#" class="fa fa-google"></a>
                                    </div>
                                </div>
                                <p style="line-height: 1.3;">
                                    Or use your Email ID / Mobile Number
                                </p>
                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="New password"
                                                autocomplete="new-password" name="password">
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p>
                                <div class="row pt-4">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="Confirm password"
                                                autocomplete="new-password" name="password">
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12  mt-63">
                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="reset()">Continue</button>
                                        <button type="button" (click)="loginemail()"
                                            class="btn btn-outline-custom btn-lg btn-block mt-14">Login
                                            with
                                            Email</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header" style="top:260px;">
                            <h3>Hello, Friend!</h3>
                            <p>Enter your personal details and start <br>journey with us</p>
                            <button type="button" class="btn btn-custom " (click)="loginemail()">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reset password -->
<div class="modal fade" id="resetPassword" tabindex="-1" role="dialog" aria-labelledby="resetPasswordLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 col-sm-7 ptb-4">
                        <div class="row pt-4 pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pb-4">
                                    <div class="col-lg-7">
                                        <h4>Reset Password</h4>
                                    </div>
                                    <div class="col-lg-5 text-right">
                                        <a class="fa fa-facebook"></a>
                                        <a class="fa fa-google"></a>
                                    </div>
                                </div>
                                <p style="line-height: 1.3;">
                                    Or use your Email ID / Mobile Number
                                </p>
                                <div class="row pt-4">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="New password"
                                                autocomplete="new-password" name='new-password'>
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p>
                                <div class="row pt-4">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="Confirm password"
                                                autocomplete="new-password" name='new-password'>
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12 mt-43">
                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="submit()">Submit</button>
                                        <button type="button" (click)="loginemail()"
                                            class="btn btn-outline-custom btn-lg btn-block mt-14">Login
                                            with
                                            Email</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header">
                            <h3>Hello, Friend!</h3>
                            <p>Enter your personal details and start <br>journey with us</p>
                            <button type="button" class="btn btn-custom " (click)="loginemail()">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>