import moment from "moment";
export class CurrentTimeAndDate {
  dateTime;
  nextDate;

  date;
  day;
  month;
  year;

  time;
  hour;
  mins;
  sec;

  constructor() {
    this.dateTime = moment().format("YYYY-MM-DD HH:mm");

    this.nextDate = moment().add(1, "days");

    this.date = moment().format("YYYY-MM-DD");
    this.time = moment().format("HH:mm");

    this.day = Number(moment().format("DD"));
    this.month = Number(moment().format("MM"));
    this.year = Number(moment().format("YYYY"));

    this.hour = Number(moment().format("HH"));
    this.mins = Number(moment().format("mm"));
    this.sec = Number(moment().format("ss"));
  }

  addBakingHours(bakingTime) {
    return moment().add(Number(bakingTime), "hours");
  }
}
