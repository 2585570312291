import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { PressReleaseComponent } from "./components/press-release/press-release.component";
import { TeamsAndPolicyComponent } from "./teams-and-policy.component";
import { BranchDetailsComponent } from "./components/branch-details/branch-details.component";
import { OurStoryComponent } from "./components/our-story/our-story.component";
import { ShippingPolicyComponent } from "./components/shipping-policy/shipping-policy.component";
import { CancellationPolicyComponent } from "./components/cancellation-policy/cancellation-policy.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {
    path: "terms-and-policy",
    component: TeamsAndPolicyComponent,
  },
  {
    path: "cancellation-policy",
    component: CancellationPolicyComponent,
  },
  {
    path: "shipping-policy",
    component: ShippingPolicyComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "pressRelease",
    component: PressReleaseComponent,
  },
  {
    path: "contactUs",
    component: ContactUsComponent,
  },
  {
    path: "ourBranches",
    component: BranchDetailsComponent,
  },
  {
    path: "ourStory",
    component: OurStoryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsAndPolicyRoutingModule {}
