import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// import { $ } from 'protractor';
declare var $: any;
@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.less"],
})
export class CarouselComponent implements OnInit {
  @Input() public imgDate: any;
  constructor(private router: Router) {}
  getdata;

  ngOnChanges() {
    //get data from home component
    if (this.imgDate) {
      this.getdata = this.imgDate
        .filter((element) => element.bannerType == "index_slider_1")
        .sort(function (a, b) {
          return a?.sortOrder - b?.sortOrder;
        });
    }
  }

  ngOnInit() {
    setTimeout(() => {
      const myCarousel = document.querySelector("#carouselExampleCaptions");
      const carousel = $(myCarousel).carousel({
        interval: 5000,
      });
    }, 10);
  }

  bannerClick(click, id) {
    if (click == true && id) {
      this.router.navigate(["/category", id]);
    }
  }

  public skeletonLoader: Boolean = true;
  checkImageLoad() {
    this.skeletonLoader = false;
  }

  prev() {}
}
