import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { AppConstants } from '../../configuration/app.constants';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})

export class HomeComponent implements OnInit {
  imageData
  loginIN
  public loading: boolean = false;
  public skeletonLoader: Boolean = true

  constructor(
    public http: HttpService,
    private analyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.skeletonLoader = true
    this.loginIN = localStorage.getItem("loginIN");
    this.get();
    this.analyticsService.pageView("View Home Page","home",window.location.origin);
  }

 //get all banner and send to other components
  get() {
    this.http.get('/admin/banner').subscribe(
      data => {
        this.imageData = data;
        this.imageData.map(x => {
          if (x.image) {
            x.image = AppConstants.SERVER_CONFIG.IMG_URL + x.image;
          }
        });
        this.loading = false
        setTimeout(() => {
          this.skeletonLoader = false
        }, 1000);

      },
      error => {
        this.loading = false
        this.skeletonLoader = false
      });
  }

}

