<!-- Modal -->
<app-loader *ngIf="loading"></app-loader>

<div class="modal fade" data-keyboard="false" data-backdrop="static" id="addonsPopupModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Make it Extra Special! </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--modal body-->
      <div class="modal-body">
        <h6 class="text-center mb-20">Frequently Bought Add-Ons</h6>
        <div class="frequently-card">
          <div *ngIf="getdata">
            <!--loop Gift product start-->
            <div class="row">
              <div class="col-md-4" *ngFor="let item of getdata">
                <div class="card box-shadow mb-20">
                  <a (click)="redirectProduct(item.productId)">
                    <img class="card-img-top" src="{{imgPth+item.productImages}}" data-holder-rendered="true" />
                  </a>
                  <div class="card-body">
                    <div class="row d-flex justify-content-between align-items-center">
                      <div class="col-lg-10 col-sm-10">
                        <p class="name">{{item.productName | titlecase }}</p>
                        <!--price section-->
                        <p class="price">
                          <span class="dis-price"> Rs.{{item?.productDetails?.pricing[0]?.disPrice}}
                          </span>
                          <del *ngIf="item?.productDetails?.pricing[0].discount != ''">
                            Rs.{{item?.productDetails?.pricing[0]?.price}} </del>
                        </p>
                      </div>
                      <!--Product Quantity Plus & Minus Button-->
                      <div class="col-lg-2 col-sm-12 mt-2 mbottom-10">
                        <i class="fa fa-minus min-crt" [ngClass]="{'disbl-minus': item.quantity === 0 }"
                          (click)=clickCrtMinus(item) aria-hidden="true"></i>
                        <input type="text" class="txtbx-crt" value="{{item.quantity}}" autocomplete="off">
                        <i class="fa fa-plus plus-crt" (click)=clickCrtPlus(item) aria-hidden="true"></i>
                        <br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End loop Gift product-->
          </div>
        </div>
      </div>
      <!--End Modal Body-->
      <div class="modal-footer">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">

              <div class="col-lg-2 price-detail pl-0">
                <h6>price details</h6>
              </div>
              <div class="col-lg-8">
                <div class="row mt-5 " style="margin-bottom: 10px;">
                  <div class="me-2 add-base-column">
                    <div class="add-base-item  mright-4">
                      <p class="fnt-11">1 Base Item</p>
                      <span class="fnt-11"><i class="fa fa-inr fnt-11" aria-hidden="true"></i> {{ mainProductPrice
                        }}</span>
                    </div>
                    <span class="ml-10 fnt-11">+</span>
                  </div>
                  <div class="me-2  add-base-column">
                    <div class="add-base-item  mright-4">
                      <p class="fnt-11">{{cartData.length - 1 }} Add-ons</p>
                      <span><i class="fa fa-inr fnt-11" aria-hidden="true"></i> {{addonPrice}}</span>
                    </div>
                    <span class="ml-10 fnt-11">+</span>
                  </div>
                  <div class="me-2  add-base-column">
                    <div class="add-base-item   mright-4">
                      <p class="fnt-11">Delivery</p>
                      <span class="fnt-11">{{deliveryCharge==0 ? "Free" : "₹"+deliveryCharge }}</span>
                    </div>
                  </div>
                  <div class="me-2">
                    <div class="add-base-column">
                      <span class="ml-10">=</span>
                      <div class="ml-10 total-column">
                        <p class="fnt-11">Total</p>
                        <span class="all-total fnt-11" style="color:red;"><i class="fa fa-inr tot-inr fnt-11"
                            aria-hidden="true"></i>
                          {{ totalPrice+deliveryCharge}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="row justify-content-end">
          <div class="col-lg-12addon-btn">
            <button type="button" *ngIf="cartData.length == 1" (click)="submitAddToCart()" class="mdl-footer">Continue
              Without Addons</button>
            <button type="button" *ngIf="cartData.length > 1" (click)="submitAddToCart()" class="mdl-footer">Continue
              With
              {{cartData.length - 1 }}
              Addons</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>