<button type="button" id class="btn btn-primary" id="openModal" #openModal hidden="true" data-toggle="modal"
  data-target="#needHelp"></button>

<div class="modal fade" id="needHelp" tabindex="-1" role="dialog" aria-labelledby="needHelpLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <a href="#" class="text-center"><img src="assets/images/logo/logo_1.png" class="rounded-circle" alt="" style="height: 115px;width: 115px;object-fit: contain;" /></a>
        <button type="button" class="close" (click)="closeNeedHelp()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">

        <h4>Need Help?</h4>
        <p class="mt-4">
          Our Cake Expert are waiting to hear your interesting ideas,
          please contact below number for any new order or enquiries.
        </p>

        <p><a class="phn-number" href="tel:{{mobile ? mobile : ''}}"> <i class="fa fa-phone"></i>&nbsp;&nbsp;+91 {{mobile}}</a></p>

        <button (click)="logout()" type="button" class="btn btn-custom">
          Continue to Logout
        </button>

      </div>
    </div>
  </div>
</div>