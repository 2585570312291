<section>
    <div class="container-fluid text-center">
        <h4> <img src="assets/images/icons/Layer_10.png" alt="">
            What’s Baking in Puppy’s?
            <img src="assets/images/icons/Layer_11.png" alt="">
        </h4>
        <div class="row mt-5 pxd-5">
            <div class="col-lg-6 col-md-6 padding " *ngFor="let item of getdata | slice:0:4;">
                <ngx-skeleton-loader *ngIf="skeletonLoader"
                    [theme]="{ height: '235px', width: '90%', 'margin-bottom': '0' }" count="1" appearance="line">
                </ngx-skeleton-loader>
                <a target="_blank" href="{{item?.bannerUrl ? item?.bannerUrl : ''}}" (click)="bannerClick()"><img class="custum-h" src="{{item.image}}" height="100%" (load)="checkImageLoad()" width="100%" alt="" ></a>
                
            </div>
        </div>
    </div>
</section>