import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { sweetAlert } from 'src/app/shared/function/toaster';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-ecommerce/google-analytics-ecommerce.service';

@Component({
  selector: 'app-themed-cakes',
  templateUrl: './themed-cakes.component.html',
  styleUrls: ['./themed-cakes.component.less']
})
export class ThemedCakesComponent implements OnInit {

  constructor(
    public http: HttpService,
    private actRoute: ActivatedRoute,
    public analyticsService:GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.actRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.analyticsService.pageView("View Themed cakes",`customise/themed?id=${params['id']}`,window.location.origin);
        this.get(params['id']);
      }
    });
  }

  getdata: any = []
  showLoader
  //get subcategory data
  get(id) {
    this.showLoader = true
    this.http.get('/admin/category/sub?id=' + id).subscribe(
      (data: any) => {
        this.getdata = data.content;
        this.showLoader = false
      },
      error => {
        this.showLoader = false
        sweetAlert('error', 'Try Again');
      });
  }



}
