import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProductDetailsRoutingModule } from "./product-details-routing.module";
import { ProductDetailsComponent } from "./product-details.component";
import { RecommendedComponent } from "./components/recommended/recommended.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { FeedBackComponent } from "./components/feed-back/feed-back.component";
import { BarRatingModule } from "ngx-bar-rating";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { NumbersOnlyDirective } from "./numbersonly.directive";


@NgModule({
  declarations: [
    ProductDetailsComponent,
    RecommendedComponent,
    FeedBackComponent,
    DateAgoPipe,
    NumbersOnlyDirective
  ],
  imports: [
    CommonModule,
    ProductDetailsRoutingModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    BarRatingModule,
    IvyCarouselModule,
  ],
  exports:[NumbersOnlyDirective]
})
export class ProductDetailsModule {}
