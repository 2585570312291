<app-loader *ngIf="loading"></app-loader>
<section style="background-color: #FFFFFF;">
    <div class="container-fluid ">
        <h4 class="text-center"> <img src="assets/images/icons/Layer_10.png" alt="">
            Trending Cakes
            <img src="assets/images/icons/Layer_11.png" alt="">
        </h4>

        <div class="row mt-4 pxd-5">
            <div id="owl-trending" class="owl-carousel" *ngIf='getdata'>
                <div class="item" *ngFor="let item of getdata">
                    <div class="card mb-3 box-shadow">
                        <div class="ribbon" *ngIf="item?.isPanIndiaProduct"><span>PAN India</span></div>
                        <a routerLink="/details/{{item.seoUrl}}" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Trending Cake detail Page',
                        'event_label': 'Trending Cake product Details',
                        'value': 'Route Trending Cake detail page'})">
                        <img  *ngIf=" !item.productImages?.includes('mp4')"
                        class="card-img-top"
                        data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
                        alt="Thumbnail [100%x225]"
                        src="{{ imgPth + item.productImages }}"
                        data-holder-rendered="true"
                      />
                      <video *ngIf="item.productImages.includes('mp4')" class="card-img-top" [src]="imgPth + item.productImages" > </video>
                      <!-- <img *ngIf="item.productImages?.includes('mp4')"
                      class="card-img-top"
                      data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
                      alt="Thumbnail [100%x225]"
                      src="{{ imgPth + item.designImages[0]}}"
                      data-holder-rendered="true"
                    /> -->
                        </a>
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="btn-group pr-name">
                                    <p class="name">{{item.productName}}</p>
                                </div>
                                <small *ngIf="(userService.loggedIn$|async)" class="text-muted"> <a (click)="like(item.productId, $event.target)">
                                        <i *ngIf="!item.like" class="fa fa-heart-o"></i>
                                        <i *ngIf="item.like == true" class="fa fa-heart"></i> </a>
                                </small>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">

                                <!--Pricing Section-->
                                <div class="btn-group">
                                    <p class="price">
                                        <span class="dis-price"> Rs.{{item?.productDetails?.pricing[0]?.disPrice?.toFixed(2)}}
                                        </span>
                                        <del  *ngIf="item?.productDetails?.pricing[0]?.discount>0">
                                            Rs.{{item?.productDetails?.pricing[0]?.price}} </del>
                                        <span *ngIf="item?.productDetails?.pricing[0]?.discount" class="dis-off">
                                            {{item?.productDetails?.pricing[0]?.discount}}%off</span>
                                    </p>
                                </div>

                                <small class="text-muted d-none"> <i *ngIf="item.paymentDetails.cod == true"
                                        class="fa fa-shopping-cart"
                                        aria-hidden="true"></i> </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
        <div class="col-lg-12 " *ngIf="getdata">
            <h5 class="pull-right" *ngIf='getdata.length > 4'>
                <a [routerLink]="['/category/trendingCake']" (click)="analyticsService.commonEvents('Onclick',{'event_category': 'Route Trending Cake Page',
                'event_label': 'Show All Trending Cake',
                'value': 'Route Trending Cake page'})" style="color: #E591A7; text-decoration: none;">
                    Show All
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
            </h5>
        </div>

    </div>

</section>