import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpService } from "../http/http.service";

interface PageMemory {
  functionName: string;
  params?: any;
  component?: any;
  date?: any;
}

@Injectable({
  providedIn: "root",
})
export class MemoryService {
  public memoryEmitter$: EventEmitter<any> = new EventEmitter();

  private memoryData$: BehaviorSubject<any | null> = new BehaviorSubject(null);

  public memorydata: Observable<any> = this.memoryData$.asObservable();

  constructor(
    private service : HttpService
  ) {}

  setMemory(data: PageMemory | null) {
    this.memoryData$.next(data);
  }



}
