import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "discount",
})
export class DiscountPipe implements PipeTransform {
  transform(value: any, percent: any) {
    if (percent > 0) {
      return (Number(value) * Number(percent)) / 100;
    } else {
      return 0;
    }
  }
}

@Pipe({
  name: "actual",
})
export class ActualPipe implements PipeTransform {
  transform(value: any, percent?: any) {
    if (percent > 0) {
      return Number(Number(value) - (Number(value) / 100) * Number(percent)).toFixed(
        2
      );
    } else {
      return Number(value).toFixed(2);
    }
  }
}

@Pipe({
  name: "numberpipe",
})
export class NumberPipe implements PipeTransform {
  transform(value: any, num?: any) {
    return Number(value).toFixed(num ?? 2);
  }
}
