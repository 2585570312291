import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../../../services/http/http.service";
declare var $: any;

@Component({
  selector: "app-insta",
  templateUrl: "./insta.component.html",
  styleUrls: ["./insta.component.less"],
})
export class InstaComponent implements OnInit {
  getdata = [];

  constructor(public http: HttpService) {}

  ngOnInit(): void {
    //get all instagram id
    this.http.get("/instafeeds").subscribe((data: any) => {
      this.getdata = data.content;
      this.getdata.map((x) => {
        x.url = `https://www.instagram.com/p/${x.postId}/embed`;
      });

      console.log(this.getdata, "instagram");

      setTimeout(() => {
        this.refreshSlider();
      }, 200);
    });
  }

  //refresh slider
  refreshSlider() {
    $(document).ready(function () {
      $("#owl-insta").owlCarousel({
        items: 4,
        nav: true,
        responsive: {
          0: {
            items: 1,
            navigation: true,
            margin: 5,
            nav: true,
            dots: true,
            navText: [
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
            ],
          },
          640: {
            items: 1,
            navigation: true,
            dots: true,
            nav: true,
            margin: 20,
            navText: [
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
            ],
          },
          768: {
            items: 2,
            navigation: true,
            dots: true,
            nav: true,
            margin: 20,
            navText: [
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
            ],
          },
          1200: {
            items: 4,
            navigation: true,
            dots: true,
            nav: true,
            margin: 20,
            navText: [
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;transform: rotate(180deg);'>",
              "<img src='assets/images/icons/right_arrow_cover.png' style='width: 10px;'>",
            ],
          },
        },
        navigation: true,
        lazyLoad: true,
        pagination: false,
        scrollPerPage: true,
      });
    });
  }
}
