import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { HomeRoutingModule } from "./home-routing.module";
import { GroupComponent } from "./components/group/group.component";
import { BakingComponent } from "./components/baking/baking.component";
import { FeaturedComponent } from "./components/featured/featured.component";
import { TendingComponent } from "./components/tending/tending.component";
import { BestSellerComponent } from "./components/best-seller/best-seller.component";
import { TestimonialComponent } from "./components/testimonial/testimonial.component";
import { DessertAndWeddingComponent } from "./components/dessert-and-wedding/dessert-and-wedding.component";
import { WhatWeDoComponent } from "./components/what-we-do/what-we-do.component";
import { InstaComponent } from "./components/insta/insta.component";
import { BlogsComponent } from "./components/blogs/blogs.component";
import { CustomiseComponent } from "./components/customise/customise.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NewsLetterComponent } from "./components/news-letter/news-letter.component";
import { FormsModule } from "@angular/forms";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SafePipe } from "src/app/shared/function/safeUrl";
import { MatTabsModule } from "@angular/material/tabs";
import { DesertTestimonialComponent } from './components/desert-testimonial/desert-testimonial.component';
import { WeddingTestimonialComponent } from './components/wedding-testimonial/wedding-testimonial.component';
import { CustomTestimonialComponent } from './components/custom-testimonial/custom-testimonial.component';
import { TestimonialViewComponent } from './components/testimonial-view/testimonial-view.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { BlogViewComponent } from './components/blog-view/blog-view.component';
@NgModule({
  declarations: [
    HomeComponent,
    CarouselComponent,
    GroupComponent,
    BakingComponent,
    FeaturedComponent,
    TendingComponent,
    BestSellerComponent,
    TestimonialComponent,
    DessertAndWeddingComponent,
    WhatWeDoComponent,
    InstaComponent,
    BlogsComponent,
    CustomiseComponent,
    NewsLetterComponent,
    SafePipe,
    DesertTestimonialComponent,
    WeddingTestimonialComponent,
    CustomTestimonialComponent,
    TestimonialViewComponent,
    BlogViewComponent,

  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    InfiniteScrollModule,
    SharedModule,
    FormsModule,
    MatTabsModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [TestimonialComponent, DesertTestimonialComponent, WeddingTestimonialComponent,CustomTestimonialComponent],
})
export class HomeModule {}
